import styled from "@emotion/styled";

export const PricingCardItemContainer = styled.div`
    border: 2px solid rgb(229, 231, 235);
    background-color: #e7f1f6;
    width: 30%;
    border-radius: 20px;
    padding: 2rem 1.4rem;
    gap: 1.5rem;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        border: 2px solid #3184d1;
    }

    @media (max-width: 1024px) {
        width: 30%;
    }

    @media (max-width: 768px) {
        width: 40%;
    }
`;

export const PricingCardItemContentContainer = styled.div`
    display: grid;
    gap: 1.5rem;
`;

export const PricingCardItemContentTitle = styled.h3`
    color: #163b5e;
    text-transform: capitalize;
    font-weight: 500;
    transition: 0.4s;
`;

export const PricingCardItemContentDescription = styled.p`
    color: #163b5e;
    font-size: 0.875rem;
    margin-top: 1rem;
    line-height: 1.5rem;
`;

export const PricingCardItemContentPrice = styled.h1`
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    color: #163b5e;
`;

export const PricingCardItemContentPriceMonth = styled.span`
    color: #163b5e;
    line-height: 1.5rem;
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.4rem;
`;

export const PricingCardItemContentButtonContainer = styled.div`
    width: 100%;
    margin: 0rem auto;
`;

export const PricingCardItemContentButton = styled.button`
    width: 100%;
    padding: 0.7rem 0px;
    border: none;
    border-radius: 5px;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background-color: #163b5e;
    color: white;

    &:hover {
        background-color: #3184d1;
    }
`;

export const PricingCardItemCategoryListContainer = styled.div`
    margin-top: 1rem;
`;

export const PricingCardItemCategoryList = styled.ul`
    display: grid;
    gap: 1rem;
    padding-bottom: 5rem;
`;

export const PricingCardItemCategoryListItem = styled.li`
    display: flex;
    gap: 0.7rem;
    color: #163b5e;
    font-size: 14px;

    svg {
        color: #163b5e;
        font-size: 1.25rem;
    }
`;
