// StyledComponents.ts
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react"; // Correct import for keyframes

// Keyframe animations
export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const pulse = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
`;

export const scale = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

// Styled components
export const LoadingContainer = styled.div`
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
`;

export const ProgressBar = styled.progress`
    width: 100%;
    height: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    appearance: none;
    border-radius: 20px;
    overflow:hidden;
    background-color: #f3f3f3;

    &::-webkit-progress-bar {
        background-color: #f3f3f3;
        border-radius: 5px;
    }

    &::-webkit-progress-value {
        background-color: rgb(51, 65, 85);
        border-radius: 5px;
    }

    &::-moz-progress-bar {
        background-color: rgb(51, 65, 85);
    }
`;

export const ProgressText = styled.div`
    font-size: 1.2em;
    color: #333;
`;

export const Icon = styled.div<{ animation: string }>`
    font-size: 3rem;
    margin-top: 20px;
    color: rgb(51, 65, 85); /* Default icon color */
    transition: transform 0.3s ease, opacity 0.3s ease;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${({ animation }) => animation} 2s linear infinite;
`;


export const H2 = styled.h2``;