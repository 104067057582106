import { GuidePageTranslations } from "./types";
import featureImage from "../guides/content/placeholder_img.png";

export const deTranslations: GuidePageTranslations = {
    main: {
        title: "Anleitungen",
        searchPlaceholder: "Anleitungen suchen",
        notFoundText: "Anleitung nicht gefunden"
    },        
    sectionArea: [
        {
            slug: "creating-class",
            title: "Revolutionäre Plattform ermöglicht es Medizinstudenten, in die Rolle eines Juniorarztes zu schlüpfen",
            featureImage: featureImage,
            featureVideo:
                "https://www.youtube.com/embed/3UEOptwfkgg?si=MAhHsgA0nY7nHJk6",
            summary:
                "Diese revolutionäre Plattform ermöglicht es Medizinstudenten, in die Rolle eines Juniorarztes zu schlüpfen",
            sections: [
                {
                    id: "create-class",
                    title: "Erstellen einer Klasse",
                    paragraphs: [
                        "Diese revolutionäre Plattform ermöglicht es Medizinstudenten...",
                        "Wir suchen Freiwillige, die sich mit der Simulation beschäftigen...",
                    ],
                    videoEmbed:
                        "https://www.youtube.com/embed/3UEOptwfkgg?si=MAhHsgA0nY7nHJk6",
                },
            ],
        },
        {
            slug: "reviewing-class",
            title: "Überprüfen einer Klasse",
            featureImage: featureImage,
            featureVideo:
                "https://www.youtube.com/embed/3UEOptwfkgg?si=MAhHsgA0nY7nHJk6",
            summary:
                "Diese revolutionäre Plattform ermöglicht es Medizinstudenten, in die Rolle eines Juniorarztes zu schlüpfen",
            sections: [
                {
                    id: "reviewing-class",
                    title: "Überprüfen einer Klasse",
                    paragraphs: [
                        "Diese revolutionäre Plattform ermöglicht es Medizinstudenten...",
                        "Wir suchen Freiwillige, die sich mit der Simulation beschäftigen...",
                    ],
                    image: featureImage,
                },
            ],
        },
    ],
};
