// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { examData } from "../../../LocalStorage/patients/interface";


/*
The payload for posting an exam.
 */
export interface PostExamPayload {
    turn: number;
    patient_id: number;
    exam: string;
}


/*
The interface for the response from the post exam endpoint.
@status: The HTTP status code.
@error: The error message.
@data: The object containing the turn, exam, and examination findings.
 */
export interface PostExamResponse {
    status: number;
    error?: string;
    data?: examData;
}


export type PostExamType = (jwt: string, postExamPayload: PostExamPayload) => Promise<PostExamResponse>;



/*
The function to post an examination for a patient.

@jwt: The JWT of the user requesting the post.
@postExamPayload: The payload containing the turn, patient ID, and exam type.
@returns: The ApiResponse from the post exam endpoint.
 */
export async function postExam(jwt: string, postExamPayload: PostExamPayload): Promise<PostExamResponse> {
    const url = new PatientUrl().postExam;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<examData | string>(url, {
            turn: postExamPayload.turn,
            patient_id: postExamPayload.patient_id,
            exam: postExamPayload.exam
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });

    } catch (error) {
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 201) {
        return {
            status: response.status,
            data: response.data as examData
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
