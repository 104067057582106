import React, { useState, ChangeEvent, FormEvent, useEffect, SyntheticEvent } from 'react';
import Select from "react-select";
import {
    ConfirmPageMainForm,
    ConfirmPageMainFormTextArea,
    ConfirmPageLabel,
    ConfirmPageSubmitButton,
    ConfirmPageButtonArea,
    ConfirmPageInputFieldArea,
    ConfirmPageResetButton,
    ConfirmPageSelectParent
} from "./FormComponent.styles";

// Translation imports
import { enTranslations } from "../../translations/en";
import { deTranslations } from "../../translations/de";
import { ConsultationPageTranslations } from "../../translations/types";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";
import { useLanguage } from "../../../../../core/translations/LanguageContext";

// Constants
const TRANSLATIONS: Record<SupportedLanguage, ConsultationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};
export interface FormData {
    specialty: string;
    summary: string;
    reason: string;
}

/*
The props for the RequestComponent component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export interface RequestComponentFormProps {
    onSubmit: (data: FormData) => Promise<boolean>;
    initialFormData?: FormData;
}

/*
The RequestComponent component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export const OrderConsultationForm: React.FC<RequestComponentFormProps> = ({ onSubmit, initialFormData }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    // hook for the state of the form inputs and the state of the form submission
    const [formData, setFormData] = useState<FormData>(initialFormData || {
        specialty: "",
        summary: "",
        reason: ""
    });
    const [validationSuccess, setValidationSuccess] = useState<Boolean>(false);

    useEffect(() => {
        setFormData(initialFormData || { specialty: "", summary: "", reason: "" });
    }, [initialFormData]);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const updatedFormData = { ...formData, [e.target.name]: e.target.value };
        setFormData(updatedFormData);
        handleCharacterLimit(updatedFormData);
    };

    const handleSelectChange = (selectedOption: any) => {
        const updatedFormData = { ...formData, specialty: selectedOption.value };
        setFormData(updatedFormData);
        handleCharacterLimit(updatedFormData);
    };

    const handleCharacterLimit = (updatedFormData: FormData) => {
        const charachterLimit = 10;
        const isValid = updatedFormData.reason.length >= charachterLimit &&
            updatedFormData.summary.length >= charachterLimit &&
            updatedFormData.specialty !== "";
        setValidationSuccess(isValid);
    };

    const clearForm = () => {
        setFormData({ specialty: "", summary: "", reason: "" });
        setValidationSuccess(false);
    };

    const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validationSuccess) {
            const success = await onSubmit(formData);
            if (success) {
                clearForm();
            }
        }
    };

    return (
        <ConfirmPageMainForm onSubmit={handleFormSubmit} onReset={clearForm}>
            <ConfirmPageInputFieldArea>
                <ConfirmPageSelectParent>
                    <ConfirmPageLabel>{translations.specialtyLabel}</ConfirmPageLabel>
                    <Select
                        onChange={handleSelectChange}
                        options={translations.specialtyOptions}
                        value={translations.specialtyOptions.find(option => option.value === formData.specialty)}
                        name='specialty'
                    />
                </ConfirmPageSelectParent>
            </ConfirmPageInputFieldArea>
            <ConfirmPageInputFieldArea>
                <ConfirmPageLabel>{translations.summaryLabel}</ConfirmPageLabel>
                <ConfirmPageMainFormTextArea
                    rows={3}
                    name="summary"
                    value={formData.summary}
                    onChange={handleChange}
                />
            </ConfirmPageInputFieldArea>
            <ConfirmPageInputFieldArea>
                <ConfirmPageLabel>{translations.reasonLabel}</ConfirmPageLabel>
                <ConfirmPageMainFormTextArea
                    rows={6}
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                />
            </ConfirmPageInputFieldArea>
            <ConfirmPageButtonArea>
                <ConfirmPageResetButton type="reset">{translations.clearButtonText}</ConfirmPageResetButton>
                <ConfirmPageSubmitButton type="submit" disabled={!validationSuccess}>
                    {translations.submitButtonText}
                </ConfirmPageSubmitButton>
            </ConfirmPageButtonArea>
        </ConfirmPageMainForm>
    );
};
