import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

// Main container for the form
export const FormContainer = styled.div`
    font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

export const LoadingClass = styled.div`
    text-align: center;
p {
    color: black;
    font-weight: bold;
}
`;
export const RegistrationLoading = styled.div`
 font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    div {
        width: 100%;
    }
    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
    }
    input[type="email"],
    select,
    input[type="password"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
        font-size: 15px;
    }
    input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }

    button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
    }
    p {
        outline: none;
        color: red;
        text-align: center;
    }

    button:hover {
        background-color: #0056b3;
    }
    input:hover {
        background-color: #f2f2f2;
    }
        text-align: center;
p {
    color: black;
    font-weight: bold;
`;

// Style for input fields
export const FormGroup = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`;

// Label style
export const Label = styled.label`
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
`;

// Input fields for email and password
export const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    box-sizing: border-box;

    &:focus {
        border-color: #007bff;
    }
`;

// Submit button style
export const Button = styled.button`
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

// Error message style
export const ErrorText = styled.p`
    color: red;
    font-size: 14px;
    margin-top: 10px;
`;

// Success message container
export const SuccessContainer = styled.div`
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
    max-width: 400px;
    padding-top: 20px;
    font-family: Helvetica, Arial, sans-serif;
    display: flex;
`;

// Success message
export const SuccessText = styled.p`
    color: green;
    font-weight: bold;
`;

// Loading state container
export const LoadingContainer = styled.div`
    text-align: center;
    padding: 20px;
`;

// Link to "Get Started" after successful login
export const GetStartedLink = styled.a`
    text-decoration: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    font-size: 16px;

    &:hover {
        background-color: #0056b3;
    }
`;

// "OR" Section style
export const OrSection = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
`;

export const OrSectionLink = styled.a`
   text-decoration: none;
    color: #fff;
    cursor: pointer;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    font-size: 16px;
`;

// Link to registration page
export const RegisterLink = styled.a`
    text-decoration: none;
    color: #007bff;
    font-size: 16px;
`;

export const LoadingImage = styled.img`
    margin-bottom: 10px;
    max-width: 60px;
`;

export const SuccessImage = styled.img`
    margin-bottom: 10px;
    max-width: 60px;
`;

export const MarginTop = styled.h2`
margin-top:0px;
`;

export const ImageMarginBottomZero = styled.img`
margin-Bottom:0px;
`;

export const RegistrationForm = styled.form`
    font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    div {
        width: 100%;
    }
    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
    }
    input[type="email"],
    select,
    input[type="password"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
        font-size: 15px;
    }
    input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }

    button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
    }
    p {
        outline: none;
        color: red;
        text-align: center;
    }

    button:hover {
        background-color: #0056b3;
    }
    input:hover {
        background-color: #f2f2f2;
    }
`;

export const SuccessClass = styled.div`
    text-align: center;
    p {
        color: green;
        font-weight: bold;
    }
`;

export const RegistrationSuccess = styled.div`
  font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    div {
        width: 100%;
    }
    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
    }
    input[type="email"],
    select,
    input[type="password"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
        font-size: 15px;
    }
    input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }

    button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
    }
    p {
        outline: none;
        color: red;
        text-align: center;
    }

    button:hover {
        background-color: #0056b3;
    }
    input:hover {
        background-color: #f2f2f2;
    }
         text-align: center;
    p {
        color: green;
        font-weight: bold;
    }
`;

export const RegistrationJiggle = styled.form<{ animation: string }>`
font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    div {
        width: 100%;
    }
    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
    }
    input[type="email"],
    select,
    input[type="password"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
        font-size: 15px;
    }
    input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }

    button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
    }
    p {
        outline: none;
        color: red;
        text-align: center;
    }

    button:hover {
        background-color: #0056b3;
    }
    input:hover {
        background-color: #f2f2f2;
    }
                  animation: ${({ animation }) => animation} 0.5s ease-in-out;

`;

export const jiggle = keyframes`
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(5px);
    }
    75% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
`;

export const JiggleAnimation = styled.div<{ animation: string }>`
      font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    div {
        width: 100%;
    }
    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
    }
    input[type="email"],
    select,
    input[type="password"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
        font-size: 15px;
    }
    input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }

    button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
    }
    p {
        outline: none;
        color: red;
        text-align: center;
    }

    button:hover {
        background-color: #0056b3;
    }
    input:hover {
        background-color: #f2f2f2;
    }
     text-align: center;
    p {
        color: green;
        font-weight: bold;
    }
          animation: ${({ animation }) => animation} 0.5s ease-in-out;
`;
