import { GameLayoutTranslations } from "./types";

export const deGameLayoutTranslations: GameLayoutTranslations = {
    leftSidebar: {
        dashboard: "Übersicht",
        dischargedDashboard: "Verlegungen",
        plan: "Vogelperspektive",
        reviewPage: "Vitalparameter",
        chatPage: "Anamnese",
        examPage: "Untersuchung",
        testsOverview: "Diagnostik",
        orderBloodTest: "Laboranforderung",
        reviewBloodTest: "Laborwerte",
        investigations: "Apparative Diagnostik",
        consultOverview: "Konsil",
        specialistConsult: "Expertenkonsil",
        therapyConsult: "Zusätzliche Tests",
        treatmentOverview: "Behandlung",
        documentationOverview: "Dokumentation",
        drugChart: "Medikamente",
        treatmentPage: "Andere Maßnahmen",
        notes: "Notizen ansehen",
        forms: "Arztbrief",
        guidelines: "Leitlinien",
    },
    patientSidebar: {
        information: "Information",
        symptom: "Symptom",
        age: "Alter",
        sex: "Geschlecht",
        diagnosis: "Diagnose",
        dischargePatient: "Entlassung",
        addDiagnosis: "Diagnostizieren",
        reviewNotes: "Notizen ansehen",
        addNotes: "Notizen hinzufügen",
        notesPlaceholder: "Notizen hinzufügen",
        movePatient: "Verlegen",
    },
    header: {
        backToDashboard: "Übersicht",
        endGame: "Spiel beenden",
        dropdown: {
            profile: "Profil",
            badges: "Awards",
            logout: "Abmelden",
        },
    },
    notifications: {
        noNotifications: "Keine Benachrichtigungen",
        notificationSenders: {
            frontDesk: "Empfang",
        },
        notificationMessages: {
            newPatientArrival: "Neue/r Patient:in ist angekommen",
        },
    },
    diagnosisModal: {
        modalTitle: "Diagnose",
        saveButton: "Diagnose speichern",
        selectPlaceholder: "Diagnose auswählen",
    },
    movePatientModal: {
        modalTitle: "Patient verschieben",
        sentenceAboutLocation: "befindet sich derzeit in ",
        illegalMove:
            "Sie können den Patienten nicht an diesen Ort verschieben. Der Ort ist voll. Bitte wählen Sie einen anderen Ort.",
        placeholder: "Wählen Sie einen Ort",
        movePatient: "Patient verschieben",
        dischargeInfo:
            "Sie können Patienten nur von der Seite „Formular“ aus nach Hause entlassen oder auf eine Station einweisen.",
    },
};
