// External imports
import React, { useState, useEffect } from "react";
import { FaArrowCircleDown } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";

// Internal imports
import { BloodTestOptionsSequenceWithTitle } from "./config/BloodTestOptions";
import { RightSideForm } from "./components/RightSideForm/RightSideForm";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import { getTurnCount } from "../../../core/LocalStorage/turnLogic/core";
import {
    getJwt,
    GetJwtType,
} from "../../../core/LocalStorage/AuthSession/core";
import {
    BloodOrderPageMain,
    LeftSidePanel,
    RightSidePanel,
    Accordion,
    Panel,
    AccordionSection,
} from "./BloodOrderScreen.styles";

// Dependency injection
import { PostBloodsType } from "../../../core/ServerApiLayer/VOne/patients/postBloods";
import { SetBloodsToLocalStorageType } from "../../../core/LocalStorage/patients/bloods";
import { UpdateBloodDocumentationToLocalStorageType } from "../../../core/LocalStorage/patients/notes";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// translations
import { BloodOrderPageTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { BloodTestTranslations } from "../../../core/translations/bloods/types";
import { enBloodTestTranslations } from "../../../core/translations/bloods/en";
import { deBloodTestTranslations } from "../../../core/translations/bloods/de";
import { GAME_TRANSLATIONS } from "../../../core/translations/gameTranslations";

/**
 * BloodOrderPageTranslations Interface
 */
const TRANSLATIONS: Record<SupportedLanguage, BloodOrderPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * BloodTestTranslations
 */
const BLOOD_TEST_TRANSLATIONS: Record<
    SupportedLanguage,
    BloodTestTranslations
> = {
    en: enBloodTestTranslations,
    de: deBloodTestTranslations,
};

/**
 * CardData Interface
 */
interface CardData {
    name: string;
    title: string;
    options: string[];
}

/**
 * BloodOrderScreen Interface
 */
interface BloodOrderScreenProps extends WithPatientProps {
    postBloodsApiCall: PostBloodsType;
    setBloodsToLocalStorage: SetBloodsToLocalStorageType;
    updateBloodDocumentationToLocalStorage: UpdateBloodDocumentationToLocalStorageType;
}

type CombinedProps = BloodOrderScreenProps & WithGameDependenciesProps;

/**
 * BloodOrderScreen Component
 */
export const BloodOrderScreenBase: React.FC<CombinedProps> = ({
    postBloodsApiCall,
    setBloodsToLocalStorage,
    updateBloodDocumentationToLocalStorage,
    // Injected game dependencies
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount();
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Translations for the ExamPage
    const { language } = useLanguage();
    const pageTranslations = TRANSLATIONS[language];
    const bloodTestTranslations = BLOOD_TEST_TRANSLATIONS[language];

    // Navigation hook
    const navigate = useNavigate();

    // State to store the selected values
    const [selectedValuesArray, setSelectedValuesArray] = useState<string[]>(
        []
    );
    const [accordionState, setAccordionState] = useState<{
        [key: string]: boolean;
    }>({});

    useEffect(() => {
        const initialAccordionState: { [key: string]: boolean } = {};
        BloodTestOptionsSequenceWithTitle.forEach((cardDataArray) => {
            cardDataArray.forEach((cardData) => {
                initialAccordionState[cardData.name] = false;
            });
        });
        setAccordionState(initialAccordionState);
    }, []);

    const toggleAccordion = (name: string) => {
        setAccordionState((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    // Function to select/unselect all child items when the parent key is clicked
    const selectUnselect = (name: string, options: readonly string[]) => {
        const isChecked = options.every((option) =>
            selectedValuesArray.includes(option)
        );

        if (isChecked) {
            setSelectedValuesArray((prevSelected) =>
                prevSelected.filter(
                    (selectedId) => !options.includes(selectedId)
                )
            );
        } else {
            setSelectedValuesArray((prevSelected) => [
                ...prevSelected,
                ...Array.from(options).filter(
                    (option) => !prevSelected.includes(option)
                ),
            ]);
        }
    };

    // Function to handle single checkbox changes
    const handleSingleCheckboxChanges = (id: string) => {
        if (selectedValuesArray.includes(id)) {
            setSelectedValuesArray((prevSelected) =>
                prevSelected.filter((selectedId) => selectedId !== id)
            );
        } else {
            setSelectedValuesArray((prevSelected) => [...prevSelected, id]);
        }
    };

    // Function to handle item removal
    const handleItemRemoval = (id: string) => {
        // Remove the selected item
        setSelectedValuesArray((prevSelected) =>
            prevSelected.filter((selectedId) => selectedId !== id)
        );
    };

    // Function to handle form submission
    const handleSubmit = async (
        bloods: Array<string>,
        reason: string
    ): Promise<boolean> => {
        const payload = {
            patient_id: Number(patientId),
            turn: turnCount,
            blood_list: bloods,
            reason: reason,
        };

        const jwt = getJwt();
        //@ts-ignore
        const response = await postBloodsApiCall(jwt, payload);

        if (response.status === 201) {
            //@ts-ignore
            setBloodsToLocalStorage(
                patientId,
                response.data?.blood_order?.tests
            );
            //@ts-ignore
            updateBloodDocumentationToLocalStorage(
                patientId,
                response.data?.docs
            );
            navigate(`/patients/${patientId}/bloods/results`); // Redirect on success only
            return true;
        } else {
            console.log("Error posting bloods:", response.error);
            return false;
        }
    };

    // Function to get the group key
    const getGroupKey = (title: string) => {
        switch (title) {
            case "FBC":
                return "FULL_BLOOD_COUNT";
            case "Liver Profile":
                return "LIVER_PROFILE";
            case "U&Es":
                return "RENAL_PROFILE";
            case "Inflammatory Markers":
                return "INFLAMMATORY_MARKERS";
            case "Bone Profile":
                return "BONE_PROFILE";
            case "Coagulation":
                return "COAGULATION";
            case "Endocrinology":
                return "ENDOCRINOLOGY";
            case "Haemotinics":
                return "HAEMOTINICS";
            case "Cardiac Markers":
                return "CARDIAC_MARKERS";
            case "DIABETES":
                return "DIABETES";
            case "Blood Gases":
                return "GASES";
            case "Lipids":
                return "LIPIDS";
            case "ABG":
                return "ABG";
            case "VBG":
                return "VBG";
            case "Misc":
                return "MISC";
            default:
                return title;
        }
    };

    // Function to get the translated test name
    const getTranslatedTestName = (testKey: string) => {
        return bloodTestTranslations.types[testKey] || testKey;
    };

    // Function to get the translated group name
    const getTranslatedGroupName = (title: string) => {
        const groupKey = getGroupKey(title);
        return bloodTestTranslations.groups[groupKey] || title;
    };

    return (
        <GamePageLayout
            pageTitle={pageTranslations.pageTitle}
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            translations={translations}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
            turnDependencies={turnDependencies}
        >
            <BloodOrderPageMain>
                <LeftSidePanel>
                    {BloodTestOptionsSequenceWithTitle.map(
                        (cardDataArray, i) => (
                            <div key={`index_${i}`}>
                                {cardDataArray.map((cardData) => (
                                    <AccordionSection key={cardData.name}>
                                        <Accordion
                                            onClick={() =>
                                                toggleAccordion(cardData.name)
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                checked={cardData.options.every(
                                                    (option) =>
                                                        selectedValuesArray.includes(
                                                            option
                                                        )
                                                )}
                                                onChange={() =>
                                                    selectUnselect(
                                                        cardData.name,
                                                        cardData.options
                                                    )
                                                }
                                            />
                                            <label>
                                                {getTranslatedGroupName(
                                                    cardData.title
                                                )}
                                            </label>
                                            <FaArrowCircleDown size={16} />
                                        </Accordion>
                                        <Panel
                                            isOpen={
                                                accordionState[cardData.name]
                                            }
                                        >
                                            {cardData.options.map((option) => (
                                                <div key={option}>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedValuesArray.includes(
                                                            option
                                                        )}
                                                        onChange={() =>
                                                            handleSingleCheckboxChanges(
                                                                option
                                                            )
                                                        }
                                                    />
                                                    <label>
                                                        {getTranslatedTestName(
                                                            option
                                                        )}
                                                    </label>
                                                </div>
                                            ))}
                                        </Panel>
                                    </AccordionSection>
                                ))}
                            </div>
                        )
                    )}
                </LeftSidePanel>
                <RightSidePanel>
                    <RightSideForm
                        selectedItems={selectedValuesArray}
                        handleItemRemoval={handleItemRemoval}
                        onSubmit={handleSubmit}
                    />
                </RightSidePanel>
            </BloodOrderPageMain>
        </GamePageLayout>
    );
};

export const BloodOrderScreenPage =
    withGameDependencies<BloodOrderScreenProps>(BloodOrderScreenBase);
