import { JoinClassTranslations } from "./types";

export const enTranslations: JoinClassTranslations = {
    errorOccurredAuthCode: "An error occurred: ",
    failedToJoinClass: "Failed to join class",
    loginExpired: "Please login again. Login expired.",
    nextPage: "Next",
    pleaseEnterAuthCode: "Please Enter Your Game Code",
    teacherWillProvideCode: "The teacher will provide this",
    example: "e.g. MF128",
};
