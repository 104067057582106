import css from '@emotion/styled';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';


interface CentralFormPanelProps {
  failure?: boolean;
}

export const CentralFormPanel = styled.form<CentralFormPanelProps>`
  font-family: 'Helvetica', 'Arial', sans-serif;
  max-width: min(60%, 500px);
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #333333;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.failure && `animation: ${jiggle} 0.5s ease-in-out;`}

  & > div {
    width: 100%;
    button {
      width: 100%;
      margin-bottom: 10px;
    }
    button:last-child {
      margin-bottom: 0;
    }
  }
`;
const jiggle = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(-5px); }
  50% { transform: translateY(5px); }
  75% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
`;

export const JiggleAnimation = styled.div`
  animation: ${jiggle} 0.5s ease-in-out;
`;


export const NewGameInput = styled.form`
  display: flex;
  flex-direction: column; // Stack input and button vertically
  width: 100%;
  gap: 10px; // Space between input and button

  input, button {
    width: 100%; // Ensure both input and button fill the container width
  }
`;

export const Input = styled.input`
  width: calc(100% - 40px); // Explicit width to fit within NewGameInput padding
  border: 1px solid #333333;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box; // Ensure padding and border are included in the width
  margin: 0 auto; // Center the input

  &:focus {
    outline: none;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 5px;
`;

export const Paragraph = styled.p`
display:flex;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
`;
export const Title = styled.span`

`;
