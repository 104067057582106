import { DiagnosisTypesTranslations } from "./types";

export const enDiagnosisTranslations: DiagnosisTypesTranslations = {
    ABDOMINAL_AORTIC_ANEURYSM: "Abdominal Aortic Aneurysm",
    ACIDIC_REFLUX: "Acid reflux",
    ACUTE_APPENDICITIS: "Acute appendicitis",
    ACUTE_CHOLECYSTITIS: "Acute cholecystitis",
    ACUTE_RENAL_FAILURE: "Acute Renal Failure",
    ACUTE_URINARY_RETENTION: "Acute urinary retention",
    ACUTE_PSYCHOSIS: "Acute Psychosis",
    ANAPHYLAXIS: "Anaphylaxis",
    AORTIC_DISSECTION: "Aortic Dissection",
    AORTIC_STENOSIS: "Aortic Stenosis",
    ATRIAL_FIBRILLATION: "Atrial fibrillation",
    AV_BLOCK_COMPLETE: "3rd degree AV Block",
    AVNRT: "Atrioventricular nodal reentry tachycardia",
    BENIGN_PAROXYSMAL_POSITIONAL_VERTIGO:
        "Benign paroxysmal positional vertigo",
    CHEST_SEPSIS: "Chest sepsis",
    COMMUNITY_ACQUIRED_PNEUMONIA: "Community-acquired pneumonia",
    COMMUNITY_ACQUIRED_PNEUMONIA_OLD: "Community-acquired pneumonia (old)",
    CONGESTIVE_HEART_FAILURE: "Congestive heart failure",
    COVID_INFECTION: "COVID Infection",
    DEPRESSION: "Depressive Episode",
    DEHYDRATION: "Dehydration",
    DIABETIC_COMA: "Diabetic Coma",
    ECTOPIC_PREGNANCY: "Ectopic Pregnancy",
    FEBRILE_SEIZURE: "Febrile Seizure",
    HYPERGLYCAEMIA: "Hyperglycaemia",
    HYPERKALEMIA: "Hyperkalemia",
    HYPERTENSIVE_CRISIS: "Hypertensive crisis",
    HYPERTENSIVE_EMERGENCY: "Hypertensive Emergency",
    HYPERTHYROIDISM: "Hyperthyroidism",
    HYPOGLYCAEMIA: "Hypoglycaemia",
    NON_INFECTIVE_EXACERBATION_OF_COPD: "Non infective exacerbation of COPD",
    INFECTIVE_GASTROENTERITIS: "Infective gastroenteritis (norovirus)",
    ISCHAEMIC_STROKE: "Ischaemic Stroke",
    KIDNEY_STONES: "Kidney stones (Nephrolithiasis)",
    LEFT_SIDED_ENDOCARDITIS: "Left-Sided Endocarditis",
    LIVER_CIRRHOSIS: "Liver Cirrhosis",
    LOWER_GI_BLEEDING: "Lower gastrointestinal bleeding",
    MENINGITIS: "Meningitis",
    MESENTERIC_ISCHAEMIA: "Mesenteric Ischaemia",
    MIGRAINE: "Migraine",
    MUSCULOSKELETAL_CHEST_PAIN: "Musculoskeletal chest pain",
    NON_INFECTIVE_EXACERBATION_OF_ASTHMA:
        "Non infective exacerbation of asthma",
    INFECTIVE_EXACERBATION_OF_COPD: "Infective exacerbation of COPD",
    NSTEMI: "NSTEMI",
    ORTHOSTATIC_SYNCOPE: "Orthostatic syncope",
    OVARIAN_TORSION: "Ovarian Torsion",
    PANIC_ATTACK: "Panic Attack",
    PERIPHERAL_ARTERY_DISEASE: "Peripheral Artery Disease",
    PNEUMOTHORAX: "Pneumothorax",
    PREECLAMPSIA: "Pre-Eclampsia",
    PULMONARY_EMBOLISM: "Pulmonary embolism (Stable)",
    PULMONARY_EMBOLISM_UNSTABLE: "Pulmonary embolism (Unstable)",
    SHOULDER_DISLOCATION: "Shoulder Dislocation",
    SEIZURE: "Seizure",
    SIGMOID_DIVERTICULITIS: "Sigmoid Diverticulitis",
    SINUS_TACHYCARDIA: "Sinus Tachycardia",
    SINUS_VEIN_THROMBOSIS: "Sinus Vein Thrombosis",
    STEMI_ANTERIOR: "STEMI anterior",
    STEMI_POSTERIOR: "STEMI posterior",
    SUBARACHNOID_HEMORRHAGE: "Subarachnoid Hemorrhage",
    SUBDURAL_HEMATOMA: "Subdural Haematoma",
    TESTICULAR_TORSION: "Testicular Torsion",
    TRANSIENT_ISCHAEMIC_ATTACK: "Transcient Ischaemic Attack",
    TRAUMATIC_BRAIN_INJURY: "Traumatic Brain Injury",
    UPPER_GI_BLEED: "Upper gastrointestinal bleeding (Stable)",
    UPPER_GI_BLEED_UNSTABLE: "Upper gastrointestinal bleeding (Unstable)",
    UPPER_RESPIRATORY_TRACT_INFECTION: "Upper respiratory tract infection",
    UROSEPSIS: "Urosepsis",
};
