// External Imports
import React, { useRef } from "react";

// Internal Imports
import { Modal } from "../../../../components/core/modal/Modal";
import {
    TermsModalContainer,
    TermsModalContentContainer,
    TermsModalParagraph,
    TermsModalTitle,
    TermsModalSubtitle,
    TermsModalLink,
    TermsModalBoldText,
    TermsModalUnorderList,
    TermsModalList,
    TermsModalBtns,
    PrintableArea
} from "./TermsModal.styles";
import WebsiteButton from "../../../../components/core/button/WebsiteButton";
import PrintButton from "../../../../components/core/button/print/PrintButton";

/**
 * Props for the TermsModalPreview component
 */
interface TermsModalProps {
    onRequestClose: () => void;
}


/**
 * A modal that displays a terms and conditions. This contains a terms and conditions for the game, with 
 * a single button to close the modal.
 * 
 * @param onClose - A function to call when the modal is closed
 */
export const TermsModalPreview: React.FC<TermsModalProps> = ({ onRequestClose }) => {

    /**
     * Include useRef at the top of the page and create const from it to include on page section.
     * After adding this to printable section pass the same in PrintButton component 
     * Then print button will print the section provided to it.
     */

    const componentRef = useRef();
    // Logic to close the modal
    const closeModal = () => {
        onRequestClose();
    }

    const additionalRights = [
        "do not use the Website in any way or take any action that may undermine, disrupt, damage or manipulate the working functionality, performance, availability or accessibility of the Website",
        "do not copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify or create derivative works of the Website, or any updates or any part thereof",
        "do not use the Website in any manner that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.",
        "do not impersonate any person or entity, misrepresent any affiliation with another person, entity, or association, use false IP addresses or headers, or otherwise conceal your identity for any purpose.",
        "do not violate, plagiarise, or infringe on the rights of third parties, including without limitation copyright, trademark, trade secret, privacy, publicity or other proprietary rights.",
        "do not use data collected from the Website for any direct marketing activity (including without limitation email marketing, SMS marketing, telemarketing and direct mailing).",
    ]

    return (
        <Modal onRequestClose={closeModal}>
            <TermsModalContainer>
                <TermsModalContentContainer>
                    {/* @ts-ignore */}
                    <PrintableArea ref={componentRef}>
                        <TermsModalTitle>TERMS AND CONDITIONS</TermsModalTitle>
                        <TermsModalParagraph>
                            The Website <TermsModalLink href="https://www.clinicalmetrics.co.uk/">https://www.clinicalmetrics.co.uk/</TermsModalLink>  and/or any other URL which facilitates access to the Website (together, "the Website", “we” or “us”) is owned and operated by Yellow Bird Consulting Ltd., a private limited company registered under the laws of England and Wales with Company Number 11772572, VAT Number GB3521433321 and registered office at Building 423 - Sky View (Ro) Argosy Road, East Midlands Airport, Derby DE74 2SA
                        </TermsModalParagraph>
                        <TermsModalParagraph>
                            <TermsModalBoldText>
                                PLEASE READ THIS DOCUMENT CAREFULLY BEFORE ACCESSING OR USING THE WEBSITE. BY USING, ACCESSING, UPLOADING OR DOWNLOADING ANY INFORMATION TO OR FROM THE WEBSITE, YOU, THE USER OF THIS WEBSITE, AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH BELOW. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS AND CONDITIONS, YOU ARE ADVISED NOT TO CONTINUE USING THIS WEBSITE ANY LONGER.
                            </TermsModalBoldText>
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            ABOUT CLINICAL METRICS
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            Clinical Metrics is an online interactive product line that uses virtual patients to simulate real life medical conditions and thereby improve clinical education and patient care. We have a number of products ranging from a simulation emergency room to a consensus building tool for building guidelines between clinicians. The focus of the games is to teach and assess clinical reasoning, guide medical management of common presentations in the relevant setting and highlight where more education needs to be provided. Bespoke options are available on request for different users (such as junior doctors, teachers, etc), environments or languages. There are also a number of extendable options available on top off the base game such as extra diseases, different clinical settings or clinical reasoning options.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            REGISTRATION OF USER ACCOUNT
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            In order to successfully use Clinical Metrics, you must be at least 18 years of age and must complete the registration process by setting up a user account with a username and password of your choice. Clinical Metrics does not knowingly collect or solicit any information from anyone under the age of 18 or knowingly allow such persons to register on the Website. You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account. You may choose to close your account at any time from the options available on the dashboard or choose to write to our customer care at <TermsModalLink href="admin@clinicalmetrics.co.uk">admin@clinicalmetrics.co.uk</TermsModalLink>. You agree to notify us immediately of any unauthorised use, or suspected unauthorised use of your account or any other breach of security. Clinical Metrics cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            USER-GENERATED CONTENT
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            Certain features of our products and services may allow users to post, link, share, publish and otherwise make available certain content (“User Content”) in or through the Website. You are solely responsible for such User Content and hereby ensure that it does not offend any third-party rights, including but not limited to any applicable intellectual property rights, privacy rights, publicity rights, contractual rights and does not in any way be considered against applicable laws. Clinical Metrics does not and cannot review all User Content generated by users, and shall not be held responsible for the same under any circumstances whatsoever. We reserve the right, but are not obligated to, delete, modify, edit or update any User Content that we, in our sole discretion, deem to be in violation of these Terms and Conditions or deem to be otherwise unacceptable. Notwithstanding the above, by accepting these Terms and Conditions you grant us the right and license to use, modify, display and distribute such User Content by and through the Website and make it available to other users, who may also use such User Content subject to these Terms and Conditions.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            ACCEPTABLE USE OF WEBSITE
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            In order to maximise your user experience in using the Website, and avoid, eliminate or minimise disruptive activities, we kindly ask you to maintain the following:
                        </TermsModalParagraph>
                        <TermsModalParagraph>
                            <TermsModalUnorderList>
                                {additionalRights.map((liData: string, index: number) => (
                                    <TermsModalList key={index}>{liData}</TermsModalList>
                                ))}
                            </TermsModalUnorderList>
                        </TermsModalParagraph>
                        <TermsModalParagraph>
                            Without prejudice to our other rights under these Terms and Conditions, in case of a breach, we may take such action as we deem appropriate under the circumstances, including suspending your access to the Website, prohibiting you from accessing the Website, blocking computers using your IP address from accessing the Website, contacting your internet service provider to request that they block your access to the Website and/or bringing court proceedings against you.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            SUSPENSION OF SERVICE
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            Clinical Metrics reserves the right to suspend, discontinue or cease providing any product or service relating to the Website, temporarily or permanently, at any time, with or without notice, and shall have no liability or responsibility to you in any manner whatsoever if it chooses to do so. All provisions of these Terms and Conditions which by their nature should survive such suspension or termination shall survive, including, without limitation, intellectual property ownership, warranty disclaimers, indemnity and limitations of liability.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            DISCLAIMER OF WARRANTIES
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            By using the Clinical Metrics Website, you hereby acknowledge and agree that such usage is at your sole risk and responsibility. To the maximum extent permitted by applicable law, Clinical Metrics is provided on an “as is” and “as available” basis without warranties of any kind, either expressed or implied, including, but not limited to, all warranties of quality, accuracy, completeness, reliability, conditions of merchantability, fitness for a particular purpose, title or non-infringement. Clinical Metrics does not warrant that the products and services offered through the Website will meet your specific requirements, that the Website will be uninterrupted or error-free, that the Website will be free from viruses or similar harmful matter or that any defects in the Website will be immediately corrected. No oral or written communication offered by Clinical Metrics will create any warranty for this purpose.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            LIMITATION OF LIABILITY
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            Under no circumstances will Clinical Metrics any of its directors, shareholders, employees and agents be liable for any incidental, consequential, or indirect damages including, but not limited to, business interruption, damages for loss of profit, loss of data, loss of goodwill and the like arising out of the use or inability of use of the service/ information provided on or downloaded from the Website, or any delay in the processing of such information or services. For removal of doubt, this includes situations where there is any failure or delay in performance of any of the obligations under these Terms and Conditions that is caused by events outside our reasonable control (force majeure events). Notwithstanding the above, nothing under these Terms and Conditions will limit or exclude any liabilities that may not be excluded under applicable law.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            INDEMNIFICATION
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            You agree to indemnify, defend and hold harmless Clinical Metrics, its officers, directors, employees, agents, licensors, consultants, representatives and agents from and against all losses, damages, suits, judgments, costs and expenses (including litigation costs and reasonable attorneys{`'`} fees) arising out of or in connection with (a) your use of or inability to use the Website (b) your violation of any provision of these Terms and Conditions or your violation of any rights of a third party, or (c) your violation of any applicable laws, rules or regulations. Clinical Metrics reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will offer complete cooperation with Clinical Metrics in asserting any available defenses.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            THIRD PARTY LINKS AND ADVERTISEMENTS
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            Clinical Metrics may occasionally provide external links and advertisements submitted by or directed towards third party websites and services, including but not limited to social media Websites like Facebook, Instagram and LinkedIn. Unless expressly stated otherwise, such advertisements do not signify that Clinical Metrics endorses and/or is associated with such third-party websites and services in any manner including, but not limited to, the content, accuracy, quality, nature, appropriateness, decency, functionality, performance, reliability, completeness, timeliness, validity, safety, legality or any other aspect thereof. We accept no responsibility for third party advertisements contained within the Website. If you agree to purchase goods and/or services from any third party who advertises in this Website, you do so at your own risk and these Terms and Conditions remains no longer applicable.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            INTELLECTUAL PROPERTY
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            All information and content displayed in the Website including text, graphics, designs, photographs, videos, typefaces, look and feel, format, and the like is owned and/or licensed by Clinical Metrics unless expressly specified otherwise, and are protected by international laws on patents, copyrights, trade secrets, trade names, trademarks (whether registered or unregistered), domain names and other applicable rights. By agreeing to these Terms and Conditions you understand and agree to not modify, copy, reproduce, republish, upload, post, transmit, or distribute, in any manner, any material on or from the Website, including text, graphics, logos and designs for any purpose whatsoever or allow any third party to do so. Please notify us immediately if you believe any content found on or linked to by this Website violates your intellectual property rights. Upon presentation of suitable proof, Clinical Metrics will act as required or appropriate under applicable law, including deleting any such content or link to the infringing material.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            ELECTRONIC COMMUNICATIONS
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            The communications between you and Clinical Metrics use electronic means. For contractual purposes, you (a) consent to receive communications from Clinical Metrics in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Clinical Metrics provides to you electronically satisfy any legal requirement that such communications would satisfy if it were be in a hardcopy writing. With your informed consent (opt-in), such electronic communication may also include periodic emails and/or other messages for our internal marketing and promotional purposes. We take all reasonable measures to ensure that our marketing communications are compliant with the applicable laws including GDPR.
                        </TermsModalParagraph>
                        <TermsModalSubtitle>
                            GENERAL INFORMATION
                        </TermsModalSubtitle>
                        <TermsModalParagraph>
                            These Terms and Conditions shall be governed by and construed in accordance with the laws of England and Wales. All disputes as to the validity, execution, performance, interpretation or termination of this Agreement will be submitted to the exclusive jurisdiction of Courts in London. If any provision of these Terms and Conditions is found by a court of competent jurisdiction to be invalid, such invalidity will not affect the enforceability of any other part or provision of this Agreement.
                            <TermsModalParagraph>
                                Clinical Metrics reserves the right, at their sole discretion, to modify these Terms and Conditions at any time, and such modifications shall be effective immediately upon posting of the same on the Website. You agree to review this document periodically to be aware of such modifications and your continued access or use of the Website shall be deemed as a conclusive acceptance of such modified Terms and Conditions.
                            </TermsModalParagraph>
                            <TermsModalParagraph>
                                If you have any further questions, comments or requests regarding these Terms and Conditions, please email us at <TermsModalLink href="legal@clinicalmetrics.co.uk">legal@clinicalmetrics.co.uk</TermsModalLink>.
                            </TermsModalParagraph>
                            <TermsModalParagraph>
                                These Terms and Conditions were last updated in June 2023.
                            </TermsModalParagraph>
                        </TermsModalParagraph>
                    </PrintableArea>
                    <TermsModalBtns>
                        <WebsiteButton tag="button" onClick={onRequestClose}>
                            Close
                        </WebsiteButton>
                        {/* @ts-ignore */}
                        <PrintButton printableSection={componentRef} />
                    </TermsModalBtns>
                </TermsModalContentContainer>
            </TermsModalContainer>
        </Modal>
    );
}
//@ts-ignore
export default TermsModalPreview