import React from "react";
import { useParams } from "react-router-dom";

import { SidebarNavItem, SidebarNavItemProps } from "../SidebarNavItem/SidebarNavItem";
import { Sidebar, SidebarContent, SidebarInner } from "./SidebarNav.styles";
import { SidebarNavPopoutMenu } from "../SideBarNavPopoutMenu/SidebarNavPopoutMenu";
import { INavigationItem } from "../useNavigation";

// Adjusting the item structure to accommodate subitems
export interface NavItem extends SidebarNavItemProps {
  subItems?: NavItem[]; 
}


export interface SidebarNavProps {
  items: NavItem[];
  setModalOpen?: (e: boolean) => void; 
}

export const SidebarNav: React.FC<{ items: INavigationItem[], setModalOpen: (e: boolean) => void }> = ({ items, setModalOpen }) => {

    const { id: patientId } = useParams<{ id: string }>();

    return (
    <Sidebar>
      <SidebarContent>
        <SidebarInner>
          {items.map((item, index) =>
            item.subItems && item.subItems.length > 0 ? (
              // Correctly handling items with sub-items
              <SidebarNavPopoutMenu key={index} mainItem={item} subItems={item.subItems} />
            ) : (
              item.href == "/patients/:id/guidelines" ? 
              <div onClick={() => setModalOpen(true)}>
                <SidebarNavItem key={index} name={item.name} Icon={item.Icon} />
              </div> 
              : (
                // Correctly rendering items without sub-items, ensuring Icons are passed
                  //@ts-ignore
                <SidebarNavItem key={index} name={item.name} href={item.href.replace(":id", patientId!)} Icon={item.Icon} />
              )
            )
            
          )}
        </SidebarInner>
      </SidebarContent>
    </Sidebar>
  );
};