import {
  FooterSection,
  SectionTitle,
  LinkList,
  StyledLink,
} from "./FooterLinkSection.styles";

type Link = {
  name: string;
  href: string;
};

interface LinkSectionProps {
  section: string;
  links: Link[];
}

export function FooterLinkSection({ section, links }: LinkSectionProps) {
  return (
    <FooterSection>
      <SectionTitle> {section} </SectionTitle>
      <LinkList role="list">
        {links.map((item) => (
          <li key={item.name}>
            <StyledLink href={item.href}>{item.name}</StyledLink>
          </li>
        ))}
      </LinkList>
    </FooterSection>
  );
}
