import styled from "@emotion/styled";

export const PopupContainer = styled.div`
    position: absolute;
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1);
    width: 20em;
    top: 2.5em;
    right: 1em;
    z-index: 100;
`;
export const PopupContent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    color: #718096;
`;
export const NoNotificationsText = styled.span`
    color: #718096;
    padding: 5rem 0;
    font-size: 0.875rem;
    text-align: center;
`;
