import React from "react";
import { Modal } from "../Modal";
import {
    InvestigationErrorModalContainer,
    InvestigationErrorModalContentContainer,
    InvestigationErrorModalContentWrapper,
    InvestigationErrorModalTitle,
    InvestigationErrorModalButtonContainer
} from "./InvestigationErrorModal.styles";
import WebsiteButton from "../../button/WebsiteButton";

/**
 * InvestigationAlreadyOrderedModal Props.
 * @interface InvestigationAlreadyOrderedModalProps
 * @property {() => void} onClose - The function to close the modal.
 * @property {number} turnOrdered - The turn the investigation was ordered.
 * @property {number} turnAvailable - The turn the investigation might be available.
 */
interface InvestigationAlreadyOrderedModalProps {
    onClose: () => void;
    turnOrdered: number;
    turnAvailable: number;
    currentTurn: number;
}

/**
 * InvestigationAlreadyOrderedModal component.
 * @component InvestigationAlreadyOrderedModal
 * @description A modal component for displaying information about already ordered investigations.
 * @param {InvestigationAlreadyOrderedModalProps} props - The component props.
 * @returns {JSX.Element} The rendered InvestigationAlreadyOrderedModal component.
 */
function InvestigationAlreadyOrderedModal({
                                              onClose,
                                              turnOrdered,
                                              turnAvailable,
                                              currentTurn,
                                          }: InvestigationAlreadyOrderedModalProps) {
    return (
        <Modal width="35%" onRequestClose={onClose}>
            <InvestigationErrorModalContainer>
                <InvestigationErrorModalContentContainer>
                    <InvestigationErrorModalTitle>Investigation Already Ordered</InvestigationErrorModalTitle>
                    <InvestigationErrorModalContentWrapper>

                        {currentTurn > turnAvailable ? (
                            <p>
                                You have already ordered this investigation at turn {turnOrdered}. It is now available to view.
                            </p>
                        ) : (
                            <p>
                                You have already ordered this investigation at turn {turnOrdered}. It will likely not be available until turn {turnAvailable} or shortly after. Check back then.
                            </p>
                        )}
                        <InvestigationErrorModalButtonContainer>
                            <WebsiteButton tag="button" onClick={onClose}>
                                Close
                            </WebsiteButton>
                        </InvestigationErrorModalButtonContainer>
                    </InvestigationErrorModalContentWrapper>
                </InvestigationErrorModalContentContainer>
            </InvestigationErrorModalContainer>
        </Modal>
    );
}

export default InvestigationAlreadyOrderedModal;
