import styled from "@emotion/styled";

export const InformationTitle = styled.h3`
    font-size: 1.1rem;
    font-weight: 500;
    color: #111827;
    margin: 0;
`;

export const DescribeList = styled.dl`
    margin-top: 0.5rem;
`;

export const DescribeListContainer = styled.div`
    display: flex;
    padding: 0.6rem 0rem;
    justify-content: space-between;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    border-top: 1px solid #e5e7eb;
    font-family: "Helvetica", Arial, sans-serif;

    &:nth-last-of-type(1) {
        border-bottom: 1px solid #e5e7eb;
    }
`;

export const DescribeTerm = styled.dt`
    color: #6b7280;
`;

export const DescribeDefinition = styled.dd`
    color: #111827;
`;

export const ButtonContainer = styled.div`
    display: flex;
    margin-top: 1.5rem;
    flex-direction: row;
    gap: 0.5rem;

    button {
        width: 50%;
    }
`;

export const PatientButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    button{
        width: fit-content;
    }
`
