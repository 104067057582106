// External imports
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as webllm from "@mlc-ai/web-llm";

// Internal imports
import appConfig from "./app-config";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import { ChatPageContainer } from "./ChatScreen.styles";
import ChatLayoutChatLayoutWithForm from "./ChatLayoutWithForm/ChatLayoutWithForm";

// Dependency injection for chat page only
import { GetPromptDataFromLocalStorageType } from "../../../core/LocalStorage/patients/prompt";
import { UseLanguageType } from "../../../core/translations/LanguageContext";
import { GetTurnCountType } from "../../../core/LocalStorage/turnLogic/core";
import { GetDemographicsFromLocalStorageType } from "../../../core/LocalStorage/patients/demographics";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Dependency Injection for Chat Screen Page
interface ChatScreenProps extends WithPatientProps {
    getPatientData: GetDemographicsFromLocalStorageType;
    getPromptFromLocalStorage: GetPromptDataFromLocalStorageType;
}

type CombinedProps = ChatScreenProps & WithGameDependenciesProps;

// Page Translations
import { ChatPageTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";

// Define the translations for the chat screen page
const PAGE_TRANSLATIONS: Record<SupportedLanguage, ChatPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * ChatScreen Component
 *
 * @param getPatientData
 * @param getPromptFromLocalStorage
 * @param getTurnCount
 * @param useLanguage
 * @constructor
 */
const ChatScreenBase: React.FC<CombinedProps> = ({
    getPatientData,
    getPromptFromLocalStorage,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount();
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // State to store the chat history
    const [messagesHistory, setMessagesHistory] = useState<
        { userId: number; message: string }[]
    >([]);

    // State to store the chat engine and chat history, and initial prompt with loading
    // of the model
    const [chatEngine, setChatEngine] =
        useState<webllm.MLCEngineInterface | null>(null);
    const [chatHistory, setChatHistory] = useState<
        webllm.ChatCompletionMessageParam[]
    >([]);
    const [initialPrompt, setInitialPrompt] = useState<string>("");
    const [isModelLoading, setIsModelLoading] = useState<boolean>(true); // New state to track model loading

    // State to store the user message count
    const [userMessageCount, setUserMessageCount] = useState(0);

    // Translations
    const { language } = useLanguage();
    const pageTranslations = PAGE_TRANSLATIONS[language];

    // Initialize the web-llm engine and prepare the chat history
    useEffect(() => {
        const initEngine = async () => {
            let engine;

            try {
                setIsModelLoading(true); // Set loading state to true
                engine = new webllm.MLCEngine({ appConfig, logLevel: "INFO" });
                console.log("Loading model for the engine...");
                const modelId =
                    appConfig.default_model ||
                    "Llama-3.2-1B-Instruct-q4f32_1-MLC";
                console.log("Model ID:", modelId);
                await engine.reload(modelId);
                console.log("Model loaded successfully with ID:", modelId);

                setChatEngine(engine);
            } catch (err) {
                console.error("Error loading model:", err);
            } finally {
                setIsModelLoading(false); // Set loading state to false after model is loaded
            }
        };

        fetchInitialPrompt();
        initEngine();
    }, []); // Only run on mount, since an empty dependency array is provided

    // Fetch the initial prompt from the backend
    const fetchInitialPrompt = async () => {
        try {
            const prompt = getPromptFromLocalStorage(
                Number(patientId),
                "CHATBOT"
            );
            // @ts-ignore
            setInitialPrompt(prompt);
        } catch (err) {
            console.error("Failed to fetch initial prompt:", err);
        }
    };

    // Add initial prompt to chat history for the LLM engine but not for rendering
    useEffect(() => {
        if (initialPrompt) {
            setChatHistory([{ role: "system", content: initialPrompt }]);
        }
    }, [initialPrompt]);

    // Transform messages to the format expected by the LLM
    const formatChatForEngine = (
        history: { userId: number; message: string }[]
    ): { role: string; content: string }[] => {
        return history.map((msg) => ({
            role: msg.userId === 1 ? "user" : "assistant",
            content: msg.message, // Ensure that message content is a string
        }));
    };

    // Function to handle sending messages and receiving responses
    const handleSubmit = async (userMessageObject: { message: string }) => {
        const userMessage = userMessageObject.message; // Extract the message string

        // Update messages history for rendering in UI
        setMessagesHistory((prev) => [
            ...prev,
            { userId: 1, message: String(userMessage) }, // Ensure message is a string
        ]);

        // Increment the user message count
        setUserMessageCount((prevCount) => {
            const newCount = prevCount + 1;

            // If 5 messages sent, update the turn count
            if (newCount >= 5) {
                turnDependencies.updateTurns(1);
                return 0; // Reset counter
            }

            return newCount;
        });

        // Prepare new chat history with proper format for LLM
        const newHistoryForEngine = [
            ...chatHistory,
            { role: "user", content: userMessage }, // Properly formatted for the LLM engine
        ];

        // Update state used for LLM
        // @ts-ignore
        setChatHistory(newHistoryForEngine);

        if (chatEngine) {
            try {
                console.log(
                    "Sending chat history to engine:",
                    newHistoryForEngine
                );

                let generatedMessage = "";
                const completion = await chatEngine.chat.completions.create({
                    stream: true,
                    // @ts-ignore
                    messages: newHistoryForEngine,
                    stream_options: { include_usage: true },
                });

                // Collect response stream from the LLM
                for await (const chunk of completion) {
                    const curDelta = chunk.choices[0]?.delta.content;
                    if (curDelta) {
                        generatedMessage += curDelta;
                    }
                }

                // Append the generated response to the state for rendering in UI
                setMessagesHistory((prev) => [
                    ...prev,
                    { userId: 2, message: String(generatedMessage) }, // Ensure message is a string
                ]);

                // Add the generated response to chat history used for the LLM
                setChatHistory((prev) => [
                    ...prev,
                    { role: "assistant", content: generatedMessage },
                ]);
            } catch (err) {
                console.error("Error generating response: ", err);
            }
        } else {
            console.warn("Chat engine not initialized");
        }
    };

    return (
        <GamePageLayout
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            pageTitle={pageTranslations.title}
            patient={patient}
            translations={translations}
            documentationDependencies={sidebarDependencies}
            turnDependencies={turnDependencies}
            demographicsDependencies={demographicsDependencies}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
        >
            <ChatPageContainer>
                <ChatLayoutChatLayoutWithForm
                    messagesHistory={messagesHistory}
                    onSubmit={handleSubmit}
                    isDisabled={isModelLoading}
                    translations={pageTranslations}
                />
            </ChatPageContainer>
        </GamePageLayout>
    );
};

export const ChatScreen = withGameDependencies<ChatScreenProps>(ChatScreenBase);
