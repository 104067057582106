// Defines the checkbox for each disease choice
import { ChangeEvent, ReactNode, useEffect, useRef } from "react";
import {
    StyledLabel,
    StyledInput,
    StyledSpan,
} from "./DiseaseChoicesCheckbox.styles";

/*
A checkbox for each disease choice to be checked if selecting the disease.

@param label - The label for the checkbox
@param isGroup - Whether the checkbox is representing a group of diseases or an individual disease
@param checked - Whether the checkbox is checked or not
@param onChangeChecked - A function to be called when the checkbox is checked or unchecked
@param onChange - A function to be called when the checkbox is changed extracting the data from the HTML element
 */
export const DiseaseChoicesCheckbox = ({
    label,
    isGroup,
    checked: value,
    onChange,
    onChangeChecked,
    className,
    ...rest
}: {
    label?: string | ReactNode;
    isGroup?: boolean;
    className?: string;
    checked: boolean | "partial";
    onChangeChecked?: (checked: boolean) => void;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
    const checkboxRef = useRef<any>(null);

    const handleCheckedRef = () => {
        if (checkboxRef.current) {
            if (value === "partial") {
                // This field can only be set via js api
                checkboxRef.current.indeterminate = true;
            } else {
                checkboxRef.current.indeterminate = false;
                checkboxRef.current!.checked = value === true;
            }
        }
    };

    useEffect(handleCheckedRef, [value]);
    const size = isGroup ? 1.2 : 1;

    return (
        <StyledLabel isGroup={isGroup} {...rest}>
            <StyledInput
                ref={(el) => {
                    checkboxRef.current = el;
                    handleCheckedRef();
                }}
                type="checkbox"
                onChange={(e) => {
                    onChangeChecked?.(e.target.checked);
                    onChange?.(e);
                }}
                id={(label as string) + "checkbox"}
                size={size}
            />
            {label && <StyledSpan isGroup={isGroup}>{label}</StyledSpan>}
        </StyledLabel>
    );
};
