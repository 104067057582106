import { RegistrationPageTranslations } from "./types";

export const enTranslations: RegistrationPageTranslations = {
    heading: "Register",
    firstNameError: "Please fill in first name",
    lastNameError: "Please fill in last name",
    emailError: "Please fill in email",
    passwordError: "Please fill in password",
    success: "Success!",
    confirmEmail: "Please check email to confirm",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    password: "Password",
    language: "Language",
    languageTypes: [
        {
            label: "German",
            value: "german",
        },
        {
            label: "English",
            value: "english",
        },
    ],
    register: "Register",
};
