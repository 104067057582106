import { LocationTypesTranslations } from "./types";

export const enLocationTranslations: LocationTypesTranslations = {
    WAITING_ROOM: "Waiting Room",
    HOME_DISCHARGE: "Home Discharge",
    WARD_DISCHARGE: "Ward Discharge",
    ICU_DISCHARGE: "ICU Discharge",
    HDU_DISCHARGE: "HDU Discharge",
    Majors: "Majors",
    Resus: "Resus",
    WaitingRoom: "Waiting Room",
};
