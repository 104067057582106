import React, { useState } from "react";
import { RegistrationForm, FormData } from "./FormComponent/FormComponent";
import {
    UserPayload,
    CreateUserType,
} from "../../../core/ServerApiLayer/VOne/auth/register";
// @ts-ignore
// @ts-ignore
import myImage from "../../../assets/outside.jpg";
// @ts-ignore
import logoImage from "../../../assets/clinical_metrics.png";
import { useNavigate } from "react-router-dom";
import { RegistrationPageTranslations } from "./translations/types";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { useLanguage } from "../../../core/translations/LanguageContext";
import {
    Container,
    LeftPanel,
    LogoImage,
    RightPanel,
} from "./RegisterPage.styles";

/*
The props for the RegisterPage component.

@prop createUserApiCall: a function that makes the create user request to the backend
 */
export interface RegisterPageProps {
    createUserApiCall: CreateUserType;
}

const TRANSLATIONS: Record<SupportedLanguage, RegistrationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const RegisterPage: React.FC<RegisterPageProps> = ({ createUserApiCall }) => {
    // useNavigate is a hook from react-router-dom that allows us to navigate to different pages
    const navigate = useNavigate();

    const [formStatus, setFormStatus] = useState<FormData>({
        email: "",
        password: "",
        success: false,
        failure: false,
        error: "",
        firstName: "",
        lastName: "",
        language: "english",
    });

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    // this function fires when the form is submitted
    const handleFormSubmit = async (userData: FormData) => {
        // create the user payload
        const userPayload: UserPayload = {
            email: userData.email,
            password: userData.password,
            first_name: userData.firstName,
            last_name: userData.lastName,
            language: userData.language,
        };

        // create the user
        const response = await createUserApiCall(userPayload);
        if (response.status === 201) {
            setFormStatus({
                ...userData,
                success: true,
                failure: false,
                error: "",
            });
            return null;
        } else {
            setFormStatus({
                ...userData,
                success: false,
                failure: true,
                error: response.message,
            });
            return null;
        }
    };

    return (
        <Container backgroundImage={myImage}>
            <LeftPanel>
                <div>
                    <LogoImage
                        src={logoImage}
                        alt="description"
                        onClick={() => navigate("/")}
                    />
                    {!formStatus.success && <h1>{translations.heading}</h1>}
                    <RegistrationForm
                        onSubmit={handleFormSubmit}
                        initialFormData={formStatus}
                    />
                </div>
            </LeftPanel>
            <RightPanel>{/*    */}</RightPanel>
        </Container>
    );
};

export default RegisterPage;
