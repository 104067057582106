import React, { useState } from "react";

import { OutsideClickHandler } from "../../../../core/modal/terms-modal/OutsideClickHandler";
import { NotificationPopup } from "../NotificationPopup/NotificationPopup";

// Import the styled components
import {
    Container,
    NotificationCount,
    NotificationCountText,
    BellContainer,
    FaBellIcon,
} from "./NotificationBell.styles";

/*
An individual notification.

    id: The unique identifier for the notification.
    message: The message of the notification.
*/
export interface Notification {
    id: string;
    message: string;
}

/*
The prop for the unread notification count.

    unreadCount: The number of unread notifications.
    onDismiss: A callback function to handle dismissing notifications.
*/
export interface NotificationInfoProps {
    unreadNotifications: Notification[];
    onDismiss: (id: string) => void;
    translations: any;
}

/**
 * A component that displays a bell icon and the number of unread notifications. If
 * there are no unread notifications, the bell icon will be empty. If there are unread
 * notifications, the bell icon will have a red circle with the number of unread.
 *
 * Clicking on the bell icon will open a popup displaying the unread notifications, or
 * close the popup if it is already open.
 *
 * @returns The NotificationBell component.
 */
export const NotificationBell: React.FC<NotificationInfoProps> = ({
    unreadNotifications,
    onDismiss,
    translations,
}) => {
    const [notifsOpen, setNotifsOpen] = useState(false);

    return (
        <OutsideClickHandler onOutsideClick={() => setNotifsOpen(false)}>
            <Container onClick={() => setNotifsOpen(!notifsOpen)}>
                <NotificationCount unreadCount={unreadNotifications.length}>
                    <NotificationCountText>
                        {Math.min(unreadNotifications.length, 9)}{" "}
                        {/* Ensures the circle maintains its shape with a max of 9 */}
                    </NotificationCountText>
                </NotificationCount>
                <BellContainer notifsOpen={notifsOpen}>
                    <FaBellIcon />
                </BellContainer>
                {notifsOpen && (
                    <NotificationPopup
                        unreadNotifications={unreadNotifications}
                        onDismiss={onDismiss}
                        translations={translations}
                    />
                )}
            </Container>
        </OutsideClickHandler>
    );
};
