import { AdditionalTestTranslations } from "./types";

export const enTranslations: AdditionalTestTranslations = {
    testLabel: "Other Additional Tests",
    professional: "Choose Professional",
    professionalOptions: [
        { value: "PHYSIO", label: "Physio" },
        { value: "NURSE", label: "Nurse" },
    ],
    deailedInspection: "Detailed Inspection",
    detailedInspectionCheckbox: [
        { name: "ANTERIOR_VIEW", label: "Anterior View" },
        { name: "POSTERIOR_VIEW", label: "Posterior View" },
        { name: "SIDE_VIEW", label: "Side View" },
    ],
    specialTests: "Special Tests",
    specialTestsCheckbox: [
        { name: "SLR", label: "SLR" },
        { name: "STEINMANN", label: "Steinmann 1" },
        { name: "FABERS_SIGN", label: "Faber's Sign" },
    ],
    clinicalPrediction: "Clinical Prediction",
    clinicalPredictionCheckbox: [
        { name: "WELLS_CRITERIA", label: "Wells Criteria" },
        { name: "OTTAWA_ANKLE", label: "Ottawa Ankle" },
        { name: "OTTAWA_KNEE", label: "Ottawa Knee" },
        { name: "AMSTERDAM_WRIST", label: "Amsterdam Wrist" },
        { name: "CANADIAN_C_SPINE", label: "Canadian C-Spine" },
    ],
    mobility: "Mobility",
    mobilityCheckbox: [
        { name: "TRANSFERS", label: "Transfers" },
        { name: "AID_ASSESSMENT", label: "Aid Assessment" },
        { name: "STAIR_ASSESSMENT", label: "Stair Assessment" },
        { name: "GAIT_ANALYSIS", label: "Gait Analysis" },
    ],
    adlAssessment: "ADL Related Assessment",
    adlRelatedAssessment: [
        { name: "TIP_TOEING", label: "Tip Toeing" },
        { name: "SQUAT", label: "Squat" },
        { name: "SINGLE_LEG_STANCE", label: "Single Leg Stance" },
    ],
    reasonReferral: "Single Leg Stance",
    summary: "Summary",
    clearButton: "Clear",
    submitButton:"Submit Referral",
    result:"Results",
    pending: "pending",
    available: "available",
};
