
// External Imports
import React from 'react';
import { FaUser, FaUserCheck, FaChartBar } from 'react-icons/fa';
import { PiUsersFill } from "react-icons/pi";

// Internal Imports
import {
    ReviewPageStatsOptionsContainer,
    ReviewPageStatsSection,
    ReviewPageStatsSectionItem,
} from "./ReviewPageStatsOptions.styles";

// Language context
import { useLanguage} from "../../../../../core/translations/LanguageContext";

// translations
import { LeftSectionStatsTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";

/**
 * CONSTANTS for translations
 */
const TRANSLATIONS: Record<SupportedLanguage, LeftSectionStatsTranslations> = {
    en: enTranslations,
    de: deTranslations
};

/**
 * Interface for the ReviewPageStatsOptions component
 */
export interface ReviewPageStatsOptionsProps {
    classDetails: {
        total_patients: number;
        total_students: number;
        total_sessions: number;
        average_score: number;
    };
}

/**
 * Stats options for the review page
 * @param classDetails - data to be displayed in the stats options
 */
const ReviewPageStatsOptions: React.FC<ReviewPageStatsOptionsProps> = ({
                                                                           classDetails, // Receive classDetails as props
                                                                       }) => {

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    return (
        <ReviewPageStatsOptionsContainer>
            <ReviewPageStatsSection>
                <FaUser size={16} />
                <ReviewPageStatsSectionItem>{translations.patients} {classDetails.total_patients}</ReviewPageStatsSectionItem>
            </ReviewPageStatsSection>
            <ReviewPageStatsSection>
                <PiUsersFill size={16} />
                <ReviewPageStatsSectionItem>{translations.students} {classDetails.total_students}</ReviewPageStatsSectionItem>
            </ReviewPageStatsSection>
            <ReviewPageStatsSection>
                <FaUserCheck size={16} />
                <ReviewPageStatsSectionItem>{translations.sessions} {classDetails.total_sessions}</ReviewPageStatsSectionItem>
            </ReviewPageStatsSection>
            <ReviewPageStatsSection>
                <FaChartBar size={16} />
                <ReviewPageStatsSectionItem>{translations.averageScore} {classDetails.average_score}%</ReviewPageStatsSectionItem>
            </ReviewPageStatsSection>
        </ReviewPageStatsOptionsContainer>
    );
}

export default ReviewPageStatsOptions;
