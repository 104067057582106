import styled from "@emotion/styled";

export const Flex = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;
export const StyledInput = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    box-sizing: border-box;

    &:focus {
        border-color: #007bff;
    }
`;
