import React from "react";
import {
    DischargePatientContainer,
    DischargePatientContentContainer,
    DischargePatientDiagnosisInfoContainer,
    DischargePatientFeedbackContainer,
    DischargePatientFeedbackContent,
    DischargePatientFeedbackScore,
} from "./DischargePatient.styles";
import FeedbackPatientInfo from "./components/FeedbackPatientInfo/FeedbackPatientInfo";
import FeedbackDiagnosisBox from "./components/FeedbackDiagnosisBox/FeedbackDiagnosisBox";
import ItemListTable from "../../item-list-table/ItemListTable";
import Tooltip from "../../tooltip/Tooltip";
import {
    CORRECT_DIAGNOSE_SCORE_PERCENTAGE,
    CORRECT_MANAGEMENT_SCORE_PERCENTAGE,
    NEGATIVE_SCORE_PERCENTAGE_FOR_INCORRECT_MANAGEMENT,
} from "./config";
import { Modal } from "../Modal";

/**
 * IPatientDetail Props.
 * @interface IPatientDetail
 * @property {string} faceURL - The URL of the patient's face.
 * @property {string} firstName - The first name of the patient.
 * @property {string} lastName - The last name of the patient.
 * @property {number} age - The age of the patient.
 * @property {string} sex - The gender of the patient.
 * @property {string} symptoms - The symptoms of the patient.
 */
export interface IPatientDetail {
    faceURL: string;
    name: string;
    demographics: string;
    symptom: string;
}

/**
 * DischargePatientModal Props.
 * @interface DischargePatientModalProps
 * @property {IPatientDetail} patient - The patient to be discharged.
 * @property {any} feedback - The feedback for the patient.
 * @property {() => void} onClose - The function to close the modal.
 */
interface DischargePatientModalProps {
    patient: IPatientDetail;
    feedback: any;
    onClose: () => void;
}

/**
 * DischargePatientModal component.
 * @component DischargePatientModal
 * @description A modal component for discharging a patient.
 * @param {DischargePatientModalProps} props - The component props.
 * @returns {JSX.Element} The rendered DischargePatientModal component.
 */
function DischargePatientModal({
    patient,
    feedback,
    onClose,
}: DischargePatientModalProps) {
    // Check if the history is relevant
    const hasRelevantHistory = (history: any) => {
        if (!history) return false;

        for (const key in history) {
            if (history[key] && Object.keys(history[key]).length) return true;
        }
        return false;
    };

    // Calculate the score and return total percentage score
    const scoreCalculation = () => {
        const {
            correctDiagnosisScore,
            correctManagementScore,
            negativeScoreForIncorrectManagement,
        } = feedback;

        // Calculate weighted scores
        const weightedCorrectDiagnosisScore =
            correctDiagnosisScore * (CORRECT_DIAGNOSE_SCORE_PERCENTAGE / 100);
        const weightedCorrectManagementScore =
            correctManagementScore *
            (CORRECT_MANAGEMENT_SCORE_PERCENTAGE / 100);
        const weightedNegativeScore =
            negativeScoreForIncorrectManagement *
            (NEGATIVE_SCORE_PERCENTAGE_FOR_INCORRECT_MANAGEMENT / 100);

        return (
            weightedCorrectDiagnosisScore +
            weightedCorrectManagementScore -
            weightedNegativeScore
        );
    };

    return (
        <Modal onRequestClose={onClose}>
            <DischargePatientContainer>
                <DischargePatientContentContainer>
                    <FeedbackPatientInfo patient={patient} />
                    <DischargePatientDiagnosisInfoContainer>
                        <FeedbackDiagnosisBox
                            title="Your Diagnosis"
                            diagnosis={feedback?.user_diagnosis}
                            isCorrect={
                                feedback?.user_diagnosis ===
                                feedback?.real_diagnosis
                            }
                        />
                        <FeedbackDiagnosisBox
                            title={"Correct Diagnosis"}
                            diagnosis={feedback?.real_diagnosis}
                            isCorrect={true}
                        />
                    </DischargePatientDiagnosisInfoContainer>
                    <DischargePatientFeedbackScore>
                        Score:{" "}
                        <Tooltip
                            tooltip={
                                <>
                                    Diagnosis Score:{" "}
                                    {feedback.correctDiagnosisScore}%
                                    <br />
                                    Management Score:{" "}
                                    {feedback.correctManagementScore}%
                                    <br />
                                    Negative Score:{" "}
                                    {
                                        feedback.negativeScoreForIncorrectManagement
                                    }
                                    %
                                </>
                            }
                            placement="right"
                        >
                            <span>{scoreCalculation()}</span>
                        </Tooltip>
                    </DischargePatientFeedbackScore>
                    {hasRelevantHistory(feedback?.history) && (
                        <ItemListTable
                            title="Your History Taking"
                            history={feedback?.history}
                        />
                    )}
                    {feedback?.protocol &&
                        Object.keys(feedback?.protocol).length > 0 && (
                            <ItemListTable
                                title="Your Management"
                                protocol={feedback?.protocol}
                            />
                        )}
                </DischargePatientContentContainer>

                <DischargePatientFeedbackContainer>
                    <DischargePatientFeedbackContent>
                        {feedback.feedback.split(/\n/).map((line: string) => (
                            <p key={line}>{line}</p>
                        ))}
                    </DischargePatientFeedbackContent>
                </DischargePatientFeedbackContainer>
            </DischargePatientContainer>
        </Modal>
    );
}

export default DischargePatientModal;
