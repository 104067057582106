import { consultationData, individualConsultationData } from "./interface";
import { getSpecificPatientData, updatePatientData} from "./core";


// Function signature type for setting to local storage
export type SetConsultationsToLocalStorageType = (patient_id: number, newConsultationData: individualConsultationData) => void;


/*
Set the consultation data for a patient in localStorage.
*/
export function setConsultationsToLocalStorage(patient_id: number, newConsultationData: individualConsultationData): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has consultation data, initialize if none
    let existingConsultation = patientData.consultations || {};

    // Get keys of the new consultation data
    const consultationType = newConsultationData.specialty;

    // Add or update the specific consultation type
    existingConsultation[consultationType] = newConsultationData;

    // Update the patient's consultation data
    patientData.consultations = existingConsultation;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}



// Function signature type for getting from local storage
export type GetConsultationDataFromLocalStorageType = (patient_id: number) => consultationData | null;


// Function to get the consultation data for a patient from localStorage
export function getConsultationDataFromLocalStorage(patient_id: number): consultationData | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // Return the consultation data, or null if no data is found
    return patientData?.consultations || null;
}


/*
Type for the filtered consultation data.
 */
export type FilteredConsultationData = {
    available: individualConsultationData[];
    pending: individualConsultationData[];
}


// Function signature type for filtering consultation data
export type FilterConsultationDataType = (patient_id: number, turn: number) => FilteredConsultationData;

export function filterConsultationData(patient_id: number, turn: number): FilteredConsultationData {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return { available: [], pending: [] };
    }

    // Check if the patient already has consultation data, initialize if none
    let existingConsultation = patientData.consultations || {};

    // Get the keys of the consultation data
    const consultationKeys = Object.keys(existingConsultation);

    // Filter the consultation data based on the turn
    const availableData: individualConsultationData[] = [];
    const pendingData: individualConsultationData[] = [];

    consultationKeys.forEach((key) => {
        const consultation = existingConsultation[key];
        if (consultation.turn_available <= turn) {
            availableData.push(consultation);
        } else {
            pendingData.push(consultation);
        }
    });

    return { available: availableData, pending: pendingData };
}