// External imports
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Internal imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import { FaSearch } from "react-icons/fa";
import { MdArrowOutward } from "react-icons/md";
import {
    GuidesPageContainer,
    GuidesPageHeadingMain,
    GuidesPageHead,
    GuidesPageTitle,
    GuidesPageSearchField,
    GuidesPageSearchFieldSpan,
    GuidesPageSearchFieldInput,
    GuidesPageBackgroundSection,
    GuidesPageCardContainer,
    GuidesPageCard,
    GuidesPageCardFigure,
    GuidesPageCardFigureImage,
    GuidesPageCardTitle,
    GuidesPageCardDescription,
    GuidesPageCardDescriptionSubTitle,
    GuidesPageCardDescriptionParagraph,
} from "./GuidesPage.styles";
import { useLanguage } from "../../../core/translations/LanguageContext";
import { loadGuides } from "./guides/guideLoader";
import { isGuide } from "./guides/validator";
import { Guide } from "./guides/types";

// Translations
import { GuidePageTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

const TRANSLATIONS: Record<SupportedLanguage, GuidePageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * The GuidesPage component displays a list of guides.
 */
export const GuidesPage: React.FC = () => {
    const { language } = useLanguage();
    const [guides, setGuides] = useState<Guide[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const translations = TRANSLATIONS[language];

    useEffect(() => {
        const fetchGuides = async () => {
            setLoading(true);
            setError(null);
            try {
                // Load guides dynamically based on language
                const loadedGuides = await loadGuides(language);
                // Validate guides
                const validatedGuides = loadedGuides.filter(isGuide);
                setGuides(validatedGuides);
            } catch (err) {
                console.error("Failed to load guides:", err);
                setError("Failed to load guides.");
            } finally {
                setLoading(false);
            }
        };

        fetchGuides();
    }, [language]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <MainPageLayout>
            <GuidesPageContainer>
                <GuidesPageHead>
                    <GuidesPageHeadingMain className="blog-heading">
                        <GuidesPageTitle>{translations.main.title}</GuidesPageTitle>
                    </GuidesPageHeadingMain>
                    <GuidesPageSearchField>
                        <GuidesPageSearchFieldSpan>
                            <FaSearch size={22} />
                        </GuidesPageSearchFieldSpan>
                        <GuidesPageSearchFieldInput
                            type="text"
                            placeholder={translations.main.searchPlaceholder}
                        />
                    </GuidesPageSearchField>
                </GuidesPageHead>
            </GuidesPageContainer>
            <GuidesPageBackgroundSection>
                <GuidesPageContainer>
                    <GuidesPageCardContainer>
                        {guides.map((guide) => (
                            <GuidesPageCard key={guide.slug}>
                                <Link to={`/guides/${guide.slug}`}>
                                    <GuidesPageCardFigure>
                                        <GuidesPageCardFigureImage
                                            src={guide.featureImage}
                                            width={379}
                                            height={208}
                                            alt={guide.title}
                                        />
                                        <GuidesPageCardDescription>
                                            <GuidesPageCardDescriptionSubTitle>
                                                {guide.title}
                                            </GuidesPageCardDescriptionSubTitle>
                                            <MdArrowOutward />
                                            <GuidesPageCardDescriptionParagraph>
                                                {guide.summary}
                                            </GuidesPageCardDescriptionParagraph>
                                        </GuidesPageCardDescription>
                                    </GuidesPageCardFigure>
                                </Link>
                            </GuidesPageCard>
                        ))}
                    </GuidesPageCardContainer>
                </GuidesPageContainer>
            </GuidesPageBackgroundSection>
        </MainPageLayout>
    );
};
