// This file contains the configuration for the blood test options. 

export const BloodTestOptions = {
    FULL_BLOOD_COUNT: [
        'HAEMOGLOBIN',
        'HCT',
        'MCH',
        'MCHC',
        'MCV',
        'PLATELETS',
        'TOTAL_WHITE_BLOOD_CELLS',
        'LYMPHOCYTES',
        'BASOPHILS',
        'EOSINOPHILS',
        'MONOCYTES',
        'NEUTROPHILS',
    ] as const,
    LIVER_PROFILE: [
        'ALBUMIN',
        'ALP',
        'ALT',
        'AST',
        'BILIRUBIN',
        'BILIRUBIN_CONJUGATED',
        'GGT',
        'TOTAL_PROTEIN',
    ] as const,
    RENAL_PROFILE: [
        'SODIUM',
        'POTASSIUM',
        'CREATININE',
        'UREA',
        'EGFR',
    ] as const,
    INFLAMMATORY_MARKERS: ['CRP', 'PROCALCITONIN'] as const,
    BONE_PROFILE: ['MAGNESIUM', 'PHOSPHATE', 'CALCIUM'] as const,
    COAGULATION: [
        'APTT',
        'FIBRINOGEN',
        'INR',
        'PROTHROMBIN',
        'D_DIMER',
    ] as const,
    ENDOCRINOLOGY: ['FT3', 'FT4', 'TSH'] as const,
    HAEMOTINICS: [
        'FERRITIN',
        'FOLIC_ACID',
        'IRON',
        'TRANSFERRIN',
        'TRANSFERRIN_SATURATION',
        'VITAMIN_B12',
    ] as const,
    DIABETES: ['GLUCOSE', 'HBA1C'] as const,
    LIPIDS: [
        'TRIGLYCERIDES',
        'CHOLESTEROL',
        'LDL',
        'HDL',
        'NON_HDL_CHOLESTEROL',
    ] as const,
    CARDIAC_MARKERS: ['TROPONIN', 'NTPRO_BNP', 'CK', 'CKMB'] as const,
    GASES: ['ABG', 'VBG'] as const,
    ABG: ['ABG'] as const,
    VBG: ['VBG'] as const,
    MISC: ['ALCOHOL', 'URIC_ACID', 'AMYLASE', 'LIPASE', 'LDH'] as const,
} as const


export const BLOOD_TEST_GROUP_NAME = {
    FULL_BLOOD_COUNT: "Full Blood Count",
    LIVER_PROFILE: "Liver Profile",
    RENAL_PROFILE: "Renal Profile",
    INFLAMMATORY_MARKERS: "Inflammatory Markers",
    BONE_PROFILE: "Bone Profile",
    COAGULATION: "Coagulation",
    ENDOCRINOLOGY: "Endocrinology",
    HAEMOTINICS: "Haemotinics",
    DIABETES: "Diabetes",
    LIPIDS: "Lipids",
    CARDIAC_MARKERS: "Cardiac Markers",
    GASES: "Gases",
    ABG: "ABG",
    VBG: "VBG",
    MISC: "Misc",
};

export const BLOOD_TEST_TYPE_NAME = {
    HAEMOGLOBIN: "Haemoglobin",
    HCT: "HCT",
    MCH: "MCH",
    MCHC: "MCHC",
    MCV: "MCV",
    PLATELETS: "Platelets",
    TOTAL_WHITE_BLOOD_CELLS: "Total White Blood Cells",
    LYMPHOCYTES: "Lymphocytes",
    BASOPHILS: "Basophils",
    EOSINOPHILS: "Eosinophils",
    MONOCYTES: "Monocytes",
    NEUTROPHILS: "Neutrophils",
    ALBUMIN: "Albumin",
    ALP: "ALP",
    ALT: "ALT",
    AST: "AST",
    BILIRUBIN: "Bilirubin",
    BILIRUBIN_CONJUGATED: "Bilirubin Conjugated",
    GGT: "GGT",
    TOTAL_PROTEIN: "Total Protein",
    SODIUM: "Sodium",
    POTASSIUM: "Potassium",
    CREATININE: "Creatinine",
    UREA: "Urea",
    EGFR: "eGFR",
    CRP: "CRP",
    PROCALCITONIN: "Procalcitonin",
    MAGNESIUM: "Magnesium",
    PHOSPHATE: "Phosphate",
    CALCIUM: "Calcium",
    APTT: "APTT",
    FIBRINOGEN: "Fibrinogen",
    INR: "INR",
    PROTHROMBIN: "Prothrombin",
    D_DIMER: "D-Dimer",
    FT3: "FT3",
    FT4: "FT4",
    TSH: "TSH",
    FERRITIN: "Ferritin",
    FOLIC_ACID: "Folic Acid",
    IRON: "Iron",
    TRANSFERRIN: "Transferrin",
    TRANSFERRIN_SATURATION: "Transferrin Saturation",
    VITAMIN_B12: "Vitamin B12",
    GLUCOSE: "Glucose",
    HBA1C: "HbA1c",
    TRIGLYCERIDES: "Triglycerides",
    CHOLESTEROL: "Cholesterol",
    LDL: "LDL",
    HDL: "HDL",
    NON_HDL_CHOLESTEROL: "Non-HDL Cholesterol",
    TROPONIN: "Troponin",
    NTPRO_BNP: "NTpro-BNP",
    CK: "CK",
    CKMB: "CKMB",
    ABG: "ABG",
    VBG: "VBG",
    ALCOHOL: "Alcohol",
    URIC_ACID: "Uric Acid",
    AMYLASE: "Amylase",
    LIPASE: "Lipase",
    LDH: "LDH",
};

export const BloodTestOptionsSequenceWithTitle = [
    [
        {
            name: "fbc",
            title: "FBC",
            options: BloodTestOptions.FULL_BLOOD_COUNT

        },
        {
            name: "liver_profile",
            title: "Liver Profile",
            options: BloodTestOptions.LIVER_PROFILE

        },
        {
            name: "u_and_e",
            title: "U&Es",
            options: BloodTestOptions.RENAL_PROFILE

        }
    ],
    [
        {
            name: "inflamatory_markers",
            title: "Inflammatory Markers",
            options: BloodTestOptions.INFLAMMATORY_MARKERS

        },
        {
            name: "bone_profile",
            title: "Bone Profile",
            options: BloodTestOptions.BONE_PROFILE

        },
        {
            name: "coagulation",
            title: "Coagulation",
            options: BloodTestOptions.COAGULATION

        }
    ],
    [
        {
            name: "endocrinology",
            title: "Endocrinology",
            options: BloodTestOptions.ENDOCRINOLOGY

        },
        {
            name: "haemotinics",
            title: "Haemotinics",
            options: BloodTestOptions.HAEMOTINICS

        },
        {
            name: "diabetes",
            title: "DIABETES",
            options: BloodTestOptions.DIABETES

        }
    ],
    [
        {
            name: "lipids",
            title: "Lipids",
            options: BloodTestOptions.LIPIDS

        },
        {
            name: "cardiac_markers",
            title: "Cardiac Markers",
            options: BloodTestOptions.CARDIAC_MARKERS

        },
        {
            name: "gases",
            title: "GASES",
            options: BloodTestOptions.GASES

        }
    ],
    [
        {
            name: "abg",
            title: "ABG",
            options: BloodTestOptions.ABG

        },
        {
            name: "vbg",
            title: "VBG",
            options: BloodTestOptions.VBG

        },
        {
            name: "misc",
            title: "MISC",
            options: BloodTestOptions.MISC

        }
    ]
 ] as const
 export const Priorities = [{
    value: "1",
    label: "High"
},
{
    value: "2",
    label: "Medium"
},
{
    value: "3",
    label: "Low"
}]
export type IBloodTestProfile = keyof typeof BloodTestOptions
export type IBloodTestKey = typeof BloodTestOptions[IBloodTestProfile][number]
export default BloodTestOptions
export const BloodTestProfiles = Object.keys(
    BloodTestOptions
) as IBloodTestProfile[]