// External imports
import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";

// Internal imports
import GlobalSmallPopup from "../GlobalSmallPopup";
import {
    ClassCreatedModalContainer,
    ClassCreatedModalContentContainer,
    ClassCreatedModalContentWrapper,
    ClassCreatedModalTitle,
    ClassCreatedModalSubTitle,
    ClassCreatedModalText,
    ClassCreatedModalSpan,
} from "./ClassCreatedModal.styles";

// Translation imports
import { useLanguage } from '../../../../core/translations/LanguageContext';
import { ClassCreatedModalTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../../core/translations/supportedLanguages';

// Constants
const TRANSLATIONS: Record<SupportedLanguage, ClassCreatedModalTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const COPY_CONFIRMATION_TIMEOUT = 5000;

/**
 * Props interface for the ClassCreatedModal component
 * @interface ClassCreatedModalProps
 */
interface ClassCreatedModalProps {
    onRequestClose: () => void;
    authCode: string;
}

/**
 * ClassCreatedModal component
 * Displays a modal with the class authentication code and copy functionality
 * @param {ClassCreatedModalProps} props - Component props
 * @returns {React.ReactElement} Rendered component
 */
const ClassCreatedModal: React.FC<ClassCreatedModalProps> = ({
                                                                 authCode,
                                                                 onRequestClose
                                                             }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const [isCopied, setIsCopied] = useState(false);

    /**
     * Handles copying the auth code to clipboard
     * Shows a confirmation message for 2 seconds
     */
    const handleCopyClick = async (): Promise<void> => {
        try {
            await navigator.clipboard.writeText(authCode);
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, COPY_CONFIRMATION_TIMEOUT);
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };

    return (
        <GlobalSmallPopup onRequestClose={onRequestClose}>
            <ClassCreatedModalContainer>
                <ClassCreatedModalContentContainer>
                    <ClassCreatedModalTitle>
                        {translations.title}
                    </ClassCreatedModalTitle>
                    <ClassCreatedModalSubTitle>
                        {translations.subtitle}
                    </ClassCreatedModalSubTitle>
                    <ClassCreatedModalContentWrapper>
                        <ClassCreatedModalText>
                            {authCode}
                        </ClassCreatedModalText>
                        <FaCopy
                            onClick={handleCopyClick}
                            role="button"
                            aria-label="Copy auth code"
                            style={{ cursor: 'pointer' }}
                        />
                    </ClassCreatedModalContentWrapper>
                    {isCopied && (
                        <ClassCreatedModalSpan>
                            {translations.copyConfirmation}
                        </ClassCreatedModalSpan>
                    )}
                </ClassCreatedModalContentContainer>
            </ClassCreatedModalContainer>
        </GlobalSmallPopup>
    );
};

export default ClassCreatedModal;