// StyledComponents.ts
import styled from '@emotion/styled';

// Styled components
export const MessageTextRight = styled.div`
    background: white;
    max-width: 100%;
    padding: 10px 27px;
    display: inline-block;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 10px; 
    font-family: Inter, sans-serif;
`;

export const InnerPreviewMessageRight = styled.div`
    max-width: 75%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    min-width: 100px;
    padding: 10px 27px;
    display: block;
    margin-left: auto;
    border: 1px solid #dad4d430;
`;

