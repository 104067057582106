import styled from "@emotion/styled";

export const FeedbackDiagnosisBoxContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 0.25rem;
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-start;
`;

export const FeedbackDiagnosisBoxTitle = styled.h2`
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
`;

export const FeedbackDiagnosisBoxContent = styled.div`
    padding: 1rem;
    border-radius: 0.25rem;
    width: 100%;
    background-color: ${({ isCorrect }: { isCorrect: boolean }) =>
        isCorrect ? "#A7F3D0" : "#FECACA"};
    color: ${({ isCorrect }: { isCorrect: boolean }) =>
        isCorrect ? "#047857" : "#B91C1C"};
`;
