import styled from "@emotion/styled";

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4em 0;
    gap: 40px;
    @media (min-width: 1024px) {
        flex-direction: column;
        max-width: 1536px;
        padding: 0 3rem;
        margin: auto;
    }
`;

export const Title = styled.h1`
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #173b5e;
    @media (min-width: 640px) {
        font-size: 48px;
        line-height: 48px;
        color: #002147;
        font-weight: 700;
    }
`;

export const CardSection = styled.div`
    width: 100%;
    display: grid;
    grid: auto / auto;
    grid-gap: 10px;
    row-gap: 0.5rem;
    @media (min-width: 550px) {
        grid: auto / auto auto;
    }
    @media (min-width: 780px) {
        grid: auto / auto auto auto;
    }
    @media (min-width: 950px) {
        grid: auto / auto auto auto auto;
    }
`;

export const CardArea = styled.div`
    width: 100%;
    display: flex;
    row-gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    position: relative;
`;

export const CardSpan = styled.span`
    color: black;
    text-align: center;
    padding: 5px 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    width: fit-content;
    border-radius: 5px;
`;

export const Image = styled.img`
    width: 100px;
    height: 150px;
`;

export const LockIcon = styled.div`
    font-size: 9rem;
    padding: 10px;
    width: 190px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        color: rgb(40 79 156);
    }
`;

export const BagesTemplateGoBackButton = styled.a`
    font-size: 0.938rem;
    white-space: nowrap;
    font-family: Helvetica, Arial, sans-serif;
    padding: 0.6em 1.3em;
    border-radius: 0.8em;
    cursor: pointer;
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 0.5em;
    font-weight: 500;
    width: fit-content;
    border: none;
    background-color: rgb(22, 59, 93);
    color: white;
    text-decoration: none;
`;
