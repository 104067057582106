// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { individualInvestigationData } from "../../../LocalStorage/patients/interface";

/*
The payload for posting an investigation.
 */
export interface PostInvestigationPayload {
    turn: number;
    patient_id: number;
    investigation: string;
    reason: string;
    priority: "Low" | "Medium" | "High";
}

/*
The interface for the response from the post investigation endpoint.
@status: The HTTP status code.
@error: The error message.
@data: The object containing the investigation details.
 */
export interface PostInvestigationResponse {
    status: number;
    error?: string;
    data?: individualInvestigationData;
}

export type PostInvestigationType = (
    jwt: string,
    postInvestigationPayload: PostInvestigationPayload
) => Promise<PostInvestigationResponse>;

/*
The function to post an investigation for a patient.

@jwt: The JWT of the user requesting the post.
@postInvestigationPayload: The payload containing the turn, patient ID, investigation details, reason, and priority.
@returns: The ApiResponse from the post investigation endpoint.
 */
export async function postInvestigation(
    jwt: string,
    postInvestigationPayload: PostInvestigationPayload
): Promise<PostInvestigationResponse> {
    const url = new PatientUrl().postInvestigation;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<individualInvestigationData | string>(
            url,
            {
                turn: postInvestigationPayload.turn,
                patient_id: postInvestigationPayload.patient_id,
                investigation: postInvestigationPayload.investigation,
                reason: postInvestigationPayload.reason,
                priority: postInvestigationPayload.priority,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    token: jwt,
                },
                validateStatus: () => true,
            }
        );
    } catch (error) {
        return {
            status: 500,
            error: "Network or other error occurred",
        };
    }

    if (response.status === 201) {
        console.log("Investigation posted successfully:", response.data);
        return {
            status: response.status,
            data: response.data as individualInvestigationData,
        };
    } else {
        return {
            status: response.status,
            error: response.data as string,
        };
    }
}
