import styled from "@emotion/styled";

export const RoleChoiceForm = styled.div`
    font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    button {
        width: calc(100% - 20px); /* Adjust for padding */
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
        margin: 10px;
    }
    h2 {
        margin-left: 10px;
        font-weight: normal;
    }
`;

export const RoleCard = styled.div`
    display: flex;
    border-color: #333333;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
    p {
        margin: 10px;
    }
    &:hover {
        background-color: #f2f2f2;
    }
`;

export const SelectedRoleCard = styled.div`
    background-color: lightblue;
`;

export const RoleCardBySelectedRoleCard = styled.div`
    display: flex;
    border-color: #333333;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
    p {
        margin: 10px;
    }
    &:hover {
        background-color: #f2f2f2;
    }
    background-color: lightblue;
`;
export const SubmitButton = styled.button``;
