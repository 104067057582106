import { VOneUrl } from "../url";

/*
The Url endpoints for the Patient API.

Attributes:
    base: The base url.
 */
export class PatientUrl extends VOneUrl {
    public base: string;
    public getAll: string;
    public postDiagnosis: string;
    public postExam: string;
    public postInvestigation: string;
    public getInvestigations: string;
    public postConsultation: string;
    public getConsultations: string;
    public postTreatment: string;
    public getAllTreatments: string;
    public postNote: string;
    public getAllNotes: string;
    public getAllBloods: string;
    public postBlood: string;
    public getAllObservations: string;
    public postObservations: string;
    public postDrug: string;
    public getAllDrugs: string;
    public postForm: string;
    public postAdditionalTests: string;
    public getAdditionalTests: string;
    public postLocation: string;

    constructor() {
        super();
        this.base = this.defineRoot() + "api/v1/patients/";
        this.getAll = this.base + "get/all";
        this.postDiagnosis = this.base + "actions/user-diagnosis/post";
        this.postExam = this.base + "actions/examine";
        this.postInvestigation = this.base + "actions/investigate";
        this.getInvestigations = this.base + "actions/investigate/get-all";
        this.postTreatment = this.base + "actions/treatment/post";
        this.getAllTreatments = this.base + "actions/treatment/get";
        this.postNote = this.base + "actions/documentation/post";
        this.getAllNotes = this.base + "actions/documentation/get-all";
        this.getAllBloods = this.base + "actions/blood-order/get";
        this.postBlood = this.base + "actions/blood-order/post";
        this.getAllObservations = this.base + "observations/readings/get_all";
        this.postObservations = this.base + "observations/readings/take";
        this.postDrug = this.base + "actions/drug-chart/post";
        this.getAllDrugs = this.base + "actions/drug-chart/get";
        this.postForm = this.base + "actions/forms/post";
        this.postConsultation = this.base + "actions/consultation/post";
        this.getConsultations = this.base + "actions/consultations/get-all";
        this.postAdditionalTests = this.base + "actions/additionaltests/post";
        this.getAdditionalTests = this.base + "actions/additionaltests/get";
        this.postLocation = this.base + "movements/move-patient";
    }
}
