// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";

/**
 * The payload for posting a location update.
 */
export interface PostLocationPayload {
    patient_id: number;
    session_id: number;
    new_location?: string;
    new_type_of_location: string;
}

/**
 * The interface for the response from the post location endpoint.
 */
export interface PostLocationResponse {
    status: number;
    error?: string;
    message?: string;
}

/**
 * Type definition for the post location function.
 */
export type PostLocationType = (
    jwt: string,
    postLocationPayload: PostLocationPayload
) => Promise<PostLocationResponse>;

/**
 * Function to post a location update for a patient.
 *
 * @param jwt - The JWT of the user making the request.
 * @param postLocationPayload - The payload containing the location update details.
 * @returns The API response from the endpoint.
 */
export async function postLocationPatients(
    jwt: string,
    postLocationPayload: PostLocationPayload
): Promise<PostLocationResponse> {
    const url = new PatientUrl().postLocation;
    let response: AxiosResponse<any>;

    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    try {
        response = await axios.post<PostLocationResponse | string>(
            url,
            {
                patient_id: postLocationPayload.patient_id,
                session_id: postLocationPayload.session_id,
                new_location: postLocationPayload.new_location,
                new_type_of_location: postLocationPayload.new_type_of_location,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    token: jwt,
                },
                // Allow handling of all HTTP status codes
                validateStatus: () => true,
            }
        );
    } catch (error) {
        // Handle network or other Axios-related errors
        return {
            status: 500,
            error: "Network or other error occurred",
        };
    }

    if (response.status === 201) {
        // Successful response
        return {
            status: response.status,
            message: response.data as string,
        };
    } else {
        // Other response codes with an error message
        return {
            status: response.status,
            error: response.data as string,
        };
    }
}
