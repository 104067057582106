import { TreatmentPageTranslations } from "./types";

export const deTranslations: TreatmentPageTranslations = {
    pageTitle: "Behandlung bestellen",
    treatment: "Behandlung",
    priority: "Priorität",
    justification: "Begründung für die Behandlung",
    orderTreatment: "Behandlung bestellen",
    clearForm: "Formular löschen",
    orderedTreatments: "Bestellte Behandlungen",
};
