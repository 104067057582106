import { DischargePageTranslations } from "./types";

export const enTranslations: DischargePageTranslations = {
    dischargedPatients: "Discharged Patients",
    table: {
        feedback: "Feedback",
        noPatient: "No patients discharged yet",
        columns: {
            location: "Location",
            name: "Name",
            ageGender: "Age/Gender",
            symptom: "Symptom",
            diagnosis: "Diagnosis",
            realDiagnosis: "Real Diagnosis",
            score: "Score",
        },
    },
};
