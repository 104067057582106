import styled from "@emotion/styled";
import { AiFillQuestionCircle } from "react-icons/ai";

export const Nav = styled.nav`
    background-color: #334155;
    font-family: "Helvetica", Arial, sans-serif;
    border-bottom: 2px solid #2f4f4f;
    height: 80px;
`;
export const Container = styled.div`
    padding: 0.5rem 1.25rem;
`;
export const FlexContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    gap: 1.5em;
`;
export const FlexItem = styled.div`
    display: flex;
    flex-grow: 1;
`;
export const FlexItemCenter = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    @media (min-width: 640px) {
        align-items: stretch;
        justify-content: flex-start;
    }
`;
export const FlexItemShrink = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
`;
export const StyledAiFillQuestionCircle = styled(AiFillQuestionCircle)`
    font-size: 1.8em;
    margin-left: 0.75rem;
    color: #d1d5db;
    cursor: pointer;
    &:hover {
        color: #9ca3af;
    }
`;

export const EndGameButton = styled.button`
    font-size: 0.938rem;
    white-space: nowrap;
    font-family: Helvetica, Arial, sans-serif;
    padding: 0.6em 1.3em;
    border-radius: 0.8em;
    cursor: pointer;
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 0.5em;
    font-weight: 500;
    width: fit-content;
    background: rgb(5, 132, 199);
    color: white;
    border: none;
`