import React, { useEffect, useState } from "react";
// import init, * as wasm from "../../../rust_modules/standard_chat/pkg/standard_chat";


export const Chat: React.FC = () => {

  const[rustChat, setRustChat] = useState<
                                                                  ((a: string, b: string) => string | null)
                                                               >(null);
  const[message, setMessage] = useState<string>("");
  const[data, setData] = useState<string>("");
  const [response, setResponse] = useState<string>("");

  useEffect(() => {
    init().then(() => {
      setRustChat(() => wasm.chat);
      setData('[["are you in pain", "no"], ["are you short of breath", "yes"]]');
    });
  }, []);

  const handleSendMessage = () => {
    if (rustChat) {
      const reply = rustChat(message, data);
      setResponse(reply || "No response.");
    }
  };

  return (
      <div>
        <h1>Chat</h1>
        <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message"
        />
        <button onClick={handleSendMessage}>Send</button>
        <div>
          <p>Response: {response}</p>
        </div>
      </div>
  );
};
