import { FormConfigTranslations } from './types';

export const enTranslations: FormConfigTranslations = {
    ecgImages: {
        label: "ECG Images Enabled",
        description: "Enable ECG images for patients in the class. ECG images will be available as investigations, providing additional diagnostic information for each patient case."
    },
    clinicalReasoning: {
        label: "Clinical Reasoning Prompts",
        description: "A simulated senior doctor will ask students for differentials on patients, encouraging critical thinking and clinical reasoning skills."
    }
};