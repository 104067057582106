import styled from "@emotion/styled";

export const ReviewPageTableHeadWithIcon = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: 600;
    gap: 2px;
    justify-content: start;
    font-size: 14px;
    padding: 10px 0px;
    align-item: center;
    @media (min-width: 1380px) {
        gap: 5px;
        font-size: 14px;
      }
    @media (max-width:767px) {
        flex-direction: column;
      }
`;
export const ReviewPageTableHeadWithIconItem = styled.div`
    display: flex;
    flex-direction: row;
`;
export const ReviewPageTable = styled.table`
    border-spacing: 0px;
    margin-top: 5px;
    width: 100%;
    cursor: pointer;
`;
export const ReviewPageTableHead = styled.thead`
    text-align: left;
`;
export const ReviewPageTableBody = styled.tbody`
`;
export const ReviewPageTableRow = styled.tr`
    font-size: 14px;
    cursor: pointer;
    &:nth-child(odd) {
        background-color: #f3f4f6;
        color: #000;
    }
    &:nth-child(even) {
        background-color: #e5e7eb;
        color: #000;
    }
`;
export const ReviewPageTableData = styled.td`
    padding: 15px 20px;
`;
export const ReviewPageTableRowHead = styled.th`
    padding: 10px 14px;
`;