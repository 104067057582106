// This file defines the Blogs page of the website.

//External imports
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//Internal Imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import { FaSearch } from "react-icons/fa";
import { MdArrowOutward } from "react-icons/md";
import cardPhoto from "./placeholder_img.png";
import {
    BlogsPageContainer,
    BlogsPageHeadingMain,
    BlogsPageHead,
    BlogsPageTitle,
    BlogsPageSearchField,
    BlogsPageSearchFieldSpan,
    BlogsPageSearchFieldInput,
    BlogsPageBackgroundSection,
    BlogsPageCardContainer,
    BlogsPageCard,
    BlogsPageCardFigure,
    BlogsPageCardFigureImage,
    BlogsPageCardTitle,
    BlogsPageCardDescription,
    BlogsPageCardDescriptionSubTitle,
    BlogsPageCardDescriptionParagraph,
    BlogFiltersArea,
    BlogsPageSearchFieldDropdown,
    BlogsPageSearchFieldDropdownSpan,
    BlogsPageSearchFieldDropdownInput,
    DropdownFieldArea,
    DropdownField,
    DropdownList,
    DropdownListItem,
} from "./BlogsPage.styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import { useLanguage } from "../../../core/translations/LanguageContext";
import { loadBlogs } from "./blogs/blogLoader";
import { isBlog } from "./blogs/validator";
import { Blog } from "./blogs/types";

// Translations
import { BlogPageTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

const TRANSLATIONS: Record<SupportedLanguage, BlogPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};


/*
The Blogs page of the website.
 */
function BlogsPage() {
    const [selectedCategory, setSelectedCategory] = useState("");
    const { language } = useLanguage();
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const translations = TRANSLATIONS[language];

    useEffect(() => {
        const fetchBlogs = async () => {
            setLoading(true);
            setError(null);
            try {
                // Load blogs dynamically based on language
                const loadedBlogs = await loadBlogs(language);
                // Validate blogs
                const validatedBlogs = loadedBlogs.filter(isBlog);
                setBlogs(validatedBlogs);
            } catch (err) {
                console.error("Failed to load blogs:", err);
                setError("Failed to load blogs.");
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, [language]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    // Array of blogs records

    const categoryOptions = [
        {
            name: "User Testing",
        },
        {
            name: "Two",
        },
        {
            name: "Three",
        },
        {
            name: "Four",
        },
    ];
    return (
        <MainPageLayout>
            <BlogsPageContainer>
                <BlogsPageHead>
                    <BlogsPageHeadingMain className="blog-heading">
                        <BlogsPageTitle>{translations.main.title}</BlogsPageTitle>
                    </BlogsPageHeadingMain>
                    <BlogFiltersArea>
                        <BlogsPageSearchFieldDropdown>
                            <DropdownFieldArea>
                                <BlogsPageSearchFieldDropdownInput
                                    type="text"
                                    placeholder={translations.main.selectCategory}
                                    value={selectedCategory}
                                    readOnly
                                />
                                <BlogsPageSearchFieldDropdownSpan>
                                    <IoIosArrowDown size={22} />
                                    <IoIosArrowUp size={22} />
                                </BlogsPageSearchFieldDropdownSpan>

                                <DropdownField>
                                    <DropdownList>
                                        {categoryOptions.map(
                                            (categoryData, index) => (
                                                <DropdownListItem
                                                    onClick={() =>
                                                        setSelectedCategory(
                                                            categoryData.name
                                                        )
                                                    }
                                                    key={index}
                                                >
                                                    {categoryData.name}
                                                </DropdownListItem>
                                            )
                                        )}
                                    </DropdownList>
                                </DropdownField>
                            </DropdownFieldArea>
                        </BlogsPageSearchFieldDropdown>
                        <BlogsPageSearchField>
                            <BlogsPageSearchFieldSpan>
                                {" "}
                                <FaSearch size={22} />
                            </BlogsPageSearchFieldSpan>
                            <BlogsPageSearchFieldInput
                                type="text"
                                placeholder={translations.main.searchPlaceholder}
                            />
                        </BlogsPageSearchField>
                    </BlogFiltersArea>
                </BlogsPageHead>
            </BlogsPageContainer>
            <BlogsPageBackgroundSection>
                <BlogsPageContainer>
                    <BlogsPageCardContainer>
                        {blogs.map((blog, i) => (
                            <BlogsPageCard key={i}>
                                <Link to={`/blog/${blog.slug}`}>
                                    <BlogsPageCardFigure>
                                        <BlogsPageCardFigureImage
                                            src={blog.featureImage}
                                            width={379}
                                            height={208}
                                            alt={blog.title}
                                        />
                                        <BlogsPageCardDescription>
                                            <BlogsPageCardDescriptionSubTitle>
                                                {blog.title}
                                            </BlogsPageCardDescriptionSubTitle>
                                            <MdArrowOutward />
                                            <BlogsPageCardDescriptionParagraph>
                                                {blog.summary}
                                            </BlogsPageCardDescriptionParagraph>
                                        </BlogsPageCardDescription>
                                    </BlogsPageCardFigure>
                                </Link>
                            </BlogsPageCard>
                        ))}
                    </BlogsPageCardContainer>
                </BlogsPageContainer>
            </BlogsPageBackgroundSection>
        </MainPageLayout>
    );
}

export default BlogsPage;
