import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

// Main form container style
export const RegistrationFormContainer = styled.form`
  font-family: "Helvetica", "Arial", sans-serif;
  max-width: 400px;
  padding-top: 20px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
`;

// Div container for form elements
export const FormGroup = styled.div`
  width: 100%;
`;

// Label style
export const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
`;

// Input field style (for text, email, password)
export const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures padding doesn't affect overall width */
  font-size: 15px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

// Select dropdown style
export const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures padding doesn't affect overall width */
  font-size: 15px;
`;

// Button style
export const Button = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

// Error message style
export const ErrorText = styled.p`
  outline: none;
  color: red;
  text-align: center;
`;

// Success message style
export const SuccessClass = styled.div`
  text-align: center;
`;

// Success message text
export const SuccessMessage = styled.p`
  color: green;
  font-weight: bold;
`;

// Container for first name and last name fields
export const FirstLastNameContainer = styled.div`
  display: flex;
  gap: 1rem; /* Space between fields */
`;

// Individual field container inside first/last name
export const FieldContainer = styled.div`
  flex: 1; /* Each child div takes up equal space */
`;

// Animation for shaking the form when there is an error
export const JiggleAnimation = styled.div`
  animation: jiggle 0.5s ease-in-out;

  @keyframes jiggle {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(5px);
    }
    75% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

// Anchor link style for "get started" and "or" sections
export const GetStartedAnchor = styled.a`
  text-decoration: none;
`;

export const OrSection = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  min-width: 240px;
  max-width: 400px;
`;

export const OnSectionAnchor = styled.a`
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
`;

export const RegistrationSuccess = styled.div`
  font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    div {
        width: 100%;
    }
    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
    }
    input[type="email"],
    select,
    input[type="password"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
        font-size: 15px;
    }
    input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }

    button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
    }
    p {
        outline: none;
        color: red;
        text-align: center;
    }

    button:hover {
        background-color: #0056b3;
    }
    input:hover {
        background-color: #f2f2f2;
    }
         text-align: center;
    p {
        color: green;
        font-weight: bold;
    }
`;

export const RegistrationSuccessJiggle = styled.div<{ animation: string }>`
font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    div {
        width: 100%;
    }
    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
    }
    input[type="email"],
    select,
    input[type="password"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
        font-size: 15px;
    }
    input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }

    button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
    }
    p {
        outline: none;
        color: red;
        text-align: center;
    }

    button:hover {
        background-color: #0056b3;
    }
    input:hover {
        background-color: #f2f2f2;
    }
          text-align: center;
    p {
        color: green;
        font-weight: bold;
    }
                  animation: ${({ animation }) => animation} 0.5s ease-in-out;

`;

export const jiggle = keyframes`
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(5px);
    }
    75% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
`;

export const H2 = styled.h2``;
export const Paragraph = styled.p``;
export const Image = styled.img``;
export const DIV = styled.div``;
export const SubmitButton = styled.button``;


export const RegistrationJiggle = styled.form<{ animation: string }>`
font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    div {
        width: 100%;
    }
    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
    }
    input[type="email"],
    select,
    input[type="password"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
        font-size: 15px;
    }
    input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }

    button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
    }
    p {
        outline: none;
        color: red;
        text-align: center;
    }

    button:hover {
        background-color: #0056b3;
    }
    input:hover {
        background-color: #f2f2f2;
    }
                  animation: ${({ animation }) => animation} 0.5s ease-in-out;

`;

export const RegistrationForms = styled.form`
    font-family: "Helvetica", "Arial", sans-serif;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    div {
        width: 100%;
    }
    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
    }
    input[type="email"],
    select,
    input[type="password"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
        font-size: 15px;
    }
    input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }

    button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
    }
    p {
        outline: none;
        color: red;
        text-align: center;
    }

    button:hover {
        background-color: #0056b3;
    }
    input:hover {
        background-color: #f2f2f2;
    }
`;