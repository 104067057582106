import { SelectOption } from "../SelectBox";

export const onSelectOptionConversion = (
    options: string[],
    optionsType: { [key: string]: string },
    sort?: boolean
) => {
    const convertedOption: SelectOption[] = options.map(
        (item: string, index: number) => ({
            label: optionsType[item as keyof typeof optionsType],
            value: item,
            turn: index + 1,
        })
    );

    if (sort)
        return convertedOption.sort((a, b) => a.value.localeCompare(b.value));
    else return convertedOption;
};

export const onSelectOptionChange = (
    state: SelectOption,
    options: SelectOption[],
    setState: React.Dispatch<React.SetStateAction<SelectOption | undefined>>
) => {
    const selectedItem: SelectOption | undefined = options.find(
        (item: SelectOption) => item.value === state.value
    );

    if (selectedItem) setState({ ...selectedItem });
};
