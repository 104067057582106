import styled from "@emotion/styled";

export const BlogDetailPageContainer = styled.div`
    width: 100%;
    margin: auto;
    @media screen and (max-width:768px) {
        width: 90%;
    }
`;
export const BlogDetailMenuIcon = styled.div`
`;
export const BlogDetailMenuDetail = styled.div`
    display: flex;
    gap: 15px;
    flex-direction: column;
`;
export const BlogDetailContentMenuBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: -webkit-fill-available;      
`;
export const BlogDetailPageBreadCrumb = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center; 
    @media screen and (max-width:560px) {
        flex-wrap:wrap;
    }
`;
export const BlogDetailPageHeading = styled.div`
    & > p {
        display: flex;
        align-items: center;
        @media screen and (max-width:768px) {
            margin: 15px 0 10px;
            line-height: 1.5rem;
        }
    }
    & > p > a {
        color: #4f7281;
        font-weight: 600;
        &:hover {
            text-decoration: underline;
        } 
    }
`;
export const BlogDetailBreadCrumbsBtn = styled.div`
    & > a {
        background-color: #173b5e;
        color: #ffffff;
        padding: 7px 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        transition: 0.6s all;
        @media screen and (max-width:560px) {
            padding: 5px 10px;
        }
        &:hover{
            background-color: #232242;
        }
    }
`;
export const BlogDetailsHeading = styled.h2`
    font-size: 2.25rem;
    line-height: 2.5rem;
    color: #173b5e;
    font-family: "Helvetica", "Arial", sans-serif;
    @media screen and (max-width:768px) {
        margin: 10px 0px;
        line-height: 1.5rem;
    }
`;
export const BlogDetailContentMainSection = styled.div`
    display: flex;
    width: 100%;
    gap: 60px;
    margin-top: 20px;
    flex-direction: row;
    @media screen and (max-width:769px) {
        flex-direction: column;
    }
`;
export const BlogDetailContentMenu = styled.div`
    background-color: #D6E4E9;
    justify-content: start;
    align-items: center;
    min-width: 200px;
    height: fit-content;
    min-height: 150px;
    padding: 20px;
    font-family: Inter, sans-serif;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    border-radius: 20px;
`;
export const BlogDetailContentMenuAnchor = styled.a`
    color: #3284d1;
    text-decoration: none;
    cursor: pointer;
    font-family: "Helvetica", "Arial", sans-serif;
`;
export const BlogDetailContentMenuHighlightedText = styled.p`
    color: #3284d1;
    font-family: "Helvetica", "Arial", sans-serif;
    margin-bottom: 5px;  
`;
export const BlogDetailContentMenuText = styled.p`
    color: #182b3d;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: "Helvetica", "Arial", sans-serif;
`;
export const BlogDetailDescription = styled.div`
    text-align: justify;
    font-family: "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    font-weight: regular;
    color: #173b5e;
    line-height: 1.7em;
`;
export const BlogDetailDescriptionParagraph = styled.p`
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-size: 1.063em;
    line-height: 1.6em;
    font-family: "Helvetica", "Arial", sans-serif;
    @media screen and (max-width:560px) {
        font-size: 14px;
    }
`;
export const BlogDetailDescriptionImage = styled.img`
    width: 100%;
    border-radius: 10px;
`;
export const BlogDetailDivSection = styled.div`
`;