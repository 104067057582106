import styled from "@emotion/styled";

export const DiseaseChoicesContainer = styled.div`
    display: flex;
    font-family: "Helvetica", Arial, sans-serif;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    width: 100%;
    background-color: #f7fafc;
    border-radius: 0.5rem;
    overflow: hidden;
`;
export const DiseaseChoicesContainerSplit = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
`;
export const LeftPanel = styled.div`
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    height: 38em;
`;
export const RightPanel = styled.div`
    padding: 1.8em 0.8em;
`;
export const CategoryButtonContainer = styled.div<{ selected?: boolean }>`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 1.2em 0.8em;
    background-color: ${(props) =>
        props.selected ? "#334155" : "transparent"};
    color: ${(props) =>
        props.selected ? "#ffffff" : "#4a5568"};
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    &:hover {
        background-color: #4a5568;
        color:#ffffff ;
    }
`;
export const DiseaseOption = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    overflow: hidden;
`;
