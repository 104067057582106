import styled from "@emotion/styled";

export const ReviewPageRightSection = styled.div`
    width: 100%;
    background: #f3f3f3;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 70vh;
    padding: 20px;
`;
export const ReviewPageFilterSection = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
    padding: 10px;
    & > div {
        width: 90%;
    }
`;
export const ReviewPageRightSectionTitle = styled.h3`
    margin-top: 15px;
    @media (min-width: 1380px) {
        margin-top: 0px;
    }
`;
export const ReviewPageParagraph = styled.p``;
export const ReviewPageRightSectionHoldingText = styled.p`
    font-size: 14px;
    margin-top: 10px;
`;