import styled from "@emotion/styled";

export const IconActionButtonContainer = styled.div<{
    selected: boolean;
}>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    width: fit-content;
    height: fit-content;
    background-color: #e5e7eb;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 4px;

    &:hover {
        background-color: #d1d5db;
    }

    &:hover svg {
        color: #0ea5e9;
    }

    svg {
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: ${({ selected }) => (selected ? "#0ea5e9" : "#9ca3af")};
        cursor: pointer;
    }
`;
