import { BloodTestTranslations } from './types';

export const enBloodTestTranslations: BloodTestTranslations =  {
    groups: {
        FULL_BLOOD_COUNT: "Full Blood Count",
        LIVER_PROFILE: "Liver Profile",
        RENAL_PROFILE: "U&Es",
        INFLAMMATORY_MARKERS: "Inflammatory Markers",
        BONE_PROFILE: "Bone Profile",
        COAGULATION: "Coagulation",
        ENDOCRINOLOGY: "Endocrinology",
        HAEMOTINICS: "Haemotinics",
        CARDIAC_MARKERS: "Cardiac Markers",
        MISC: "Misc",
        DIABETES: "Diabetes",
        ABG: "ABG",
        VBG: "VBG",
        GASES: "Blood Gases",
        LIPIDS: "Lipids"
    },
    types: {
        HAEMOGLOBIN: "Haemoglobin",
        HCT: "HCT",
        MCH: "MCH",
        MCHC: "MCHC",
        MCV: "MCV",
        PLATELETS: "Platelets",
        TOTAL_WHITE_BLOOD_CELLS: "Total WBC",
        LYMPHOCYTES: "Lymphocytes",
        BASOPHILS: "Basophils",
        EOSINOPHILS: "Eosinophils",
        MONOCYTES: "Monocytes",
        NEUTROPHILS: "Neutrophils",
        ALBUMIN: "Albumin",
        ALP: "ALP",
        ALT: "ALT",
        AST: "AST",
        BILIRUBIN: "Bilirubin",
        BILIRUBIN_CONJUGATED: "Conjugated Bilirubin",
        GGT: "GGT",
        TOTAL_PROTEIN: "Total Protein",
        SODIUM: "Sodium",
        POTASSIUM: "Potassium",
        CREATININE: "Creatinine",
        UREA: "Urea",
        EGFR: "eGFR",
        CRP: "CRP",
        PROCALCITONIN: "Procalcitonin",
        MAGNESIUM: "Magnesium",
        PHOSPHATE: "Phosphate",
        CALCIUM: "Calcium",
        APTT: "APTT",
        FIBRINOGEN: "Fibrinogen",
        INR: "INR",
        PROTHROMBIN: "Prothrombin",
        D_DIMER: "D-Dimer",
        FT3: "Free T3",
        FT4: "Free T4",
        TSH: "TSH",
        FERRITIN: "Ferritin",
        FOLIC_ACID: "Folic Acid",
        IRON: "Iron",
        TRANSFERRIN: "Transferrin",
        TRANSFERRIN_SATURATION: "Transferrin Saturation",
        VITAMIN_B12: "Vitamin B12",
        TROPONIN: "Troponin",
        NTPRO_BNP: "BNP",
        GLUCOSE: "Glucose",
        HBA1C: "HbA1c",
        CK: "Creatine Kinase",
        CKMB: "Creatine Kinase MB",
        ALCOHOL: "Alcohol",
        URIC_ACID: "Uric Acid",
        AMYLASE: "Amylase",
        LIPASE: "Lipase",
        LDH: "LDH",
        TRIGLYCERIDES: "Triglycerides",
        CHOLESTEROL: "Total Cholesterol",
        LDL: "LDL",
        HDL: "HDL",
        NON_HDL_CHOLESTEROL: "Non-HDL Cholesterol"
    }
};