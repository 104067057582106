// External Imports
import React from "react";

// Internal Imports
import { PatientPictureContainer } from "./PatientPicture.styles";

/**
 * PatientPictureProps
 * 
 * This is the props interface for the PatientPicture component.
 * 
 * @param url - The url of the patient's picture.
 */
interface PatientPictureProps {
    url: string;
}

/**
 * PatientPicture
 * 
 * This is the PatientPicture component. This component is used to display the patient's picture.
 */
export const PatientPicture: React.FC<PatientPictureProps> = ({ url }) => {
    return (
        <PatientPictureContainer>
            <img src={url} alt="Patient Face" />
        </PatientPictureContainer>
    );
}

