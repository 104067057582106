import { TreatmentTypesTranslations } from "./types";

export const deTreatmentTranslations: TreatmentTypesTranslations = {
    CARDIOVERSION: "Externe Kardioversion",
    NON_INVASIVE_VENTILATION: "Nicht-invasive Beatmung",
    VALSAVA: "Valsalva Pressversuch",
    SUPRAPUBIC_CATHETER: "Suprapubischer Blasenkatheter",
    URETHRAL_CATHETER: "Transurethraler Dauerkatheter",
    CHEST_DRAIN: "Thoraxdrainage",
    REBREATH_BAG: "Rückatmung in eine Papiertüte",
    GASTRIC_TUBE: "Magensonde legen",
    LINTON_PROBE: "Transösophageale Ballontamponade",
    PARTICLE_REPOSITIONING: "Partikelrepositionsmanöver",
};
