import React from "react";
import { StyledTextArea } from "./TextArea.styles";

interface InputProps extends React.HTMLProps<HTMLTextAreaElement> {
    color?: string;
    borderColor?: string;
    height?: string;
    onChangeText?: (str: string) => void;
}

function TextArea({
    color,
    borderColor,
    height,
    onChangeText,
    onChange,
    ...props
}: InputProps) {
    return (
        <StyledTextArea
            {...props}
            as="textarea"
            height={height}
            color={color}
            borderColor={borderColor}
            onChange={
                onChangeText ? (e) => onChangeText(e.target.value) : onChange
            }
        />
    );
}

export default TextArea;
