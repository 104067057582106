import styled from "@emotion/styled";

export const NotesItem = styled.li`
    list-style: none;
    font-family: "Helvetica", Arial, sans-serif;
`;

export const ListItemContainer = styled.div`
    position: relative;
    padding-bottom: 2rem;
`;

export const VerticalLine = styled.span`
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    margin-left: -1px;
    transform: translateX(-4px);
    width: 0.125rem;
    height: 100%;
    background-color: #e5e7eb;
`;

export const ListItemContentContainer = styled.div`
    display: flex;
    gap: 0.75rem;
    position: relative;
`;

export const ListIconContainer = styled.div`
    width: fit-content;
    height: fit-content;
    margin-top: 5px;
`;

export const ListIcon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 0 0 calc(8px + 0px) #ffffff;
    width: 2rem;
    height: 2rem;
    background-color: ${({ color }: { color: string }) => color};

    svg {
        width: 1.25rem;
        height: 1.25rem;
        color: #ffffff;
    }
`;

export const ListContentRightSide = styled.div`
    margin-top: 0.25rem;
    flex: 1 1 0%;
`;

export const ListContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ListContentTitle = styled.h3`
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #000000;
    margin: 0px;
`;

export const ListContentDescription = styled.p`
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6b7280;
    margin: 0px;
`;
