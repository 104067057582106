import styled from "@emotion/styled";

export const TooltipTriggerButton = styled.button`
    width: fit-content;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    width: fit-content;
    padding: 0px;
`;


export const TooltipIcon = styled.div`
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    color: #333333;
`;
