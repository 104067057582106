import React from "react";
import {
    ClockText,
    PopupContainer,
    PopupContent,
    TransparentContainer,
    ProgressBarWrapper,
    ProgressBar,
    StartNumber,
    EndNumber
} from "./DigitalClockPopup.styles";

const DigitalClockPopup: React.FC = () => {
    return (
        <TransparentContainer>
            <PopupContainer>
                <PopupContent>
                    <ClockText>Progress through Shift</ClockText>
                    <ProgressBarWrapper>
                        <StartNumber>0</StartNumber>
                        <ProgressBar value={5} max={8} />
                        <EndNumber>8</EndNumber>
                    </ProgressBarWrapper>
                    <ClockText>Hours</ClockText>
                </PopupContent>
            </PopupContainer>
        </TransparentContainer>
    );
};

export default DigitalClockPopup;
