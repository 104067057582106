// Defines the page that tells the visitor about the company and its vision.
import { useRef, useState } from "react";
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import { FlexContainer, PageContainer, Section } from "./AboutPage.styles";
import { ProductOverview } from "./subComponents/ProductOverview/ProductOverview";
import { Vision } from "./subComponents/Vision/Vision";


// The AboutPage component.
function AboutPage() {

  const visionRef = useRef<HTMLDivElement>(null);

  const handleReadMore = () => {
    visionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <PageContainer>
      <MainPageLayout>
        <Section style={{ paddingBottom: "4.5rem" }}>
          <FlexContainer>
            <ProductOverview handleReadMore={handleReadMore} />
            <div className="hidden lg:block lg:w-3/5 overflow-hidden"></div>
          </FlexContainer>
        </Section>
        <Vision visionRef={visionRef}/>
      </MainPageLayout>
    </PageContainer>
  );
}

export default AboutPage;
