// External Imports
import React, { useState } from "react";

// Internal Imports
import {
    OrderTreatmentResultListItemContainer,
    OrderTreatmentResultListItemContent,
    OrderTreatmentResultListItemContentContainer,
    OrderTreatmentResultListItemContentTitle,
    StyledOrderTreatmentResultListItem,
} from "./OrderTreatmentListItem.styles";
import TurnTablet from "../../../notePage/components/TurnTablet/TurnTablet";
import WebsiteButton from "../../../../../components/core/button/WebsiteButton";
import { formatGameTime } from "../../../../../core/gameTime/formatTime";
import { turnsToTime } from "../../../../../core/LocalStorage/turnLogic/core";
// import OrderInvestigationReportModal from "../orderInvestigationReportModal/OrderInvestigationReport";

/**
 * OrderTreatmentResultListItemProps
 */
interface OrderTreatmentResultListItemProps {
    title: string;
    turn_ordered: number;
    reason?: string;
}

/**
 * OrderTreatmentResultListItem
 * 
 * This component is a list item for the investigation results.
 */
export const OrderTreatmentListItem = ({
    title,
    turn_ordered,
    reason
}: OrderTreatmentResultListItemProps) => {
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);

    return (
        <>
            <StyledOrderTreatmentResultListItem>
                <OrderTreatmentResultListItemContainer>
                    <OrderTreatmentResultListItemContentContainer>
                        <OrderTreatmentResultListItemContentTitle>
                            {title}
                        </OrderTreatmentResultListItemContentTitle>
                        <OrderTreatmentResultListItemContent>
                            <TurnTablet turn={formatGameTime(turnsToTime(turn_ordered))} />
                        </OrderTreatmentResultListItemContent>
                    </OrderTreatmentResultListItemContentContainer>
                </OrderTreatmentResultListItemContainer>
            </StyledOrderTreatmentResultListItem>
        </>
    );
}