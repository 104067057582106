import styled from "@emotion/styled";

export const StyledOrderTreatmentResultListItem = styled.li`
    display: block;
    border-top: 1px solid #e5e7eb;
    font-family: "Helvetica", Arial, sans-serif;

    &:first-of-type {
        border-top: none;
    }

    &:hover {
        background-color: #f9fafb;
    }
`;

export const OrderTreatmentResultListItemContainer = styled.div`
    padding: 1rem;

    @media (min-width: 640px) {
        padding: 1rem 1.5rem;
    }
`;

export const OrderTreatmentResultListItemContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
`;

export const OrderTreatmentResultListItemContentTitle = styled.h1`
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #0284c7;
`;

export const OrderTreatmentResultListItemContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
`;
