import { ScoringTranslations } from './types';

export const deTranslations: ScoringTranslations = {
    labels: {
        protocol: "Protokoll",
        diagnosis: "Diagnose",
        neverEvents: "Nie-Ereignisse"
    },
    ariaLabels: {
        protocolLock: "Protokoll-Score-Sperre umschalten",
        diagnosisLock: "Diagnose-Score-Sperre umschalten",
        neverEventsLock: "Nie-Ereignisse-Score-Sperre umschalten",
        protocolScore: "Protokoll-Punktzahl",
        diagnosisScore: "Diagnose-Punktzahl",
        neverEventsScore: "Nie-Ereignisse-Punktzahl"
    }
};