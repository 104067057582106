import { AdditionalTestTranslations } from "./types";

export const deTranslations: AdditionalTestTranslations = {
    testLabel: " Zusätzliche Tests anfordern",
    professional: "Berufsgruppe auswählen",
    professionalOptions: [
        { value: "PHYSIO", label: "Physio" },
        { value: "NURSE", label: "Pflegepersonal" },
    ],
    deailedInspection: "3-Seiten-Inspektion",
    detailedInspectionCheckbox: [
        { name: "ANTERIOR_VIEW", label: "Frontansicht" },
        { name: "POSTERIOR_VIEW", label: "Rückansicht" },
        { name: "SIDE_VIEW", label: "Seitenansicht" },
    ],
    specialTests: "Provokationstests",
    specialTestsCheckbox: [
        { name: "SLR", label: "Lasegué-Zeichen" },
        { name: "STEINMANN", label: "Steinman 1-Zeichen" },
        { name: "FABERS_SIGN", label: "Vierer-Zeichen" },
    ],
    clinicalPrediction: "Klinische Vorhersageregeln",
    clinicalPredictionCheckbox: [
        { name: "WELLS_CRITERIA", label: "Wells Kriterien" },
        { name: "OTTAWA_ANKLE", label: "Ottawa-Knieregeln" },
        { name: "OTTAWA_KNEE", label: "Ottawa- Sprunggelenksregeln" },
        { name: "AMSTERDAM_WRIST", label: "Amsterdam-Handgelenksregeln" },
        { name: "CANADIAN_C_SPINE", label: "Canadian C-Spine-Regeln" },
    ],
    mobility: "Mobilität",
    mobilityCheckbox: [
        { name: "TRANSFERS", label: "Transfers" },
        { name: "AID_ASSESSMENT", label: "Hilfsmittelbedarf" },
        { name: "STAIR_ASSESSMENT", label: "Gangschule Treppe" },
        { name: "GAIT_ANALYSIS", label: "Gangschule" },
    ],
    adlAssessment: "ADL-Fähigkeit",
    adlRelatedAssessment: [
        { name: "TIP_TOEING", label: "Zehenstand" },
        { name: "SQUAT", label: "Kniebeuge" },
        { name: "SINGLE_LEG_STANCE", label: "Einbeinstand" },
    ],
    reasonReferral: "Grund für Überweisung",
    summary: "Zusammenfassung",
    clearButton: "Löschen",
    submitButton:"Überweisung abschicken",
    result:"Ergebnisse",
    pending: "ausstehend",
    available: "verfügbar",
};
