import { NewClassTranslations } from './types';

export const deTranslations: NewClassTranslations = {
    header: "Neue Klasse erstellen",
    classStartsOpen: "Klasse startet offen",
    createButton: "Klasse erstellen",
    errors: {
        noJwt: "Fehler beim Erstellen der Klasse: Kein JWT gefunden",
        createClass: "Fehler beim Erstellen der Klasse: "
    }
};