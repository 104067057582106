// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";

/*
The payload for posting additional tests.
 */
export interface PostAdditionalTestsPayload {
    turn: number;
    patient_id: number;
    professional: string;
    summary: string;
    reason: string;
    tests: string[];
}

/*
The interface for the test findings response.
 */
export interface AdditionalTestsData {
    findings: string;
    turn_ordered: number;
    turn_available: number;
    professional: string;
    summary: string;
    reason: string;
}

/*
The interface for the response from the post additional tests endpoint.
@status: The HTTP status code.
@error: The error message.
@data: The object containing the test findings and details.
 */
export interface PostAdditionalTestsResponse {
    status: number;
    error?: string;
    data?: AdditionalTestsData;
}

export type PostAdditionalTestsType = (jwt: string, payload: PostAdditionalTestsPayload) => Promise<PostAdditionalTestsResponse>;

/*
The function to post additional tests for a patient.

@jwt: The JWT of the user requesting the post.
@payload: The payload containing the turn, patient ID, professional, summary, reason, and tests.
@returns: The ApiResponse from the post additional tests endpoint.
 */
export async function postAdditionalTests(jwt: string, payload: PostAdditionalTestsPayload): Promise<PostAdditionalTestsResponse> {
    const url = new PatientUrl().postAdditionalTests;
    let response: AxiosResponse<any>;

    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    try {
        response = await axios.post<AdditionalTestsData | string>(url, {
            turn: payload.turn,
            patient_id: payload.patient_id,
            professional: payload.professional,
            summary: payload.summary,
            reason: payload.reason,
            tests: payload.tests
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });

    } catch (error) {
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 201) {
        console.log("Additional tests posted successfully:", response.data);
        return {
            status: response.status,
            data: response.data as AdditionalTestsData
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}