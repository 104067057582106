import { drugChartData, drugData, individualDrugData } from "./interface";
import { getSpecificPatientData, updatePatientData } from "./core";

// Function signature type for setting to local storage
export type SetDrugsToLocalStorageType = (
    patient_id: number,
    updatedDrugChart: drugChartData
) => void;

/*
Set the drug data for a patient in localStorage.
 */
export function setDrugsToLocalStorage(
    patient_id: number,
    updatedDrugChart: drugChartData
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has drug data, initialize if none
    let existingDrugs = patientData.drugs || {
        drugChart: {},
        anyAllergies: null,
        allergyList: [],
    };

    // Set the drug data for the turn
    existingDrugs.drugChart = updatedDrugChart;

    // Update the patient's drug data
    patientData.drugs = existingDrugs;

    // Update the patient data in localStorage
    updatePatientData(patient_id, patientData);
}

export type SetAllergiesToLocalStorageType = (
    patient_id: number,
    allergies: string[]
) => void;

/*
Set the allergies for a patient in localStorage.
 */
export function setAllergiesToLocalStorage(
    patient_id: number,
    allergies: string[]
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has drug data, initialize if none
    let existingDrugs = patientData.drugs || {
        drugChart: {},
        anyAllergies: null,
        allergyList: [],
    };
    // Set the allergies
    existingDrugs.anyAllergies = true;
    existingDrugs.allergyList = allergies;

    // Update the patient's drug data
    patientData.drugs = existingDrugs;

    // Update the patient data in localStorage
    updatePatientData(patient_id, patientData);
}

// Function signature type for getting from local storage
export type GetDrugsFromLocalStorageType = (
    patient_id: number
) => drugData | null;

// Function to get the drug data for a patient from localStorage
export function getDrugsFromLocalStorage(patient_id: number): drugData | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // Return the drug data, or null if no data is found
    return patientData?.drugs || null;
}

// Function signature type for getting allergies from local storage
export type GetAllergiesFromLocalStorageType = (
    patient_id: number
) => string[] | null;

// Function to get the allergies for a patient from localStorage
export function getAllergiesFromLocalStorage(
    patient_id: number
): string[] | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // if anyAllergies is false, return an empty array to indicate no allergies
    if (patientData?.drugs?.anyAllergies === false) {
        return [];
    } else if (patientData?.drugs?.anyAllergies === null) {
        // Return null if no data is found as this means the allergies have not been checked
        return null;
    } else {
        // Return the allergies, or null if no data is found
        return patientData?.drugs?.allergyList || null;
    }
}
