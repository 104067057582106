import styled from "@emotion/styled";

export const OrderAdditionalTestsContainer = styled.div`
    width: 100%;
`;

export const OrderAdditionalTestsDivider = styled.div`
    margin: 2rem 0px;
    width: 100%;
    height: 1px;
    background-color: #f3f4f6;
`;

export const OrderAdditionalTestsSpace = styled.div`
    margin: 2rem 0px;
    width: 100%;
    height: 1px;
`;
export const OrderAdditionalTestsPageContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: row;
    @media (max-width: 640px) {
        flex-direction: column;
    }
`;
