// External imports
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Internal imports
import PatientObservationsGraph, {
    PatientObsGraphData,
} from "./PatientObservationsGraph/PatientObservationsGraph";
import {
    GraphContainer,
    ObservationsContainer,
    ObservationsPageContainer,
} from "./PatientObservationsPage.styles";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import ObservationsTableComponent from "./TableSection/TableSection";
import { HandoverModal } from "../../../components/core/modal/handover-modal/HandoverModal";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Dependency injections for observation page only
import { GetAllObservationsType } from "../../../core/ServerApiLayer/VOne/patients/getObservations";
import { PostObservationsType } from "../../../core/ServerApiLayer/VOne/patients/postObservations";
import {
    SetObservationDataType,
    GetObservationDataType,
} from "../../../core/LocalStorage/patients/observations";
import { GetHandoverFromLocalStorageType } from "../../../core/LocalStorage/patients/handover";

// Translation imports
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { ObservationPageTranslations } from "./translations/types";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { HandoverModalTranslations } from "../../../components/core/modal/handover-modal/translations/types";
import { enHandoverTranslations } from "../../../components/core/modal/handover-modal/translations/en";
import { deHandoverTranslations } from "../../../components/core/modal/handover-modal/translations/de";

// Translations for page
const PAGE_TRANSLATIONS: Record<
    SupportedLanguage,
    ObservationPageTranslations
> = {
    en: enTranslations,
    de: deTranslations,
};

// Translations for handover
const HANDOVER_TRANSLATIONS: Record<
    SupportedLanguage,
    HandoverModalTranslations
> = {
    en: enHandoverTranslations,
    de: deHandoverTranslations,
};

/**
 * Interface for the PatientObservations component. This is where we are managing
 * the dependency injections.
 */
interface PatientObservationsProps extends WithPatientProps {
    getObservationsApiCall: GetAllObservationsType;
    postObservationsApiCall: PostObservationsType;
    setObservationsLocalStorage: SetObservationDataType;
    getObservationsLocalStorage: GetObservationDataType;
    getHandoverFromLocalStorage: GetHandoverFromLocalStorageType;
}

// Combined Props
type CombinedProps = PatientObservationsProps & WithGameDependenciesProps;

/**
 * Renders the patient observations page.
 */
const PatientObservationsBase: React.FC<CombinedProps> = ({
    // Review pgae dependencies
    getObservationsApiCall,
    postObservationsApiCall,
    setObservationsLocalStorage,
    getObservationsLocalStorage,
    getHandoverFromLocalStorage,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    const navigate = useNavigate();

    // Get the turn count and patient id
    const turnCount = turnDependencies.getTurnCount();
    const { id: patientId } = useParams<{ id: string }>();
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Translations
    const { language } = useLanguage();
    const pageTranslations = PAGE_TRANSLATIONS[language];
    const handoverTranslations = HANDOVER_TRANSLATIONS[language];

    // Get handover from local storage
    const handover = getHandoverFromLocalStorage(Number(patientId));

    // State for the graphs
    const [hoveredRow, setHoveredRow] = useState(-1);
    const [rawObservations, setRawObservations] = useState<any | null>([]); // State for the raw observations
    const [graphObservations, setGraphObservations] = useState<
        PatientObsGraphData[] | null
    >([]); // State for graph observations
    const [isLoading, setIsLoading] = useState(true); // Loading state

    // State for handover popup
    const [showHandoverPopup, setShowHandoverPopup] = useState(false);

    // Get jwt
    const jwt = getJwt();

    // Get the observations from the API or localStorage
    useEffect(() => {
        // @ts-ignore
        let localObservations = getObservationsLocalStorage(
            // @ts-ignore
            parseInt(patientId)
        );
        if (localObservations) {
            // If observations are found in localStorage, set them in state, and put loading to false
            setRawObservations(
                convertRawObservationsToArray(localObservations)
            );
            setGraphObservations(convertData(localObservations));
            setIsLoading(false);
        } else {
            // Get observations from the API if not in localStorage

            // If the jwt is not found, navigate to the login page
            if (!jwt) {
                navigate("/login", {
                    state: { error: "Login has expired. Please login again." },
                });
                return;
            }

            // Payload for the API call
            let payload = {
                // @ts-ignore
                patient_id: parseInt(patientId),
            };

            // API call to get observations
            getObservationsApiCall(jwt, payload).then((response) => {
                if (response.status === 201) {
                    let observations = response.data;
                    // @ts-ignore
                    setObservationsLocalStorage(
                        // @ts-ignore
                        parseInt(patientId),
                        // @ts-ignore
                        observations
                    );
                    setRawObservations(
                        convertRawObservationsToArray(observations)
                    );
                    setGraphObservations(convertData(observations));
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    setIsLoading(false);
                }
            });
        }
    }, [
        getObservationsApiCall,
        navigate,
        patientId,
        getObservationsLocalStorage,
        setObservationsLocalStorage,
    ]);

    /***
     * Handle change of popup state to do not show the popup for single patient after once
     */
    useEffect(() => {
        if (!localStorage.getItem(`patient_${patientId}_handover_seen`)) {
            setShowHandoverPopup(true);
            localStorage.setItem(`patient_${patientId}_handover_seen`, "yes");
        }
    }, [patientId]);

    /**
     * Handles the request for a new observation.
     */
    const onRequestNewObservation = async () => {
        if (!jwt) {
            navigate("/login", {
                state: { error: "Login has expired. Please login again." },
            });
            return;
        }

        const payload = {
            // @ts-ignore
            patient_id: parseInt(patientId),
            turn: turnCount,
        };

        const response = await postObservationsApiCall(jwt, payload);

        if (response.status === 201) {
            // Handle successful response
            const latestObservations = response.data;
            // @ts-ignore
            const existingObservations =
                // @ts-ignore
                getObservationsLocalStorage(parseInt(patientId)) || {};
            const updatedObservations = {
                ...existingObservations,
                ...latestObservations,
            };

            setRawObservations(
                convertRawObservationsToArray(updatedObservations)
            );
            setGraphObservations(convertData(updatedObservations));
            // @ts-ignore
            setObservationsLocalStorage(
                // @ts-ignore
                parseInt(patientId),
                updatedObservations
            );
        } else {
            // Handle errors
            console.error("Error posting observation:", response.error);
        }
    };

    /**
     * Handles the mouse enter event on a graph point.
     *
     * @param {number} index - The index of the hovered graph point.
     */
    const onGraphPointMouseEnter = (index: number) => {
        setHoveredRow(index);
    };

    /**
     * Handles the mouse leave event on a graph point.
     */
    const onGraphPointMouseLeave = () => {
        setHoveredRow(-1);
    };

    /**
     * Handle handover modal close request
     */
    const onRequestClose = (): void => {
        setShowHandoverPopup(false);
    };

    /**
     * Converts the observation data to a format suitable for the graph component.
     *
     * @param {Object[]} data - The observation data.
     * @returns {Object[]} The converted data for the graph component.
     */
    function convertData(data: any): PatientObsGraphData[] {
        const blue = `#2e4290`;
        const result: PatientObsGraphData[] = [
            { id: "Blood Pressure (diastolic)", color: blue, values: [] },
            { id: "Blood Pressure (systolic)", color: blue, values: [] },
            { id: "Pulse (bpm)", color: "red", values: [] },
        ];

        // Assuming 'data' is structured with turns and readings
        Object.keys(data).forEach((turnCount) => {
            result[0].values.push({
                turn: parseInt(turnCount),
                value: data[turnCount].DIASTOLIC.value,
            });
            result[1].values.push({
                turn: parseInt(turnCount),
                value: data[turnCount].SYSTOLIC.value,
            });
            result[2].values.push({
                turn: parseInt(turnCount),
                value: data[turnCount].PULSE.value,
            });
        });

        return result;
    }

    const convertRawObservationsToArray = (observations: any) => {
        return Object.keys(observations)?.map((turn) => ({
            TURN: parseInt(turn, 10),
            ...observations[turn],
        }));
    };

    // Conditional rendering: show loading or error state if observations is null or loading
    if (isLoading) {
        return <div>Loading observations...</div>;
    }

    return (
        <GamePageLayout
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            pageTitle={pageTranslations.title}
            patient={patient}
            translations={translations}
            documentationDependencies={sidebarDependencies}
            demographicsDependencies={demographicsDependencies}
            turnDependencies={turnDependencies}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
        >
            <ObservationsPageContainer>
                <ObservationsContainer>
                    <GraphContainer>
                        <PatientObservationsGraph
                            hoveredIndex={hoveredRow}
                            onPointMouseEnter={onGraphPointMouseEnter}
                            onPointMouseLeave={onGraphPointMouseLeave}
                            width={600}
                            height={400}
                            // @ts-ignore
                            data={graphObservations}
                        />
                    </GraphContainer>
                    <ObservationsTableComponent
                        observations={rawObservations}
                        isLoading={isLoading}
                        onRequestNewObservation={onRequestNewObservation}
                        setHoveredRow={setHoveredRow}
                        hoveredRow={hoveredRow}
                    />
                </ObservationsContainer>
            </ObservationsPageContainer>
            {showHandoverPopup && (
                <HandoverModal
                    onRequestClose={onRequestClose}
                    handover={handover}
                    pageTranslations={handoverTranslations}
                    translations={translations}
                    demographicsDependencies={demographicsDependencies}
                    patientId={Number(patientId)}
                />
            )}
        </GamePageLayout>
    );
};

export const PatientObservations =
    withGameDependencies<PatientObservationsProps>(PatientObservationsBase);
