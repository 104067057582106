import { NotesTranslations } from "./types";

export const deTranslations: NotesTranslations = {
    heading: "Notizen",
    noNotes: "Keine Notizen verfügbar",
    turn: "drehen",
    turns: "Drehungen",
    notesType: {
        HANDOVER: "Übergabe",
        BLOOD_ORDER: "Blutbestellung",
        NOTE: "Notiz",
        INVESTIGATION_NOTE: "Untersuchungsnotiz",
        DIAGNOSIS: "Diagnose",
        INVESTIGATION_ORDER: "Untersuchungsauftrag",
        TREATMENT_ORDER: "Behandlungsauftrag",
        ADMISSION_FORM: "Aufnahmeformular",
        CONSULTATION_ORDER: "Konsultationsauftrag",
        DRUG_PRESCRIPTION: "Medikamentenverschreibung",
        DISCHARGE_FORM: "Entlassungsformular",
        ADDITIONAL_TEST: "Zusätzliche Tests",
    },
};
