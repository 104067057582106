import { BlogPageTranslations } from "./types";

export const enTranslations: BlogPageTranslations = {
    main: {
        title: "Blog Posts",
        titleForSingleEntiry: "Blog",
        searchPlaceholder: "Search blog posts",
        notFoundText: "Blog not found. Please check the URL or try again later.",
        selectCategory: "Select Category",
        allPosts: "All Posts",
    }
};