// StyledComponents.ts
import styled from '@emotion/styled';
import { FaUserCircle } from 'react-icons/fa'; // Import FaUserCircle for SVG styling

// Styled components
export const MessageTextLeft = styled.div`
  max-width: 100%;
  padding: 10px 27px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.875rem;
  font-family: Inter, sans-serif;
`;

export const InnerPreviewMessage = styled.div`
  max-width: 75%;
  background: #ebebeb;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  min-width: 100px;
  padding: 10px 27px;
  display: inline-block;
`;

// Styling for the SVG icon inside message
export const StyledFaUserCircle = styled(FaUserCircle)`
  font-size: 40px;
  color: lightgrey;
`;
