import styled from "@emotion/styled";

export const FeedbackPatientInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
`;

export const FeedbackPatientInfoAvatar = styled.img`
    object-fit: cover;
    margin-bottom: 1rem;
    border-radius: 9999px;
    width: 8rem;
    height: 8rem;
`;

export const FeedbackPatientInfoContentContainer = styled.div``;

export const FeedbackPatientInfoTitle = styled.h3`
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
`;

export const FeedbackPatientInfoSubtitle = styled.p`
    margin-bottom: 1rem;
    color: #374151;
`;
