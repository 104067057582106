// External imports
import React, { useState } from "react";

// Internal imports
import { SelectOption } from "../../config/OrderTreatment.type";
import {
    OrderTreatmentDivider,
    OrderTreatmentFormContainer,
    OrderTreatmentFormFieldContainer,
    OrderTreatmentFormGridContainer,
    OrderTreatmentFormGridItemBy3,
    OrderTreatmentFormGridItemBy6,
    OrderTreatmentFormLabel,
    OrderTreatmentTurnLabel,
    StyledOrderTreatmentForm,
} from "./OrderTreatmentForm.styles";
import SelectBox from "../../../../../components/core/select-box/SelectBox";
import TextArea from "../../../../../components/core/input-form/TextArea";
import ClearSubmitFormButtons from "../../../../../components/core/clear-submit-form-buttons/ClearSubmitFormButtons";

// OrderTreatmentFormProps
interface OrderTreatmentFormProps {
    onSubmit: (treatment: string, priority: string, reason: string) => void;
    translations: any;
    treatmentTranslations: any;
    priorityTranslations: any;
}

/**
 * OrderTreatmentForm
 *
 * @description Represents the form for ordering a treatment.
 * @returns {JSX.Element} The rendered OrderTreatmentForm component.
 */
export const OrderTreatmentForm: React.FC<OrderTreatmentFormProps> = ({
    onSubmit,
    translations,
    treatmentTranslations,
    priorityTranslations,
}) => {
    /**
     * Represents the selected treatment option.
     */
    const [treatment, setTreatment] = useState<SelectOption>();

    /**
     * Represents the selected priority option.
     */
    const [priority, setPriority] = useState<SelectOption>();

    /**
     * Represents the justification for the treatment.
     */
    const [reason, setJustification] = useState<string>("");

    /**
     * Converts the options array to an array of SelectOption objects.
     * @param options - The options array to convert.
     * @param optionsType - The object containing the mapping of option values to labels.
     * @param sort - Optional. Specifies whether to sort the converted options array. Default is false.
     * @returns The converted array of SelectOption objects.
     */
    const onSelectOptionConversion = (
        options: string[],
        optionsType: { [key: string]: string },
        sort?: boolean
    ) => {
        const convertedOption: SelectOption[] = options.map(
            (item: string, index: number) => ({
                label: optionsType[item as keyof typeof optionsType],
                value: item,
                turn: index + 1,
            })
        );

        if (sort)
            return convertedOption.sort((a, b) =>
                a.value.localeCompare(b.value)
            );
        else return convertedOption;
    };

    /**
     * Handles the change event of the SelectBox component.
     * @param state - The selected option.
     * @param options - The array of options.
     * @param setState - The state setter function.
     */
    const onSelectOptionChange = (
        state: SelectOption,
        options: SelectOption[],
        setState: React.Dispatch<React.SetStateAction<SelectOption | undefined>>
    ) => {
        const selectedItem: SelectOption | undefined = options.find(
            (item: SelectOption) => item.value === state.value
        );

        if (selectedItem) setState({ ...selectedItem });
    };

    // Convert treatment and priority options
    const treatmentOptions = Object.entries(treatmentTranslations)
        .map(([key, value]) => ({
            label: value,
            value: key,
            turn: undefined,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const priorityOptions = Object.entries(priorityTranslations)
        .map(([key, value]) => ({
            label: value,
            value: key,
            turn: undefined,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    // Clear the form fields.
    const clearForm = () => {
        setTreatment(treatmentOptions[0]);
        setPriority(priorityOptions[0]);
        setJustification("");
    };

    // Handle form submit, and clear the form if successful.
    const handleFormSubmit = async () => {
        if (treatment && priority && reason.trim().length >= 5) {
            const success = await onSubmit(
                treatment.value,
                priority.value,
                reason
            );
            // @ts-ignore
            if (success) {
                clearForm();
            }
        }
    };

    return (
        <StyledOrderTreatmentForm
            onSubmit={(e: React.SyntheticEvent) => e.preventDefault()}
        >
            <OrderTreatmentFormContainer>
                <div>
                    <OrderTreatmentFormGridContainer>
                        <OrderTreatmentFormGridItemBy3>
                            <OrderTreatmentFormLabel htmlFor="OrderTreatment">
                                {translations.treatment}:{" "}
                            </OrderTreatmentFormLabel>
                            <OrderTreatmentFormFieldContainer>
                                <SelectBox
                                    value={[
                                        treatment
                                            ? treatment
                                            : treatmentOptions[0],
                                    ]}
                                    options={treatmentOptions}
                                    onChange={(value: SelectOption) =>
                                        onSelectOptionChange(
                                            value,
                                            treatmentOptions,
                                            setTreatment
                                        )
                                    }
                                />
                            </OrderTreatmentFormFieldContainer>
                        </OrderTreatmentFormGridItemBy3>
                        <OrderTreatmentFormGridItemBy3>
                            <OrderTreatmentFormLabel htmlFor="priority">
                                {translations.priority}:
                            </OrderTreatmentFormLabel>
                            <OrderTreatmentFormFieldContainer>
                                <SelectBox
                                    value={[
                                        priority
                                            ? priority
                                            : priorityOptions[0],
                                    ]}
                                    options={priorityOptions}
                                    onChange={(value: SelectOption) =>
                                        onSelectOptionChange(
                                            value,
                                            priorityOptions,
                                            setPriority
                                        )
                                    }
                                />
                            </OrderTreatmentFormFieldContainer>
                        </OrderTreatmentFormGridItemBy3>
                        <OrderTreatmentFormGridItemBy6>
                            <OrderTreatmentFormLabel htmlFor="reasons">
                                {translations.justification}:
                            </OrderTreatmentFormLabel>
                            <OrderTreatmentFormFieldContainer>
                                <TextArea
                                    rows={3}
                                    height="auto"
                                    color="#000"
                                    borderColor="#d1d5db"
                                    value={reason}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                    ) => setJustification(e.target.value)}
                                />
                            </OrderTreatmentFormFieldContainer>
                        </OrderTreatmentFormGridItemBy6>
                    </OrderTreatmentFormGridContainer>
                </div>
            </OrderTreatmentFormContainer>

            <OrderTreatmentDivider />

            <ClearSubmitFormButtons
                submitText={translations.orderTreatment}
                clearText={translations.clearForm}
                isSubmitDisabled={reason.trim().length < 5}
                onSubmit={handleFormSubmit}
                onClear={clearForm}
                RenderComponent={() => <></>}
            />
        </StyledOrderTreatmentForm>
    );
};
