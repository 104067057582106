import styled from "@emotion/styled";

export const BloodTestResultTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-family: "Helvetica", Arial, sans-serif;
`;

export const BloodTestResultTable = styled.table`
    border-radius: 0.375rem;
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    border: 1px solid #e5e7eb;
    font-family: "Helvetica", Arial, sans-serif;
`;

export const BloodTestResultTableHead = styled.thead``;

export const BloodTestResultTableBody = styled.tbody``;

export const BloodTestResultTableRow = styled.tr<{
    addBackground?: boolean;
    addLightBackground?: boolean;
}>`
    ${({
        addBackground = false,
        addLightBackground = false,
    }: {
        addBackground?: boolean;
        addLightBackground?: boolean;
    }) =>
        addBackground
            ? "background-color: #e5e7eb; color: #000;"
            : addLightBackground
            ? "background-color: #f9fafb; color: #000;"
            : ""}
`;


export const BloodTestResultLeftTableData = styled.td<{
    fontBold?: boolean;
    addIconColor?: boolean;
    value?: string | unknown;
    addBackground?: boolean;
    addLightBackground?: boolean;
    addError?: boolean;
}>`
    min-width: 100px;
    padding: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border: 1px solid #e5e7eb;
    position: relative;

    ${({ addError = false }: { addError?: boolean }) =>
    addError && "color: #B91C1C; background-color: #FECACA;"}

    ${({
                                                                        addBackground = false,
                                                                        addLightBackground = false,
                                                                    }: {
    addBackground?: boolean;
    addLightBackground?: boolean;
}) =>
    addBackground
        ? "background-color: #e5e7eb; color: #000;"
        : addLightBackground
            ? "background-color: #f9fafb; color: #000;"
            : ""}

    ${({ fontBold }: { fontBold?: boolean }) =>
    fontBold ? "font-weight: 600;" : ""}

    svg {
        color: ${({
                                                              addIconColor = false,
                                                              value,
                                                          }: {
    addIconColor?: boolean;
    value?: string | unknown;
}) => (addIconColor ? (value ? "#10B981" : "#EF4444") : "")};
    }

    button {
        width: 100%;
        text-align: left;
    }
`;



export const BloodTestResultTableData = styled.td<{
    fontBold?: boolean;
    addIconColor?: boolean;
    value?: string | unknown;
    addBackground?: boolean;
    addLightBackground?: boolean;
    addError?: boolean;
}>`
    min-width: 100px;
    padding: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border: 1px solid #e5e7eb;
    position: relative;
    text-align: center;

    ${({ addError = false }: { addError?: boolean }) =>
        addError && "color: #B91C1C; background-color: #FECACA;"}

    ${({
        addBackground = false,
        addLightBackground = false,
    }: {
        addBackground?: boolean;
        addLightBackground?: boolean;
    }) =>
        addBackground
            ? "background-color: #e5e7eb; color: #000;"
            : addLightBackground
            ? "background-color: #f9fafb; color: #000;"
            : ""}

    ${({ fontBold }: { fontBold?: boolean }) =>
        fontBold ? "font-weight: 600;" : ""}

    svg {
        color: ${({
            addIconColor = false,
            value,
        }: {
            addIconColor?: boolean;
            value?: string | unknown;
        }) => (addIconColor ? (value ? "#10B981" : "#EF4444") : "")};
    }

    button {
        width: 100%;
        text-align: left;
    }
`;

export const BloodTestResultTurnPaginationContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
`;

export const BloodTestResultTurnPaginationButton = styled.button`
    width: 22px !important;
    height: 22px;
    border: 1px solid #000;
    outline: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    padding: 0px;

    &:hover {
        background-color: rgba(16, 16, 16, 0.15);
    }
`;

