import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
// @ts-ignore
import Select from "react-select";
import { DrugsArray } from "../StaticData";
import { InputFieldArea, MainForm, SelectParent, SubmitButton } from "./FormComponent.styles";

export interface FormData {
    drug: string;
}

/*
The props for the DrugChartComponent component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export interface FormComponentProps {
    onSubmit: (data: FormData) => void;
    initialFormData?: FormData;
    translations: any;
}

/*
The DrugChartComponent component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export const DrugChartComponent: React.FC<FormComponentProps> = ({
    onSubmit,
    initialFormData,
    translations,
}) => {
    // hook for the state of the form inputs and the state of the form submission
    const [formData, setFormData] = useState<FormData>(
        initialFormData || {
            drug: "",
        }
    );

    useEffect(() => {
        // @ts-ignore
        setFormData(initialFormData); // Update internal state when initialFormData changes
    }, [initialFormData]);

    // hook for the animation of the form
    const [formClass, setFormClass] = useState("");

    // handle the change of the form inputs
    const handleChange = (selectedData: string) => {
        setFormData({ ...formData, drug: selectedData });
    };

    // handle the submission of the form
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
        console.log(formData);
    };

    return (
        <MainForm
            onSubmit={handleSubmit}
        >
            <InputFieldArea>
                <SelectParent>
                    <label>{translations.drugType}</label>
                    {/* @ts-ignore */}
                    <Select
                        options={DrugsArray}
                        placeholder={translations.selectOrType}
                        onChange={handleChange as any}
                    />
                </SelectParent>
                </InputFieldArea>
            <div>
                <SubmitButton type="submit">
                    {translations.prescribe}
                </SubmitButton>
            </div>
        </MainForm>
    );
};
