// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { ObservationReadings } from "../../../LocalStorage/patients/interface";


/*
The payload for posting observations.

@patient_id: The id of the patient.
@turn: The turn number of the observation.
 */
export interface PostObservationsPayload {
    patient_id: number;
    turn: number;
};


/*
The interface for the response from the post observations endpoint.

@status: The HTTP status code.
@error: The error message.
@data: The object containing the observations.
 */
export interface PostObservationsResponse {
    status: number;
    error?: string;
    data?: ObservationReadings;
};


// Function Signature
export type PostObservationsType = (jwt: string, postObservationsPayload: PostObservationsPayload) => Promise<PostObservationsResponse>;


/*
The function to post observations for a patient.

@jwt: The JWT of the user requesting the observations.
@postObservationsPayload: The payload containing the patient ID and turn number.

@returns: The ApiResponse from the post observations endpoint. This contains the
observations for the patient up to the given turn number.
 */
export async function postObservations(jwt: string, postObservationsPayload: PostObservationsPayload): Promise<PostObservationsResponse> {
    const url = new PatientUrl().postObservations;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<ObservationReadings | string>(url, {
            patient_id: postObservationsPayload.patient_id,
            turn: postObservationsPayload.turn
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });
    } catch (error) {
        return { status: 500, error: "Network or other error occurred" };
    }

    if (response.status === 201) {
        return { status: 201, data: response.data as ObservationReadings };
    } else {
        return { status: response.status, error: response.data };
    }
}

