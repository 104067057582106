import { VOneUrl } from "../url";


/*
The Url endpoints for the Classes API.

Attributes:
    base: The base url.
    assign: The url for assigning a class to a user.
 */
export class ClassUrl extends VOneUrl {

    public base: string;
    public assign: string;
    public create: string;
    public getAll: string;
    public get: string;
    public openClose: string;
    public reviewClass: string;

    constructor() {
        super();
        this.base = this.defineRoot() + "api/v1/classes/";
        this.assign = this.base + "assign"; // post
        this.create = this.base + "create"; // post
        this.getAll = this.base + "get/all"; // get
        this.get = this.base + "get"; // post
        this.openClose = this.base + "open_close"; // post
        this.reviewClass = this.base + "review"; // post
    }

}
