import {
    ObservationsTable,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TakeObsButton
} from "./TableSection.styles"

//Language context
import { useLanguage } from "../../../../core/translations/LanguageContext";

// Translation imports
import { enTranslations } from "../translations/en"
import { deTranslations } from "../translations/de";
import { ObservationPageTranslations } from "../translations/types";
import { SupportedLanguage } from "../../../../core/translations/supportedLanguages";
import { updateTurnCount } from "../../../../core/LocalStorage/turnLogic/core";

// Constants
const TRANSLATIONS: Record<SupportedLanguage, ObservationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

interface ObservationsTableProps {
    /**
     * An array of observation objects. Each object represents a set of patient observations
     */
    observations: {
        TURN: number;
        SYSTOLIC: { value: number };
        DIASTOLIC: { value: number };
        PULSE: { value: number };
        RESP_RATE: { value: number };
        OXYGEN_SATS: { value: number };
        TEMPERATURE: { value: number };
    }[];
    /**
     * A boolean indicating whether a new observation is being loaded
     */
    isLoading: boolean;
    /**
     * A function to be called when the "Take Obs" button is clicked
     */
    onRequestNewObservation: () => void;
    setHoveredRow: (index: number) => void;
    hoveredRow: number;
}

/**
 * Renders the patient observations page.
 */
const ObservationsTableComponent: React.FC<ObservationsTableProps> = ({
    observations,
    isLoading,
    onRequestNewObservation,
    setHoveredRow,
    hoveredRow
}) => {

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    /**
     * Handles the mouse enter event on a table row.
     *
     * @param {number} index - The index of the hovered row.
     */
    const onTableRowMouseEnter = (index: number) => {
        setHoveredRow(index);
    };

    /**
     * Handles the mouse leave event on a table row.
     */
    const onTableRowMouseLeave = () => {
        setHoveredRow(-1);
    };

    /**
     * Handles the click event on the "Take Obs" button.
     *
     * @param {React.MouseEvent<HTMLButtonElement>} event - The mouse event object.
     */
    const takeObservation = async (event?: any) => {
        event?.preventDefault();
        await onRequestNewObservation();
        updateTurnCount(1);
    };

    /**
     * Formats a temperature value for display.
     *
     * @param {number | string} temp - The temperature value.
     * @returns {string} The formatted temperature string.
     */
    const formatTemp = (temp: number | string) => {
        const tempString = parseFloat(String(temp)).toFixed(1);
        return `${tempString}`;
    };

    /**
     * Creates a table row component for an observation.
     *
     * @param {Object} observation - The observation object.
     * @param {number} index - The index of the observation.
     * @returns {JSX.Element} The rendered table row component.
     */
    const createTableRow = (
        observation: (typeof observations)[0],
        index: number
    ) => (
        <TableRow
            isHovered={hoveredRow === index}
            onMouseEnter={() => onTableRowMouseEnter(index)}
            onMouseLeave={onTableRowMouseLeave}
        >
            <TableCell>{Math.round(observation["TURN"])}</TableCell>
            <TableCell>{`${Math.round(
                observation["SYSTOLIC"].value
            )}/${Math.round(observation["DIASTOLIC"].value)}`}</TableCell>
            <TableCell>{Math.round(observation["PULSE"].value)}</TableCell>
            <TableCell>{Math.round(observation["RESP_RATE"].value)}</TableCell>
            <TableCell>
                {Math.round(observation["OXYGEN_SATS"].value)}
            </TableCell>
            <TableCell>
                {formatTemp(Math.round(observation["TEMPERATURE"].value))}
            </TableCell>
        </TableRow>
    );
    // ! Game page layout disabled for now since the styling is broken
    return (
        <ObservationsTable>
            <TableHead>
                <tr>
                    <th>{translations.columns.turns}</th>
                    <th>
                        {translations.columns.bloodPressure}
                        <div style={{ fontSize: "0.75rem" }}>{translations.columns.mmHg}</div>
                    </th>
                    <th>
                        {translations.columns.pulse}
                        <div style={{ fontSize: "0.75rem" }}>{translations.columns.bpm}</div>
                    </th>
                    <th>
                        {translations.columns.respiratoryRate}
                        <div style={{ fontSize: "0.75rem" }}>{translations.columns.bpm}</div>
                    </th>
                    <th>
                        {translations.columns.oxygenSaturation}
                        <div style={{ fontSize: "0.75rem" }}>{translations.columns.percentage}</div>
                    </th>
                    <th>
                        {translations.columns.temperature}
                        <div style={{ fontSize: "0.75rem" }}>{translations.columns.degCel}</div>
                    </th>
                </tr>
            </TableHead>
            <TableBody>
                {/* the row for taking new observation */}
                <TableRow isHovered={false}>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>
                        <TakeObsButton
                            disabled={isLoading}
                            onClick={takeObservation}
                        >
                            {translations.takeObs}
                        </TakeObsButton>
                    </TableCell>
                </TableRow>
                {/* actual rows for the data */}
                {observations.map(createTableRow)}
            </TableBody>
        </ObservationsTable>

    );
};

export default ObservationsTableComponent;
