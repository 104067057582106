import styled from "@emotion/styled";

export const NewClassFormStyledButton = styled.button<{
    active: boolean;
}>`
    display: inline-block;
    padding: 0.5rem 1rem;
    font-weight: 600;
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;
    &:hover {
        background: ${(props) => (props.active ? "#334155" : "#E9E9E9")};
    }

    border: rgba(0, 0, 0, 0.1) 1px solid;
    color: ${(props) => (props.active ? "white" : "#565656")};
    background: ${(props) => (props.active ? "#334155" : "#E9E9E9")};
`;
