import { ExamTranslations } from "./types";

export const deTranslations: ExamTranslations = {
    sideBar: {
        examined: "Untersucht",
        notExamined: "Nicht untersucht",
    },
    contents: {
        loadingFindings: "Lade Befunde...",
        noFindingsAvailable: "Keine Befunde verfügbar",
        examinePatient: "Untersuchen",
        findings: "Befunde",
    },
};
