// src/pages/analytics/translations/de.ts
import { AnalyticsTranslations } from './types';

export const deTranslations: AnalyticsTranslations = {
    title: "ANALYTISCHE EINBLICKE",
    sections: [
        {
            text: "Clinical Metrics ist ein medizinisches Simulationsprodukt, bei dem Benutzer die Rolle eines Assistenzarztes übernehmen, der Notfallpatienten betreut. Die Simulation wurde entwickelt, um Assistenzärzte und Medizinstudierende beim Üben ihres klinischen Denkens zu unterstützen und zu lernen, wie man Patienten in einer stark frequentierten Notaufnahme priorisiert. Yellow Bird Consulting ist Eigentümer dieses Produkts und verantwortlich für das gesamte Produktdesign und die Entwicklung."
        },
        {
            text: "Virtuelle Patienten werden dem Benutzer in einer simulierten Notaufnahme präsentiert. Der Benutzer kann mit dem Patienten interagieren, indem er Fragen stellt (über einen Chatbot), Untersuchungen anordnet und Medikamente verschreibt. Unbehandelte Patienten verschlechtern sich mit der Zeit. Der Benutzer muss Zeit und Ressourcen effektiv einsetzen, um möglichst viele Patienten sicher zu behandeln."
        },
        {
            text: "Die virtuellen Patienten werden aus epidemiologischen Daten aus veröffentlichten Studien und Expertenmeinungen generiert. Die virtuellen Patienten sind so realistisch wie möglich gestaltet, und die Simulation ist so realitätsnah wie möglich konzipiert. Die Simulation soll sowohl unterhaltsam als auch lehrreich sein."
        },
        {
            text: "Die Entwicklung dieses Produkts hat uns als Unternehmen viel gelehrt, da wir modernste Prozesse für die Entwicklung virtueller Patienten, die Verwaltung hoher Benutzerzahlen (ganze Nutzergruppen loggen sich gleichzeitig ein) und die Bewältigung der Simulationskomplexität entwickeln mussten. Wir mussten auch eine robuste und skalierbare Infrastruktur aufbauen. Aus diesem Grund haben wir eigene Entwicklungswerkzeuge erstellt, unsere Praktiken in kontinuierlicher Integration und Tests gefestigt und sind zu Rust als unserer Hauptsprache für die Backend-Entwicklung übergegangen."
        },
        {
            text: "Für weitere Informationen zu diesem Produkt besuchen Sie bitte die",
            includesLink: true
        }
    ],
    websiteLink: "#",
    websiteLinkText: "Clinical Metrics Webseite",
    imageAlts: {
        clinicalMetrics: "Clinical Metrics Plattform-Oberfläche",
        insideSim: "Einblick in die Simulationsumgebung",
        ecg: "EKG-Überwachungsanzeige",
        dashboard: "Analytisches Dashboard"
    }
};