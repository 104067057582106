import { UserUrl } from "./AUrl";
import axios from 'axios';


/*
The data needed to create a user.

@first_name: The first name of the user.
@last_name: The last name of the user.
@email: The email of the user (will be used to confirm the user)
@password: The password of the user.
 */
export interface UserPayload {
    email: string;
    password: string;
    first_name?: string;
    last_name?: string;
    language: string;
}


/*
The interface for the response from the create user endpoint.

@status: The status code of the response.
@message: The message from the response.
@body: The body of the response.
 */
export interface ApiResponse {
    status: number;
    message: string;
    body?: any; // Optional body
}


export type CreateUserType = (payload: UserPayload) => Promise<ApiResponse>;


/*
Creates a user.

@payload: The data needed to create a user.

@returns: The ApiResponse from the create user endpoint.
 */
export async function CreateUser(payload: UserPayload): Promise<ApiResponse> {
    const url = new UserUrl().create;
    try {
        const response = await axios.post(url, payload, {
            headers: {'Content-Type': 'application/json'},
        });

        // Check for status code 201
        if (response.status === 201) {
            return { status: response.status, message: "User created successfully" };
        } else {
            // If the status code is not 201, return the status and the message from the response body
            return { status: response.status, message: response.data || `Unexpected response code: ${response.status}` };
        }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            // If it's an Axios error with a response, return the status and error message from the response body
            return { status: error.response.status, message: error.response.data || error.message };
        } else {
            // For other types of errors, return a generic message
            return { status: 500, message: "An error occurred" };
        }
    }
};
