// This config contains the options for the investigation form. In the future
// We will need to make sure that we deal with the language of the options

export const INVESTIGATION_TEST_TYPE = [
    "ECHO",
    "CATH_LAB",
    "VENOUS_DUPLEX",
    "CT_CHEST",
    "CT_ABDO",
    "CT_HEAD",
    "THORACOCENTESIS_DIAGNOSTIC",
    "LUMBAR_PUNCTURE",
    "ASCITIC_TAP",
    "PEAK_FLOW",
    "URINE_DIPSTICK",
    "DIX_HALLPIKE_MANEUVER",
    "PREGNANCY_TEST",
    "ECG",
    "BLOOD_CULTURE",
    "URINE_CULTURE",
    "SPUTUM_CULTURE",
    "WOUND_CULTURE",
    "THROAT_CULTURE",
    "GENITAL_CULTURE",
    "ABDO_USS",
    "THYROID_USS",
    "ABDO_X_RAY",
    "CHEST_X_RAY",
    "LUMBAR_X_RAY",
    "ARTERIAL_DUPLEX",
];

export const INVESTIGATION_TEST_TYPE_NAME = {
    ECHO: "ECHO",
    CATH_LAB: "Cath Lab",
    VENOUS_DUPLEX: "Venous Duplex",
    CT_CHEST: "CT Chest",
    CT_ABDO: "CT Abdo",
    CT_HEAD: "CT Head",
    THORACOCENTESIS_DIAGNOSTIC: "Thoracocentesis Diagnostic",
    LUMBAR_PUNCTURE: "Lumbar Puncture",
    ASCITIC_TAP: "Ascitic Tap",
    PEAK_FLOW: "Peak Flow",
    URINE_DIPSTICK: "Urine Dipstick",
    DIX_HALLPIKE_MANEUVER: "Dix Hallpike Maneuver",
    PREGNANCY_TEST: "Pregnancy Test",
    ECG: "ECG",
    BLOOD_CULTURE: "Blood Culture",
    URINE_CULTURE: "Urine Culture",
    SPUTUM_CULTURE: "Sputum Culture",
    WOUND_CULTURE: "Wound Culture",
    THROAT_CULTURE: "Throat Culture",
    GENITAL_CULTURE: "Genital Culture",
    ABDO_USS: "Abdo USS",
    THYROID_USS: "Thyroid USS",
    ABDO_X_RAY: "Abdo X-Ray",
    CHEST_X_RAY: "Chest X-Ray",
    LUMBAR_X_RAY: "Lumbar X-Ray",
    ARTERIAL_DUPLEX: "Arterial Duplex",
};

export const PRIORITY_TYPE = ["LOW", "MEDIUM", "HIGH"];

export const PRIORITY_TYPE_NAME = {
    LOW: "Low",
    MEDIUM: "Medium",
    HIGH: "High",
};

export const STATUS = {
    PENDING: "pending",
    AVAILABLE: "available",
} as const;