import { ReactNode, useEffect, useState } from "react";
import {
    FooterColumnContainer,
    FooterColumnContent,
    TBody,
    THead,
    TableStyle,
    Th,
} from "./Table.styles";
import TableRow from "./components/TableRow/TableRow";
import _ from "lodash";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

/**
 * Represents a column in the table.
 * @interface Column
 * @property {string} key - The key for the column.
 * @property {(columnData: any, row: any) => ReactNode} render - The render function for the column.
 * @property {string} label - The label for the column.
 */
export interface Column {
    key: string;
    render?: (columnData: any, row: any) => ReactNode;
    label: string;
    accessor: string;
}

/**
 * Props for the Table component.
 * @interface TableProps
 * @property {any[]} data - The data to be displayed in the table.
 * @property {Column[]} [columns] - The columns to be displayed in the table. If not provided, the keys of the first item in the data array will be used as column labels.
 * @property {string} [noRowsText] - The text to be displayed when there are no rows in the table.
 */
export interface TableProps {
    data: any[];
    columns?: Column[];
    noRowsText?: string;
    enableReviewModal?: boolean;
}

/**
 * A customizable table component.
 * @param {TableProps} props - The props for the Table component.
 * @returns {JSX.Element} - The rendered Table component.
 */
export default function Table({
    data,
    columns,
    noRowsText = "No data to display",
    enableReviewModal,
}: TableProps): JSX.Element {
    // Constants for the sort order.
    const ASCENDING_ORDER = "asc";
    const DESCENDING_ORDER = "desc";

    // State for the sort configuration.
    const [sortConfig, setSortConfig] = useState({
        key: "",
        direction: ASCENDING_ORDER,
    });

    // The columns for the table.
    const tableColumns =
        columns ||
        ((data.length
            ? Object.keys(data[0]).map((key) => ({
                  key,
                  label: key,
              }))
            : []) as Column[]);

    // Function to sort the data.
    const sortData = (key: string) => {
        let direction = ASCENDING_ORDER;
        if (
            sortConfig.key === key &&
            sortConfig.direction === ASCENDING_ORDER
        ) {
            direction = DESCENDING_ORDER;
        }
        setSortConfig({ key, direction });
    };

    // Sort the data with the current sort configuration.
    const sortedData = _.orderBy(
        data,
        [sortConfig.key],
        [
            sortConfig.direction === ASCENDING_ORDER
                ? ASCENDING_ORDER
                : DESCENDING_ORDER,
        ]
    );

    return (
        <TableStyle>
            <THead>
                <tr>
                    {tableColumns.map((column, index) => (
                        <Th
                            key={column.accessor || index}
                            scope="col"
                            onClick={() => sortData(column.accessor)}
                        >
                            <div>
                                {column.label}

                                {column.accessor &&
                                    sortConfig.key === column.accessor &&
                                    (sortConfig.direction ===
                                    ASCENDING_ORDER ? (
                                        <TiArrowSortedUp />
                                    ) : (
                                        <TiArrowSortedDown />
                                    ))}
                            </div>
                        </Th>
                    ))}
                </tr>
            </THead>
            <TBody>
                {sortedData.map((item, rowIndex) => (
                    <TableRow
                        key={rowIndex}
                        item={item}
                        tableColumns={tableColumns}
                        enableReviewModal={enableReviewModal}
                    />
                ))}
            </TBody>
            {data.length ? null : (
                <tfoot>
                    <tr>
                        <td
                            colSpan={tableColumns.length}
                            className="p-4 bg-gray-50"
                        >
                            <FooterColumnContainer>
                                <FooterColumnContent>
                                    {noRowsText}
                                </FooterColumnContent>
                            </FooterColumnContainer>
                        </td>
                    </tr>
                </tfoot>
            )}
        </TableStyle>
    );
}
