// External Imports
import React, { useEffect } from "react";
import { FaLock } from "react-icons/fa";

// Internal Imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import { getJwt } from "../../../core/LocalStorage/AuthSession/core";
import {
    PageHeader,
    CenteredTableContainer,
    ActionButton,
    ReviewLink,
    StatusIcon,
    ErrorMessage,
    LoadingIndicator,
    ClassCode,
} from "./ViewClasses.styles";
import { ViewClassTable } from "./TableComponent/ViewClassTable";

// Translation imports
import { useLanguage } from '../../../core/translations/LanguageContext';
import { ViewClassesTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

// Dependency Injection
import { GetAllClassesType } from "../../../core/ServerApiLayer/VOne/classes/getAll";
import { OpenCloseClassType } from "../../../core/ServerApiLayer/VOne/classes/openClose";

const TRANSLATIONS: Record<SupportedLanguage, ViewClassesTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

interface TeacherReviewsProps {
    getClassesApiCall: GetAllClassesType;
    lockClassApiCall: OpenCloseClassType;
}

// Helper function to format dates
const formatDate = (dateString: string | null | undefined): string => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().split('T')[0];
};

export const ViewClassesPage: React.FC<TeacherReviewsProps> = ({
                                                                   getClassesApiCall,
                                                                   lockClassApiCall,
                                                               }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState("");

    useEffect(() => {
        const fetchClasses = async () => {
            const jwt = getJwt();
            try {
                //@ts-ignore
                const response = await getClassesApiCall(jwt);
                const sortedData = response?.data?.sort((a: any, b: any) => {
                    // Convert the date_created string to Date objects for comparison
                    //@ts-ignore
                    return new Date(b.date_created) - new Date(a.date_created);
                });
                // Format dates in the response data
                //@ts-ignore
                const formattedData = sortedData.map(item => ({
                    ...item,
                    date_created: formatDate(item.date_created),
                    date_opened: formatDate(item.date_opened),
                    date_closed: formatDate(item.date_closed)
                }));
                //@ts-ignore
                setData(formattedData);
            } catch (err) {
                setError(translations.errors.loadingFailed);
            } finally {
                setLoading(false);
            }
        };

        fetchClasses();
    }, [getClassesApiCall, translations.errors.loadingFailed]);

    const columns = [
        {
            key: "name",
            accessor: "name",
            label: translations.columns.className,
        },
        {
            key: "class_code",
            accessor: "auth_code",
            label: "Class Code",
            render: (row: any) => (
                <ClassCode>{row.auth_code}</ClassCode>
            ),
        },
        {
            key: "status",
            label: translations.columns.status,
            render: (row: any) => {
                if (row.date_closed && new Date(row.date_closed) < new Date()) {
                    return (
                        <StatusIcon>
                            <FaLock title={translations.status.locked} />
                        </StatusIcon>
                    );
                }
                if (!row.date_closed) {
                    return (
                        <ActionButton
                            className="lock-button"
                            onClick={() => handleLockClass(row.id)}
                        >
                            {translations.actions.lock}
                        </ActionButton>
                    );
                }
                return null;
            },
        },
        {
            key: "students_enrolled",
            accessor: "students_enrolled",
            label: translations.columns.studentsEnrolled,
        },
        {
            key: "games_played",
            accessor: "games_played",
            label: translations.columns.gamesPlayed,
        },
        {
            key: "date_created",
            accessor: "date_created",
            label: translations.columns.dateCreated,
        },
        {
            key: "date_opened",
            accessor: "date_opened",
            label: translations.columns.dateOpened,
        },
        {
            key: "date_closed",
            accessor: "date_closed",
            label: translations.columns.dateClosed,
        },
        {
            key: "review",
            label: translations.columns.review,
            render: (row: any) => {
                if (row.date_closed && new Date(row.date_closed) < new Date()) {
                    return (
                        <ReviewLink href={`/teacher/review-class/${row.id}`}>
                            {translations.actions.review}
                        </ReviewLink>
                    );
                }
                return null;
            },
        },
    ];

    const handleLockClass = async (classId: number) => {
        const jwt = getJwt();
        try {
            //@ts-ignore
            await lockClassApiCall({ class_id: classId, open: false }, jwt);
            setData((prevData: any) =>
                prevData.map((classItem: any) =>
                    classItem.id === classId
                        ? { ...classItem, date_closed: formatDate(new Date().toISOString()) }
                        : classItem
                )
            );
        } catch (err) {
            setError(translations.errors.lockFailed);
            console.error("Error locking class:", err);
        }
    };

    if (loading) {
        return (
            <MainPageLayout>
                <LoadingIndicator>Loading classes...</LoadingIndicator>
            </MainPageLayout>
        );
    }

    return (
        <MainPageLayout>
            <PageHeader>
                <h1>{translations.title}</h1>
            </PageHeader>
            <CenteredTableContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <ViewClassTable data={data} columns={columns} />
            </CenteredTableContainer>
        </MainPageLayout>
    );
}