// External imports
import React, {ChangeEvent, useState, useEffect} from 'react';

// Internal imports
//@ts-ignore
import {
    CentralFormPanel,
    NewGameInput,
    Input,
    Paragraph,
    ErrorMessage,
    Title,
} from "./NewGameFormComponent.styles";
import WebsiteButton, { WebsiteButtonProps} from '../../../../components/core/button/WebsiteButton';
import Tooltip from '../../../../components/core/tooltip/Tooltip';
import { FaInfo } from "react-icons/fa";
import { TooltipIcon } from "../../../../components/core/tooltip/Tooltip.styles";
import { JoinClassTranslations } from "../translations/types";


/*
The props for the NewGameFormComponent.

@prop NewGame: boolean - if the new game form should be displayed
@prop error: string - the error message to be displayed
@prop failure: boolean - if the error message should be displayed
 */
export interface NewGameFormProps {
    NewGame: boolean;
    error: string;
    failure: boolean;
    HandleNewGame: (data: NewGameState) => void;
    translations: JoinClassTranslations;
}


/*
The state for the NewGameFormComponent (data to be sent to backend).

@prop code: string - the code of the new game
 */
export interface NewGameState {
    authCode: string;
}


/*
The state for the NewGameFormComponent (presentation).

@prop newGame: boolean - if the new game form should be displayed
@prop newGameName: boolean - if the new game name form should be displayed
@prop error: string - the error message to be displayed
@prop failure: boolean - if the error message should be displayed
 */
export interface ComponentState {
    newGame: boolean;
    error: string;
    failure: boolean;
}

/**
 * The New Game Form Component
 *
 * This component is used to display the form for the student to enter the game code. We
 * must have error handling for the form input as student may enter the
 * wrong code, or try to join a class that they do not have permission to do so.
 *
 * From this component there will be an API call to the backend to check if the game code is valid.
 */
export const NewGameFormComponent: React.FC<NewGameFormProps> = ({
    NewGame, HandleNewGame, error, failure, translations
}) => {
    // Set the state for the component
    const [newGameState, setNewGameState] = useState<NewGameState>({ authCode: "" });
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [state, setState] = useState<ComponentState>({
        newGame: NewGame,
        error: error,
        failure: failure,
    });

    useEffect(() => {
        // Adjusted regex to validate exactly 2 letters followed by 3 numbers
        // e.g. MF128. If the regex matches, the button is enabled. If not, the button is disabled.
        setIsButtonDisabled(!newGameState.authCode.match(/^[A-Z]{2}[0-9]{3}$/));
    }, [newGameState.authCode]);

    // Handle the change of the input. This will update the state
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNewGameState({ ...newGameState, [e.target.name]: e.target.value.trim().toUpperCase() });
    };

    // Handle the submission of the form
    const handleSubmit = (e: React.FormEvent) => {
        if (!isButtonDisabled && !failure) {
            HandleNewGame(newGameState);
        }
    };

    // Handle the button click
    const handleButtonClick = (e: any) => {  // Temporarily use 'any' to bypass type mismatch
        e.preventDefault();  // Prevent default button behavior
        handleSubmit(e);      // Call the form submit handler        
    };

    return (
        <CentralFormPanel onSubmit={handleSubmit} failure={state.failure}>
            {NewGame && (
                <>
                    <Paragraph>{translations.pleaseEnterAuthCode}
                        <Tooltip placement={"right-start"} tooltip={
                            <p>
                                {translations.teacherWillProvideCode}
                            </p>
                        }>
                            <TooltipIcon><FaInfo size={16} /></TooltipIcon>
                        </Tooltip>
                    </Paragraph>

                    <NewGameInput>
                        <Input
                            type="text"
                            name="authCode"
                            placeholder={translations.example}
                            onChange={handleChange}
                            value={newGameState.authCode}
                        />
                        <WebsiteButton id="joinClassButton" type="submit" disabled={isButtonDisabled} onClick={handleButtonClick}>{translations.nextPage}</WebsiteButton>
                    </NewGameInput>
                </>
            )}

            {failure && <ErrorMessage id="ErrorMessage">{error}</ErrorMessage>}
        </CentralFormPanel>
    );
};