// Internal Imports
import { GameTime } from './../LocalStorage/turnLogic/interface';


/**
 * Converts the time object to the number of turns.
 *
 * @param time
 */
export function formatGameTime(time: GameTime): string {
    return `${String(time.hours).padStart(2, '0')}:${String(time.minutes).padStart(2, '0')}`;
}

