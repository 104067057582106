import { FaHandsBubbles } from "react-icons/fa6";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { LuArrowsUpFromLine } from "react-icons/lu";
import { WHITE_BG } from "./Partners.styles";
import UNIVERSITY_BONN from "../../../assets/university_of_bonn.png";
import UNIVERSITY_GOETTINGEN from "../../../assets/university_of_goettingen.png";
import UNIVERSITY_BERLIN from "../../../assets/university_of_berlin.jpg";
import DFN from "../../../assets/DFN.svg";


export const BENEFIT_DATA = [
    {
        id: 'productivity',
        icon: FaHandsBubbles,
    },
    {
        id: 'efficiency',
        icon: LuArrowsUpFromLine,
    },
    {
        id: 'technology',
        icon: IoExtensionPuzzleOutline,
    },
];

export const PARTNERS_DATA = [
    {
        id: 'bonn',
        name: "University of Bonn",
        logo: UNIVERSITY_BONN,
        color: WHITE_BG,
        link: "https://www.uni-bonn.de/",
    },
    {
        id: 'goettingen',
        name: "University of Goettingen",
        logo: UNIVERSITY_GOETTINGEN,
        color: WHITE_BG,
        link: "https://www.uni-goettingen.de/",
    },
    {
        id: 'dfn',
        name: "DFN",
        logo: DFN,
        color: WHITE_BG,
        link: "https://www.dfn.de/",
    },
];

export const CURRENT_USERS_DATA = [
    {
        id: 'bonn',
        name: "University of Bonn",
        logo: UNIVERSITY_BONN,
        color: WHITE_BG,
        link: "https://www.uni-bonn.de/",
    },
    {
        id: 'goettingen',
        name: "University of Goettingen",
        logo: UNIVERSITY_GOETTINGEN,
        color: WHITE_BG,
        link: "https://www.uni-goettingen.de/",
    },
    {
        id: 'berlin',
        name: "University of Berlin",
        logo: UNIVERSITY_BERLIN,
        color: WHITE_BG,
        link: "https://www.fu-berlin.de/en/index.html",
    },
];