import { GameLayoutTranslations } from "./types";
import { deGameLayoutTranslations } from "./de";

export const enGameLayoutTranslations: GameLayoutTranslations = {
    leftSidebar: {
        dashboard: "Dashboard",
        dischargedDashboard: "Discharged",
        plan: "Bird's Eye View",
        reviewPage: "Review",
        chatPage: "Chat",
        examPage: "Examinations",
        testsOverview: "Tests",
        orderBloodTest: "Order Blood Tests",
        reviewBloodTest: "Blood Results",
        investigations: "Investigations",
        consultOverview: "Consult",
        specialistConsult: "Specialty Consult",
        therapyConsult: "Therapy Consult",
        treatmentOverview: "Treatment",
        documentationOverview: "Documentation",
        drugChart: "Drug Chart",
        treatmentPage: "Treatments",
        notes: "Notes",
        forms: "Forms",
        guidelines: "Guidelines",
    },
    patientSidebar: {
        information: "Information",
        symptom: "Symptom",
        age: "Age",
        sex: "Sex",
        diagnosis: "Diagnosis",
        dischargePatient: "Discharge Patient",
        addDiagnosis: "Add Diagnosis",
        reviewNotes: "Review Notes",
        addNotes: "Add Notes",
        notesPlaceholder: "Add notes here",
        movePatient: "Move Patient",
    },
    header: {
        backToDashboard: "Dashboard",
        endGame: "End Game",
        dropdown: {
            profile: "Profile",
            badges: "Badges",
            logout: "Logout",
        },
    },
    notifications: {
        noNotifications: "No notifications",
        notificationSenders: {
            frontDesk: "Front Desk",
        },
        notificationMessages: {
            newPatientArrival: "New patient has arrived",
        },
    },
    diagnosisModal: {
        modalTitle: "Diagnosis",
        saveButton: "Save Diagnosis",
        selectPlaceholder: "Select Diagnosis",
    },
    movePatientModal: {
        modalTitle: "Move Patient",
        sentenceAboutLocation: "is currently in ",
        illegalMove:
            "You can't move the patient to this location. Location is full. Please select another location.",
        placeholder: "Select a location",
        movePatient: "Move Patient",
        dischargeInfo:
            "You can only discharge patients home or admit them to a ward from the Form page.",
    },
};
