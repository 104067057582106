// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { ApiPatientData } from "../sessions/start";


/*
The payload for posting a diagnosis.
 */
export interface PostDiagnosisPayload {
    patient_id: number;
    diagnosis: String;
}



/*
The interface for the response from the post diagnosis endpoint.

@status: The HTTP status code.
@error: The error message.
@data: The data returned from the endpoint.
 */
export interface PostDiagnosisResponse {
    status: number;
    error?: string;
    data?: ApiPatientData;
}

export type PostDiagnosisType = (jwt: string, postDiagnosisPayload: PostDiagnosisPayload) => Promise<PostDiagnosisResponse>;


/*
The function to get all patients

@jwt: The JWT of the user requesting the classes.

@returns: The ApiResponse from the get all classes endpoint.
 */
export async function postDiagnosisPatients(jwt: string, postDiagnosisPayload: PostDiagnosisPayload): Promise<PostDiagnosisResponse> {
    const url = new PatientUrl().postDiagnosis;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<PostDiagnosisResponse | string>(url, {
            patient_id: postDiagnosisPayload.patient_id,
            diagnosis: postDiagnosisPayload.diagnosis
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },

            // Resolve the promise for all HTTP status codes
            validateStatus: () => true
        });

    } catch (error) {
        // Handle network or other Axios-related errors
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 201) {
        // Successful response
        return {
            status: response.status,
            data: response.data as PostDiagnosisResponse['data']
        };
    } else {
        // Other response codes with a string message
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
