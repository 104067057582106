import React from "react";
import { groupBy } from "lodash";
import { useLayoutEffect, useRef } from "react";

// Internal imports
import PatientExamState from "../../state/PatientExamState";
import { ExamSidebarStyle } from "./ExamSidebar.styles";
import { ExamSidebarSection } from "../ExamSidebarSection/ExamSidebarSection";
import { PatientExamination } from "../../types/PatientExamTypes";

/**
 * PatientExamSidebarProps
 *
 * This interface is used to define the props of the PatientExamSidebarSection component
 */
export type PatientExamSidebarProps = {
    cards: PatientExamination[];
    gender?: "Male" | "Female";
    setSelectedCard?: (examination: PatientExamination) => void;
    translations?: any;
};

/**
 * PatientExamSidebar component
 *
 * This component renders the sidebar for the patient exam panel, grouping the exams
 * into "Examined" and "Unexamined" sections.
 */
export const PatientExamSidebar: React.FC<PatientExamSidebarProps> = ({
    cards,
    gender,
    setSelectedCard,
    translations,
}) => {
    // Group the examinations by whether they have been examined or not
    const groupedByExamined = groupBy(cards, (exam) =>
        PatientExamState.isCardExamined(exam) ? "Examined" : "Unexamined"
    );

    // Handle scrolling to the top when the examined state changes
    const divScrollRef = useRef<any>(null);
    useLayoutEffect(() => {
        if (divScrollRef.current) {
            divScrollRef.current.scrollTop = 0;
        }
    }, [PatientExamState.examinedCardIds.join("")]); // Ensure it scrolls to top when examined state changes

    // Return the sidebar component
    return (
        <ExamSidebarStyle ref={divScrollRef}>
            {/* Render the Examined section if there are examined cards */}
            {groupedByExamined.Examined && (
                <ExamSidebarSection
                    key="Examined"
                    cards={groupedByExamined.Examined}
                    examined={true}
                    gender={gender}
                    setSelectedCard={setSelectedCard}
                    translations={translations}
                />
            )}

            {/* Render the Unexamined section if there are unexamined cards */}
            {groupedByExamined.Unexamined && (
                <ExamSidebarSection
                    key="Unexamined"
                    cards={groupedByExamined.Unexamined}
                    examined={false}
                    gender={gender}
                    setSelectedCard={setSelectedCard}
                    translations={translations}
                />
            )}
        </ExamSidebarStyle>
    );
};
