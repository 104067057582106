import styled from "@emotion/styled";

export const PatientInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PatientNameTitle = styled.h2`
    font-size: 18px;
    line-height: 28px;
    color: #111827;
    margin: 0px;
`;

export const PatientDescriptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    color: #6b7280;
`;

export const PatientDescription = styled.p`
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #6b7280;
    margin: 0px;
`;
