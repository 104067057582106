import {Patient} from "../../core/metaDataAccess/patients/patient";
import {PatientFactory} from "../../core/metaDataAccess/patients/factory";
import React, {useEffect, useState} from "react";


export interface PatientComponentProps {
    patient: Patient;
}



export const PatientComponent: React.FC<PatientComponentProps> = ({patient}) => {
    const handleDelete = () => {
        PatientFactory.deleteById(patient.id);
    };

    return (
        <li>
            {patient.name} - Age: {patient.age}
            <button onClick={handleDelete}>Delete</button>
        </li>
    );
};


export const PatientsList = () => {
    const [patients, setPatients] = useState<Map<string, Patient>>(new Map());

    useEffect(() => {
        setPatients(PatientFactory.getAllPatients());

        const newPatients = () => {
            const newPatients = PatientFactory.getAllPatients();
            setPatients(new Map(newPatients));
        };

        // Subscribe to PatientFactory updates
        const unsubscribe = PatientFactory.subscribe(newPatients);

        // Cleanup subscription on component unmount
        return () => unsubscribe();
    }, []);

    return (
        <div>
            <h2>Patients List</h2>
            <ul>
                {Array.from(patients.values()).map((patient) => (
                    <PatientComponent key={patient.id} patient={patient} />
                ))}
            </ul>
        </div>
    );
};
