// External imports
import React, { useState } from "react";
import { useParams } from "react-router-dom";

// Internal imports
import { PatientNotesContainer } from "./PatientNotes.styles";
import TextArea from "../../../core/input-form/TextArea";
import WebsiteButton from "../../../core/button/WebsiteButton";

// Dependencies injection
import { postNote } from "../../../../core/ServerApiLayer/VOne/patients/postNote";
import {
    setDocumentationToLocalStorage,
    updateNotesDocumentationToLocalStorage,
} from "../../../../core/LocalStorage/patients/notes";
import { getJwt } from "../../../../core/LocalStorage/AuthSession/core";
import { getTurnCount } from "../../../../core/LocalStorage/turnLogic/core";
import { noteData } from "../../../../core/LocalStorage/patients/interface";
import { updateTurnCount } from "../../../../core/LocalStorage/turnLogic/core";

// Patient Notes interface
interface PatientNotesProps {
    translations?: {
        patientSidebar: {
            notesPlaceholder: string;
            addNotes: string;
        };
    };
}

/**
 * PatientNotes component
 *
 * This is the component for the patient notes section in the patient sidebar.
 */
export const PatientNotes: React.FC<PatientNotesProps> = ({ translations }) => {
    const [patientNotes, setPatientNotes] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const { id } = useParams<{ id: string }>();

    const submitNotes = async () => {
        console.log("submitNotes");
        try {
            const jwt = getJwt();
            if (!jwt) {
                setError("Authorization token is missing or expired.");
                return;
            }

            const turnCount = getTurnCount();
            const patientId = parseInt(id || "", 10);

            if (isNaN(patientId)) {
                setError("Invalid patient ID.");
                return;
            }

            const payload = {
                turn: turnCount,
                patient_id: patientId,
                entry_type: "note",
                text_body: patientNotes,
            };

            const response = await postNote(jwt, payload);

            if (response.status === 201) {
                // Update the turn count in local storage
                updateTurnCount(2);

                //@ts-ignore
                updateNotesDocumentationToLocalStorage(
                    patientId,
                    response.data as noteData["documentation"]
                );
                clearNotes();
                window.location.reload();
            } else {
                setError(response.error || "Failed to add notes.");
            }
        } catch (err) {
            setError("An unexpected error occurred while submitting notes.");
        }
    };

    const clearNotes = () => {
        setPatientNotes("");
        setError(null);
    };

    return (
        <PatientNotesContainer>
            <TextArea
                placeholder={
                    translations?.patientSidebar.notesPlaceholder ||
                    "Enter notes here..."
                }
                value={patientNotes}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setPatientNotes(e.target.value)
                }
            />
            {error && <div className="error-message">{error}</div>}
            <WebsiteButton
                tag="button"
                disabled={!patientNotes.trim()}
                onClick={submitNotes}
            >
                {translations?.patientSidebar.addNotes || "Add Notes"}
            </WebsiteButton>
        </PatientNotesContainer>
    );
};
