import styled from '@emotion/styled';

export const TableWrapper = styled.div`
    overflow-x: auto;
    width: 100%;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
`;

export const Th = styled.th`
    padding: 1rem;
    background: #f7fafc;
    border-bottom: 2px solid #e2e8f0;
    font-weight: 600;
    text-align: center;

    &:first-of-type {
        text-align: left; // Keep the first column (usually class name) left-aligned
    }
`;

export const Td = styled.td`
    padding: 1rem;
    border-bottom: 1px solid #e2e8f0;
    vertical-align: middle;
    text-align: center;

    &:first-of-type {
        text-align: left; // Keep the first column (usually class name) left-aligned
    }
`;

export const Tr = styled.tr`
    &:hover {
        background: #f7fafc;
    }
`;

// For elements that need special alignment within cells
export const CellContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;