import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Internal imports
import OrderAdditionalTestsForm from "./components/OrderAdditionalTestsForm/OrderAdditionalTestsForm";
import {
    OrderAdditionalTestsContainer,
    OrderAdditionalTestsDivider,
    OrderAdditionalTestsSpace,
    OrderAdditionalTestsPageContainer,
} from "./OrderAdditionalTests.styles";
import { STATUS } from "./config/additionalTestOptions";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import { getTurnCount } from "../../../core/LocalStorage/turnLogic/core";
import { getJwt } from "../../../core/LocalStorage/AuthSession/core";
import { OrderAdditionalTestResult } from "./components/AdditionalTestsResult/orderAdditionalTestResult/OrderAdditionalTestResult";

// Dependency Injection for additional tests page only
import {
    SetAdditionalTestsToLocalStorageType,
    GetAdditionalTestDataFromLocalStorageType,
    FilterAdditionalTestDataType,
} from "../../../core/LocalStorage/patients/additionalTests";
import { UpdateAdditionalTestsDocumentationToLocalStorageType } from "../../../core/LocalStorage/patients/notes";
import { PostAdditionalTestsType } from "../../../core/ServerApiLayer/VOne/patients/postAdditionalTests";
import { GetAllAdditionalTestsType } from "../../../core/ServerApiLayer/VOne/patients/getAllAdditionalTests";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Translation imports
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { AdditionalTestTranslations } from "./translations/types";

// Define the translations for the additional tests page
const TRANSLATIONS: Record<SupportedLanguage, AdditionalTestTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Dependency Injection for Order Additional Tests Page
 */
interface OrderAdditionalTestsProps extends WithPatientProps {
    setAdditionalTestsToLocalStorage: SetAdditionalTestsToLocalStorageType;
    getAdditionalTestDataFromLocalStorage: GetAdditionalTestDataFromLocalStorageType;
    filterAdditionalTestsFromStorage: FilterAdditionalTestDataType;
    updateAdditionalTestsDocumentationToLocalStorage: UpdateAdditionalTestsDocumentationToLocalStorageType;
    postAdditionalTestsApiCall: PostAdditionalTestsType;
    getAllAdditionalTestsApiCall: GetAllAdditionalTestsType;
}

type CombinedProps = OrderAdditionalTestsProps & WithGameDependenciesProps;

/**
 * Order Additional Tests Page
 *
 * @param setAdditionalTestsToLocalStorage
 * @param getAdditionalTestDataFromLocalStorage
 * @param filterAdditionalTestsFromStorage
 * @param updateAdditionalTestsDocumentationToLocalStorage
 * @param postAdditionalTestsApiCall
 * @param getAllAdditionalTestsApiCall
 * @constructor
 */
const OrderAdditionalTestsBase: React.FC<CombinedProps> = ({
    // Additional tests dependencies
    setAdditionalTestsToLocalStorage,
    getAdditionalTestDataFromLocalStorage,
    filterAdditionalTestsFromStorage,
    updateAdditionalTestsDocumentationToLocalStorage,
    postAdditionalTestsApiCall,
    getAllAdditionalTestsApiCall,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount();
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Get the translations
    const { language } = useLanguage();
    const pageTranslations = TRANSLATIONS[language];

    // State to store the pending and available data
    const [pendingData, setPendingData] = useState<any[]>([]);
    const [availableData, setAvailableData] = useState<any[]>([]);

    // Fetch the additional tests data
    const fetchAdditionalTestsData = async () => {
        const jwt = getJwt();
        //@ts-ignore
        const response = await getAllAdditionalTestsApiCall(jwt, {
            patient_id: Number(patientId),
        });

        if (response.status === 200 && response.data) {
            const filteredData = filterAdditionalTestsFromStorage(
                Number(patientId),
                turnCount
            );
            setPendingData(filteredData.pending);
            setAvailableData(filteredData.available);
        } else {
            console.error("Error fetching additional tests:", response.error);
        }
    };

    useEffect(() => {
        fetchAdditionalTestsData();
    }, []);

    const handleSubmit = async (
        professional: string,
        tests: string[],
        reason: string,
        summary: string
    ): Promise<boolean> => {
        const jwt = getJwt();
        const payload = {
            turn: turnCount,
            patient_id: Number(patientId),
            professional,
            summary,
            reason,
            tests,
        };

        // Check if tests already exist
        const existingTests = getAdditionalTestDataFromLocalStorage(
            Number(patientId)
        );
        if (existingTests && tests.some((test) => existingTests[test])) {
            console.warn("Some tests already ordered for this patient");
            return false;
        }

        //@ts-ignore
        const response = await postAdditionalTestsApiCall(jwt, payload);

        if (response.status === 201 && response.data) {
            // Update the turn count
            turnDependencies.updateTurns(3);

            // Update the additional tests data in local storage
            setAdditionalTestsToLocalStorage(Number(patientId), response.data);

            // Update the notes data in local storage
            updateAdditionalTestsDocumentationToLocalStorage(
                Number(patientId),
                response.data.turn_ordered,
                response.data!
            );

            // Re-fetch the data to update the UI
            await fetchAdditionalTestsData();
            return true;
        } else {
            console.error("Error posting additional tests:", response.error);
            return false;
        }
    };

    return (
        <GamePageLayout
            pageTitle={pageTranslations.testLabel}
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            patient={patient}
            translations={translations}
            documentationDependencies={sidebarDependencies}
            turnDependencies={turnDependencies}
            demographicsDependencies={demographicsDependencies}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
        >
            <OrderAdditionalTestsPageContainer>
                <OrderAdditionalTestsContainer>
                    <OrderAdditionalTestsForm onSubmit={handleSubmit} />
                    <OrderAdditionalTestsDivider />
                    <OrderAdditionalTestResult
                        status={pageTranslations[STATUS.PENDING]}
                        data={pendingData}
                    />
                    <OrderAdditionalTestsSpace />
                    <OrderAdditionalTestResult
                        status={pageTranslations[STATUS.AVAILABLE]}
                        data={availableData}
                    />
                </OrderAdditionalTestsContainer>
            </OrderAdditionalTestsPageContainer>
        </GamePageLayout>
    );
};

export const OrderAdditionalTestsPage =
    withGameDependencies<OrderAdditionalTestsProps>(OrderAdditionalTestsBase);
