// External Imports
import React from 'react';

// Internal Imports
import {
    TableWrapper,
    StyledTable,
    Th,
    Tr,
    Td,
    CellContent,
} from './ViewClassTable.styles';

/**
 * Represents a column in the table.
 */
interface Column {
    key: string;
    label: string;
    accessor?: string;
    render?: (row: any) => React.ReactNode;
}

/**
 * Props for the Table component.
 */
interface TableProps {
    data: any[];
    columns: Column[];
}

/**
 * The ViewClassTable component displays a table of classes.
 */
export const ViewClassTable: React.FC<TableProps> = ({ data, columns }) => {
    const renderCell = (column: Column, row: any) => {
        if (column.render) {
            return (
                <CellContent>
                    {column.render(row)}
                </CellContent>
            );
        }

        if (column.accessor) {
            return row[column.accessor];
        }

        return null;
    };

    return (
        <TableWrapper>
            <StyledTable>
                <thead>
                <tr>
                    {columns.map((column) => (
                        <Th key={column.key}>{column.label}</Th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((row, rowIndex) => (
                    <Tr key={rowIndex}>
                        {columns.map((column) => (
                            <Td key={`${rowIndex}-${column.key}`}>
                                {renderCell(column, row)}
                            </Td>
                        ))}
                    </Tr>
                ))}
                </tbody>
            </StyledTable>
        </TableWrapper>
    );
};