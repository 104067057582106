import { Guide, GuideSection, GuideLanguage } from './types';

/**
 * Type guard for GuideLanguage
 */
export function isGuideLanguage(language: string): language is GuideLanguage {
    return ['en', 'de'].includes(language);
}

/**
 * Type guard for GuideSection
 */
export function isGuideSection(section: any): section is GuideSection {
    return (
        typeof section === 'object' &&
        typeof section.id === 'string' &&
        typeof section.title === 'string' &&
        Array.isArray(section.paragraphs) &&
        section.paragraphs.every((p: any) => typeof p === 'string') &&
        (section.videoEmbed === undefined || typeof section.videoEmbed === 'string') &&
        (section.image === undefined || typeof section.image === 'string')
    );
}

/**
 * Type guard for Guide
 */
export function isGuide(guide: any): guide is Guide {
    return (
        typeof guide === 'object' &&
        typeof guide.slug === 'string' &&
        isGuideLanguage(guide.language) &&
        typeof guide.title === 'string' &&
        typeof guide.featureImage === 'string' &&
        typeof guide.summary === 'string' &&
        Array.isArray(guide.sections) &&
        guide.sections.every(isGuideSection) &&
        (guide.featureVideo === undefined || typeof guide.featureVideo === 'string') &&
        (guide.meta === undefined || (
            typeof guide.meta === 'object' &&
            (guide.meta.author === undefined || typeof guide.meta.author === 'string') &&
            (guide.meta.publishDate === undefined || typeof guide.meta.publishDate === 'string') &&
            (guide.meta.category === undefined || typeof guide.meta.category === 'string')
        ))
    );
}