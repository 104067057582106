import { NotesTranslations } from "./types";

export const enTranslations: NotesTranslations = {
    heading: "Notes",
    noNotes: "No notes available",
    turn: "turn",
    turns: "turns",
    notesType: {
        HANDOVER: "Handover",
        BLOOD_ORDER: "Blood Order",
        NOTE: "Note",
        INVESTIGATION_NOTE: "Investigation Note",
        DIAGNOSIS: "Diagnosis",
        INVESTIGATION_ORDER: "Investigation Order",
        TREATMENT_ORDER: "Treatment Order",
        ADMISSION_FORM: "Admission Form",
        CONSULTATION_ORDER: "Consultation Order",
        DRUG_PRESCRIPTION: "Drug Prescription",
        DISCHARGE_FORM: "Discharge Form",
        ADDITIONAL_TEST: "Additional Tests",
    },
};
