import styled from "@emotion/styled";

export const LocationIconContainer = styled.span`
    svg {
        color: #0284c7;
        font-size: 1rem;
        transition: all 0.3s ease-in-out;
    }

    svg:hover {
        color: #0c4a6e;
    }
`;
