import { RewardsTranslations } from './types';

export const deTranslations: RewardsTranslations = {
    content: {
        title: "Abzeichen",
        badgeLabel: "Name des Abzeichens",
        badges: {
            1: "Anfänger",
            2: "Schnell-Lerner",
            3: "Aufsteigender Stern",
            4: "Problemlöser",
            5: "Diagnose-Experte",
            6: "Klinischer Ass",
            7: "Medizin-Meister",
            8: "Wissenssuchender",
            9: "Pflege-Champion",
            10: "Gesundheits-Held",
            11: "Medizinischer Virtuose",
            12: "Großmeister"
        }
    }
};