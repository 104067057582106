// External imports
import React from "react";
import { useEffect, useState } from "react";

// Internal imports
import { PatientExamContent } from "../ExamContent/ExamContent";
import { PatientExamSidebar } from "../ExamSidebar/ExamSidebar";
import { PatientExamStyle } from "./ExamPanel.styles";
import PatientExamConfig from "../../config/PatientExamConfig";
import PatientExamState from "../../state/PatientExamState";
import { PatientExamination } from "../../types/PatientExamTypes";

/**
 * PatientExamProps
 *
 */
export interface PatientExamProps {
    RESPIRATORY?: string;
    ABDOMEN?: string;
    CARDIOVASCULAR?: string;
    GENERAL?: string;
    HEAD_AND_NECK?: string;
    LYMPH_NODE?: string;
    MUSCULOSKELETAL?: string;
    NEUROLOGY?: string;
    GENITAL?: string;
    gender: "Male" | "Female";
    patientId: number; // Pass patientId to ExamPanel
    postExamApiCall: (jwt: string, payload: any) => Promise<any>; // Passed in dependency
    setExamLocalStorage: (patientId: number, examData: any) => void; // Passed in dependency
    getExamLocalStorage: (patientId: number) => any | null; // Passed in dependency
    onExaminedUpdate: () => void; // Callback function
    translations: any;
    examTranslations: any;
    turnDependencies: any;
    getJwt: any;
}

/**
 * ExamPanel
 *
 * This component is used to display the patient exam
 */
export const ExamPanel: React.FC<PatientExamProps> = ({
    gender,
    patientId,
    postExamApiCall,
    setExamLocalStorage,
    getExamLocalStorage,
    onExaminedUpdate,
    translations,
    examTranslations,
    turnDependencies,
    getJwt,
}) => {
    const patient = PatientExamConfig.examinations;
    const [selectedCard, setSelectedCard] = useState<PatientExamination | null>(
        null
    );

    // Populate the selected card based on the state
    useEffect(() => {
        const currentSelectedCard =
            patient.find(
                (exam) => exam.id === PatientExamState.selectedCardId
            ) || patient[0];
        setSelectedCard(currentSelectedCard);
    }, [patient]);

    return (
        <PatientExamStyle>
            <PatientExamSidebar
                cards={patient}
                setSelectedCard={setSelectedCard}
                gender={gender}
                translations={translations}
            />
            {selectedCard && (
                <PatientExamContent
                    patient={patient}
                    selectedCard={selectedCard}
                    gender={gender}
                    patientId={patientId} // Pass patientId to PatientExamContent
                    postExamApiCall={postExamApiCall} // Injected dependency
                    setExamLocalStorage={setExamLocalStorage} // Injected dependency
                    getExamLocalStorage={getExamLocalStorage} // Injected dependency
                    onExaminedUpdate={onExaminedUpdate} // Callback function
                    translations={translations}
                    examTranslations={examTranslations}
                    turnDependencies={turnDependencies}
                    getJwt={getJwt}
                />
            )}
        </PatientExamStyle>
    );
};
