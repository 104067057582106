
// In the future we need to get this from the config
export interface DrugDataArrayProps {
    value: number,
    label: string
}


export const DrugsArray: Array<DrugDataArrayProps> = [
    {
        value: 1,
        label: "4-DMAP, IV, 1000 mg"
    },
    {
        value: 2,
        label: "Acetylcysteine, IV, 1000 mg"
    },
    {
        value: 3,
        label: "Adensine, IV, 6 mg"
    },
    {
        value: 4,
        label: "Adrenaline, Intramuscular Injection, 0.5 mg"
    },
    {
        value: 5,
        label: "Adrenaline, IV, 1 mg"
    },
    {
        value: 6,
        label: "Adrenaline, IV, 5 mcg/min via Perfusor"
    },
    {
        value: 7,
        label: "Ajmaline, IV, 50 mg uber 5 min"
    },
    {
        value: 8,
        label: "Alteplase, IV, 90 mg uber 60 min"
    }
];