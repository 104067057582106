import styled from "@emotion/styled";

export const ReviewPageStatsOptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const ReviewPageStatsSection = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: 600;
    gap: 2px;
    justify-content: start;
    font-size: 14px;
    padding: 10px 0px;
    align-item: center;
    @media (min-width: 1380px) {
        gap: 5px;
        font-size: 14px;
      }
    @media (max-width:767px) {
        flex-direction: column
      }
`;
export const ReviewPageStatsSectionItem = styled.div`
    display: flex;
    flex-direction: row;
`;