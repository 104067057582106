import styled from "@emotion/styled";

export const PatientPictureContainer = styled.div`
    display: block;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 0.5rem;

    img {
        object-fit: cover;
        height: auto;
        max-width: 100%;
    }
`;
