import styled from "@emotion/styled";

export const PricingSectionContainer = styled.section`
    margin-top: 4rem;
    margin-bottom: 4rem;
`;

export const PricingSectionContentContainer = styled.div`
    text-align: center;
`;

export const PricingSectionTitle = styled.h1`
    font-size: 3rem;
    margin-bottom: 0.9rem;
`;

export const PricingSectionDescription = styled.p`
    font-size: 18px;
    line-height: 36px;
    color: gray;
`;

export const PricingCardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    display: flex;
    justify-content: center;
    gap: 2rem;
`;
