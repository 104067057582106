// Defines basic utils for the app (if a util grows branch it out to its own module)
import {useCallback, useState} from "react";


/**
 * A hook that forces the component to update and re-render.
 * @returns {function} A function that forces a component to update.
 */
export const useForceUpdate = () => {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
        setTick(tick => tick + 1);
    }, []);
    return update;
};
