import styled from '@emotion/styled';

/* DrugChart.module.css */
export const FlexContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const DrugChartHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
`;

export const DrugChartTitle =styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
`;

export const UpdateAllergiesButton = styled.button`
    background-color: white;
    color: red;
    border: 1px solid red;
    padding: 2px 6px;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.3s ease;
    &:hover {
        background-color: red; 
        color: white; 
    }
`;