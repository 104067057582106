// External imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Internal imports
import {
    DischargePatientDetailsTableContainer,
    ReviewDischargePatientButton,
} from "./DischargePatientDetails.styles";
import Table from "../../../../components/core/table/Table";
import { IPatientLocation } from "../../../../components/core/location-icon/types/IPatientLocation";
import { LocationIcon } from "../../../../components/core/location-icon/LocationIcon";
import GenderIcon from "../../../../components/core/gender-icon/GenderIcon";
import { DemographicsContainer } from "../../dashBoardPage/dashBoardTable/dashBoardTable.styles";
import { Patient } from "../../../../core/LocalStorage/patients/interface";
import { DischargedPatient } from "../dischargeBoardPage";
import DischargePatientModal from "../../../../components/core/modal/discharge-patient/DischargePatient";
import { DischargePatientProps1 } from "../../../../components/core/modal/discharge-patient/DischargePatient.props";

// Translation imports
import { DischargePageTranslations } from "../translations/types";

export interface DischargePatientDetailsProps {
    data: DischargedPatient[];
    translations: DischargePageTranslations;
    demographicsDependencies: any;
}

export const DischargePatientDetails: React.FC<
    DischargePatientDetailsProps
> = ({
    data,
    translations,
    demographicsDependencies,
}: DischargePatientDetailsProps) => {
    //State to show hide feedback modal
    const [feedbackModalOpen, setFeedbackModelOpen] = useState(false);

    //State to collect selected user data from localstorage by feedback button click
    const [selectedPatientData, setSelectedPatientData] = useState(null);

    const showFeedbackPopup = (patient_id: number): void => {
        const selectedPatient =
            demographicsDependencies.getPatientData(patient_id);
        //@ts-ignore
        selectedPatient.demographics = selectedPatient?.age;

        //@ts-ignore
        setSelectedPatientData(selectedPatient);
        setFeedbackModelOpen(true);
    };
    const columns = [
        {
            key: "location",
            label: translations.table.columns.location,
            render: (patient: DischargedPatient) => (
                <LocationIcon location={patient.location} />
            ),
        },
        {
            key: "name",
            label: translations.table.columns.name,
            render: (patient: DischargedPatient) => <span>{patient.name}</span>,
        },
        {
            key: "demographics",
            label: translations.table.columns.ageGender,
            render: (patient: DischargedPatient) => {
                const [age, gender] = patient.demographics.split(" ");
                return (
                    <DemographicsContainer>
                        {age}{" "}
                        <GenderIcon
                            gender={gender === "Male" ? "male" : "female"}
                        />
                    </DemographicsContainer>
                );
            },
        },
        {
            key: "symptom",
            label: translations.table.columns.symptom,
            render: (patient: DischargedPatient) => (
                <span>{patient.symptom}</span>
            ),
        },
        {
            key: "diagnosis",
            label: translations.table.columns.diagnosis,
            render: (patient: DischargedPatient) => (
                <span>{patient.diagnosis || "Undiagnosed"}</span>
            ),
        },
        {
            key: "real_diagnosis",
            label: translations.table.columns.realDiagnosis,
            render: (patient: DischargedPatient) => (
                <span>{patient.real_diagnosis || "TO ADD"}</span>
            ),
        },
        {
            key: "score",
            label: translations.table.columns.score,
            render: (patient: DischargedPatient) => (
                <span>{patient.score ?? "N/A"}</span>
            ),
        },
        {
            key: "review",
            render: (patient: DischargedPatient) => (
                <ReviewDischargePatientButton
                    onClick={() => showFeedbackPopup(Number(patient.id))}
                >
                    {translations.table.feedback}
                </ReviewDischargePatientButton>
            ),
        },
    ];

    return (
        <DischargePatientDetailsTableContainer>
            <Table
                data={data || []}
                //@ts-ignore
                columns={columns}
                enableReviewModal={true}
                noRowsText={translations.table.noPatient}
            />
            {feedbackModalOpen && selectedPatientData && (
                <DischargePatientModal
                    patient={selectedPatientData}
                    feedback={DischargePatientProps1.feedback}
                    onClose={() => {
                        setFeedbackModelOpen(false);
                    }}
                />
            )}
        </DischargePatientDetailsTableContainer>
    );
};
