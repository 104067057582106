import styled from "@emotion/styled";

export const InvestigationFormContainer = styled.div`
    border-top-width: 1px;
    border-color: #e5e7eb;
    font-family: "Helvetica", Arial, sans-serif;
`;

export const InvestigationFormGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 1rem;
    row-gap: 1.5rem;

    @media (min-width: 640px) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
`;

export const InvestigationFormGridItemBy3 = styled.div`
    @media (min-width: 640px) {
        grid-column: span 3 / span 3;
    }
`;

export const InvestigationFormGridItemBy6 = styled.div`
    @media (min-width: 640px) {
        grid-column: span 6 / span 6;
    }
`;

export const InvestigationFormLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #374151;
`;

export const InvestigationFormFieldContainer = styled.div`
    margin-top: 0.25rem;
`;

export const InvestigationTurnLabel = styled.p`
    font-size: 0.875rem;
`;
