import styled from "@emotion/styled";

export const ScoringContainer = styled.div`
    font-family: 'Helvetica', 'Arial', sans-serif;
    display: flex;
    width: 100%;
    margin-top: 20px;
    min-width: 700px;
    max-width: 1200px;
`;

export const LeftPanel = styled.div`
    flex: 0 0 50%;
    margin: 10px;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const RightPanel = styled.div`
    flex: 1;
    margin: 10px;
    margin-left: 0;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ScoringControl = styled.div`
    border: 1px solid #cfcacab0;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;
    min-width: 300px;
    max-height: 80px;
`;

export const ScoringLabel = styled.p`
    display: flex;
    height: fit-content;
    font-size: 20px;
    width: 100%;
`;

export const ScoringInput = styled.input`
    border: none;
    border-left: 1px solid #ccc;
    font-size: 20px;
    flex: 0 0 18%;
    width: 100%;
    text-align: center;
    height: 100%;
`;

export const SwitchContainer = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    min-width: 60px;
    min-height: 34px;
    margin: 15px;
`;

export const SwitchInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`;

export const Slider = styled.span<{ checked: boolean }>`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.checked ? '#ea1e33' : '#acec9c'};
    transition: 0.4s;
    border-radius: 34px;
    border: 1px solid #cfcacab0;

    &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: 0.4s;
        border-radius: 34px;
        margin: -2px;
        transform: ${props => props.checked ? 'translateX(26px)' : 'translateX(0)'};
    }
`;