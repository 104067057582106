// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { drugData, drugChartData } from "../../../LocalStorage/patients/interface";


/*
The payload for getting all drugs prescribed to a patient.
 */
export interface GetAllDrugsPayload {
    patient_id: number;
};


/*
The interface for the response from the get all drugs endpoint.

@status: The HTTP status code.
@error: The error message.
@data: The object containing all the drugs.
 */
export interface GetAllDrugsResponse {
    status: number;
    error?: string;
    data?: drugChartData;
};


/* Function Signature */
export type GetAllDrugsType = (jwt: string, getAllTreatmentsPayload: GetAllDrugsPayload) => Promise<GetAllDrugsResponse>;


/*
The function to get all drugs for a patient.

@jwt: The JWT of the user requesting the drugs.
@getAllTreatmentsPayload: The payload containing the patient ID.

@returns: The ApiResponse from the get all drugs endpoint. This contains all the drugs for the patient.
 */
export async function getAllDrugs(jwt: string, getAllDrugsPayload: GetAllDrugsPayload): Promise<GetAllDrugsResponse> {
    const url = new PatientUrl().getAllDrugs;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<drugChartData | string>(url, {
            patient_id: getAllDrugsPayload.patient_id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });
    } catch (error) {
        return { status: 500, error: "Network or other error occurred" };
    }
    if (response.status === 201) {
        return { status: response.status, data: response.data as drugChartData };
    } else {
        return { status: response.status, error: response.data };
    }
}