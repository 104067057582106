import React from "react";
import { TooltipContent, TooltipCore, TooltipTrigger } from "./Tooltip.config";
import { Placement } from "@floating-ui/react";

/**
 * TooltipProps interface.
 * @interface TooltipProps
 * @description The props for the Tooltip component.
 * @property {React.ReactNode} children - The children to render.
 * @property {React.ReactNode} tooltip - The tooltip to display.
 * @property {Placement} placement - The placement of the tooltip.
 */
interface TooltipProps {
    children: React.ReactNode;
    tooltip?: React.ReactNode;
    placement?: Placement;
}

/**
 * Tooltip component.
 * @component
 * @description A tooltip component that displays additional information when hovering over an element.
 * @param {TooltipProps} props - The component props.
 * @param {React.ReactNode} props.children - The children to render.
 * @param {React.ReactNode} props.tooltip - The tooltip to display.
 * @param {Placement} props.placement - The placement of the tooltip.
 * @returns {JSX.Element} The rendered Tooltip component.
 */
function Tooltip({
    children,
    tooltip,
    placement = "left-start",
}: TooltipProps): JSX.Element {
    return (
        <TooltipCore placement={placement ?? "left-start"}>
            <TooltipTrigger>{children}</TooltipTrigger>
            <TooltipContent>{tooltip}</TooltipContent>
        </TooltipCore>
    );
}

export default Tooltip;
