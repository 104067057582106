// defines the label for the new class inputs.
import React from "react";
import { FieldContainer, FieldLabel } from "./NewClassFormLabelledField.styles";


/*
Defines the label for the new class inputs (essentially a wrapper).

@prop label - the label for the input
@prop children - the input component
 */
export const NewClassFormLabelledField = ({
    label,
    children,
}: {
    label: string;
    children: React.ReactNode;
}) => {
    const htmlFor = (React.Children.only(children)! as any).props.name;

    return (
        <FieldContainer>
            <FieldLabel htmlFor={htmlFor}>{label}</FieldLabel>
            {children}
        </FieldContainer>
    );
};
