import { DiagnosisTypesTranslations } from "./types";

export const deDiagnosisTranslations: DiagnosisTypesTranslations = {
    ABDOMINAL_AORTIC_ANEURYSM: "Bauchaortenaneurysma",
    ACIDIC_REFLUX: "Gastroösophageale Refluxerkrankung (GERD)",
    ACUTE_APPENDICITIS: "Appendizitis",
    ACUTE_CHOLECYSTITIS: "Cholezystitis",
    ACUTE_RENAL_FAILURE: "Akute Niereninsuffizienz",
    ACUTE_URINARY_RETENTION: "Akuter Harnverhalt",
    ACUTE_PSYCHOSIS: "Akute Psychose",
    ANAPHYLAXIS: "Anaphylaxie",
    AORTIC_DISSECTION: "Aortendissektion",
    AORTIC_STENOSIS: "Aortenklappenstenose",
    ATRIAL_FIBRILLATION: "Vorhofflimmern",
    AV_BLOCK_COMPLETE: "AV-Block III˚",
    AVNRT: "AVNRT",
    BENIGN_PAROXYSMAL_POSITIONAL_VERTIGO:
        "Benigner paroxysmaler Lagerungsschwindel",
    CHEST_SEPSIS: "Pneumogene Sepsis",
    COMMUNITY_ACQUIRED_PNEUMONIA: "Ambulant erworbene Pneumonie",
    COMMUNITY_ACQUIRED_PNEUMONIA_OLD: "Ambulant erworbene Pneumonie (alt)",
    CONGESTIVE_HEART_FAILURE: "Akute Herzinsuffizienz",
    COVID_INFECTION: "COVID-Infektion",
    DEPRESSION: "Depressive Episode",
    DEHYDRATION: "Dehydratation",
    DIABETIC_COMA: "Diabetisches Koma",
    ECTOPIC_PREGNANCY: "Extrauteringravidität",
    FEBRILE_SEIZURE: "Fieberkrampf",
    HYPERGLYCAEMIA: "Hyperglykämie",
    HYPERKALEMIA: "Hyperkaliämie",
    HYPERTENSIVE_CRISIS: "Hypertensive Krise",
    HYPERTENSIVE_EMERGENCY: "Hypertensiver Notfall",
    HYPERTHYROIDISM: "Hyperthyreose",
    HYPOGLYCAEMIA: "Hypoglykämie",
    NON_INFECTIVE_EXACERBATION_OF_COPD:
        "COPD-Exazerbation (nicht infektbedingt)",
    INFECTIVE_GASTROENTERITIS: "Gastroenteritis (Norovirus-Infektion)",
    ISCHAEMIC_STROKE: "Ischämischer Schlaganfall",
    KIDNEY_STONES: "Nierenkolik",
    LEFT_SIDED_ENDOCARDITIS: "Endokarditis",
    LIVER_CIRRHOSIS: "Leberzirrhose",
    LOWER_GI_BLEEDING: "Untere gastrointestinale Blutung",
    MENINGITIS: "Meningitis",
    MESENTERIC_ISCHAEMIA: "Mesenterialischämie",
    MIGRAINE: "Migräne",
    MUSCULOSKELETAL_CHEST_PAIN: "Muskuloskelettaler Brustschmerz",
    NON_INFECTIVE_EXACERBATION_OF_ASTHMA:
        "Asthma-Exazerbation (nicht infektbedingt)",
    INFECTIVE_EXACERBATION_OF_COPD: "COPD-Exazerbation (infektassoziiert)",
    NSTEMI: "NSTEMI",
    ORTHOSTATIC_SYNCOPE: "Orthostatische Synkope",
    OVARIAN_TORSION: "Ovarialtorsion",
    PANIC_ATTACK: "Panikattacke",
    PERIPHERAL_ARTERY_DISEASE: "Periphere arterielle Verschlusskrankheit",
    PNEUMOTHORAX: "Pneumothorax",
    PREECLAMPSIA: "Präeklampsie",
    PULMONARY_EMBOLISM: "Lungenembolie (stabil)",
    PULMONARY_EMBOLISM_UNSTABLE: "Lungenembolie (instabil)",
    SHOULDER_DISLOCATION: "Schulterluxation",
    SEIZURE: "Krampfanfall",
    SIGMOID_DIVERTICULITIS: "Sigmadivertikulitis",
    SINUS_TACHYCARDIA: "Sinustachykardie",
    SINUS_VEIN_THROMBOSIS: "Sinusvenenthrombose",
    STEMI_ANTERIOR: "STEMI anterior",
    STEMI_POSTERIOR: "STEMI posterior",
    SUBARACHNOID_HEMORRHAGE: "Subarachnoidalblutung",
    SUBDURAL_HEMATOMA: "Subduralhämatom",
    TESTICULAR_TORSION: "Hodentorsion",
    TRANSIENT_ISCHAEMIC_ATTACK: "Transitorische ischämische Attacke",
    TRAUMATIC_BRAIN_INJURY: "Schädel-Hirn-Trauma",
    UPPER_GI_BLEED: "Obere gastrointestinale Blutung (stabil)",
    UPPER_GI_BLEED_UNSTABLE: "Obere gastrointestinale Blutung (instabil)",
    UPPER_RESPIRATORY_TRACT_INFECTION: "Oberer Atemwegsinfekt",
    UROSEPSIS: "Urosepsis",
};
