// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { noteData } from "../../../LocalStorage/patients/interface";

/*
The payload for getting all notes.
 */
export interface GetAllNotesPayload {
    patient_id: number;
}

/*
The interface for the response from the get all notes endpoint.

@status: The HTTP status code.
@error: The error message.
@data: The object containing all the notes.
 */
export interface GetAllNotesResponse {
    status: number;
    error?: string;
    data?: noteData;
}

/* Function Signature */
export type GetAllNotesType = (
    jwt: string,
    getAllNotesPayload: GetAllNotesPayload
) => Promise<GetAllNotesResponse>;

/*
The function to get all notes for a patient.

@jwt: The JWT of the user requesting the notes.
@getAllNotesPayload: The payload containing the patient ID.

@returns: The ApiResponse from the get all notes endpoint. This contains all the notes for the patient.
 */
export async function getAllNotes(
    jwt: string,
    getAllNotesPayload: GetAllNotesPayload
): Promise<GetAllNotesResponse> {
    const url = new PatientUrl().getAllNotes;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<noteData | string>(
            url,
            {
                patient_id: getAllNotesPayload.patient_id,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    token: jwt,
                },
                validateStatus: () => true,
            }
        );
    } catch (error) {
        return { status: 500, error: "Network or other error occurred" };
    }

    // Check the response status, and return the appropriate response.
    if (response.status === 201) {
        return {
            status: response.status,
            data: response.data as noteData,
        };
    } else {
        return {
            status: response.status,
            error: response.data,
        };
    }
}
