import { NewClassTranslations } from './types';

export const enTranslations: NewClassTranslations = {
    header: "Create New Class",
    classStartsOpen: "Class starts open",
    createButton: "Create Class",
    errors: {
        noJwt: "Error creating class: No JWT found",
        createClass: "Error creating class: "
    }
};