// External Imports
import React from 'react';

// Defines the main pages routes
import ResearchPage from './research/Research';
import AnalyticsInsightsPage from './analytics-insights/AnalyticsInsights';
import {PressPage} from './press/Press';
import Pricing from './pricingPage/Pricing';
import {GuidesPage} from './guides-page/GuidesPage';
import { Guide } from './guides-page/guides/Guide';
import Partners from "./partners/Partners";
import BlogsPage from './blogs-page/BlogsPage';
import BlogDetailPage from "./blogs-page/blogs/Blog";


export const MainPageRoutes = [
    {
        path: "/research",
        element: <ResearchPage
        />,
    },
    {
        path: "/analytics",
        element: <AnalyticsInsightsPage
        />,
    },
    {
        path: "/press",
        element: <PressPage
        />,
    },
    {
        path: "/products",
        element: <Pricing
        />,
    },
    {
        path: "/guides",
        element: <GuidesPage
        />,
    },
    {
        path: "/guides/:slug",
        element: <Guide
        />,
    },
    {
        path: "/partners",
        element: <Partners/>
    },
    {
        path: "/blog",
        element: <BlogsPage/>
    },
    {
        path: "/blog/:slug",
        element: <BlogDetailPage />
    }
]
