import styled from "@emotion/styled";

export const NewClassFormTabsContainer = styled.ul`
    display: flex;
    justify-content: center;
    gap: 0.5em;
    position: absolute;
    top: -2em;
    left: 50%;
    transform: translateX(-50%);
    li {
        list-style: none;
    }
`;
