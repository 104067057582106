import React, { useEffect, useRef, useState } from "react";
import { TbArrowsVertical } from "react-icons/tb";
import { ScrubberContainer, ScrubberInputField } from "./ScubberInput.styles";

export interface ScrubberInputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    min?: number;
    max?: number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ScrubberInput: React.FC<ScrubberInputProps> = (props) => {
    const [dragging, setDragging] = useState(false);
    const [startY, setStartY] = useState(0);
    const [startValue, setStartValue] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (!dragging) return;

            const delta = Math.round((startY - e.clientY) / 5);
            let newValue = startValue + delta;

            if (props.min !== undefined) {
                newValue = Math.max(newValue, props.min);
            }

            if (props.max !== undefined) {
                newValue = Math.min(newValue, props.max);
            }

            if (newValue !== parseFloat((props.value as string) || "0")) {
                props.onChange?.({
                    target: { value: newValue.toString() },
                } as React.ChangeEvent<HTMLInputElement>);
            }
        };

        const handleMouseUp = () => {
            setDragging(false);
            document.body.style.userSelect = "";
        };

        if (dragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
            document.body.style.userSelect = "none";
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            document.body.style.userSelect = "";
        };
    }, [dragging, startY, startValue, props]);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === inputRef.current) return;

        setDragging(true);
        setStartY(e.clientY);
        setStartValue(parseFloat((props.value as string) ?? "0"));
    };

    return (
        <ScrubberContainer onMouseDown={handleMouseDown}>
            <ScrubberInputField ref={inputRef} {...props} />
            <TbArrowsVertical color="black" />
        </ScrubberContainer>
    );
};

export default ScrubberInput;
