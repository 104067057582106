import { VOneUrl } from "../url";


/*
The Url endpoints for the User API.

Attributes:
    base: The base url.
    create: The url for creating a user.
    login: The url for logging in a user.
    updateRole: The url for updating a user auth session role that is currently logged in.
    confirm: The url for confirming a user.
    passwordReset: The url for resetting a user's password.
    passwordResetRequest: The url for requesting a password reset for a user.
 */
export class UserUrl extends VOneUrl {

    public base: string;
    public create: string;
    public login: string;
    public updateRole: string;
    public confirm: string;
    public passwordReset: string;
    public passwordResetRequest: string;
    public ssoLogin: string;
    public dataAgree: string;
    public agreements: string;

    constructor() {
        super();
        this.base = this.defineRoot() + "api/v1/user/";
        this.create = this.base + "create";
        this.login = this.base + "login";
        this.updateRole = this.base + "update/role";
        this.confirm = this.base + "confirm";
        this.passwordReset = this.base + "password/reset";
        this.passwordResetRequest = this.base + "password/reset/request";
        this.ssoLogin = this.base + "dfn/post";
        this.dataAgree = this.base + "data/agree";
        this.agreements = this.base + "agreements";
    }

}
