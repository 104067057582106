import React from "react";
import { startCase } from "lodash";
import {
    OrderAdditionalTestResultContainer,
    OrderAdditionalTestResultList,
    OrderAdditionalTestResultTitle,
} from "./OrderAdditionalTestResult.styles";
import { OrderAdditionalTestResultListItem } from "../orderAdditionalTestResultListItem/OrderAdditionalTestResultListItem";
import { enTranslations } from "../../../translations/en";
import { deTranslations } from "../../../translations/de";
import { SupportedLanguage } from "../../../../../../core/translations/supportedLanguages";
import { AdditionalTestTranslations } from "../../../translations/types";
import { useLanguage } from "../../../../../../core/translations/LanguageContext";

/**
 * OrderAdditionalTestResultProps
 */
interface OrderAdditionalTestResultProps {
    status: string;
    data: any[];
}

/**
 * OrderAdditionalTestResult
 *
 * This component is a list of investigation results. It displays the investigation results that are
 * available or pending. There are two statuses: available and pending.
 */
export const OrderAdditionalTestResult = ({
    status,
    data,
}: OrderAdditionalTestResultProps) => {
    const TRANSLATIONS: Record<SupportedLanguage, AdditionalTestTranslations> =
        {
            en: enTranslations,
            de: deTranslations,
        };

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    return (
        <OrderAdditionalTestResultContainer>
            <OrderAdditionalTestResultTitle>{`${startCase(status)} ${
                translations.result
            }`}</OrderAdditionalTestResultTitle>
            <OrderAdditionalTestResultList>
                {data && data.length > 0
                    ? data.map((item, index) =>
                          status === "pending" ? (
                              // For pending results, show a tooltip and no buttons
                              <OrderAdditionalTestResultListItem
                                  key={index}
                                  title={item.professional}
                                  complete={false}
                                  details={`Ordered Turn ${item.turn_ordered}`}
                                  allowNotes={false}
                                  turn_ordered={item.turn_ordered}
                                  reason={item.reason}
                                  summary={item.summary}
                              />
                          ) : (
                              // For available results, show the full details
                              <OrderAdditionalTestResultListItem
                                  key={index}
                                  title={item.professional}
                                  complete={true}
                                  details={item.findings}
                                  allowNotes={true}
                                  turn_ordered={item.turn_ordered}
                                  reason={item.reason}
                                  summary={item.summary}
                              />
                          )
                      )
                    : null}
            </OrderAdditionalTestResultList>
        </OrderAdditionalTestResultContainer>
    );
};
