export const DischargePatientProps1 = {
    patient: {
        faceURL:
            "https://divina-game-faces-bucket.s3.eu-west-2.amazonaws.com/P_4_F_21.jpg",
        name: "Marilyn O'Neill",
        demographics: "20 male",
        symptom: "Chest Pain",
        diagnosis: "",
    },
    feedback: {
        user_diagnosis: "Acute Renal Failure",
        real_diagnosis: "Acute Renal Failure",
        history: {
            presenting_system: {
                actual_symptoms: {
                    Troponin: true,
                    ECG: false,
                    Heparin: true,
                    Aspirin: true,
                },
            },
            past_medical_history: true,
            smoking_status: true,
            alcohol: false,
        },
        protocol: {
            Troponin: true,
            ECG: false,
            Heparin: true,
            Aspirin: true,
        },
        feedback: "Well done! You have correctly diagnosed the patient.",
        correctDiagnosisScore: 15,
        correctManagementScore: 15,
        negativeScoreForIncorrectManagement: 5,
    },
    onClose: () => {},
};

export const DischargePatientProps2 = {
    patient: {
        faceURL:
            "https://divina-game-faces-bucket.s3.eu-west-2.amazonaws.com/P_4_F_21.jpg",
        name: "Marilyn O'Neill",
        demographics: "20 male",
        symptom: "Chest Pain",
        diagnosis: "",
    },
    feedback: {
        user_diagnosis: "Acute Failure",
        real_diagnosis: "Acute Renal Failure",
        history: {
            presenting_system: {
                actual_symptoms: {
                    Troponin: true,
                    ECG: false,
                    Heparin: true,
                    Aspirin: true,
                },
            },
            past_medical_history: true,
            smoking_status: true,
            alcohol: false,
        },
        protocol: {
            Troponin: true,
            ECG: false,
            Heparin: false,
            Aspirin: true,
        },
        feedback:
            "You have incorrectly diagnosed the patient. Please try again.",
        correctDiagnosisScore: 25,
        correctManagementScore: 15,
        negativeScoreForIncorrectManagement: 10,
    },
    onClose: () => {},
};
