import styled from "@emotion/styled";

export const Flex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    background-color: #f3f4f6;
    padding: 1em 1.2em;
    padding-bottom: 2em;
    height: 43em;
    overflow-y: auto;
    border-radius: 0.5rem;
    > * {
        flex-shrink: 0;
    }
`;
export const SectionHeader = styled.h2`
    background-color: #163b5d;
    font-size: 0.92em;
    margin-top: 0;
    color: #FFF;
    margin-bottom: 0;
    padding: 0.5em 1em;
`;
export const SectionItem = styled.div`
    font-size: 0.93em;
    width: 50%;
    box-sizing: border-box;
    padding: 0.5em 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    &:nth-child(odd) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &:hover {
        overflow: visible;
        color: transparent;
        > .tooltip {
            opacity: 1;
            color: black;
            display: flex;
        }
    }
    &:nth-child(odd) {
        > .tooltip {
            left: 0;
        }
    }
    &:nth-child(even) {
        > .tooltip {
            right: 0;
            justify-content: flex-end;
        }
    }
    > .tooltip {
        width: fit-content;
        top: 0;
        font-weight: 600;
        display: flex;
        opacity: 0;
        transition: opacity 0.2s;
        cursor: pointer;
        user-select: none;
        background: #fafafa;
        font-size: 1em;
        position: absolute;
        z-index: 1;
        height: 100%;
        align-items: center;
        padding: 0 1em;
        pointer-events: none;
    }
`;
export const SectionItems = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
export const Section = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    overflow: hidden;
    font-size: 0.94em;
`;
export const NoDiseases = styled.div`
    font-size: 0.95em;
    flex-grow: 1;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
