import styled from "@emotion/styled";

// SidebarItemContainer adjusted to ensure it centers its children and applies the desired background and text color
export const SidebarItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0.45rem;
  border-radius: 0.375rem;
  min-width: 130px; 
  height: 80px;
  background-color: #313E52; // Default background color
  color: #DFF2FF; // Default text color
  text-decoration: none;
  &:hover {
    background-color: #252E3D; // Darker on hover
    color: white;
    height:80px;
    // Shows SubItemsContainer on hover
    & > div {
      display: flex;
    }
  }
`;

// Item styled to layout the icon above the text, centered
export const Item = styled.a`
  box-sizing: border-box;
  display: flex;
  margin-top:9px;
  gap:5px;
  flex-direction: column; // Icon above text
  align-items: center; // Center content
  text-decoration: none;
  color: inherit; // Inherits color for consistency
  width: 100%; // Utilize full width for alignment
  & > span {
    font-size:14px;
    color:white;
  }

`;

// Item styled to layout the icon above the text, centered
export const NavIcon = styled.i`

& > svg {
    width:25px;
    height:25px;
  }

`;

export const SubTitle = styled.p`
text-wrap:nowrap;
`;