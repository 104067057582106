import React from "react";
import { Notification } from "../../NotificationBell/NotificationBell";
import {
    ItemContainer,
    DismissButton,
    IconsArea,
} from "./NotificationItem.styles";
import { FaNotesMedical, FaDesktop, FaBed } from "react-icons/fa";
import { MarkNotificationReadType } from "../../../../../../core/LocalStorage/Notifications/core";
/**
 * The props for the NotificationItem component.
 */
interface NotificationItemProps {
    notification: Notification;
    onDismiss: MarkNotificationReadType;
    translations?: any;
}

/**
 * A simple component that displays an individual notification.
 *
 * @returns The NotificationItem component.
 *
 * @example
 * ```tsx
 * <NotificationItem notification={notification} onDismiss={handleDismiss} />
 * ```
 */
export const NotificationItem: React.FC<NotificationItemProps> = ({
    notification,
    onDismiss,
    translations,
}) => {
    // Stop event propagation to prevent the menu from closing once the dismiss button is clicked
    const handleDismissClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        console.log("dismissed");
        event.stopPropagation();
        onDismiss(notification.id);
    };

    // Get the icon for the notification
    const getNotificationIcon = () => {
        //@ts-ignore
        switch (notification.sender) {
            case "frontDesk":
                return <FaDesktop />;
            case "deteriorationPatient":
                return <FaNotesMedical />;
            default:
                return <FaBed />;
        }
    };

    // Render the notification item
    return (
        <ItemContainer>
            <IconsArea>{getNotificationIcon()}</IconsArea>
            {
                translations.notifications.notificationMessages[
                    notification.message
                ]
            }
            <DismissButton onClick={handleDismissClick}>Dismiss</DismissButton>
        </ItemContainer>
    );
};
