// External imports
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Internal imports
import "./styles/index.css";
import { UserRoutes } from "./pages/users/routes";
import { TeacherRoutes } from "./pages/teachers/routes";
import { StudentRoutes } from "./pages/students/routes";
import { MainPageRoutes } from "./pages/main/routes";
import { Chat } from "./components/experimental/Chat";
import { FrontPage } from "./pages/main/FrontPage";
import AboutPage from "./pages/main/about/AboutPage";
import ContactPage from "./pages/main/contact/ContactPage";
import { PatientsList } from "./pages/main/patientList";
import NotFoundPage from "./pages/main/not-found-page/NotFoundPage";

// Dependency Injection
import { getUserRole } from "./core/LocalStorage/AuthSession/core";

// Language Context
import { LanguageProvider } from './core/translations/LanguageContext';

/**
 * Compiles all routes for the application
 * @returns Array of route configurations
 */
function compileRoutes() {
  const routes = [
    {
      path: "/",
      element: <FrontPage getRoleApiCall={getUserRole} />,
    },
    {
      path: "/about",
      element: <AboutPage />,
    },
    {
      path: "/contact",
      element: <ContactPage />,
    },
    {
      path: "/chat",
      element: <Chat />,
    },
    {
      path: "/patients",
      element: <PatientsList />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ];

  return routes
      .concat(UserRoutes)
      .concat(TeacherRoutes)
      .concat(StudentRoutes)
      .concat(MainPageRoutes);
}

/**
 * Root component that provides language context to the entire app
 */
const Root: React.FC = () => {
  const router = createBrowserRouter(compileRoutes());

  return (
      <LanguageProvider>
        <RouterProvider router={router} />
      </LanguageProvider>
  );
};

// Initialize React root and render application
const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<Root />);
} else {
  console.error("Failed to find root element");
}