import styled from "@emotion/styled";

export const FooterSection = styled.div``;
export const SectionTitle = styled.h3`
  font-weight: 600;
  font-size: 1em;
`;
export const LinkList = styled.ul`
  padding: 0;
  margin-top: 1rem;
  & > li:not(:last-child) {
    margin-bottom: 0.7rem;
  }
  list-style-type: none;
`;
export const StyledLink = styled.a`
  &:hover {
    color: #64748b;
  }
`;
