import React, { lazy } from "react";
import { Td } from "./TableRow.style";
import DischargePatientModal from "../../../modal/discharge-patient/DischargePatient";

interface TableRowProps {
    item: any;
    tableColumns: any[];
    enableReviewModal?: boolean;
}

const TableRow: React.FC<TableRowProps> = ({
                                               item,
                                               tableColumns,
                                               enableReviewModal,
                                           }) => {
    const [dischargeModalOpen, setDischargeModalOpen] =
        React.useState<boolean>(false);

    return (
        <tr>
            {tableColumns.map((column, index) => (
                <React.Fragment key={index}>
                    <Td>
                        {column.render
                            ? column.render(item) // Pass the entire item (Patient object) to the render function
                            : item[column.key!]}
                    </Td>
                    {column.key === "review" &&
                        enableReviewModal &&
                        dischargeModalOpen && (
                            <DischargePatientModal
                                patient={item.patient}
                                feedback={item.feedback}
                                onClose={() => setDischargeModalOpen(false)}
                            />
                        )}
                </React.Fragment>
            ))}
        </tr>
    );
};

export default TableRow;
