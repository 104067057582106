import clinicalmetricsRegular from "./clinical_metrics.png";
import clinicalmetricsAlt from "./clinical_metrics_alt.svg";
import divinaAlt from "./logo-alt.svg";
import divinaRegular from "./logo.svg";

const logoPaths = {
    divina: {
        regular: divinaRegular,
        alt: divinaAlt,
    },
    clinicalmetrics: {
        regular: clinicalmetricsRegular,
        alt: clinicalmetricsAlt,
    },
};

const Logo = ({
    alt,
    version: _version,
}: {
    alt?: boolean;
    version?: "divinagame" |"clinicalmetrics";
}) => {
    // Hardcoded to clinicalmetrics for now
    const version: "divinagame" | "clinicalmetrics"  =
        _version ?? ("clinicalmetrics" as any);

    const getAppVersion = () => {
        if (version === "divinagame") {
            return {
                dfn: true,
                defaultLanguage: "de",
                logo: "divina",
                logoSrc: logoPaths.divina,
                version,
            };
        }  else {
            return {
                dfn: false,
                defaultLanguage: "en",
                logo: "clinicalmetrics",
                logoSrc: logoPaths.clinicalmetrics,
                version,
            };
        }
    };
    const versionD = getAppVersion();

    return (
        <a href="/" className="cursor-pointer">
            {versionD.logo === "divina" ? (
                <img
                    src={alt ? versionD.logoSrc.alt : versionD.logoSrc.regular}
                    width={72}
                    height={16}
                    alt="Divina logo"
                />
            ) : (
                <img
                    src={alt ? versionD.logoSrc.alt : versionD.logoSrc.regular}
                    className="w-52"
                    style={{
                        position: "relative",
                        top: ".18em",
                        width: "13em",
                    }}
                />
            )}
        </a>
    );
};

export default Logo;
