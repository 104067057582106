import styled from "@emotion/styled";

export const Sidebar = styled.div`
  top: 80px;
  display: block;
  width: auto; 
  height: inherit;
  left: 0;
  background-color: #313E52;
  overflow-x: visible; // Allows popout menus to overlay horizontally
  z-index: 1000;
`;


export const SidebarInner = styled.div`
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: stetch // or 'stretch' for full-width buttons
    gap: 8px; // Adjust based on your design needs
    padding: 1rem; // Ensure there's padding to avoid items touching the edges
`;


export const SidebarContent = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.55em;
`;

