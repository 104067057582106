import React from "react";
import {
    GuidelineItemContainer,
    GuidelineItemSubTitle,
    GuidelineItemTitle,
    GuidelineItemParagraph,
} from "./GuidelineItem.styles";

/**
 * GuidelineItem Props.
 * @interface GuidelineItemProps
 * @property {string} title - The title of the guideline.
 * @property {string} href - The URL of the guideline.
 * @property {string} year - The year of the guideline.
 */
interface GuidelineItemProps {
    name: string;
    disease: string;
    url: string;
    year: number;
    tags: string[];
}

/**
 * GuidelineItem component.
 * @component GuidelineItem
 * @description A component that represents a single guideline item in a modal.
 * @param {GuidelineItemProps} props - The component props.
 * @returns {JSX.Element} The rendered GuidelineItem component.
 */
function GuidelineItem({ name, disease, url, year, tags }: GuidelineItemProps) {
    return (
        <GuidelineItemContainer>
            <GuidelineItemSubTitle>{name}</GuidelineItemSubTitle>
            <GuidelineItemParagraph>
                {disease} ({year})
            </GuidelineItemParagraph>
            <GuidelineItemTitle
                target="_blank"
                rel="noopener noreferrer"
                href={url}
            >
                {url}
            </GuidelineItemTitle>
            <Tags tags={tags} />
        </GuidelineItemContainer>
    );
}

export default GuidelineItem;

const Tags = ({ tags }: { tags: string[] }) => (
    <div>
        {tags.map((tag) => (
            <span
                key={tag}
                style={{
                    padding: "2px 6px",
                    borderRadius: "4px",
                    backgroundColor: "#f3f4f6",
                    border: "1px solid #e5e7eb",
                    textTransform: "capitalize",
                    fontSize: "0.75rem",
                }}
            >
                {tag}
            </span>
        ))}
    </div>
);
