import styled from "@emotion/styled";

export const ToggleButtonContainerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem auto;
`;

export const ToggleButtonContainer = styled.div`
    width: fit-content;
    border-radius: 63px;
    display: flex;
    padding: 3px;
    align-items: center;
    border: 1px solid rgb(229, 231, 235);
`;

export const StyledToggleButton = styled.button<{ isMonthly: boolean }>`
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    padding: 5px 15px;
    transition: all 0.3s ease-in-out;
    border-radius: 2rem;
    background-color: ${({ isMonthly }) => (isMonthly ? "#163b5e" : "")};
    color: ${({ isMonthly }) => (isMonthly ? "white" : "rgb(97, 90, 90)")};
    font-weight: 500;
`;
