import { PartnersTranslations } from './types';

export const enTranslations: PartnersTranslations = {
    banner: {
        label: "Partnerships",
        title: "Building a new way of educating with Clinical Metrics",
        subtitle: "Join us to improve your medical student experience of managing complex conditions emergency rooms.",
        buttonText: "Partner with Clinical Metrics",
        demoText: "Interested in partnering? Book a demo through the link above.",
        imageAlt: "Medical education partnership illustration"
    },
    benefits: {
        title: "Benefits",
        description: "Our partners offer a range of tools, services and solutions to help teams reach their productivity potential.",
        cards: {
            productivity: {
                title: "Boost your productivity",
                description: "Get more done with less distraction by bringing the tools that your teams use daily into Slack."
            },
            efficiency: {
                title: "Enhance your efficiency",
                description: "Leverage our partners capabilities to adopt better ways of collaborating in and out of your organization."
            },
            technology: {
                title: "Extend your tech investment",
                description: "Connect Slack oh-so-easily to your existing IT architecture with our open platform."
            }
        }
    },
    partnerships: {
        title: "Partnerships with Clinical Metrics",
        description: "Clinical Metrics partners are industry-leading organizations that collaborate with us to provide innovative solutions and services for medical education. Together, we aim to enhance the experience of managing complex conditions in emergency rooms and improve the productivity of medical teams.",
        logoAlt: (name) => `${name} logo`,
        partners: {
            bonn: {
                description: "Leading research institution with a focus on innovation and excellence in education."
            },
            goettingen: {
                description: "Renowned research university known for its strong emphasis on interdisciplinary collaboration and cutting-edge research in various fields."
            },
            dfn: {
                description: "DFN is the National Research and Education Network in Germany, providing high-performance networking services for the research and education community."
            }
        }
    },
    currentUsers: {
        title: "Current Users",
        description: "We are proud to have a diverse range of partners who are using our platform to improve medical education and patient care. From prestigious medical institutions to innovative healthcare organizations, our partners are making a difference in the field of medicine. Join us and become a part of our growing community of medical professionals dedicated to advancing healthcare education.",
        logoAlt: (name) => `${name} logo`,
        users: {
            bonn: {
                description: "Leading research institution with a focus on innovation and excellence in education."
            },
            goettingen: {
                description: "Renowned research university known for its strong emphasis on interdisciplinary collaboration and cutting-edge research in various fields."
            },
            berlin: {
                description: "The University of Berlin is a prestigious institution known for its rich history and academic excellence. With a diverse range of programs and a commitment to cutting-edge research, the university offers students a vibrant and intellectually stimulating environment."
            }
        }
    }
};