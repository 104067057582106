// External Imports
import React, { useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";

// Internal Imports
import { RightSideFormContainer, InputFieldArea, OrderBloodSubmitButton, TagContainer, Tag } from "./RightSideForm.styles";

// Translations
import { useLanguage } from "../../../../../core/translations/LanguageContext";
import { RightSideBloodOrderTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";
import { BloodTestTranslations } from "../../../../../core/translations/bloods/types";
import { enBloodTestTranslations} from "../../../../../core/translations/bloods/en";
import { deBloodTestTranslations} from "../../../../../core/translations/bloods/de";


// Define translations
const TRANSLATIONS: Record<SupportedLanguage, RightSideBloodOrderTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

// Define blood test translations
const BLOOD_TEST_TRANSLATIONS: Record<SupportedLanguage, BloodTestTranslations> = {
    en: enBloodTestTranslations,
    de: deBloodTestTranslations,
};


/**
 * RightSideForm Interface
 */
export interface RightSideFormProps {
    selectedItems: Array<string>;
    handleItemRemoval: (id: string) => void;
    onSubmit: (bloods: Array<string>, reason: string) => Promise<boolean>;
}

/**
 * RightSideForm Component
 */
export const RightSideForm: React.FC<RightSideFormProps> = ({
                                                                selectedItems,
                                                                handleItemRemoval,
                                                                onSubmit,
                                                            }) => {
    const [reason, setReason] = useState<string>("");

    // translations
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const bloodTestTranslations = BLOOD_TEST_TRANSLATIONS[language];

    // Submits the form
    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedItems.length > 0 && reason.trim().length >= 5) {
            const success = await onSubmit(selectedItems, reason);
            if (success) {
                setReason(""); // Clear form if successful
            }
        }
    };

    // Get translated test name
    const getTranslatedTestName = (testKey: string) => {
        return bloodTestTranslations.types[testKey] || testKey;
    };

    return (
        <RightSideFormContainer onSubmit={handleFormSubmit}>
            <InputFieldArea>
                <label>{translations.selectedItems}</label>
                <TagContainer>
                    {selectedItems.map((singleValue, index) => (
                        <Tag key={index}>
                            {getTranslatedTestName(singleValue)}
                            <span onClick={() => handleItemRemoval(singleValue)}>
                                <FaRegWindowClose size={16} />
                            </span>
                        </Tag>
                    ))}
                </TagContainer>
            </InputFieldArea>
            <InputFieldArea>
                <label>{translations.reason}</label>
                <textarea
                    rows={6}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
            </InputFieldArea>
            <InputFieldArea>
                <OrderBloodSubmitButton type="submit">
                    {translations.submitOrder}
                </OrderBloodSubmitButton>
            </InputFieldArea>
        </RightSideFormContainer>
    );
};
