import { ExamTypesTranslations } from "./types";

export const deExamTranslations: ExamTypesTranslations = {
    RESPIRATORY: "Lunge",
    ABDOMEN: "Abdomen",
    CARDIOVASCULAR: "Herz & Gefäße",
    GENERAL: "Allgemeines Erscheinungsbild",
    HEAD_AND_NECK: "Kopf und Hals",
    LYMPH_NODE: "Lymphatisches System",
    NEUROLOGY: "Nervensystem",
    GENITAL: "Urogenitalsystem",
    ARM: "Arm",
    LEGS: "Beine",
    SPINE: "Wirbelsäule",
};
