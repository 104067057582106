

/*
The different types of scores that can be held by a class.

NeverEvent: The weighting of the total score that is based on avoiding never events.
Diagnosis: The weighting of the total score that is based on getting the right diagnosis.
Protocol: The weighting of the total score that is based on getting the right protocol order.
 */
export enum ScoreType {
    NeverEvent = "NeverEvent",
    Diagnosis = "Diagnosis",
    Protocol = "Protocol",
}


/*
A component of a class that holds a score for a specific score type.

score: The score value.
scoreType: The type of score.
locked: Whether the score is locked or not.
checked: Whether the score has been checked or not.
 */
export class ClassScore {
    score: number;
    scoreType: ScoreType;
    locked: boolean;
    checked: boolean;

    /*
    The constructor for the class score.

    score: The score value.
    scoreType: The type of score.
    locked: Whether the score is locked or not.
    checked: Whether the score has been checked or not.
     */
    constructor(score: number, scoreType: ScoreType, locked: boolean, checked: boolean = false) {
        this.score = score;
        this.scoreType = scoreType;
        this.locked = locked;
        this.checked = checked;
    }

    checkLock() {
        this.locked = !this.locked;
    }

}
