// External imports
import React, { useState } from "react";

// Internal imports
import {
    PageContainer,
    ProfileCard,
    Heading,
    Paragraph,
    Span,
    ButtonContainer,
    Button,
    Logo,
    ProfileTemplateGoBackButton,
} from "./ProfilePage.styles";
import { BiSolidLeftArrowCircle } from "react-icons/bi";
import DFN from "../../../assets/DFN.svg";
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import { getUserRole } from "../../../core/LocalStorage/AuthSession/core";
import { useNavigate } from "react-router-dom";

const ProfilePage: React.FC = () => {
    // Get logged-in user role data
    const role = getUserRole();

    //Constant to show data of user
    const data = {
        email: "User1@example.com",
        institution: "Test",
        role: "Teacher",
        createdAt: "2024-11-18",
        gamesPlayed: 9,
        patientSeen: 6,
        currentLicense: "ABCLicense",
        licenseExpire: "2026-10-10",
    };
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    return (
        <MainPageLayout>
            <ProfileTemplateGoBackButton onClick={() => goBack()}>
                <BiSolidLeftArrowCircle />
                <span>Go Back</span>
            </ProfileTemplateGoBackButton>
            <PageContainer>
                <ProfileCard>
                    <Heading>Profile</Heading>
                    {data.email.includes("@") ? (
                        <Paragraph>
                            Email:
                            <Span>{data.email}</Span>
                        </Paragraph>
                    ) : (
                        <Paragraph>
                            User:
                            <Span>DFN {data.email}</Span>
                        </Paragraph>
                    )}
                    <Paragraph>
                        Institution: <Span> {data.institution}</Span>
                    </Paragraph>
                    <Paragraph>
                        Role: <Span>{data.role}</Span>
                    </Paragraph>
                    <Paragraph>
                        Date created: <Span>{data.createdAt}</Span>
                    </Paragraph>
                    <Paragraph>
                        Games played: <Span>{data.gamesPlayed}</Span>
                    </Paragraph>
                    <Paragraph>
                        Patients seen: <Span>{data.patientSeen}</Span>
                    </Paragraph>
                    <Paragraph>
                        Current license: <Span>{data.currentLicense}</Span>
                    </Paragraph>
                    <Paragraph>
                        License expiry Date: <Span>{data.licenseExpire}</Span>
                    </Paragraph>
                    <ButtonContainer>
                        <Button href="#">Change Password</Button>
                        {role === "student" && (
                            <Button href="#">See Badges</Button>
                        )}
                        {(role === "student" || role === "teacher") && (
                            <Button href="#">See Classes</Button>
                        )}
                    </ButtonContainer>
                </ProfileCard>
            </PageContainer>
        </MainPageLayout>
    );
};

export default ProfilePage;
