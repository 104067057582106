import styled from "@emotion/styled";

export const ReviewPageLeftSection = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 10px;
`;

export const ClassHeaderSection = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
`;

export const ClassName = styled.h2`
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: #333;
`;

export const AuthCode = styled.span`
    background: #0084c7;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
`;