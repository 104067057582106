// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { ObservationReadings } from "../../../LocalStorage/patients/interface";


/*
The payload for getting observations.

@patient_id: The id of the patient.
 */
export interface GetAllObservationsPayload {
    patient_id: number;
}


/*
The interface for the response from the get all observations endpoint.

@status: The HTTP status code.
@error: The error message.
@data: The object containing all the observations.
 */
export interface GetAllObservationsResponse {
    status: number;
    error?: string;
    data?: ObservationReadings;
};


/* Function Signature */
export type GetAllObservationsType = (jwt: string, getAllObservationsPayload: GetAllObservationsPayload) => Promise<GetAllObservationsResponse>;


/*
The function to get all observations for a patient.

@jwt: The JWT of the user requesting the observations.
@getAllObservationsPayload: The payload containing the patient ID.

@returns: The ApiResponse from the get all observations endpoint. This contains
all the observations for the patient. They are indexed by turn number and observation name.
 */
export async function getAllObservations(jwt: string, getAllObservationsPayload: GetAllObservationsPayload): Promise<GetAllObservationsResponse> {
    const url = new PatientUrl().getAllObservations;

    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<ObservationReadings | string>(url, {
            patient_id: getAllObservationsPayload.patient_id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });
        console.log(response.data);
    } catch (error) {
        return { status: 500, error: "Network or other error occurred" };
    }
    if (response.status === 201) {
        return { status: 201, data: response.data };
    } else {
        return { status: response.status, error: response.data };
    }
}