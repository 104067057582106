import { ExamTypesTranslations } from "./types";

export const enExamTranslations: ExamTypesTranslations = {
    RESPIRATORY: "Respiratory",
    ABDOMEN: "Abdominal",
    CARDIOVASCULAR: "Cardiology",
    GENERAL: "General",
    HEAD_AND_NECK: "Head and Neck",
    LYMPH_NODE: "Lymph Node",
    NEUROLOGY: "Neurology",
    GENITAL: "Genital",
    ARM: "Arm",
    LEGS: "Legs",
    SPINE: "Spine",
};
