import styled from "@emotion/styled";

export const ShowInvestigationReportModalContainer = styled.div`
    width: 100%;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    & > svg {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        cursor: pointer;
        font-size: 20px;
        transform: translateY(0);
        transition: all 0.3s ease-in-out;

        &:hover {
            transform: translateY(-2px);
        }
    }
`;

export const ShowInvestigationReportModalContentContainer = styled.div`
    overflow-y: auto;
    margin-top: 1.5rem;
    max-height: 35em;
    position: relative;
    scroll-behavior: smooth;  /* Smooth scrolling behavior */

    /* Ensuring content always starts at the top */
    &::-webkit-scrollbar {
        display: none; /* Optionally hide the scrollbar */
    }

    &::before {
        content: '';
        display: block;
        height: 0;  /* This ensures the scroll always starts from the top */
    }
`;

export const ShowInvestigationReportModalForm = styled.form`
    width: 100%;
`;

export const ShowInvestigationReportModalFormTitle = styled.div`
    width: fit-content;
    height: fit-content;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #111111;
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
        margin: 0px;
    }

    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
    }
`;

export const ShowInvestigationReportModalContentWrapper = styled.div<{
    margin?: boolean;
}>`
    width: 100%;
    height: fit-content;
    margin: ${({ margin = true }) => (margin ? "1.5rem 0px" : "0px")};
`;

export const ShowInvestigationReportModalContentLabel = styled.h3`
    margin: 0.5rem 0px;
    color: #374151;
    font-weight: 500;
    font-size: 14px;
`;

export const ShowInvestigationImageReportModalContentImage = styled.img`
    width: 100%;
    height: 80%;
    aspect-ratio: 4/3;
    object-fit: fill;
`;

export const ShowInvestigationImageReportModalContentDescription = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: #374151;
`;

export const ShowInvestigationImageReportModalFormFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
