//! This API calls makes sure that the backend and frontend are in sync
//! each other. Whatever the latest turn is, both are updated with the same
//! data. This is done by comparing the turns of the backend and frontend, and
//! if the backend is ahead, the frontend is updated with the latest data. And
//! if the frontend is ahead, the backend is updated with the latest data.

import { ClassesUrl } from "./AUrl";
import { ApiResponse } from "../interfaces";
import axios, { AxiosResponse } from "axios";

/*
The payload for checking turns

@session_id: The id of the session to check
@turn_number: The current turn number from the frontend
*/
export interface CompareTurnPayload {
    session_id: number;
    turn_number: number;
}

/*
The response containing the correct turn number
*/
export interface CompareTurnResponse {
    turn_number: number;
}

export type CompareTurnType = (
    jwt: String,
    payload: CompareTurnPayload
) => Promise<ApiResponse<CompareTurnResponse>>;

/*
API call to check and synchronize turn numbers between frontend and backend

@jwt: The authentication token
@payload: The request payload containing session_id and current turn number
Returns a promise containing the API response with the correct turn number
*/
export async function compareTurns(
    jwt: String,
    payload: CompareTurnPayload
): Promise<ApiResponse<CompareTurnResponse>> {
    const url = new ClassesUrl().compareTurns;

    try {
        const response = await axios.post<CompareTurnResponse | string>(
            url,
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                    //@ts-ignore
                    token: jwt,
                },
                // Resolve the promise for all HTTP status codes
                validateStatus: () => true,
            }
        );

        if (response.status === 200) {
            return {
                status: response.status,
                data: response.data as CompareTurnPayload,
            };
        } else if (response.status === 404) {
            return {
                status: response.status,
                error: "Session not found",
            };
        } else {
            return {
                status: response.status,
                error: response.data as string,
            };
        }
    } catch (error) {
        return {
            status: 500,
            error: error as string,
        };
    }
}
