export enum IPatientLocation {
    WaitingRoom = "WaitingRoom",
    AMBULANCE = "AMBULANCE",

    Majors = "Majors",
    RESUS_ROOM = "Resus",
    Home = "Home",

    ITU_DISCHARGE = "ITU_DISCHARGE",
    INTERMEDIATE_CARE_DISCHARGE = "INTERMEDIATE_CARE_DISCHARGE",
    WARD_DISCHARGE = "WARD_DISCHARGE",
    HOME_DISCHARGE = "HOME_DISCHARGE",

    HOSPITAL_ADMISSION = "HOSPITAL_ADMISSION",
}
