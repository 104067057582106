
// External imports
import React, { Fragment } from "react";
import { FaCheck as CheckIcon } from "react-icons/fa";

// Internal imports
import de from "../../../assets/flag/de.png";
import en from "../../../assets/flag/en.png";
import {
    StyledListbox,
    StyledButton,
    LanguageSpan,
    FlagContainer,
    FlagImage,
    StyledOptions,
    StyledOption,
    OptionContent,
    CheckIconContainer,
} from "./LanguageMenu.styles";

/**
 * Type for mapping language codes to flag image paths
 */
type Flags = {
    [lang: string]: string;
};

/**
 * Props for the LanguageMenu component
 * @property value - Current selected language code
 * @property onChange - Callback function when language selection changes
 * @property appearance - Optional appearance variant, currently supports "game" style
 */
interface LanguageMenuProps {
    value: "en" | "de";
    onChange: (value: "en" | "de") => void;
    appearance?: "game";
}


/**
* Language selection dropdown component
*
* Displays a dropdown menu with language options and their corresponding flags.
* Supports English and German languages with flag icons and selection indicators.
*/
export const LanguageMenu: React.FC<LanguageMenuProps> = ({ value, onChange, appearance }) => {
    const flags: Flags = {
        en,
        de,
    };
    const languages = ["en", "de"];
    const [menuOpen, setMenuOpen] = React.useState(false);

    /**
     * Handles language selection and calls the onChange callback
     * @param code - Selected language code
     */
    const changeLanguage = (code: "en" | "de") => {
        onChange(code);
    };

    return (
        <StyledListbox>
            <StyledButton
                onClick={() => setMenuOpen(!menuOpen)}
                appearance={appearance}
            >
                <LanguageSpan appearance={appearance}>{value}</LanguageSpan>
                <FlagContainer>
                    <FlagImage src={flags[value]} alt={`${value} flag`} />
                </FlagContainer>
            </StyledButton>

            {menuOpen && (
                <StyledOptions>
                    {languages.map((lang, key) => {
                        const isSelected = lang === value;
                        return (
                            <StyledOption
                                key={key}
                                active={isSelected}
                                onClick={() =>
                                    changeLanguage(lang as "en" | "de")
                                }
                            >
                                <Fragment>
                                    <OptionContent selected={isSelected}>
                                        {lang}

                                        <FlagContainer>
                                            <FlagImage
                                                src={flags[lang]}
                                                alt={`${lang} flag`}
                                            />
                                        </FlagContainer>
                                    </OptionContent>

                                    {isSelected && (
                                        <CheckIconContainer>
                                            <CheckIcon aria-hidden="true" />
                                        </CheckIconContainer>
                                    )}
                                </Fragment>
                            </StyledOption>
                        );
                    })}
                </StyledOptions>
            )}
        </StyledListbox>
    );
}
