// Internal imports
import { getSessionData, setSessionData } from "../gameSession/core";
import { getJwt } from "../AuthSession/core";
import { GameTime } from "./interface";
import {
    checkNotificationsDue,
    addNotificationToStorage,
} from "../Notifications/core";
import {
    getNewPatient,
    GetNewPatientPayload,
} from "../../ServerApiLayer/VOne/sessions/newPatient";
import { setFromApiData } from "../../LocalStorage/patients/core";

// Constants
const START_HOUR = 9; // Game starts at 9am

// Signature for the getTurnCount function
export type GetTurnCountType = () => number;

/*
Get turn count from sessionData
 */
export function getTurnCount(): number {
    const sessionData = getSessionData();
    return sessionData ? sessionData.turn : 0;
}

// Signature for the updateTurnCount function
export type UpdateTurnCountType = (turnCount: number) => void;

/*
Update the turn count in sessionData by X amount

@turnCount: The amount to update the turn count by
 */
export async function updateTurnCount(turnCount: number): Promise<void> {
    const sessionData = getSessionData();
    if (!sessionData) return;

    // Update the turn count
    sessionData.turn += turnCount;

    // Save turn update first to prevent duplicate processing
    setSessionData(sessionData);

    // Check if a new patient is due
    if (sessionData.turn >= sessionData.nextPatientInterval) {
        console.log("New patient due");

        // Get JWT for API call
        const jwt = getJwt();
        if (!jwt) return;

        // Get language if is "en" then language is "ENGLISH"
        // if "de" then language is "GERMAN"
        const language = localStorage.getItem("language");
        let languageString = "ENGLISH";
        if (language === "de") {
            languageString = "GERMAN";
        }

        try {
            // Prepare payload for new patient request
            const payload: GetNewPatientPayload = {
                session_id: sessionData.sessionID,
                patients: 1,
                language: languageString,
                turn: sessionData.turn,
            };

            // Make API call to get new patient
            const response = await getNewPatient(jwt, payload);
            console.log("New patient response:", response);

            if (response.status === 201 && response.data) {
                // Store the new patient data
                setFromApiData(sessionData.turn, {
                    session_id: sessionData.sessionID,
                    patients: response.data.patients,
                    patientIntervals: sessionData.patientIntervals,
                });

                // Add notification for new patient
                addNotificationToStorage(sessionData.turn, {
                    id: `new-patient-${Date.now()}`,
                    sender: "frontDesk",
                    message: "newPatientArrival",
                });

                // Update the next patient interval AFTER successful patient creation
                const updatedSessionData = getSessionData();
                if (updatedSessionData) {
                    updatedSessionData.nextPatientInterval +=
                        updatedSessionData.patientIntervals;
                    setSessionData(updatedSessionData);
                }
            } else {
                console.error("Failed to get new patient:", response.error);
            }
        } catch (error) {
            console.error("Error during new patient creation:", error);
        }
    }
    // Check notifications
    const newNotifications = checkNotificationsDue(sessionData.turn);
}

/**
 * Converts the number of turns to a time object.
 *
 * @param turns
 */
export function turnsToTime(turns: number): GameTime {
    const totalMinutes = turns;
    const gameHours = Math.floor((START_HOUR * 60 + totalMinutes) / 60);
    const gameMinutes = (START_HOUR * 60 + totalMinutes) % 60;

    return {
        hours: gameHours,
        minutes: gameMinutes,
    };
}
