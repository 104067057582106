import { startCase } from "lodash";
import { TbGenderFemale, TbGenderMale } from "react-icons/tb";
import { GenderIconContainer } from "./GenderIcon.styles";

/**
 * GenderIconProps
 *
 * @interface GenderIconProps
 * @description Props for the GenderIcon component.
 * @property {boolean} labelled - Determines whether to display the label for the gender.
 * @property {boolean} noIcon - Determines whether to display the gender icon.
 * @property {string} gender - The gender value (e.g., "male" or "female").
 */
interface GenderIconProps {
    labelled?: boolean;
    noIcon?: boolean;
    gender?: string;
}

/**
 * GenderIcon
 *
 * @param {GenderIconProps} props - The props for the GenderIcon component.
 * @description A component that displays a gender icon based on the provided gender value.
 * @returns {JSX.Element} The rendered GenderIcon component.
 */
export default function GenderIcon({ ...props }: GenderIconProps) {
    const { noIcon, gender, labelled, ...rest } = props;

    if (!gender || typeof gender !== "string") return null;

    const isMale = !/female/i.test(gender);
    const Component = isMale ? TbGenderMale : TbGenderFemale;
    const label = startCase(gender.toLowerCase() ?? "");

    return (
        <GenderIconContainer>
            {labelled ? label : null}
            {noIcon !== true ? (
                <Component
                    {...rest}
                    color={isMale ? "#4757ce" : "#e86d6b"}
                    size="1.4em"
                />
            ) : null}
        </GenderIconContainer>
    );
}
