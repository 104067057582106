import { LocationTypesTranslations } from "./types";

export const deLocationTranslations: LocationTypesTranslations = {
    WAITING_ROOM: "Wartezimmer",
    HOME_DISCHARGE: "Nach Hause entlassen",
    WARD_DISCHARGE: "Stationäre Entlassung",
    ICU_DISCHARGE: "Intensivstation Entlassung",
    HDU_DISCHARGE: "Hochabhängigkeitsstation Entlassung",
    Majors: "Majors",
    Resus: "Resus",
    WaitingRoom: "Wartezimmer",
};
