import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import MainPageLayout from "../../../../components/core/header/MainPageLayout";
import featureImg from "./scrubs.jpg";
import { FaUserCircle } from "react-icons/fa";
import { LuCalendarPlus } from "react-icons/lu";
import { IoMdPricetags } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";

import {
    BlogDetailContentMenuHighlightedText,
    BlogDetailPageContainer,
    BlogDetailContentMainSection,
    BlogDetailContentMenu,
    BlogDetailContentMenuAnchor,
    BlogDetailDescription,
    BlogDetailDivSection,
    BlogDetailDescriptionParagraph,
    BlogDetailDescriptionImage,
    BlogDetailContentMenuText,
    BlogDetailPageBreadCrumb,
    BlogDetailPageHeading,
    BlogDetailBreadCrumbsBtn,
    BlogDetailsHeading,
    BlogDetailContentMenuBox,
    BlogDetailMenuDetail,
    BlogDetailMenuIcon,
} from "./Blog.styles";
import { IconContext } from "react-icons";
import { loadBlog } from "./blogLoader";
// Language Context
import { useLanguage } from "../../../../core/translations/LanguageContext";

// Types
import { Blog as BlogType } from "./types";

// Translations
import { BlogPageTranslations } from '../translations/types';
import { enTranslations } from '../translations/en';
import { deTranslations } from '../translations/de';
import { SupportedLanguage } from '../../../../core/translations/supportedLanguages';

const TRANSLATIONS: Record<SupportedLanguage, BlogPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};
/* The blog detail page of the website. */

function Blog() {
    const { language } = useLanguage();
    const { slug } = useParams();
    const [searchParams] = useSearchParams();
    const translations = TRANSLATIONS[language];

    const [blog, setBlog] = useState<BlogType | null>(null);
    const [loading, setLoading] = useState(true);

    function formatDate(isoDateString: string | null) {
        if(isoDateString){
            const date = new Date(isoDateString);
            return date.toLocaleDateString('en-US'); // You can change 'en-US' to other locales as needed
        }else{
            return "-"
        }
        
    }
    useEffect(() => {
        async function loadBlogContent() {
            if (!slug) return;

            try {
                const loadedBlog = await loadBlog(slug, language);
                setBlog(loadedBlog);
            } catch (error) {
                console.error("Failed to load blog:", error);
                setBlog(null);
            } finally {
                setLoading(false);
            }
        }

        loadBlogContent();
    }, [slug, language]);

    if (loading) {
        return (
            <MainPageLayout>
                <BlogDetailPageContainer>
                    <p>Loading...</p>
                </BlogDetailPageContainer>
            </MainPageLayout>
        );
    }

    if (!blog) {
        return (
            <MainPageLayout>
                <BlogDetailPageContainer>
                    <p>
                        {translations.main.notFoundText}
                    </p>
                </BlogDetailPageContainer>
            </MainPageLayout>
        );
    }
    return (
        <MainPageLayout>
            <BlogDetailPageContainer>
                <BlogDetailPageBreadCrumb>
                    <BlogDetailPageHeading>
                        <BlogDetailsHeading>{translations.main.titleForSingleEntiry}</BlogDetailsHeading>
                        <BlogDetailDescriptionParagraph>
                            <BlogDetailContentMenuAnchor href="#">
                                {translations.main.titleForSingleEntiry}
                            </BlogDetailContentMenuAnchor>{" "}
                            <IconContext.Provider
                                value={{ color: "#182b3d", size: "25px" }}
                            >
                                <MdKeyboardArrowRight />
                            </IconContext.Provider>
                            <BlogDetailContentMenuAnchor href="#">
                                {blog.title}
                            </BlogDetailContentMenuAnchor>
                        </BlogDetailDescriptionParagraph>
                    </BlogDetailPageHeading>
                    <BlogDetailBreadCrumbsBtn>
                        <BlogDetailContentMenuAnchor href="/blog">
                            {translations.main.allPosts}
                            <IconContext.Provider
                                value={{ color: "#ffffff", size: "25px" }}
                            >
                                <BsArrowRight />
                            </IconContext.Provider>
                        </BlogDetailContentMenuAnchor>
                    </BlogDetailBreadCrumbsBtn>
                </BlogDetailPageBreadCrumb>
                <BlogDetailContentMainSection>
                    <BlogDetailContentMenu>
                        <BlogDetailContentMenuBox>
                            <BlogDetailMenuIcon>
                                <FaUserCircle size={30} />
                            </BlogDetailMenuIcon>
                            <BlogDetailMenuDetail>
                                <BlogDetailContentMenuText>
                                    Authors:
                                </BlogDetailContentMenuText>
                                <BlogDetailContentMenuHighlightedText>
                                    {blog.meta?.author}
                                </BlogDetailContentMenuHighlightedText>
                            </BlogDetailMenuDetail>
                        </BlogDetailContentMenuBox>
                        <BlogDetailContentMenuBox>
                            <BlogDetailMenuIcon>
                                <LuCalendarPlus size={25} />
                            </BlogDetailMenuIcon>
                            <BlogDetailMenuDetail>
                                <BlogDetailContentMenuText>
                                    Posted:
                                </BlogDetailContentMenuText>
                                <BlogDetailContentMenuHighlightedText>
                                    {formatDate(blog.meta?.publishDate ?? null)}
                                </BlogDetailContentMenuHighlightedText>
                            </BlogDetailMenuDetail>
                        </BlogDetailContentMenuBox>
                        <BlogDetailContentMenuBox>
                            <BlogDetailMenuIcon>
                                <IoMdPricetags size={30} />
                            </BlogDetailMenuIcon>
                            <BlogDetailMenuDetail>
                                <BlogDetailContentMenuText>
                                    Categories:
                                </BlogDetailContentMenuText>
                                <BlogDetailContentMenuHighlightedText>
                                    {blog.meta?.category}
                                </BlogDetailContentMenuHighlightedText>
                            </BlogDetailMenuDetail>
                        </BlogDetailContentMenuBox>
                    </BlogDetailContentMenu>
                    {blog.sections.map((section) => (
                        <BlogDetailDescription key={section.id}>
                            <BlogDetailDescriptionImage src={section.image} alt={section.title}/>
                            <BlogDetailDivSection>
                                {section.paragraphs.map((paragraph, i) => (
                                    <BlogDetailDescriptionParagraph key={i}>
                                        {paragraph}
                                    </BlogDetailDescriptionParagraph>
                                ))}

                                <BlogDetailDescriptionParagraph>
                                    {section.helpText}
                                    <BlogDetailContentMenuAnchor href={section.hereLink}>
                                        {" "}
                                        {section.hereText}{" "}
                                    </BlogDetailContentMenuAnchor>
                                </BlogDetailDescriptionParagraph>
                                <BlogDetailDescriptionParagraph>
                                    {section.queryText}
                                    <BlogDetailContentMenuAnchor href={`mailto:${section.queryEmail}`}>
                                        {" "}
                                        {section.queryEmail}
                                    </BlogDetailContentMenuAnchor>
                                </BlogDetailDescriptionParagraph>
                            </BlogDetailDivSection>
                        </BlogDetailDescription>
                    ))}
                </BlogDetailContentMainSection>
            </BlogDetailPageContainer>
        </MainPageLayout>
    );
}

export default Blog;
