// External Imports
import {
    FaAmbulance,
    FaBed,
    FaHeartbeat,
    FaHospital,
    FaProcedures,
    FaRegHospital,
    FaHome,
} from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { MdDirectionsWalk } from "react-icons/md";
import { IconType } from "react-icons";

// Internal Imports
import { IPatientLocation } from "./types/IPatientLocation";
import Tooltip from "../tooltip/Tooltip";
import { LocationIconContainer } from "./LocationIcon.styles";

/**
 * LocationIconProps Interface
 */
export interface LocationIconProps {
    location: IPatientLocation;
}

/**
 * LocationIcon Component
 *
 * This is the LocationIcon component. This component is used to display an icon
 * that represents the location of a patient. For example, an ambulance icon
 * represents a patient in an ambulance.
 *
 * @param location - The location of the patient
 * @returns A LocationIcon component
 */
export const LocationIcon: React.FC<LocationIconProps> = ({ location }) => {
    const getIcon = () => {
        const loc = location.replace(/_[0-9]$/, "") as IPatientLocation;

        const locationCollection: {
            [key: string]: { icon: IconType; dataIcon: string };
        } = {
            [IPatientLocation.AMBULANCE]: {
                icon: FaAmbulance,
                dataIcon: "ambulance",
            },
            [IPatientLocation.WaitingRoom]: {
                icon: MdDirectionsWalk,
                dataIcon: "waiting-room",
            },
            [IPatientLocation.HOSPITAL_ADMISSION]: {
                icon: FaRegHospital,
                dataIcon: "hospital-admission",
            },
            [IPatientLocation.Majors]: {
                icon: FaProcedures,
                dataIcon: "majors",
            },
            [IPatientLocation.RESUS_ROOM]: {
                icon: FaHeartbeat,
                dataIcon: "resus-room",
            },
            [IPatientLocation.HOME_DISCHARGE]: {
                icon: FaHome,
                dataIcon: "home-discharge",
            },
            [IPatientLocation.Home]: {
                icon: FaHome,
                dataIcon: "home-discharge",
            },
            [IPatientLocation.WARD_DISCHARGE]: {
                icon: FaHospital,
                dataIcon: "ward-discharge",
            },
            [IPatientLocation.INTERMEDIATE_CARE_DISCHARGE]: {
                icon: FaBed,
                dataIcon: "intermediate-care",
            },
            [IPatientLocation.ITU_DISCHARGE]: {
                icon: FaUserDoctor,
                dataIcon: "itu-discharge",
            },
        };

        if (locationCollection[loc]) return locationCollection[loc];

        return { icon: MdDirectionsWalk, dataIcon: "waiting-room" };
    };

    const { icon: Icon, dataIcon } = getIcon();

    return (
        <span style={{ display: "inline-flex" }} data-icon={dataIcon}>
            <Tooltip tooltip={location}>
                <LocationIconContainer>
                    <Icon />
                </LocationIconContainer>
            </Tooltip>
        </span>
    );
};
