// External imports
import React, { useEffect, useState } from "react";

// Internal imports
import {
    AuthButtonsContainer,
    FlexGrow,
    LinksContainer,
    NavBar,
    NavigationLink,
    Anchor,
} from "./Header.styles";
// @ts-ignore
import logoImage from "../../../assets/clinical_metrics.png";
import WebsiteButton from "../button/WebsiteButton";
import { LanguageMenu } from "../language-menu/LanguageMenu";
import { ProfileDropdownProps } from "../profile-dropdown/ProfileDropdown.props";
import { ProfileDropdown } from "../profile-dropdown/ProfileDropdown";
import { getJwt } from "../../../core/LocalStorage/AuthSession/core";

// Dependency Injection
import { getUserRole } from "../../../core/LocalStorage/AuthSession/core";

// Language Context
import { useLanguage } from "../../../core/translations/LanguageContext";

// Translations
import { HeaderTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../modal/logout-modal/LogoutModal";

// Translations for the header
const TRANSLATIONS: Record<SupportedLanguage, HeaderTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/** Interface for the header component
 * @param loggedIn - boolean indicating if the user is logged in
 */
interface HeaderProps {
    loggedIn: boolean;
}

/** Interface for the header auth buttons component
 * @param translations - translations for the header
 */
interface HeaderAuthButtonsProps {
    translations: HeaderTranslations["header"];
}

/** Interface for the header links component
 * @param translations - translations for the header
 */
interface HeaderLinksProps {
    translations: HeaderTranslations["header"];
}

/** Header auth buttons component for the Main Pages */
const HeaderAuthButtons: React.FC<HeaderAuthButtonsProps> = ({
    translations,
}) => {
    const isAuthenticated = getJwt();

    if (isAuthenticated) {
        return null;
    }

    return (
        <AuthButtonsContainer>
            <WebsiteButton tag="a" href="https://calendar.google.com" secondary>
                {translations.bookDemo}
            </WebsiteButton>
            <WebsiteButton tag="a" href="/login">
                {translations.login}
            </WebsiteButton>
        </AuthButtonsContainer>
    );
};

/** Header links component for the Main Pages */
const HeaderLinks: React.FC<HeaderLinksProps> = ({ translations }) => {
    const current = window.location.pathname;
    const navigation = [
        { name: translations.home, href: "/" },
        { name: translations.about, href: "/about" },
        { name: translations.contact, href: "/contact" },
    ].map((item) => ({
        current: item.href === current,
        ...item,
    }));
    return (
        <LinksContainer>
            {navigation.map((item) => (
                <NavigationLink
                    key={item.href}
                    href={item.href}
                    current={item.current}
                    aria-current={item.current ? "page" : undefined}
                >
                    {item.name}
                </NavigationLink>
            ))}
        </LinksContainer>
    );
};

/**
 * Header component for the Main Pages
 * Shows language selector only when user is not logged in
 */
const Header: React.FC<HeaderProps> = () => {
    const { language, setLanguage } = useLanguage();
    const [translations, setTranslations] = useState<
        HeaderTranslations["header"] | null
    >(null);
    const [profileDropdownOptions, setProfileDropdownOptions] = useState(
        ProfileDropdownProps.default
    );
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    const navigate = useNavigate();

    const isAuthenticated = getJwt();

    /**
     * Get logged in user role
     */
    const role = getUserRole();

    useEffect(() => {
        if (role === "teacher" || role === null) {
            // Remove the 'Badges' option when the role is 'teacher'
            setProfileDropdownOptions((prevState) => ({
                ...prevState, // Retain other properties in the state
                navigation: prevState.navigation.filter(
                    (option) => option.name !== "Badges"
                ),
            }));
        } else {
            // If the role is not 'teacher', reset to the default options
            setProfileDropdownOptions(ProfileDropdownProps.default);
        }
        updateLogoutClickHandler();
    }, [role]); // Dependency on 'role'

    useEffect(() => {
        const currentTranslations = TRANSLATIONS[language]?.header;
        setTranslations(currentTranslations);
    }, [language]);

    const handleLanguageChange = (newLanguage: SupportedLanguage) => {
        setLanguage(newLanguage);
    };

    const onRequestClose = (): void => {
        setShowLogoutPopup(false);
    };
    
    const openLogoutPopup = (): void => {
        setShowLogoutPopup(true);
    }
    const onSubmitLogout = (): void => {
        const cookiesSeen = localStorage.getItem('cookiesSeen');

        localStorage.clear();
        localStorage.setItem('language', language);
        if(cookiesSeen !== null){
            localStorage.setItem('cookiesSeen', cookiesSeen);
        }
        setShowLogoutPopup(false);
        navigate("/");
    };

    function updateLogoutClickHandler() {
        // Create a copy of the navigation array with the updated "Logout" item
        const updatedNavigation = profileDropdownOptions.navigation.map(item => {
          if (item.name === "Logout") {
            return {
              ...item, // Copy existing properties
              onClick: openLogoutPopup, // Add the onClick handler
            };
          }
          return item; // Return other items unchanged
        });
      
        // Update state with the new navigation array
        setProfileDropdownOptions(prevState => ({
          ...prevState,
          navigation: updatedNavigation,
        }));
      }

    if (!translations) return null;

    return (
        <NavBar>
            <Anchor href="/">
                <img src={logoImage} alt="logo" style={{ width: "12em" }} />
            </Anchor>
            <HeaderLinks translations={translations} />
            <FlexGrow />
            {!isAuthenticated && (
                <LanguageMenu
                    value={language}
                    onChange={handleLanguageChange}
                />
            )}
            {isAuthenticated ? (
                <ProfileDropdown {...profileDropdownOptions} />
            ) : (
                <HeaderAuthButtons translations={translations} />
            )}
          {showLogoutPopup && <LogoutModal isGame={false} onRequestClose={onRequestClose} onSubmitLogout={onSubmitLogout}/>}
        </NavBar>
    );
};

export default Header;
