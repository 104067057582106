import React from 'react';
import { MessageTextLeft, InnerPreviewMessage, StyledFaUserCircle } from './LeftSideMessagePreview.styles'; // Import styled components

export interface ChatProps {
    message: string;
}

const LeftSideMessagePreview: React.FC<ChatProps> = ({ message }) => {
    return (
        <MessageTextLeft>
            <StyledFaUserCircle />
            <InnerPreviewMessage>
                {message}
            </InnerPreviewMessage>
        </MessageTextLeft>
    );
}

export default LeftSideMessagePreview;
