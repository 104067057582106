// External Imports
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// Internal Imports
import PrivacyPolicy from "../../../pages/main/modals/privacy-policy-modal/PrivacyPolicyModal";
import TermsAndConditions from "../../../pages/main/modals/terms-and-condition-modal/TermsModal";
import DisclaimerModal from "../../../pages/main/modals/disclaimer-modal/DisclaimerModal";
import Impressum from "../../../pages/main/modals/impressum-modal/ImpressumModal";
import CookiePolicy from "../../../pages/main/modals/cookie-policy-modal/CookiePolicyModal";

/**
 * Renders global modals based on query params, if present
 */
export const ModalRenderer: React.FC = () => {
    // Get the location and navigate functions from the router
    const navigate = useNavigate();
    const location = useLocation();
    const query = useLocation().search.replace(/^\?/, "");

    // The modals to render based on the query
    const modals = [
        { key: "privacy-policy", Component: PrivacyPolicy },
        { key: "terms-and-conditions", Component: TermsAndConditions },
        { key: "disclaimer", Component: DisclaimerModal },
        { key: "impressum", Component: Impressum },
        { key: "cookie-policy", Component: CookiePolicy },
    ];

    // State to keep track of which modals are open
    const [modalOpen, setModalOpen] = useState<{ [key: string]: boolean }>({});

    // When the query changes, update the modals that are open
    useEffect(() => {
        if (!localStorage.getItem("cookiesSeen") && query != "cookie-policy") {
            navigate("/?cookie-policy")
        } else {
            const newModalOpen: { [key: string]: boolean } = {};
            modals.forEach(({ key }) => {
                newModalOpen[key] = query === key;
            });
            setModalOpen(newModalOpen);
        }
    }, [query]);

    // Close the modal
    const closeModal = () => {
        navigate(
            {
                pathname: location.pathname,
                search: "",
            },
            { replace: true }
        );
    };

    // Render the modals
    if (modalOpen) {
        return modals.map(({ key, Component }) => {
            if (modalOpen[key]) {
                return <Component key={key} onRequestClose={closeModal} />;
            }
            return null;
        });
    }
    return null;
};
