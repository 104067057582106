import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

export const CentralButtonsPanel = styled.form`
  font-family: 'Helvetica', 'Arial', sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #333333; 
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; 

  & > div {
    width: 100%; // Ensure the div takes up full width available to it

    // Target all button elements directly inside this div
    button {
      width: 100%; // Make buttons fill the container width
      margin-bottom: 10px; // Add bottom margin to create gap between buttons
    }

    // Remove bottom margin from the last button to avoid extra space at the bottom
    button:last-child {
      margin-bottom: 0;
    }
  }
`;

const jiggle = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(-5px); }
  50% { transform: translateY(5px); }
  75% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
`;

export const JiggleAnimation = styled.div`
  animation: ${jiggle} 0.5s ease-in-out;
`;


export const NewGameInput = styled.div`
  display: flex;
  flex-direction: column; // Stack input and button vertically
  width: 100%;
  gap: 10px; // Space between input and button
  padding: 0 20px; // Add horizontal padding

  button {
    width: calc(100% - 40px); // Adjust button width to account for padding
    margin: 0 auto; // Center the button if needed
  }
`;

export const Input = styled.input`
  border: 1px solid #333333;
  border-radius: 4px;
  padding: 0.6em 1.3em;
  font-size: 14px;

  &:focus {
    outline: none;
  }
  margin: 0 auto; // Center the input if desired
`;


export const ErrorMessage = styled.p`
  color: red;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 5px;
`;

export const Paragraph = styled.p`
  text-align: center;
  margin-top: 0;
`;
