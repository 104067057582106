// External Imports
import React, { SyntheticEvent, useState } from "react";

// Internal Imports
import {
    InvestigationFormGridContainer,
    InvestigationFormContainer,
    InvestigationFormGridItemBy3,
    InvestigationFormGridItemBy6,
    InvestigationFormLabel,
    InvestigationFormFieldContainer,
} from "./OrderInvestigationForm.styles";
import SelectBox, {
    SelectOption,
} from "../../../../../components/core/select-box/SelectBox";
import TextArea from "../../../../../components/core/input-form/TextArea";
import ClearSubmitFormButtons from "../../../../../components/core/clear-submit-form-buttons/ClearSubmitFormButtons";
import { onSelectOptionChange } from "../../../../../components/core/select-box/functions/SelectBox.functions";

// Translation
import { useLanguage } from "../../../../../core/translations/LanguageContext";
import { enTranslations } from "./../../translations/en";
import { deTranslations } from "./../../translations/de";
import { InvestigationPageTranslations } from "./../../translations/types";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";

// Set the translations for the page
const TRANSLATIONS: Record<SupportedLanguage, InvestigationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

// OrderInvestigationFormProps
interface OrderInvestigationFormProps {
    onSubmit: (
        investigation: string,
        priority: string,
        justification: string
    ) => void;
    investigationTranslations: any;
    priorityTranslations: any;
}

/**
 * OrderInvestigationForm
 *
 * This component is the form for ordering an investigation.
 */
export const OrderInvestigationForm: React.FC<OrderInvestigationFormProps> = ({
    onSubmit,
    investigationTranslations,
    priorityTranslations,
}) => {
    const [investigation, setInvestigation] = useState<SelectOption>();
    const [priority, setPriority] = useState<SelectOption>();
    const [justification, setJustification] = useState<string>("");

    // Language translation
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    // Convert the investigation options to SelectOption objects.
    const investigationOptions = Object.entries(investigationTranslations)
        .map(([key, value]) => ({
            value: key,
            label: value,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const priorityOptions = Object.entries(priorityTranslations)
        .map(([key, value]) => ({
            label: value,
            value: key,
            turn: undefined,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    // Clear the form fields.
    const clearForm = () => {
        setInvestigation(investigationOptions[0]);
        setPriority(priorityOptions[0]);
        setJustification("");
    };

    // Handle form submit, and clear the form if successful.
    const handleFormSubmit = async () => {
        if (investigation && priority && justification.trim().length >= 10) {
            const success = await onSubmit(
                investigation.value,
                priority.value,
                justification
            );
            //@ts-ignore
            if (success) {
                clearForm();
            }
        }
    };

    return (
        <form onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
            <InvestigationFormContainer>
                <div>
                    <InvestigationFormGridContainer>
                        <InvestigationFormGridItemBy3>
                            <InvestigationFormLabel htmlFor="priority">
                                {translations.investigation}:
                            </InvestigationFormLabel>
                            <InvestigationFormFieldContainer>
                                <SelectBox
                                    value={[
                                        investigation
                                            ? investigation
                                            : investigationOptions[0],
                                    ]}
                                    options={investigationOptions}
                                    onChange={(value: SelectOption) =>
                                        onSelectOptionChange(
                                            value,
                                            investigationOptions,
                                            setInvestigation
                                        )
                                    }
                                />
                            </InvestigationFormFieldContainer>
                        </InvestigationFormGridItemBy3>
                        <InvestigationFormGridItemBy3>
                            <InvestigationFormLabel htmlFor="priority">
                                {translations.priority}:
                            </InvestigationFormLabel>
                            <InvestigationFormFieldContainer>
                                <SelectBox
                                    value={[
                                        priority
                                            ? priority
                                            : priorityOptions[0],
                                    ]}
                                    options={priorityOptions}
                                    onChange={(value: SelectOption) =>
                                        onSelectOptionChange(
                                            value,
                                            priorityOptions,
                                            setPriority
                                        )
                                    }
                                />
                            </InvestigationFormFieldContainer>
                        </InvestigationFormGridItemBy3>
                        <InvestigationFormGridItemBy6>
                            <InvestigationFormLabel htmlFor="reasons">
                                {translations.justification}:
                            </InvestigationFormLabel>
                            <InvestigationFormFieldContainer>
                                <TextArea
                                    rows={3}
                                    height="auto"
                                    color="#000"
                                    borderColor="#d1d5db"
                                    value={justification}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                    ) => setJustification(e.target.value)}
                                />
                            </InvestigationFormFieldContainer>
                        </InvestigationFormGridItemBy6>
                    </InvestigationFormGridContainer>
                </div>
            </InvestigationFormContainer>

            <ClearSubmitFormButtons
                submitText={translations.submit}
                clearText={translations.clear}
                isSubmitDisabled={justification.trim().length < 10}
                // @ts-ignore
                onSubmit={handleFormSubmit}
                onClear={clearForm}
                RenderComponent={() => <></>}
            />
        </form>
    );
};

export default OrderInvestigationForm;
