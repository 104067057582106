// src/pages/pricing/translations/en.ts
import { PricingTranslations } from './types';

export const enTranslations: PricingTranslations = {
    section: {
        title: "Pricing plans for everyone",
        description: "Choose an affordable plan that is packed with the best features - if you are a medical student looking to improve, a junior doctor studying for their exams, or a medical school wanting to add interactive tools to your curriculum",
        toggleMonthly: "Monthly",
        toggleYearly: "Yearly",
        perMonth: "month",
        perYear: "year"
    },
    plans: {
        individual: {
            title: "Individual Plan",
            details: "A plan that allows you to have unlimited practice cases at your own pace, and individualise your learning to your aims",
            features: [
                "52 diseases",
                "Basic analytics",
                "Chatbot patients",
                "Access to guidelines"
            ]
        },
        team: {
            title: "Institution Plan",
            details: "A plan that allows your students to have unlimited practice cases, whilst giving you control to guide their learning",
            features: [
                "52 diseases",
                "Chatbot patients",
                "Advanced analytics",
                "Researcher add on"
            ]
        }
    }
};
