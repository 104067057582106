import { ViewClassesTranslations } from './types';

export const enTranslations: ViewClassesTranslations = {
    title: "View Classes",
    columns: {
        className: "Class Name",
        status: "Status",
        studentsEnrolled: "Students Enrolled",
        gamesPlayed: "Games Played",
        dateCreated: "Date Created",
        dateOpened: "Date Opened",
        dateClosed: "Date Closed",
        review: "Review"
    },
    actions: {
        lock: "Lock",
        review: "Review"
    },
    errors: {
        loadingFailed: "Failed to load classes",
        lockFailed: "Failed to lock the class. Please try again."
    },
    status: {
        locked: "Locked"
    }
};