// External imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Internal imports
import { Patient } from "../../../core/LocalStorage/patients/interface";
import { DepartmentalDetails } from "./dashBoardTable/dashBoardTable";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import { getSessionID } from "../../../core/LocalStorage/gameSession/core";

// Shared Dependency Injection across all the in-game pages
import {
    WithPatientProps,
    withGameDependencies,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Dependency injections for Dashboard Page only
import {
    setFromPatientGetAllData,
    GetPatientDataByStatusType,
} from "../../../core/LocalStorage/patients/core";
import { GetAllPatientsType } from "../../../core/ServerApiLayer/VOne/patients/getAll";

// Translations
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { DashboardTableTranslations } from "./translations/types";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";

// Constants
const TRANSLATIONS: Record<SupportedLanguage, DashboardTableTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Interface for the DashBoardPage component
 */
interface DashBoardPageProps extends WithPatientProps {
    getAllPatientApiCall: GetAllPatientsType;
    getPatientDataStorage: GetPatientDataByStatusType;
}

// Combined Props
type CombinedProps = DashBoardPageProps & WithGameDependenciesProps;

/**
 * DashBoardPage Component
 *
 * Displays patients in the department, including waiting room patients, and those currently being seen.
 * It shows if they have a diagnosis, and if they are ready to be discharged.
 *
 * @returns A DashBoardPage component
 */
export const DashBoardPageBase: React.FC<CombinedProps> = ({
    getAllPatientApiCall,
    getPatientDataStorage,
    // Injected game dependencies
    turnDependencies,
    getJwt,
    useLanguage,
    translations,
    refreshPage,
}) => {
    const navigate = useNavigate();
    const turnCount = turnDependencies.getTurnCount();

    // State of patients
    const [patients, setPatients] = useState<Patient[]>([]);

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Translations
    const { language } = useLanguage();
    const pageTranslations = TRANSLATIONS[language];

    useEffect(() => {
        const storedPatients = getPatientDataStorage("active");
        if (storedPatients.length > 0) {
            setPatients(storedPatients);
        } else {
            const session_id = getSessionID();
            const jwt = getJwt();

            if (!jwt) {
                navigate("/login", {
                    state: { error: "Login has expired. Please login again." },
                });
                return;
            }

            getAllPatientApiCall(jwt, {
                session_id: session_id,
                filter: "all",
            }).then((response) => {
                if (response.status === 200 && response.data?.patients) {
                    setFromPatientGetAllData(response.data.patients);
                    const storedPatients = getPatientDataStorage("active");
                    setPatients(storedPatients);
                }
            });
        }
    }, [getPatientDataStorage, getAllPatientApiCall, navigate]);

    return (
        <GamePageLayout
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            translations={translations}
            turnDependencies={turnDependencies}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
        >
            <DepartmentalDetails
                patients={patients}
                translations={translations}
                pageTranslations={pageTranslations}
            />
        </GamePageLayout>
    );
};

export const DashBoardPage =
    withGameDependencies<DashBoardPageProps>(DashBoardPageBase);
