// External imports
import React from "react";
import { GiPadlock } from "react-icons/gi";
import { BiSolidLeftArrowCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

// Internal imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import {
    PageContainer,
    BagesTemplateGoBackButton,
    Title,
    CardSection,
    CardArea,
    CardSpan,
    Image,
    LockIcon,
} from "./RewardPage.styles";
import activeBadge from "./assets/placeholder_img.png";

// Language Context
import { useLanguage } from "../../../core/translations/LanguageContext";

// Translations
import { RewardsTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { useLocation } from "react-router-dom";

const TRANSLATIONS: Record<SupportedLanguage, RewardsTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Badge level configuration
 */
const BADGE_LEVELS: Array<{ level: number; locked: boolean }> = [
    { level: 1, locked: false },
    { level: 2, locked: false },
    { level: 3, locked: true },
    { level: 4, locked: true },
    { level: 5, locked: true },
    { level: 6, locked: true },
    { level: 7, locked: true },
    { level: 8, locked: false },
    { level: 9, locked: true },
    { level: 10, locked: true },
    { level: 11, locked: true },
    { level: 12, locked: true },
];

/**
 * RewardPage component
 * Displays a grid of achievement badges with locked/unlocked states
 */
const RewardPage: React.FC = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    const translations = TRANSLATIONS[language];

    return (
        <MainPageLayout>
            <PageContainer>
                <BagesTemplateGoBackButton onClick={() => goBack()}>
                    <BiSolidLeftArrowCircle />
                    <span>Go Back</span>
                </BagesTemplateGoBackButton>
                <Title>{translations.content.title}</Title>
                <CardSection>
                    {BADGE_LEVELS.map((badge) => (
                        <CardArea key={badge.level}>
                            {badge.locked ? (
                                <LockIcon>
                                    <GiPadlock />
                                </LockIcon>
                            ) : (
                                <Image
                                    src={activeBadge}
                                    alt={
                                        translations.content.badges[badge.level]
                                    }
                                />
                            )}
                            <CardSpan>
                                {translations.content.badges[badge.level]}
                            </CardSpan>
                        </CardArea>
                    ))}
                </CardSection>
            </PageContainer>
        </MainPageLayout>
    );
};

export default RewardPage;
