// External imports
import React from "react";

// Internal imports
import {
    StyledToggleButton,
    ToggleButtonContainer,
    ToggleButtonContainerWrapper,
} from "./ToggleButton.styles";

/**
 * Props for the ToggleButton component
 * @property isMonthly - Whether the monthly option is selected
 * @property onClick - Function to handle toggle state change
 * @property monthlyText - Text to display for monthly option
 * @property yearlyText - Text to display for yearly option
 */
interface ToggleButtonProps {
    isMonthly: boolean;
    onClick: () => void;
    monthlyText?: string;
    yearlyText?: string;
}

/**
 * ToggleButton component
 * Provides a toggle switch between monthly and yearly options

 */
const ToggleButton: React.FC<ToggleButtonProps> = ({
                                                       isMonthly,
                                                       onClick,
                                                       monthlyText = "Monthly",
                                                       yearlyText = "Yearly"
                                                   }) => {
    return (
        <ToggleButtonContainerWrapper>
            <ToggleButtonContainer>
                <StyledToggleButton isMonthly={isMonthly} onClick={onClick}>
                    {monthlyText}
                </StyledToggleButton>
                <StyledToggleButton isMonthly={!isMonthly} onClick={onClick}>
                    {yearlyText}
                </StyledToggleButton>
            </ToggleButtonContainer>
        </ToggleButtonContainerWrapper>
    );
};

export default ToggleButton;