import { Guide } from "./types";
import { isGuide } from "./validator";

// Import raw YAML files
import creatingClassEn from "../guides/content/en/creating-class.yaml";
import creatingClassDe from "../guides/content/de/creating-class.yaml";

// Validate and typecast YAML to the Guide type
function validateGuide(data: unknown): Guide {
    if (!isGuide(data)) {
        throw new Error(`Invalid guide structure: ${JSON.stringify(data)}`);
    }
    return data;
}

// Create the guide map with validated data
export const guidesMap: Record<string, Record<string, Guide>> = {
    en: {
        "creating-class": validateGuide(creatingClassEn),
    },
    de: {
        "creating-class": validateGuide(creatingClassDe),
    },
};
