import React, { useState } from "react";

import { SidebarItemContainer, Item, NavIcon, SubTitle } from "./SidebarNavItem.styles";


/**
 * The props passed into the component.  This is used for both the main items and the sub-items.
 */
export interface SidebarNavItemProps {
  name: string;
  Icon: React.ElementType;
  href?: string;
  onClick?: () => void;
}

/**
 * SidebarNavItem is a component that represents a single item in the sidebar. It can be clicked to navigate to a new page, 
 * or expanded to show sub-items.
 * 
 * @param props The SidebarItemProps for the component.
 * @returns The SidebarNavItem component.
 */
export const SidebarNavItem: React.FC<SidebarNavItemProps> = ({ name, Icon, href, onClick }) => {

  // If onClick is provided, use it as the click handler; otherwise, default to navigation
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    } else if (href) {
      window.location.href = href;
    }
  };

  return (
    <SidebarItemContainer onClick={handleClick}>
      <Item as="div">
        {Icon &&
          <NavIcon as="i">
            <Icon aria-hidden="true" />
          </NavIcon>}
        <SubTitle>{name}</SubTitle>
      </Item>
    </SidebarItemContainer>
  );
};

