// External imports
import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";

// Internal imports
import GlobalSmallPopup from "../GlobalSmallPopup";
import {
    LogoutModalContainer,
    LogoutModalContentContainer,
    LogoutModalTitle,
    LogoutModalSubTitle,
    LogoutButton,
    CancelButton,
    ButtonContainer,
} from "./LogoutModal.styles";

// Translation imports
import { useLanguage } from '../../../../core/translations/LanguageContext';
import { SupportedLanguage } from '../../../../core/translations/supportedLanguages';
import { LogoutModalTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";

// Constants
const TRANSLATIONS: Record<SupportedLanguage, LogoutModalTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Props interface for the LogoutModal component
 * @interface LogoutModalProps
 */
interface LogoutModalProps {
    onRequestClose: () => void;
    onSubmitLogout: () => void;
    isGame:boolean;
}

/**
 * LogoutModal component
 * Displays a modal with the class authentication code and copy functionality
 * @param {LogoutModalProps} props - Component props
 * @returns {React.ReactElement} Rendered component
 */
const LogoutModal: React.FC<LogoutModalProps> = ({
                                                                 onSubmitLogout,
                                                                 onRequestClose,
                                                                 isGame,
                                                             }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];    

    return (
        <GlobalSmallPopup onRequestClose={onRequestClose}>
            <LogoutModalContainer>
                <LogoutModalContentContainer>
                    <LogoutModalTitle>
                        {translations.title}
                    </LogoutModalTitle>
                    <LogoutModalSubTitle>
                        {isGame?translations.gameSubtitle: translations.subtitle}
                    </LogoutModalSubTitle>
                    <ButtonContainer>
                    <LogoutButton onClick={onSubmitLogout}>{translations.logoutText}</LogoutButton>
                    <CancelButton onClick={onRequestClose}>{translations.cancelText}</CancelButton>
                    </ButtonContainer>
                </LogoutModalContentContainer>
            </LogoutModalContainer>
        </GlobalSmallPopup>
    );
};

export default LogoutModal;