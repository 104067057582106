import styled from "@emotion/styled";

export const Container = styled.div`
    font-family: "Helvetica", Arial, sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const TurnsText = styled.span`
    font-weight: 500;
    font-size: 0.94em;
    padding-right: 0.75em;
    color: #dceef9;
`;
export const TurnsCount = styled.div`
    padding: 0.13em 0.75em;
    font-size: 1.125em;
    font-weight: 700;
    display: block;
    border-radius: 0.375em;
    color: white;
    background: #222;
`;
