// External imports
import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";

// Internal imports
import {
    ChatPageMainForm,
    ChatPageInputFieldArea,
    ChatPageButtonArea,
    ChatPageSubmitButton,
} from "./FormComponent.styles";

/// Define the FormData interface
export interface FormData {
    message: string;
}

/**
 * Props for the ChatComponent.
 */
export interface ChatComponentFormProps {
    onSubmit: (data: FormData) => void;
    initialFormData?: FormData;
    isDisabled?: boolean;
    translations: any;
}

/**
 * ChatComponent is a form component that allows users to input a message and submit it.
 */
export const ChatComponent: React.FC<ChatComponentFormProps> = ({
    onSubmit,
    initialFormData,
    isDisabled,
    translations,
}) => {
    const [formData, setFormData] = useState<FormData>(
        initialFormData || { message: "" }
    );
    const [validationSuccess, setValidationSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (initialFormData) {
            setFormData(initialFormData); // Update internal state when initialFormData changes
        }
    }, [initialFormData]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const updatedFormData = {
            ...formData,
            [e.target.name]: e.target.value,
        };
        setFormData(updatedFormData);
        handleCharacterLimit(updatedFormData);
    };

    const handleCharacterLimit = (updatedFormData: FormData) => {
        const characterLimit = 1;
        setValidationSuccess(updatedFormData.message.length >= characterLimit);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validationSuccess) {
            onSubmit(formData); // Call the parent onSubmit handler
            // Reset form data after submission
            setFormData({ message: "" });
            setValidationSuccess(false);
        }
    };

    const handleReset = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormData({ message: "" });
        setValidationSuccess(false);
    };

    return (
        <ChatPageMainForm onSubmit={handleSubmit} onReset={handleReset}>
            <ChatPageInputFieldArea>
                <input
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder={translations.form.placeHolder}
                    disabled={isDisabled} // Disable input field based on loading state
                />
            </ChatPageInputFieldArea>
            <ChatPageButtonArea>
                <ChatPageSubmitButton
                    type="submit"
                    disabled={!validationSuccess || isDisabled}
                >
                    {translations.form.submitButton}
                </ChatPageSubmitButton>
            </ChatPageButtonArea>
        </ChatPageMainForm>
    );
};
