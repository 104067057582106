import React from "react";
import { Modal } from "../Modal";
import {
    TreatmentErrorModalContainer,
    TreatmentErrorModalContentContainer,
    TreatmentErrorModalContentWrapper,
    TreatmentErrorModalTitle,
    TreatmentErrorModalButtonContainer
} from "./TreatmentErrorModal.styles";
import WebsiteButton from "../../button/WebsiteButton";

/**
 * TreatmentAlreadyOrderedModal Props.
 * @interface TreatmentAlreadyOrderedModalProps
 * @property {() => void} onClose - The function to close the modal.
 * @property {number} turnOrdered - The turn the investigation was ordered.
 * @property {number} turnAvailable - The turn the investigation might be available.
 */
interface TreatmentAlreadyOrderedModalProps {
    onClose: () => void;
    turnOrdered: number;
}

/**
 * TreatmentAlreadyOrderedModal component.
 * @component TreatmentAlreadyOrderedModal
 * @description A modal component for displaying information about already ordered investigations.
 * @param {TreatmentAlreadyOrderedModalProps} props - The component props.
 * @returns {JSX.Element} The rendered TreatmentAlreadyOrderedModal component.
 */
function TreatmentAlreadyOrderedModal({
                                              onClose,
                                              turnOrdered,
                                          }: TreatmentAlreadyOrderedModalProps) {
    return (
        <Modal width="35%" onRequestClose={onClose}>
            <TreatmentErrorModalContainer>
                <TreatmentErrorModalContentContainer>
                    <TreatmentErrorModalTitle>Treatment Already Ordered</TreatmentErrorModalTitle>
                    <TreatmentErrorModalContentWrapper>
                        <p>
                            You have already ordered this treatment at turn {turnOrdered}. Treatments can only be ordered once.
                        </p>
                        <TreatmentErrorModalButtonContainer>
                            <WebsiteButton tag="button" onClick={onClose}>
                                Close
                            </WebsiteButton>
                        </TreatmentErrorModalButtonContainer>
                    </TreatmentErrorModalContentWrapper>
                </TreatmentErrorModalContentContainer>
            </TreatmentErrorModalContainer>
        </Modal>
    );
}

export default TreatmentAlreadyOrderedModal;
