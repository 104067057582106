import { additionalTest, additionalTestsData } from "./interface";
import { getSpecificPatientData, updatePatientData} from "./core";


// Function signature type for setting to local storage
export type SetAdditionalTestsToLocalStorageType = (patient_id: number, newData: additionalTest) => void;


/*
Set the investigation data for a patient in localStorage.
*/
export function setAdditionalTestsToLocalStorage(patient_id: number, newData: additionalTest): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has investigation data, initialize if none
    let existingTests = patientData.additionalTests || {};

    // Get keys of the new investigation data
    const additionalTestType = newData.professional;

    // Add or update the specific additional type
    existingTests[additionalTestType] = newData;

    // Update the patient's investigation data
    patientData.additionalTests = existingTests;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}



// Function signature type for getting from local storage
export type GetAdditionalTestDataFromLocalStorageType = (patient_id: number) => additionalTestsData | null;


// Function to get the additional test data for a patient from localStorage
export function getAdditionalTestDataFromLocalStorage(patient_id: number): additionalTestsData | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // Return the investigation data, or null if no data is found
    return patientData?.additionalTests || null;
}


/*
Type for the filtered additional tst data.
 */
export type FilteredAdditionalTestData = {
    available: additionalTest[];
    pending: additionalTest[];
}


// Function signature type for filtering investigation data
export type FilterAdditionalTestDataType = (patient_id: number, turn: number) => FilteredAdditionalTestData;

export function filterAdditionalTestDataFromStorage(patient_id: number, turn: number): FilteredAdditionalTestData {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return { available: [], pending: [] };
    }

    // Check if the patient already has investigation data, initialize if none
    let existingTests = patientData.additionalTests || {};

    // Get the keys of the investigation data
    const additionalTestKeys = Object.keys(existingTests);

    // Filter the additionalTest data based on the turn
    const availableData: additionalTest[] = [];
    const pendingData: additionalTest[] = [];

    additionalTestKeys.forEach((key) => {
        const additionalTest = existingTests[key];
        if (additionalTest.turn_available <= turn) {
            availableData.push(additionalTest);
        } else {
            pendingData.push(additionalTest);
        }
    });

    return { available: availableData, pending: pendingData };
}