import React from 'react';
import { MessageBox } from './ChatLayout.styles'; // Import the styled component
import RightSideMessagePreview from './RightSideMessagePreview/RightSideMessagePreview';
import LeftSideMessagePreview from './LeftSideMessagePreview/LeftSideMessagePreview';

export interface ChatProps {
    messagesHistory: Array<any>;
}

const ChatLayout: React.FC<ChatProps> = ({ messagesHistory }) => {
    return (
        <MessageBox>
            {messagesHistory.map((msg, index) => (
                msg.userId === 1 ? 
                    <RightSideMessagePreview message={msg.message} key={index} /> : 
                    <LeftSideMessagePreview message={msg.message} key={index} />
            ))}
            <div id="chat-end" />
        </MessageBox>
    );
}

export default ChatLayout;
