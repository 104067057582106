// External imports
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Internal imports
import { SidebarNavItem, SidebarNavItemProps } from '../SidebarNavItem/SidebarNavItem';
import { PopoutMenuContainer, SubmenuNavItemsMain, SubmenuNavItemsMenu, GutterContainer } from './SidebarNavPopoutMenu.styles';


/**
 * The props passed into the component.
 * 
 * @param mainItem The main item that will be displayed in the sidebar. E.g. Investigations
 * @param subItems The sub-items that will be displayed in the popout menu E.g."Blood Tests", "Radiology"
 */
export interface SidebarNavPopoutMenuProps {
  mainItem: SidebarNavItemProps;
  subItems: SidebarNavItemProps[];
}

/**
 * This component represents a main item in the sidebar that can be clicked to reveal a popout menu of sub-items, 
 * or if no sub-items are provided, it will simply display the main item, and act as a link to a new page.
 * 
 * @param props The SidebarNavPopoutMenuProps for the component.
 * @returns The SidebarNavPopoutMenu component.
 */
export const SidebarNavPopoutMenu: React.FC<SidebarNavPopoutMenuProps> = ({ mainItem, subItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null); // Ref to the main button to measure its width
  const [buttonWidth, setButtonWidth] = useState(0);
  const { id: patientId } = useParams<{ id: string }>();

  useEffect(() => {
    if (buttonRef.current) {
      // Set the width based on the button's actual width. This is annoying bit of styling
      // to ensure the popout menu is positioned correctly, even on different screen sizes.
      //@ts-ignore
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [buttonRef.current]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <SubmenuNavItemsMain>
      <div ref={buttonRef} onClick={toggleMenu}>
        <SidebarNavItem {...mainItem} />
      </div>

      {/* {isOpen && ( */}
        <SubmenuNavItemsMenu id='mainPopoutSubmenu'>
          <PopoutMenuContainer>
            <GutterContainer as="div">
            {subItems.map((item, index) => (
                //@ts-ignore
              <SidebarNavItem key={index} {...item} href={item.href.replace(":id", patientId!)}/>
            ))}
            </GutterContainer>
          </PopoutMenuContainer>
        </SubmenuNavItemsMenu>

      {/* )} */}
    </SubmenuNavItemsMain>
  );
};