// External imports
import React from "react";

// Internal imports
import NewClassFormBox from "../NewClassFormBox";
import { DiseaseChoicesCheckbox } from "./DiseaseChoicesCheckbox/DiseaseChoicesCheckbox";
import { CategoryList } from "./CategoryList/CategoryList";
import {
    DiseaseChoicesContainer,
    DiseaseChoicesContainerSplit,
    DiseaseOption,
    LeftPanel,
    RightPanel,
} from "./DiseaseChoicesComponent.styles";
import { DiseaseChoiceState } from "../../../../../core/metaDataAccess/classes/diseaseChoices/state";
import { DiseaseStateController } from "../../../../../core/metaDataAccess/classes/diseaseChoices/stateController";
import { DiseaseTranslations, DiseaseName } from '../../../../../core/translations/diseases/types';

// Translation type for DiseaseChoicesComponent
import { useLanguage } from "../../../../../core/translations/LanguageContext";

import { DiseaseChoicesTranslations } from './translations/types';
import { enChoiceTranslations } from "./translations/en";
import { deChoiceTranslations } from "./translations/de";
import { enDiseaseTranslations } from '../../../../../core/translations/diseases/en';
import { deDiseaseTranslations } from '../../../../../core/translations/diseases/de';
import { SupportedLanguage } from '../../../../../core/translations/supportedLanguages';


const TRANSLATIONS: Record<SupportedLanguage, DiseaseTranslations> = {
    en: enDiseaseTranslations,
    de: deDiseaseTranslations,
};

const DISEASE_CHOICE_TRANSLATIONS: Record<SupportedLanguage, DiseaseChoicesTranslations> = {
    en: enChoiceTranslations,
    de: deChoiceTranslations,
};




/**
 * Props for DiseaseChoicesComponent
 */
interface DiseaseChoicesProps {
    state: DiseaseChoiceState;
}

/**
 * DiseaseChoicesComponent
 */
export const DiseaseChoicesComponent: React.FC<DiseaseChoicesProps> = ({ state }) => {
    const { language } = useLanguage();
    const diseaseTranslations = TRANSLATIONS[language];
    const choicesTranslation = DISEASE_CHOICE_TRANSLATIONS[language];

    const DiseaseStateHandle = () => {
        DiseaseStateController.updateState(state);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, diseaseName: DiseaseName) => {
        const category = state.getCategory(state.selectedCategory);
        category.checkDisease(diseaseName);
        DiseaseStateHandle();
    };

    const selectedCategory = state.getCategory(state.selectedCategory);
    const diseases = Array.from(selectedCategory.diseases.entries());

    return (
        <NewClassFormBox>
            <h1>{choicesTranslation.title}</h1>
            <DiseaseChoicesContainer>
                <DiseaseChoicesContainerSplit>
                    <LeftPanel>
                        <CategoryList
                            ChangeStateHandle={DiseaseStateHandle}
                            DiseaseState={state}
                            ChosenDisease={state.selectedCategory}
                            diseaseTranslations={diseaseTranslations}
                        />
                    </LeftPanel>
                    <RightPanel>
                        {diseases.map(([name, disease]) => (

                            <DiseaseOption key={name}>
                                <DiseaseChoicesCheckbox
                                    label={diseaseTranslations.diseases[name].name}
                                    checked={disease.checked}
                                    onChange={(e) => handleCheckboxChange(e, name)}
                                />
                            </DiseaseOption>
                        ))}
                    </RightPanel>
                </DiseaseChoicesContainerSplit>
            </DiseaseChoicesContainer>
        </NewClassFormBox>
    );
};