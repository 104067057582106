import styled from "@emotion/styled";

export const LeftPanel = styled.div`
    font-family: Inter, sans-serif; /* Include font family */
    width: 30%; /* 30% of the container's width */
    min-width: 300px; /* Set minimum width of left section for desktop view */
    background-color: white; /* Set the background color white of left section */
    display: flex; /* Use Flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically if needed */
    height: 100vh; /* Full height of the viewport */
    overflow: hidden; /* Prevent scrolling within the container */
     h1 {
    margin: 0 auto; /* Make the content centered */
    font-family: Inter, sans-serif; /* Provide font-family */
}
    img {
    max-width: 200px; /* Add max width to the logo */
    margin: 0 auto; /* Make the content centered */
    margin-bottom: 20px; /* Add space between the logo and the title */
}
    @media screen and (min-width:768px) and (max-width:1366px) {
        width: 50%; /* Provide 50% area of desktop to left panel */
}
    @media screen and (max-width:767px) {
        width: 100%; /* Provide full area of desktop to left panel */
}
`;

export const TextAndButtonSection = styled.div`
    min-width: 240px; /* Assign minimum width to text section */
`; 

export const RightPanel = styled.div`
    flex-grow: 1; /* Takes the remaining space */
    background-color: #e0e0e0; /* Just for visibility, change as needed */
    background-size: cover; /* Cover the entire area */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-position: center; /* Center the background image */
    background-attachment: fixed; /* Optional: Fix the background during scroll */
    height: 100vh; /* Full height of the viewport */
    width: 100vw; /* Full width of the viewport */
    overflow: hidden; /* Prevent scrolling within the container */
    position: relative; /* Set positioning of background image */
    flex: 1 1 0%; /* Use Flexbox to make page sectioning responsive*/
    img {
    object-fit: cover; /* Cover the entire area */
    width: 100%; /* Full width of the right section */
    height: 100%; /* Full height of the right section */
    position: absolute; /* Adjust the image so make left section relative */
    right: 0; /* Connect with right corner of the screen */
    object-position: -130px 0; /* Set the positioning */
}
    @media screen and (min-width:768px) and (max-width:1366px) {
        display: block; /* show right panel in desktop view */
}
    @media screen and (max-width:767px) {
        display: none; /* hide right panel in desktop view */
}

`;

export const Button = styled.button`
    width: 100%; /* Set full width of button */
    padding: 10px; /* Add padding between text and button borders */
    border: none; /* Remove borders */
    border-radius: 4px; /* Give some border radius to button corners */
    background-color: #007bff; /* Add background color to button background */
    color: white; /* Give color to text available in the button */
    cursor: pointer; /* Change the cursor as pointer in button area */
    font-size: 16px; /* Set font size of the button */
`;

export const Mainbox = styled.div`
    display: flex;  /* Include flex box */
`;

export const DIV = styled.div``;
export const Image = styled.img``;
export const H1 = styled.h1``;
export const Paragraph = styled.p``;