// External imports
import React from "react";

// Internal imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import {
    PageContainer,
    Title,
    CardSection,
    CardArea,
    CardSectionTitle,
    CardSectionAnchor,
    CardSpan,
    CardSpanPublished,
    DateCardSection,
    CardAreaDescription
} from "./Research.styles";

// Config
import { RESEARCH_PAPERS } from "./papers/papers";

// Language Context
import { useLanguage } from '../../../core/translations/LanguageContext';

// Translations
import { ResearchTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

const TRANSLATIONS: Record<SupportedLanguage, ResearchTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * ResearchPage component
 * Displays published research papers and preprints
 * Papers and their translations are configured in Research.config.ts
 */
const ResearchPage: React.FC = () => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    return (
        <MainPageLayout>
            <PageContainer>
                <Title>
                    {translations.pageTitle}
                </Title>
                <CardSection>
                    {RESEARCH_PAPERS.map((paper) => (
                        <CardArea key={paper.id}>
                            {paper.status === 'published' ? (
                                <CardSpanPublished>
                                    {translations.labels.published}
                                </CardSpanPublished>
                            ) : (
                                <CardSpan>
                                    {translations.labels.preprint}
                                </CardSpan>
                            )}
                            <CardSectionTitle>
                                <CardSectionAnchor href={paper.doi}>
                                    {paper.translations[language].title}
                                </CardSectionAnchor>
                            </CardSectionTitle>
                            <CardAreaDescription>
                                {paper.translations[language].description}
                            </CardAreaDescription>
                            <CardAreaDescription>
                                {paper.authors.join(', ')}
                            </CardAreaDescription>
                            <DateCardSection>
                                {translations.date}
                            </DateCardSection>
                        </CardArea>
                    ))}
                </CardSection>
            </PageContainer>
        </MainPageLayout>
    );
};

export default ResearchPage;