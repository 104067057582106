// External imports
import { ComponentProps } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";

// Internal imports
import PatientExamState from "../../state/PatientExamState";
import { PatientExamination } from "../../types/PatientExamTypes";
import FemaleExamImage from "../../assets/genito-urinary-exam-female.png";
import MaleExamImage from "../../assets/genito-urinary-exam-male.png";
import { ExaminationName, PatientExamCardContainer } from "./ExamCard.styles";
import Image from "../../../../../components/core/image/Image";

// Translations
import { ExamTypesTranslations } from "../../../../../core/translations/examinations/types";

/**
 * PatientExamCardProps
 *
 * This interface is used to define the props of the PatientExamCard component
 *
 * examination: The examination to display on the card
 * isSidebar: A boolean to determine if the card is being displayed in the sidebar. This
 *          is important as the card will be smaller when as sidebar, compared to when
 *         it is the main content of the page.
 * gender: the gender
 * examined: A boolean to determine if the examination has been examined. If it has, a
 *         checkmark will be displayed in the top of the card.
 * setSelectedCard: A function to set the selected card in the state. This is used to
 */
export interface PatientExamCardProps extends ComponentProps<"div"> {
    examination: PatientExamination & {
        id: keyof ExamTypesTranslations;
    };
    isSidebar?: boolean;
    gender?: "Male" | "Female";
    examined?: boolean;
    setSelectedCard?: (examination: PatientExamination) => void;
    translations?: any;
}

/**
 * PatientExamCard
 *
 * A component that displays a card for a patient examination. If the examination has been
 * examined, a checkmark will be displayed in the top right corner of the card. If the
 * examination is a genital examination, the image will be replaced with the appropriate
 * image based on gender.
 *
 */
export const PatientExamCard: React.FC<PatientExamCardProps> = ({
    examination,
    isSidebar,
    gender,
    setSelectedCard,
    translations,
}: PatientExamCardProps) => {
    // Set the image
    let image = examination.image;

    // Logic to set the image for genital examination as this changes based on gender.
    // No other images change like this but we might need this in the future.
    if (gender && examination.id === "GENITAL") {
        if (gender === "Female") {
            image = FemaleExamImage;
        } else if (gender === "Male") {
            image = MaleExamImage;
        }
    }

    return (
        <PatientExamCardContainer
            onClick={() => {
                PatientExamState.setSelectedCard(examination);
                if (setSelectedCard) setSelectedCard(examination);
            }}
            isSidebar={isSidebar}
            isCardSelected={PatientExamState.isCardSelected(examination)}
        >
            {PatientExamState.isCardExamined(examination) ? (
                <BsFillCheckCircleFill
                    color="#3D81E1"
                    size="1.2em"
                    className="absolute"
                    style={{
                        top: "1em",
                        right: "1em",
                    }}
                />
            ) : null}
            <Image
                src={image}
                alt={examination.name}
                objectFit="contain"
                height={isSidebar ? 150 : 300}
                width={isSidebar ? 150 : 300}
                style={{
                    mixBlendMode: "multiply",
                    height: isSidebar ? 150 : 300,
                }}
            />
            <ExaminationName
                isSideBar
                style={{ fontSize: isSidebar ? ".85em" : "1em" }}
            >
                {translations[examination.id]}
            </ExaminationName>
        </PatientExamCardContainer>
    );
};

export default PatientExamCard;
