import styled from "@emotion/styled";


export const MainForm = styled.form`
    font-family: "Helvetica", Arial, sans-serif;
    label{
    font-family: Inter, sans-serif; /* Include font-family */
    font-weight: 600; /* Make the label bold */
    display: flex; /* Include flex box */
    align-items: center; /* Align content to center */
    justify-content: space-between; /* Add spacing in middle of label and span tag */
    width: 100%; /* Preview label in full width of div */
    }
`;

export const FormContainer = styled.div`
    padding-bottom: 20px; /*  Adjust the spacing of form */
    margin-bottom: 20px; /* Give some margin from bottom to add border */
    border-bottom: 1px solid #f2f3f5; /* Add border at the form */
`;

export const InputFieldArea = styled.div`
    gap: 10px; /* Add some space */
    display: flex; /* Include flexbox */
    flex-wrap: wrap; /* Wrap flexbox */
`;

export const FormField = styled.textarea``;

export const SplitInputs = styled.div`
    display: flex; /* Include flexbox */
    flex-direction: row; /* Align divs row wise */
    width: 100%; /* Preview section in full width of div */
    gap: 10px; /* Include spacing */
      @media screen and (max-width: 767px) {
    flex-direction: column; /* Change direction to column on smaller screens */
  }
`;

export const Col6 = styled.div`
    width: 100%; /* Preview section in full width of div */
    display: flex; /* Include flexbox */
    flex-direction: column; /* Align divs column wise */
    gap: 10px; /* Include spacing */
    margin-bottom: 20px; /* Add spacing from bottom */
`;

export const InfoLabel =styled.span`
    color: #8d939e; /* Set color */
    font-size: small; /* Set font size of info label */
    align-items: center; /* Align to center with respect to the icon */
    display: flex; /* Include flexbox */
    gap: 5px; /* Include spacing */
    svg {
    color: #000; /* Set color */
}
`;

export const Button =styled.button`
    width: fit-content; /* Adjust the width as per content */
    display: flex; /* Include flexbox */
    gap: 5px; /* Include spacing */
    font-family: Inter, sans-serif; /* Include font-family */
    font-weight: 600; /* Make the text bold */
    justify-content: center; /* Align it center */
    align-items: center; /* Align it vertically center */
    cursor: pointer; /* Change the cursor to pointer */
    border: none; /* Remove border */
    border-radius: 4px; /* Include some border radius */
    padding: 10px; /*  Add spacing */
    font-size: 16px; /* Set the font size */
`;

export const ButtonArea = styled.div`
    display: flex; /* Include flexbox */
    flex-direction: row; /* Set the direction of nodes row via in flexbox */
    justify-content: end; /* place the buttons at the end of the form - right side */
    gap: 5px; /* Add some gap between two buttons */
`;

export const SubmitButton = styled.button`
    width: fit-content; /* Make the button smaller instead of full width */
    background-color: #0284d2; /* set background color of submit button*/
    color: white; /* Set color */
     &:disabled {
    background-color: #0284d280; /* Set background color when disabled */
  }
`;

export const ClearButton = styled.button`
    width: fit-content; /* Make the button smaller instead of full width */
    background-color: #c6cccf; /* set background color of reset button*/
    color: black; /* Set color */
`;

export const Textarea = styled.textarea`
    font-size: 0.875rem; /* Set font size */
    line-height: 1.25rem; /* Set line height of email input field */
    padding: 10px; /* Set padding */
    border: 1px solid #ccc; /* Set border to email field */
    border-radius: 4px; /* Set border radius of input field */
    box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    margin-bottom: 20px; /* Add margin between 2 column aligned fields */
    width: 100%; /* Set the width of input field to show in full assigned area */
    font-family: Inter, sans-serif; /* Include font-family */
`;