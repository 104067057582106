import styled from "@emotion/styled";

export const ItemContainer = styled.div`
font-family: 'Helvetica', 'Arial', sans-serif;
  color: black;
  padding: 10px;
  border: 1px solid black;
  margin: 5px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const DismissButton = styled.button`
  background-color: rgb(5, 132, 199);
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.938rem;
  white-space: nowrap;
  padding: 0.6em 1.3em;
  border-radius: 0.8em;
  &:hover {
    background-color: rgb(10, 110, 189);
  }
`;

export const IconsArea = styled.div`
  display: flex;
  gap: 5px;
  svg{
    font-size: 1.15rem;
    cursor: pointer;
  }
`;
