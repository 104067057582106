import React, { useState } from 'react';
// @ts-ignore
import myImage from "../../../assets/outside.jpg";
import logoImage from "../../../assets/clinical_metrics.png";
import { ForgotPasswordForm, FormData } from "./FormComponent/FormComponent";
// @ts-ignore
import { passwordResetRequestType } from '../../../core/ServerApiLayer/VOne/auth/passwordResetRequest';
import { PasswordResetRequestPayload } from '../../../core/ServerApiLayer/VOne/auth/passwordResetRequest';
import { ConfirmPageMain, DIV, EnterEmailSummary, H1, LeftPanel, LoginLink, Mainbox, RightPanel, TextAndButtonSection } from './ForgetPassword.styles';


/**
 * Forgot Password Form Props
 * 
 * This is the props for the forgot password form component
 */
export interface ForgotPasswordFormProps {
    passwordResetRequestApiCall: passwordResetRequestType;
}

/**
 * Forgot Password Page Component
 * 
 * This is the forgot password page for the user. This is shown at the /forgot-password route, where
 * the user can enter their email address to receive a link to reset their password.
 */
export const ForgotPassword: React.FC<ForgotPasswordFormProps> = ({ passwordResetRequestApiCall }) => {

    let language = localStorage.getItem("language");
    if (language == null) {
        language = "English"
    };

    const [formStatus, setFormStatus] = useState<FormData>({
        email: '',
        success: false,
        failure: false,
        error: '',
    });

    // this function fires when the form is submitted
    const handleFormSubmit = async (userData: FormData) => {
        // create the passwordResetRequest payload
        const passwordResetRequestPayload: PasswordResetRequestPayload = {
            email: userData.email,
            language: language,
        }

        const response = await passwordResetRequestApiCall(passwordResetRequestPayload);
        if (response.status === 200) {
            setFormStatus({ ...userData, success: true, failure: false, error: '' });
            return null
        }
        else {
            setFormStatus({ ...userData, success: false, failure: true, error: response.message });
            return null
        }
    };

    return (
        <ConfirmPageMain>
            <DIV>
                <Mainbox>
                    <LeftPanel>
                        <TextAndButtonSection>
                            <img src={logoImage} alt="description" />
                            <H1>Forgot password</H1>
                            <LoginLink>Back to <a id="backToLoginLink" href="/login">Login</a></LoginLink>
                            
                            <EnterEmailSummary>Please enter your email address. You will receive a link to create a new password via email.</EnterEmailSummary>
                            <ForgotPasswordForm
                                onSubmit={handleFormSubmit}
                                initialFormData={formStatus}
                            />
                        </TextAndButtonSection>
                    </LeftPanel>
                    <RightPanel>
                        <img src={myImage} alt="description" />
                    </RightPanel>
                </Mainbox>
            </DIV>
        </ConfirmPageMain>
    );
};

export default ForgotPassword;
