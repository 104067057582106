// External imports
import React, { useState } from "react";
import { AiOutlineLineChart } from "react-icons/ai";

// Internal imports
import {Modal} from "../../../../../../components/core/modal/Modal";
import {
    OrderConsultationModalContainer,
    OrderConsultationModalContentContainer,
    OrderConsultationModalForm,
    OrderConsultationModalContentLabel,
    OrderConsultationModalFormTitle,
    OrderConsultationModalContentDescription,
    OrderConsultationModalContentWrapper,
    OrderConsultationModalFormFooter,
    OrderConsultationModalFlexContainer,
} from "./OrderConsultationReport.styles";
import { MdOutlineClose } from "react-icons/md";
import TurnTablet from "../../../../notePage/components/TurnTablet/TurnTablet";
import TextArea from "../../../../../../components/core/input-form/TextArea";
import WebsiteButton from "../../../../../../components/core/button/WebsiteButton";
import IconActionButton from "../../../../../../components/core/icon-action-button/IconActionButton";


/**
 * OrderConsultationReportProps
 * 
 * This interface is for the props of the OrderConsultationReport component.
 */
interface OrderConsultationReportProps {
    title: string;
    details: string;
    notes?: string;
    allowNotes?: boolean;
    turn_ordered: number;
    image?: string;
    imageComment?: string;
    imageOpen?: boolean;
    onImageClick?: () => void;
    onClose: () => void;
    reason?: string;
}

/**
* OrderConsultationReportModal
* 
* This component is a modal for the investigation report. This is important because it allows
* the user to view a report or image, and add notes to the report. The notes end up in the 
* documentation. 
 */
export const OrderConsultationReportModal = ({
    title,
    details,
    allowNotes,
    notes,
    turn_ordered,
    image,
    imageComment,
    onClose,
    reason
}: OrderConsultationReportProps) => {
    const [note, setNote] = useState(notes ?? "");
    const [isReportPreviewOpen, setIsReportPreviewOpen] = useState(false);

    return (
        <>
            <Modal onRequestClose={onClose}>
                <OrderConsultationModalContainer>
                    <MdOutlineClose onClick={onClose} />
                    <OrderConsultationModalFormTitle>
                        Consultation Report for {title}<TurnTablet turn={turn_ordered} />
                    </OrderConsultationModalFormTitle>
                    <OrderConsultationModalContentContainer>
                        <OrderConsultationModalForm
                            onSubmit={(e: React.ChangeEvent<HTMLFormElement>) =>
                                e.preventDefault()
                            }
                        >
                            <OrderConsultationModalContentWrapper>
                                <OrderConsultationModalContentLabel>
                                    Reason for investigation:
                                </OrderConsultationModalContentLabel>
                                <OrderConsultationModalContentDescription>
                                    {reason || "No reason provided"}
                                </OrderConsultationModalContentDescription>
                            </OrderConsultationModalContentWrapper>
                            <OrderConsultationModalContentWrapper>
                                <OrderConsultationModalFlexContainer>
                                    <OrderConsultationModalContentLabel>
                                        {image ? "Imaging Available:" : "Report:"}
                                    </OrderConsultationModalContentLabel>
                                    {image && (
                                        <IconActionButton
                                            Icon={AiOutlineLineChart}
                                            tooltipText="Show Report"
                                            onClick={() =>
                                                setIsReportPreviewOpen(true)
                                            }
                                            selected={isReportPreviewOpen}
                                        />
                                    )}
                                </OrderConsultationModalFlexContainer>
                                <OrderConsultationModalContentDescription>
                                    {image ? "Click Button to View Image" : details}
                                </OrderConsultationModalContentDescription>
                            </OrderConsultationModalContentWrapper>

                            <OrderConsultationModalContentWrapper>
                                <OrderConsultationModalContentLabel>
                                    Notes:
                                </OrderConsultationModalContentLabel>

                                {allowNotes ? (
                                    notes ? (
                                        <OrderConsultationModalContentDescription>
                                            {notes}
                                        </OrderConsultationModalContentDescription>
                                    ) : (
                                        <TextArea
                                            autoFocus
                                            rows={6}
                                            placeholder="Add any notes here..."
                                            value={note}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => setNote(e.target.value)}
                                        />
                                    )
                                ) : (
                                    <OrderConsultationModalContentDescription>
                                        No notes available
                                    </OrderConsultationModalContentDescription>
                                )}
                            </OrderConsultationModalContentWrapper>
                            <OrderConsultationModalFormFooter>
                                <WebsiteButton type="submit" tag="button">
                                    {note.length > 0 ? "Submit" : "Reviewed"}
                                </WebsiteButton>
                            </OrderConsultationModalFormFooter>
                        </OrderConsultationModalForm>
                    </OrderConsultationModalContentContainer>
                </OrderConsultationModalContainer>
            </Modal>
        </>
    );
}

export default OrderConsultationReportModal;
