import styled from "@emotion/styled";

export const ExamSidebarStyle = styled.div`
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    shrink: 0;
    gap: 0.5rem;
    border-width: 1px;
    border-color: #e5e7eb;
    background-color: #f9fafb;
    font-family: "Helvetica", Arial, sans-serif;
    height: 75vh;
    overflow-x: hidden;
    width: 15rem;
`;
