import { ResearchTranslations } from './types';

export const deTranslations: ResearchTranslations = {
    pageTitle: "Forschung",
    labels: {
        published: "Veröffentlicht",
        preprint: "Preprint",
        inreview: "In Überprüfung"
    },
    date: "Februar 2024"
};