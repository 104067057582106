// External imports
import React from "react";

// Internal imports
import { DiseaseChoicesCheckbox } from "../DiseaseChoicesComponent/DiseaseChoicesCheckbox/DiseaseChoicesCheckbox";
import {
    Label,
    Flex,
    LabelText,
    Description,
} from "./NewClassConfig.styles";

/**
 * Props interface for the LabelledSetting component
 * @interface LabelledSettingProps
 */
interface LabelledSettingProps {
    label: string;
    description: string;
    value: boolean;
    onChangeChecked: (checked: boolean) => void;
}

/**
 * LabelledSetting component
 * Renders a checkbox setting with label and description
 * @param {LabelledSettingProps} props - Component props
 * @returns {React.ReactElement} Rendered component
 */
export const LabelledSetting: React.FC<LabelledSettingProps> = ({
                                                                    label,
                                                                    description,
                                                                    value,
                                                                    onChangeChecked
                                                                }) => {
    return (
        <Label>
            <Flex>
                <DiseaseChoicesCheckbox
                    checked={value}
    onChangeChecked={onChangeChecked}
    />
    <LabelText>{label}</LabelText>
    </Flex>
    <Description>{description}</Description>
    </Label>
);
};