// External imports
import React, { createContext, useContext, useState, useEffect } from 'react';

// Internal imports
import { SupportedLanguage } from './supportedLanguages';


/**
 * Context type for the language context
 */
interface LanguageContextType {
    language: SupportedLanguage;
    setLanguage: (lang: SupportedLanguage) => void;
}

/**
 * Props interface for the LanguageProvider component
 */
interface LanguageProviderProps {
    children: React.ReactNode;
}

/**
 * Language context to provide the current language and a function to change it
 */
const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

/**
 * Language provider component to provide the language context
 */
export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
    const [language, setLanguageState] = useState<SupportedLanguage>('en');

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage && (storedLanguage === 'en' || storedLanguage === 'de')) {
            setLanguageState(storedLanguage as SupportedLanguage);
        } else {
            const defaultLanguage: SupportedLanguage =
                window.location.hostname === 'divinagame.de' ? 'de' : 'en';
            setLanguageState(defaultLanguage);
            localStorage.setItem('language', defaultLanguage);
        }
    }, []);

    const setLanguage = (newLanguage: SupportedLanguage) => {
        setLanguageState(newLanguage);
        localStorage.setItem('language', newLanguage);
    };

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
    {children}
    </LanguageContext.Provider>
);
};


// Type signature for the useLanguage hook
export type UseLanguageType = () => LanguageContextType;

/**
 * Hook to access the language context
 * @throws Error if used outside of LanguageProvider
 */
export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (context === undefined) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};