import styled from "@emotion/styled";

export const AdditionalTestsFormContainer = styled.div`
    border-top-width: 1px;
    border-color: #e5e7eb;
    font-family: "Helvetica", Arial, sans-serif;
`;

export const AdditionalTestsFormGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 1rem;
    row-gap: 1.5rem;

    @media (min-width: 640px) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
`;

export const AdditionalTestsFormGridItemBy6 = styled.div`
    @media (min-width: 640px) {
        grid-column: span 6 / span 6;
    }
`;

export const AdditionalTestsFormLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #374151;
`;

export const AdditionalTestsFormFieldContainer = styled.div`
    margin-top: 0.25rem;
`;

export const AdditionalTestsFormCheckboxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 0.5rem;

    label {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        color: #374151;
    }

    input {
        margin-right: 0.5rem;
    }
`;

export const GroupTitle = styled.h4`
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #1f2937;
`;
