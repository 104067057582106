import styled from '@emotion/styled';

export const LoaderBackground = styled.div`
max-width: min(60%, 500px);
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #333333;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoaderText = styled.p`
padding-top:10px;
font-weight:bold;
`;