import styled from "@emotion/styled";
import { NotificationCountProps, BellContainerProps } from "./NotificationBell";
import { FaBell } from "react-icons/fa";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    width: fit-content; 
`;

export const NotificationCount = styled.div<NotificationCountProps>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    font-size: 0.8em;
    border-radius: 50%;
    width: 1.3em;
    height: 1.3em;
    top: -0.25;
    right: -0.25;
    transform: translate(50%, -50%);
    z-index: 100;
    opacity: ${(props) => (props.unreadCount > 0 ? 1 : 0)};
`;

export const NotificationCountText = styled.span`
    font-weight: bold;
    color: white;
`;
export const BellContainer = styled.div<BellContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.notifsOpen ? "rgba(255,255,255,.1)" : "transparent"};
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    position: relative;
    cursor: pointer;
`;

export const FaBellIcon = styled(FaBell)`
    color: #dceef9;
    font-size: 1.2em;
`;