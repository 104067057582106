import styled from "@emotion/styled";

export const DischargePatientDetailsTableContainer = styled.div`
    max-width: 80rem;
    max-height: 72vh;
    font-family: "Helvetica", Arial, sans-serif;
    padding: 0px 1rem;
    overflow-y: auto;

    th,
    td {
        text-align: center;
    }
`;

export const ViewFeedbackButton = styled.div`
    color: #0284c7;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        color: #0c4a6e;
    }
`;

export const ReviewDischargePatientButton = styled.div`
background-color:rgb(49 62 82);
    font-size: 0.938rem;
    white-space: nowrap;
    font-family: Helvetica, Arial, sans-serif;
    padding: 0.6em 1.3em;
    border-radius: 0.8em;
    cursor: pointer;
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 0.5em;
    font-weight: 500;
    width: fit-content;
    background: rgb(5, 132, 199);
    color: white;
    border: none;
`;
