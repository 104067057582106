import styled from "@emotion/styled";

export const NotesListFlexContainer = styled.div`
    display: flex;
    gap: 20px;
`;

export const NotesListContainer = styled.ul`
    width: 100%;
    padding: 0px 16px;
    margin: 0px;
    margin-bottom: -2rem;
`;
