// External Imports
import React, { useState } from "react";

// Internal Imports
import {
    OrderAdditionalTestResultListItemContainer,
    OrderAdditionalTestResultListItemContent,
    OrderAdditionalTestResultListItemContentContainer,
    OrderAdditionalTestResultListItemContentTitle,
    StyledOrderAdditionalTestResultListItem,
} from "./OrderAdditionalTestResultListItem.styles";
import TurnTablet from "../../../../notePage/components/TurnTablet/TurnTablet";
import WebsiteButton from "../../../../../../components/core/button/WebsiteButton";
import OrderAdditionalTestReportModal from "../orderAdditionalTestReportModal/OrderAdditionalTestReport";

/**
 * OrderAdditionalTestResultListItemProps
 */
interface OrderAdditionalTestResultListItemProps {
    title: string;
    turn_ordered: number;
    details: string;
    complete: boolean;
    notes?: string;
    allowNotes?: boolean;
    image?: string;
    reason?: string;
    summary?: string;
}

/**
 * OrderAdditionalTestResultListItem
 * 
 * This component is a list item for the investigation results.
 */
export const OrderAdditionalTestResultListItem = ({
    title,
    turn_ordered,
    details,
    complete,
    allowNotes,
    notes,
    reason,
    summary,
}: OrderAdditionalTestResultListItemProps) => {
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);

    return (
        <>
            <StyledOrderAdditionalTestResultListItem>
                <OrderAdditionalTestResultListItemContainer>
                    <OrderAdditionalTestResultListItemContentContainer>
                        <OrderAdditionalTestResultListItemContentTitle>
                            {title}
                        </OrderAdditionalTestResultListItemContentTitle>
                        <OrderAdditionalTestResultListItemContent>
                            {complete ? (
                                <WebsiteButton onClick={() => setIsReportModalOpen(true)}>
                                    {"View Report"}
                                </WebsiteButton>
                            ) : (
                                <TurnTablet turn={turn_ordered} />
                            )}
                        </OrderAdditionalTestResultListItemContent>
                    </OrderAdditionalTestResultListItemContentContainer>
                </OrderAdditionalTestResultListItemContainer>
            </StyledOrderAdditionalTestResultListItem>
            {isReportModalOpen && (
                <OrderAdditionalTestReportModal
                    title={title}
                    details={details}
                    allowNotes={allowNotes}
                    turn_ordered={turn_ordered}
                    notes={notes}
                    onClose={() => setIsReportModalOpen(false)}
                    reason={reason}
                    summary={summary}
                />
            )}
        </>
    );
}