// External imports
import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";

// Internal imports
import DOCTOR_STOCK_IMAGE from "../../../assets/doctor-stock.jpg";
import {
    CurrentUsersSectionContainer,
    PartnersBannerSectionContainer,
    PartnersBannerSectionContentButton,
    PartnersBannerSectionContentContainer,
    PartnersBannerSectionContentDescription,
    PartnersBannerSectionContentLink,
    PartnersBannerSectionContentSmallText,
    PartnersBannerSectionContentSubTitle,
    PartnersBannerSectionContentTitle,
    PartnersBannerSectionImage,
    PartnersBannerSectionImageContainer,
    PartnersBenefitsCard,
    PartnersBenefitsCardContainer,
    PartnersBenefitsSectionContainer,
    PartnersBenefitsSectionContentContainer,
    PartnersBenefitsSectionContentDescription,
    PartnersBenefitsSectionContentTitle,
    PartnersMeetOursSectionContainer,
    PageContainer,
    PartnersMeetOursSectionContentContainer,
    PartnersMeetOursSectionContentDescription,
    PartnersMeetOursSectionContentTitle,
    PartnersMeetOursSectionGridContainer,
    PartnersMeetOursSectionGridItem,
    PartnersMeetOursSectionGridItemDescription,
    PartnersMeetOursSectionGridItemImageContainer,
    PartnersMeetOursSectionGridItemTitle,
} from "./Partners.styles";
import MainPageLayout from "../../../components/core/header/MainPageLayout";

import {
    BENEFIT_DATA,
    CURRENT_USERS_DATA,
    PARTNERS_DATA,
} from "./Partners.config";

// Language Context
import { useLanguage } from '../../../core/translations/LanguageContext';

// Translations
import { PartnersTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

const TRANSLATIONS: Record<SupportedLanguage, PartnersTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Partners page component
 * Displays information about partnerships, benefits, and current users
 */
const Partners: React.FC = () => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    return (
        <>
            <MainPageLayout>
                {/*<PageContainer>*/}
            {/* Banner Section */}
            <PartnersBannerSectionContainer>
                <PartnersBannerSectionContentContainer>
                    <div>
                        <PartnersBannerSectionContentDescription>
                            {translations.banner.label}
                        </PartnersBannerSectionContentDescription>
                        <PartnersBannerSectionContentTitle>
                            {translations.banner.title}
                        </PartnersBannerSectionContentTitle>
                        <PartnersBannerSectionContentSubTitle>
                            {translations.banner.subtitle}
                        </PartnersBannerSectionContentSubTitle>
                    </div>
                    <div>
                        <PartnersBannerSectionContentButton>
                            {translations.banner.buttonText}
                        </PartnersBannerSectionContentButton>
                        <PartnersBannerSectionContentSmallText>
                            {translations.banner.demoText}
                        </PartnersBannerSectionContentSmallText>
                    </div>
                </PartnersBannerSectionContentContainer>
                <PartnersBannerSectionImageContainer>
                    <PartnersBannerSectionImage
                        src={DOCTOR_STOCK_IMAGE}
                        alt={translations.banner.imageAlt}
                    />
                </PartnersBannerSectionImageContainer>
            </PartnersBannerSectionContainer>

            {/*/!* Benefits Section *!/*/}
            {/*<PartnersBenefitsSectionContainer>*/}
            {/*    <PartnersBenefitsSectionContentContainer>*/}
            {/*        <PartnersBenefitsSectionContentTitle>*/}
            {/*            {translations.benefits.title}*/}
            {/*        </PartnersBenefitsSectionContentTitle>*/}
            {/*        <PartnersBenefitsSectionContentDescription>*/}
            {/*            {translations.benefits.description}*/}
            {/*        </PartnersBenefitsSectionContentDescription>*/}
            {/*    </PartnersBenefitsSectionContentContainer>*/}
            {/*    <PartnersBenefitsCardContainer>*/}
            {/*        {BENEFIT_DATA.map((benefit, index) => (*/}
            {/*            <PartnersBenefitsCard key={index}>*/}
            {/*                <benefit.icon />*/}
            {/*                <h1>{translations.benefits.cards[benefit.id].title}</h1>*/}
            {/*                <p>{translations.benefits.cards[benefit.id].description}</p>*/}
            {/*            </PartnersBenefitsCard>*/}
            {/*        ))}*/}
            {/*    </PartnersBenefitsCardContainer>*/}
            {/*</PartnersBenefitsSectionContainer>*/}

            {/* Partnerships Section */}
            <PartnersMeetOursSectionContainer>
                <PartnersMeetOursSectionContentContainer>
                    <PartnersMeetOursSectionContentTitle>
                        {translations.partnerships.title}
                    </PartnersMeetOursSectionContentTitle>
                    <PartnersMeetOursSectionContentDescription>
                        {translations.partnerships.description}
                    </PartnersMeetOursSectionContentDescription>
                </PartnersMeetOursSectionContentContainer>
                <PartnersMeetOursSectionGridContainer>
                    {PARTNERS_DATA.map((partner, index) => (
                        <PartnersMeetOursSectionGridItem
                            key={index}
                            onClick={() => window.open(partner.link)}
                        >
                            <div>
                                <PartnersMeetOursSectionGridItemImageContainer
                                    color={partner.color}
                                >
                                    <img
                                        src={partner.logo}
                                        alt={translations.partnerships.logoAlt(partner.name)}
                                    />
                                </PartnersMeetOursSectionGridItemImageContainer>
                                <PartnersMeetOursSectionGridItemTitle>
                                    {partner.name}
                                </PartnersMeetOursSectionGridItemTitle>
                                <PartnersMeetOursSectionGridItemDescription>
                                    {translations.partnerships.partners[partner.id].description}
                                </PartnersMeetOursSectionGridItemDescription>
                            </div>
                            <FaLongArrowAltRight />
                        </PartnersMeetOursSectionGridItem>
                    ))}
                </PartnersMeetOursSectionGridContainer>
            </PartnersMeetOursSectionContainer>

            {/* Current Users Section */}
            <CurrentUsersSectionContainer>
                <PartnersMeetOursSectionContentContainer>
                    <PartnersMeetOursSectionContentTitle>
                        {translations.currentUsers.title}
                    </PartnersMeetOursSectionContentTitle>
                    <PartnersMeetOursSectionContentDescription>
                        {translations.currentUsers.description}
                    </PartnersMeetOursSectionContentDescription>
                </PartnersMeetOursSectionContentContainer>
                <PartnersMeetOursSectionGridContainer>
                    {CURRENT_USERS_DATA.map((partner, index) => (
                        <PartnersMeetOursSectionGridItem
                            key={index}
                            onClick={() => window.open(partner.link)}
                        >
                            <div>
                                <PartnersMeetOursSectionGridItemImageContainer
                                    color={partner.color}
                                >
                                    <img
                                        src={partner.logo}
                                        alt={translations.currentUsers.logoAlt(partner.name)}
                                    />
                                </PartnersMeetOursSectionGridItemImageContainer>
                                <PartnersMeetOursSectionGridItemTitle>
                                    {partner.name}
                                </PartnersMeetOursSectionGridItemTitle>
                                <PartnersMeetOursSectionGridItemDescription>
                                    {translations.currentUsers.users[partner.id].description}
                                </PartnersMeetOursSectionGridItemDescription>
                            </div>
                            <FaLongArrowAltRight />
                        </PartnersMeetOursSectionGridItem>
                    ))}
                </PartnersMeetOursSectionGridContainer>
            </CurrentUsersSectionContainer>
                {/*</PageContainer>*/}
            </MainPageLayout>
        </>
    );
};

export default Partners;