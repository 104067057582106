
// External imports
import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";

// Internal imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import {
    ReviewPageContainer,
    ReviewPageHeadTitle,
    ReviewPageHeadTitleMain,
    ReviewPageHeadTitleParagraph,
    BackButton,
} from "./ReviewClassPage.styles";
import ReviewClassLeftSection from "./LeftSection/LeftSection";
import ReviewClassRightSection from "./RightSection/RightSection";
import { getJwt } from "../../../core/LocalStorage/AuthSession/core";

// Dependency injection imports
import { ReviewClassType } from "../../../core/ServerApiLayer/VOne/classes/reviewClass";

// language context
import { useLanguage} from "../../../core/translations/LanguageContext";

// translations
import { ReviewClassTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";


// Constants
const TRANSLATIONS: Record<SupportedLanguage, ReviewClassTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Props interface for the ReviewClassPage component with dependency injection
 */
export interface ReviewPageProps {
    reviewClassApiCall: ReviewClassType;
}

export const ReviewClassPage: React.FC<ReviewPageProps> = ({ reviewClassApiCall }) => {
    const { id } = useParams<{ id: string }>();
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const navigate = useNavigate();

    const [leftTableData, setLeftTableData] = useState([]);
    const [rightTableData, setRightTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [classDetails, setClassDetails] = useState<any>({});
    const [error, setError] = useState("");

    useEffect(() => {
        const jwt = getJwt();

        //@ts-ignore
        reviewClassApiCall(jwt, { class_id: parseInt(id) })
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setClassDetails(response.data.class_details);

                    const formattedLeftTableData = response.data.feedback.map((item: any) => ({
                        disease: item.disease_name,
                        students: item.students.toString(),
                        patients: item.completed_patients.toString(),
                        diagnosis: `${item.percent_correct_diagnosis.toFixed(2)}%`,
                        avg_score: `${item.mean_score.toFixed(1)}%`,
                        actions: item.actions
                            .map((action: ReviewPageRightTableRowProps) => ({
                                action: action.action,
                                percentage: action.percentage,
                            }))
                            .sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)), // Sort actions by percentage descending
                    }));

                    setLeftTableData(formattedLeftTableData);

                    if (formattedLeftTableData.length > 0) {
                        setRightTableData(formattedLeftTableData[0].actions);
                    }
                } else {
                    setError("Failed to load class review data.");
                }
            })
            .catch((err) => {
                setError("An error occurred while fetching class review data.");
                console.error("API call error:", err);
            });
    }, [reviewClassApiCall, id]);

    const handleRowSelection = (row: any) => {
        setSelectedRow(row);
        setRightTableData(row.actions);
    };

    const handleBack = () => {
        navigate('/teacher/view-classes');
    };

    const headerInfo = (
        <ReviewPageHeadTitleMain>
            <BackButton onClick={handleBack}>
                <FaChevronLeft size={20} />
                {translations.backButton}
            </BackButton>

        </ReviewPageHeadTitleMain>
    );

    return (
        <MainPageLayout>
            {headerInfo}
            <ReviewPageContainer>
                {error && <p className="error-message">{error}</p>}
                <ReviewClassLeftSection
                    tableData={leftTableData}
                    setSelectedRow={handleRowSelection}
                    classDetails={classDetails}
                />
                <ReviewClassRightSection
                    tableData={rightTableData}
                    selectedRow={selectedRow}
                />
            </ReviewPageContainer>
        </MainPageLayout>
    );
};