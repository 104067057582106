import { LoginPageTranslations } from "./types";

export const enTranslations: LoginPageTranslations = {
    heading:"Login",
    email: "Email",
    password: "Password",
    loginButton:"Login",
    or:"OR",
    registerButton:"Register",
    loginSuccess:"Login Successful!",
    securityCheck:"Performing Security Checks...",
    emailError:"Please fill in email",
    passwordError:"Please fill in password",
    redirectingTeacher:"Redirecting to teacher dashboard...",
    redirectingGame:"Redirecting to start game...",
}