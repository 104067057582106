import { BloodTestTranslations } from './types';

export const deBloodTestTranslations: BloodTestTranslations =  {
    groups: {
        FULL_BLOOD_COUNT: "Blutbild",
        LIVER_PROFILE: "Leber",
        RENAL_PROFILE: "Niere und Elektrolyte",
        INFLAMMATORY_MARKERS: "Entzündungswerte",
        BONE_PROFILE: "Knochenprofil",
        COAGULATION: "Gerinnung",
        ENDOCRINOLOGY: "Endokrinologie",
        HAEMOTINICS: "Blutbildung",
        CARDIAC_MARKERS: "Kardiale Marker",
        DIABETES: "Diabetes",
        MISC: "Weitere Bestimmungen",
        GASES: "BGA",
        LIPIDS: "Lipidstoffwechsel",
        VBG: "Venöse BGA",
        ABG: "Arterielle BGA"
    },
    types: {
        HAEMOGLOBIN: "Hämoglobin",
        HCT: "Hämatokrit",
        MCH: "MCH",
        MCHC: "MCHC",
        MCV: "MCV",
        PLATELETS: "Thrombozyten",
        TOTAL_WHITE_BLOOD_CELLS: "Leukozyten",
        LYMPHOCYTES: "Lymphozyten",
        BASOPHILS: "Basophile Granulozyten",
        EOSINOPHILS: "Eosinophile Granulozyten",
        MONOCYTES: "Monozyten",
        NEUTROPHILS: "Neutrophile Granulozyten",
        ALBUMIN: "Albumin",
        ALP: "Alkalische Phosphatase",
        ALT: "ALT / GPT",
        AST: "AST / GOT",
        BILIRUBIN: "Gesamt-Bilirubin",
        BILIRUBIN_CONJUGATED: "Konjugiertes Bilirubin",
        GGT: "γGT",
        TOTAL_PROTEIN: "Gesamt-Protein",
        SODIUM: "Natrium",
        POTASSIUM: "Kalium",
        CREATININE: "Kreatinin",
        UREA: "Harnstoff",
        EGFR: "eGFR",
        CRP: "CRP",
        PROCALCITONIN: "Procalcitonin",
        MAGNESIUM: "Magnesium",
        PHOSPHATE: "Phosphat",
        CALCIUM: "Calcium",
        APTT: "aPTT",
        FIBRINOGEN: "Fibrinogen",
        INR: "INR",
        PROTHROMBIN: "Quick",
        D_DIMER: "D-Dimere",
        FT3: "fT3",
        FT4: "fT4",
        TSH: "TSH",
        FERRITIN: "Ferritin",
        FOLIC_ACID: "Folsäure",
        IRON: "Eisen",
        TRANSFERRIN: "Transferrin",
        TRANSFERRIN_SATURATION: "Transferrinsättigung",
        VITAMIN_B12: "Vitamin B12",
        TROPONIN: "Troponin",
        NTPRO_BNP: "NT-proBNP",
        GLUCOSE: "Glukose",
        HBA1C: "HbA1c",
        CK: "CK",
        CKMB: "CKMB",
        ALCOHOL: "Serumalkohol",
        URIC_ACID: "Harnsäure",
        AMYLASE: "Amylase",
        LIPASE: "Lipase",
        LDH: "LDH",
        TRIGLYCERIDES: "Triglyceride",
        CHOLESTEROL: "Gesamtcholesterin",
        LDL: "LDL-Cholesterin",
        HDL: "HDL-Cholesterin",
        NON_HDL_CHOLESTEROL: "Non-HDL-Cholesterin"
    }
};