import axios, { AxiosResponse } from "axios";
import { ClassUrl } from "./AUrl";

/*
 Interface for the request payload.

@class_id: The id of the class to open or close.
@open: Whether to open or close the class.
 */
export interface OpenCloseClassInterface {
    class_id: number;
    open: boolean;
}

// Function Signature
export type OpenCloseClassType = (payload: OpenCloseClassInterface, jwt: string) => Promise<string>;

/*
The function to open or close a class.

@payload: The data needed to open or close a class.
@jwt: The JWT of the user opening or closing the class (can't be a student).
 */
export async function openCloseClass(payload: OpenCloseClassInterface, jwt: string): Promise<string> {
    const url = new ClassUrl().openClose;
    if (!jwt) {
        throw new Error("JWT has expired or is invalid");
    }

    let response: AxiosResponse<string>;
    try {
        response = await axios.post<string>(url, payload, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            }
        });

        // Check if the response status is OK
        if (response.status === 200) {
            return response.data; // The response is just a string
        } else {
            throw new Error(`Server responded with status code: ${response.status} and message: ${response.data}`);
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            // Handle Axios-specific error
            throw new Error(`Axios error: ${error.message}`);
        } else {
            // Handle generic errors
            throw new Error(`An error occurred: ${error instanceof Error ? error.message : String(error)}`);
        }
    }
}
