import styled from "@emotion/styled";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4em 0;
  gap:40px;
  @media (min-width: 1024px) {
    flex-direction: column;
    max-width: 1536px;
    padding:0 3rem;
    margin: auto;
}
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #173b5e;
  @media (min-width: 640px) {
    font-size:48px;
    line-height:48px;
    color:#002147;
    font-weight:700;
  }
`;

export const CardSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap:30px;
  @media (max-width: 1024px) {
    flex-direction:column;
  }
`;

export const CardArea = styled.div`
  width: 100%;
  display:flex;
  row-gap: 0.5rem;
  flex-direction: column;
  background-color:#fff;
  padding:20px;
  border-radius:10px;
`;

export const CardAreaDescription = styled.p`
    color:#374151;
    font-size:16px;
    line-height:24px;
    font-weight:400;
`;

export const CardSectionTitle = styled.h2`
`;

export const CardSectionAnchor = styled.a`
  color:#0058be;
  font-size:20px;
  text-decoration:none;
  &:hover {
      color: #002147;
      text-decoration:underline;
  }
  @media (max-width: 560px) {
    font-size:18px;
    line-height:1.2em;
  }
`;

export const CardSpan = styled.span`
  color:#1f2937;    
  background-color:#e5e7eb;
  padding:5px 8px;
  font-size:14px;
  font-weight:400;
  line-height:20px;
  width:fit-content;
  border-radius: 5px;
`;

export const CardSpanPublished = styled.span`
  color:#14532d;    
  background-color:#DCFCE7;
  padding:5px 8px;
  font-size:14px;
  font-weight:600;
  line-height:20px;
  width:fit-content;
  border-radius: 5px;
`;

export const DateCardSection = styled.p`
  color:#4b5563;
  font-size:16px;
  font-weight:400;
  line-height:20px;
  border-top: 1px solid #00000017;
  margin-top:auto;
  padding:10px 0;
`;

