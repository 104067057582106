import styled from "@emotion/styled";


export const RightSideFormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid lightgrey;
  padding: 20px;
  box-shadow: 5px 5px 5px #e6e6e6;
  border-radius: 15px;
  height: fit-content;
  font-family: "Helvetica", Arial, sans-serif;
`;

export const InputFieldArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const OrderBloodSubmitButton = styled.button`
  color: #fff;
  cursor: pointer;
  background-color: #334155;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
`;

export const TagContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  min-height: 35px;
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 5px;
`;

export const Tag = styled.div`
  background: #33415578;
  padding: 5px;
  display: flex;
  gap: 10px;
  font-size: 0.875rem;
  color: white;
  border-radius: 5px;
  justify-content: center;
  align-items: center;

  span {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;