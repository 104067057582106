import { HandoverModalTranslations } from "./types";

export const enHandoverTranslations: HandoverModalTranslations = {
    title: "Handover Notes",
    waitingText: "is currently in the waiting area.",
    pickDestination: "Pick a destination",
    selectPlaceholder: "Select...",
    moveText: "Move",
    noHandoverAvailable: "No handover available",
};
