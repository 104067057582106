// External imports
import React, { useEffect, useState } from "react";

// Internal imports
import PrimaryButton from "../../../../../components/core/button/WebsiteButton";
import PatientExamState from "../../state/PatientExamState";
import PatientExamCard from "../ExamCard/ExamCard";
import { PatientExamination } from "../../types/PatientExamTypes";
import {
    ExaminationFindingContent,
    ExaminationFindingHeading,
    FakeTextStyle,
    PatientExamCardContainer,
    PatientExamContainer,
    PatientExamContentContainer,
    FindingsFakeText,
    TextCenteredArea,
} from "./ExamContent.styles";
import { TurnManagementDependencies } from "../../../../../core/sharedDependencies/types";

/**
 * PatientExamContentProps
 *
 * This interface is used to define the props of the PatientExamContent component
 */
export interface PatientExamContentProps {
    patient: PatientExamination[];
    selectedCard: PatientExamination;
    gender?: "Male" | "Female";
    patientId: number;
    postExamApiCall: (jwt: string, payload: any) => Promise<any>; // Passed in from parent
    setExamLocalStorage: (patientId: number, examData: any) => void; // Passed in from parent
    getExamLocalStorage: (patientId: number) => any | null; // Passed in from parent
    onExaminedUpdate: () => void; // Callback to force re-render
    translations: any;
    examTranslations: any;
    turnDependencies: TurnManagementDependencies;
    getJwt: any;
}

/**
 * FakeText component
 *
 * A small utility to simulate placeholder text during loading states.
 */
function FakeText({ length }: { length: number }) {
    return (
        <FakeTextStyle
            style={{ width: length * 0.25 + "em", maxWidth: "100%" }}
        />
    );
}

/**
 * PatientExamContent component
 *
 * A component that displays the content of a patient examination. This shows the
 * examination card and the findings of the examination. If the examination has not
 * been examined, a button will be displayed to examine the patient.
 */
export const PatientExamContent: React.FC<PatientExamContentProps> = ({
    selectedCard,
    gender,
    patientId,
    postExamApiCall,
    setExamLocalStorage,
    getExamLocalStorage,
    onExaminedUpdate,
    translations,
    examTranslations,
    turnDependencies,
    getJwt,
}) => {
    // State to track if the card is examined
    const [isExamined, setIsExamined] = useState(false);
    const [findings, setFindings] = useState<string | null>(null); // Track findings
    const [loading, setLoading] = useState(true); // Track loading state

    // Clear the examined card state on page load
    useEffect(() => {
        PatientExamState.examinedCardIds = []; // Clear the examined card state on page load
    }, []);

    // Fetch the exam findings from localStorage or mark as unexamined
    useEffect(() => {
        setLoading(true); // Start loading on card switch
        const localExamData = getExamLocalStorage(patientId);

        if (localExamData && localExamData[selectedCard.id]) {
            // If the exam exists in localStorage and card id is in the examinedCardIds
            if (PatientExamState.isCardExamined(selectedCard)) {
                // If the card is marked as examined
                setFindings(localExamData[selectedCard.id]);
                setIsExamined(true);
            } else {
                // If the card is not marked as examined even though it exists in localStorage
                // This is because we want to force students to examine the patient again if
                // they need the findings again as they should have written it down the
                // first time, just like in real life.
                setFindings(localExamData[selectedCard.id]);
                setIsExamined(false);
            }
        } else {
            // No exam in localStorage, not examined yet
            setFindings(null);
            setIsExamined(false);
        }

        setLoading(false); // Stop loading after fetching local data
    }, [selectedCard, patientId, getExamLocalStorage]);

    // Handle click event to examine the patient
    const examineCard = async () => {
        setLoading(true); // Set loading to true while fetching data

        // Load from localstroage if available
        const localExamData = getExamLocalStorage(patientId);
        if (localExamData && localExamData[selectedCard.id]) {
            setFindings(localExamData[selectedCard.id]);
            setLoading(false);
            setIsExamined(true); // Mark as examined
            PatientExamState.setCardExamined(selectedCard); // Update the global state
            turnDependencies.updateTurns(selectedCard.turns); // Update the turn count
            onExaminedUpdate(); // Trigger a re-render of the sidebar
            return;
        } else {
            // else do the API call
            const jwt = getJwt();
            const payload = {
                patient_id: patientId,
                turn: 10,
                exam: selectedCard.id,
            };

            // Make the API call to retrieve findings
            //@ts-ignore
            const response = await postExamApiCall(jwt, payload);
            if (response.status === 201) {
                // Save findings to local storage
                setExamLocalStorage(patientId, response.data);

                // Check that it has been updated in local storage by getting it
                const localExamData = getExamLocalStorage(patientId);
                if (localExamData && localExamData[selectedCard.id]) {
                    setFindings(localExamData[selectedCard.id]);
                    setLoading(false);
                }

                setIsExamined(true); // Mark as examined
                PatientExamState.setCardExamined(selectedCard); // Update the global state

                turnDependencies.updateTurns(selectedCard.turns); // Update the turn count
                onExaminedUpdate(); // Trigger a re-render of the sidebar
            }
        }
    };

    return (
        <PatientExamContainer>
            <PatientExamCardContainer>
                <PatientExamCard
                    examination={selectedCard}
                    gender={gender}
                    isSidebar={false}
                    translations={examTranslations}
                />
                <ExaminationFindingHeading>
                    {translations.contents.findings}
                </ExaminationFindingHeading>

                {/* Display loading state or the appropriate content */}
                {loading ? (
                    <TextCenteredArea>
                        {translations.contents.loadingFindings}
                    </TextCenteredArea>
                ) : isExamined ? (
                    <ExaminationFindingContent>
                        {findings || translations.contents.noFindingsAvailable}
                    </ExaminationFindingContent>
                ) : (
                    <FindingsFakeText>
                        <FakeText length={100} />
                        <FakeText length={90} />
                        <FakeText length={140} />
                        <FakeText length={110} />
                        <FakeText length={100} />

                        <PatientExamContentContainer>
                            <PrimaryButton onClick={examineCard}>
                                {translations.contents.examinePatient}
                            </PrimaryButton>
                        </PatientExamContentContainer>
                    </FindingsFakeText>
                )}
            </PatientExamCardContainer>
        </PatientExamContainer>
    );
};
