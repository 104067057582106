// Defines the user routes
import React from 'react';

// Pages
import {NewClassPage} from "./createClass/NewClassPage";
import {TeacherFrontPage} from "./frontPage/FrontPage";
import {ViewClassesPage} from "./viewClasses/ViewClasses";
import { ReviewClassPage } from './reviewClass/ReviewClassPage';

// Dependency injection for the create class page
import { createClass } from "../../core/ServerApiLayer/VOne/classes/create";
import { getSimpleJwt } from "../../core/LocalStorage/AuthSession/core";

// Dependency injection for the View Classes page
import { getAllClasses } from "../../core/ServerApiLayer/VOne/classes/getAll";
import { openCloseClass } from "../../core/ServerApiLayer/VOne/classes/openClose";

// Dependency injection for the Review Class page
import { reviewClass } from "../../core/ServerApiLayer/VOne/classes/reviewClass";



export const TeacherRoutes = [
    {
        path: "/teacher/new-class",
        element: <NewClassPage
            createApi={createClass}
        />,
    },
    {
        path: "/teacher/view-classes",
        element: <ViewClassesPage
            getClassesApiCall={getAllClasses}
            lockClassApiCall={openCloseClass}
        />
    },
    {
        path: "/teacher/review-class/:id",
        element: <ReviewClassPage
            reviewClassApiCall={reviewClass}
            openCloseClassApiCall={openCloseClass}
        />
    },
    {
        path: "/home",
        element: <TeacherFrontPage/>,
    },
    {
        path: "*",
        element: <h1>404</h1>,
    },
]
