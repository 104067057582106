
import styled from '@emotion/styled';

// Styled components
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const TurnsText = styled.span`
  font-weight: 500;
  font-size: 0.94em;
  padding-right: 0.75em;
  color: #dceef9;
`;

export const TurnsCount = styled.div`
  padding: 0.13em 0.75em;
  font-size: 1em;
  font-weight: 700;
  display: block;
  border-radius: 0.375em;
  color: white;
  font-family: 'Digital Numbers', sans-serif;
                                                
`;