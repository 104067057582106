import { DischargeFormTranslations } from "./types";

export const deTranslations: DischargeFormTranslations = {
    title: "Präsentation",
    formType: "Formulartyp",
    admissionButton: "Zulassung",
    dischargeButton: "Entlassung",
    admission: "Ziel der Aufnahme",
    selectPlaceholder: "Auswählen...",
    admissionOptions: [
        {
            value: "Ward",
            label: "Station",
        },
        {
            value: "HDU",
            label: "Überwachungsstation",
        },
        {
            value: "ITU",
            label: "Intensivstation",
        },
    ],
    admitting: "Aufnahmeteam",
    admittingOptions: [
        {
            label: "Pulmonologie",
            value: "RESPIRATORY",
        },
        {
            label: "Urologie",
            value: "UROLOGY",
        },
        {
            label: "Kardiologie",
            value: "CARDIOLOGY",
        },
        {
            label: "Gastroenterologie",
            value: "GASTROENTEROLOGY",
        },
        {
            label: "Gynäkologie und Geburtshilfe",
            value: "OBSTETRICS",
        },
        {
            label: "Orthopädie",
            value: "ORTHO",
        },
    ],
    presentation: "Präsentation",
    presentationTip:
        "Stellen Sie sicher, dass alle roten Flaggen oder besorgniserregenden Merkmale enthalten sind",
    action: "Ergriffene Maßnahme",
    follow: "Folge",
    clearButton: "Löschen",
    submitButton: "Absenden",
    titleDiagnosis: "Bitte geben Sie zuerst eine Diagnose an:",
    selectPlaceholderDiagnosis: "Auswählen...",
    diagnosisButton: "Diagnose speichern",
};
