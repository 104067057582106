import { LoginPageTranslations } from "./types";

export const deTranslations: LoginPageTranslations = {
    heading: "Anmelden",
    email: "E-Mail",
    password: "Passwort",
    loginButton: "Anmelden",
    or: "ODER",
    registerButton: "Registrieren",
    loginSuccess: "Erfolgreich angemeldet!",
    securityCheck: "Führe Sicherheitsüberprüfungen durch...",
    emailError: "Bitte E-Mail ausfüllen",
    passwordError: "Bitte Passwort ausfüllen",
    redirectingTeacher: "Weiterleitung zum Lehrer-Dashboard...",
    redirectingGame: "Weiterleitung zum Spielstart...",
};
