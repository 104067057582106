import { DrugPageTranslations } from "./types";

export const deTranslations: DrugPageTranslations = {
    title: "Medikamente",
    updateAllergies: "Allergien aktualisieren",
    doPatientHasAllergies: "Hat der Patient Allergien?",
    yes: "Ja",
    no: "Nein",
    addAllergy: "Allergie hinzufügen (durch Komma getrennt)",
    submitAllergies: "Allergien übermitteln",
    drugType: "Medikamententyp",
    selectOrType: "Auswählen oder eingeben",
    prescribe: "Verschreiben",
    time: "Zeit",
    drugName: "Medikamentenname",
    route: "Weg",
    dose: "Dosis",
};
