import { getSpecificPatientData, updatePatientData} from "./core";
import { formsData, formEntryData } from "./interface";


// Function signature type for setting forms to local storage


/*
Set the forms data for a patient in localStorage.
 */
export type SetFormsToLocalStorageType = (patient_id: number, newFormsData: formEntryData, turn: number) => void;


export function setFormsToLocalStorage(patient_id: number, newFormsData: formEntryData, turn: number): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has forms data, initialize if none
    let existingForms = patientData.forms || {};

    let stringTurn = turn.toString();

    // Add or update the specific form type
    existingForms[stringTurn] = newFormsData;

    // Update the patient's forms data
    patientData.forms = existingForms;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}


// Function signature type for getting the latest form data from local storage
export type GetLatestFormDataFromLocalStorageType = (patient_id: number) => formEntryData | null;


// Function to get the latest form data for a patient from localStorage
export function getLatestFormDataFromLocalStorage(patient_id: number): formEntryData | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // Get the forms data, if null return null
    let allForms = patientData?.forms || null;
    if (!allForms) {
        return null;
    }

    // return the latest form data
    let keys = Object.keys(allForms);
    let latestKey = Math.max(...keys.map(Number));

    return allForms[latestKey.toString()] || null;
}