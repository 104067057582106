// External imports
import React, { useState } from "react";
import { AiOutlineLineChart } from "react-icons/ai";

// Internal imports
import {Modal} from "../../../../../../components/core/modal/Modal";
import {
    OrderAdditionalTestModalContainer,
    OrderAdditionalTestModalContentContainer,
    OrderAdditionalTestModalForm,
    OrderAdditionalTestModalContentLabel,
    OrderAdditionalTestModalFormTitle,
    OrderAdditionalTestModalContentDescription,
    OrderAdditionalTestModalContentWrapper,
    OrderAdditionalTestModalFormFooter,
    OrderAdditionalTestModalFlexContainer,
} from "./OrderAdditionalTestReport.styles";
import { MdOutlineClose } from "react-icons/md";
import TurnTablet from "../../../../notePage/components/TurnTablet/TurnTablet";
import TextArea from "../../../../../../components/core/input-form/TextArea";
import WebsiteButton from "../../../../../../components/core/button/WebsiteButton";
import IconActionButton from "../../../../../../components/core/icon-action-button/IconActionButton";

/**
 * OrderAdditionalTestReportProps
 * 
 * This interface is for the props of the OrderAdditionalTestReport component.
 */
interface OrderAdditionalTestReportProps {
    title: string;
    details: string;
    notes?: string;
    allowNotes?: boolean;
    turn_ordered: number;
    onClose: () => void;
    reason?: string;
    summary?: string;
}

/**
* OrderAdditionalTestReportModal
* 
* This component is a modal for the investigation report. This is important because it allows
* the user to view a report or image, and add notes to the report. The notes end up in the 
* documentation. 
 */
export const OrderAdditionalTestReportModal = ({
    title,
    details,
    allowNotes,
    notes,
    turn_ordered,
    onClose,
    reason,
    summary,
}: OrderAdditionalTestReportProps) => {
    const [note, setNote] = useState(notes ?? "");
    const [isReportPreviewOpen, setIsReportPreviewOpen] = useState(false);

    return (
        <>
            <Modal onRequestClose={onClose}>
                <OrderAdditionalTestModalContainer>
                    <MdOutlineClose onClick={onClose} />
                    <OrderAdditionalTestModalFormTitle>
                        Report for {title}<TurnTablet turn={turn_ordered} />
                    </OrderAdditionalTestModalFormTitle>
                    <OrderAdditionalTestModalContentContainer>
                        <OrderAdditionalTestModalForm
                            onSubmit={(e: React.ChangeEvent<HTMLFormElement>) =>
                                e.preventDefault()
                            }
                        >
                            <OrderAdditionalTestModalContentWrapper>
                                <OrderAdditionalTestModalContentLabel>
                                    Summary on Referral:
                                </OrderAdditionalTestModalContentLabel>
                                <OrderAdditionalTestModalContentDescription>
                                    {summary || "No summary provided"}
                                </OrderAdditionalTestModalContentDescription>
                            </OrderAdditionalTestModalContentWrapper>
                            <OrderAdditionalTestModalContentWrapper>
                                <OrderAdditionalTestModalContentLabel>
                                    Reason for Professional Review:
                                </OrderAdditionalTestModalContentLabel>
                                <OrderAdditionalTestModalContentDescription>
                                    {reason || "No reason provided"}
                                </OrderAdditionalTestModalContentDescription>
                            </OrderAdditionalTestModalContentWrapper>
                            <OrderAdditionalTestModalContentWrapper>
                                <OrderAdditionalTestModalFlexContainer>
                                    <OrderAdditionalTestModalContentLabel>
                                        {"Report:"}
                                    </OrderAdditionalTestModalContentLabel>
                                    <OrderAdditionalTestModalContentDescription>
                                        {details || "No findings available"}
                                    </OrderAdditionalTestModalContentDescription>
                                </OrderAdditionalTestModalFlexContainer>
                            </OrderAdditionalTestModalContentWrapper>

                            <OrderAdditionalTestModalContentWrapper>
                                <OrderAdditionalTestModalContentLabel>
                                    Notes:
                                </OrderAdditionalTestModalContentLabel>

                                {allowNotes ? (
                                    notes ? (
                                        <OrderAdditionalTestModalContentDescription>
                                            {notes}
                                        </OrderAdditionalTestModalContentDescription>
                                    ) : (
                                        <TextArea
                                            autoFocus
                                            rows={6}
                                            placeholder="Add any notes here..."
                                            value={note}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => setNote(e.target.value)}
                                        />
                                    )
                                ) : (
                                    <OrderAdditionalTestModalContentDescription>
                                        No notes available
                                    </OrderAdditionalTestModalContentDescription>
                                )}
                            </OrderAdditionalTestModalContentWrapper>
                            <OrderAdditionalTestModalFormFooter>
                                <WebsiteButton type="submit" tag="button">
                                    {note.length > 0 ? "Submit" : "Reviewed"}
                                </WebsiteButton>
                            </OrderAdditionalTestModalFormFooter>
                        </OrderAdditionalTestModalForm>
                    </OrderAdditionalTestModalContentContainer>
                </OrderAdditionalTestModalContainer>
            </Modal>
            )
        </>
    );
}

export default OrderAdditionalTestReportModal;
