import React, { useState } from "react";
import { useParams, useNavigate } from 'react-router-dom'; //
import { ConfirmUserType } from '../../../core/ServerApiLayer/VOne/auth/confirm';
// @ts-ignore
import { LoginPage } from '../login/LoginPage'
// @ts-ignore
import myImage from "../../../assets/outside.jpg"
import logoImage from "../../../assets/clinical_metrics.png"
import { Button, DIV, H1, Image, LeftPanel, Mainbox, Paragraph, RightPanel, TextAndButtonSection } from "./ConfirmPage.styles";




/*
The props for the ConfirmPage component.

@prop confirmUserApiCall: a function that confirms a users account.
 */
export interface ConfirmPageProps {
    confirmUserApiCall: ConfirmUserType;
}

/**
 * Confirm Page Component
 * 
 * This is the confirm page for the user. This is shown at the /verify/uuid route, where
 * the uuid is the unique identifier for the user.
 * 
 */
export const ConfirmPage: React.FC<ConfirmPageProps> = ({confirmUserApiCall}) => {
    const { uuid } = useParams<{ uuid: string }>();

    const [message, setMessage] = useState(""); // State to store the confirmation message or error
    const navigate = useNavigate();

    // Handler for the button click event
    const handleConfirmation = async () => {
        if (uuid) {
            const response = await confirmUserApiCall(uuid);
            if (response.status === 200) {
                // User confirmed successfully
                setTimeout(() => navigate("/login"), 1000);
            } else {
                // Error occurred during confirmation
                setMessage(response.message);
            }
        } else {
            setMessage("Invalid verification link.");
        }
    };

  return (
   <DIV>
    <DIV>
      <Mainbox>
        <LeftPanel>
            <TextAndButtonSection>
              <Image src={logoImage} alt="description" />
                <H1>Verify your account</H1>
                <Paragraph id="confirmationMessage">{message || "Please confirm your email address."}</Paragraph>
                <Button id="confirmEmailButton" onClick={handleConfirmation} type="button">Confirm Email</Button>
            </TextAndButtonSection>
        </LeftPanel>
        
        <RightPanel>
            <Image src={myImage} alt="description" />
         </RightPanel>
        </Mainbox>
      </DIV>
    </DIV>
  );
};

export default ConfirmPage;

