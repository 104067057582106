// External Imports
import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

// Internal Imports
// @ts-ignore
import { formEntryData } from "../../../../../core/LocalStorage/patients/interface";
import { getLatestFormDataFromLocalStorage } from "../../../../../core/LocalStorage/patients/forms";
import { SupportedLanguage } from '../../../../../core/translations/supportedLanguages';
import { DischargeFormTranslations } from '../../translations/types';
import { enTranslations } from '../../translations/en';
import { deTranslations } from '../../translations/de';
import { useLanguage } from '../../../../../core/translations/LanguageContext';
import { ButtonArea, ClearButton, FormContainer, FormField, InfoLabel, InputFieldArea, MainForm, SubmitButton, Textarea } from './FormComponent.styles';

/**
 * The props for the DischargeForm component.
 * @onSubmit: A function to handle the form submission (passed in from the parent).
 * @initialFormData: The initial state of the form (passed in from the parent).
 */
export interface DischargeFormProps {
    onSubmit: (formData: formEntryData) => void;
    setFormsToLocalStorage: (patientId: number, formData: formEntryData, turn: number) => void;
    getLatestFormFromLocalStorage: (patientId: number) => formEntryData | null;
    patientId: number;
    turn: number;
}

// Constants
const TRANSLATIONS: Record<SupportedLanguage, DischargeFormTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * The DischargeForm component.
 */
export const DischargeForm: React.FC<DischargeFormProps> = ({
                                                                onSubmit,
                                                                patientId,
                                                                turn,
                                                                setFormsToLocalStorage,
                                                                getLatestFormFromLocalStorage,
                                                            }) => {
    // Hook for the state of the form inputs and the state of the form submission
    const [formData, setFormData] = useState<formEntryData>({
        form_type: "DISCHARGE",
        discharge_destination: "HOME_DISCHARGE",  // Hardcoded as HOME_DISCHARGE
        severity: "Low",  // Hardcoded as Low for now
        patient_details: "",
        actions: "",
        situation: "",
    });

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    const [validationSuccess, setValidationSuccess] = useState<boolean>(false);

    // Load form data from localStorage when the component mounts
    useEffect(() => {
        const storedFormData = getLatestFormDataFromLocalStorage(patientId);
        if (storedFormData) {
            setFormData(storedFormData);
        }
    }, [getLatestFormDataFromLocalStorage, patientId]);

    // Handle the change of form inputs (text areas)
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);

        // Persist the updated form data to localStorage
        setFormsToLocalStorage(patientId, updatedFormData, turn);

        // Handle validation to enable or disable the submit button
        handleCharacterLimit(updatedFormData);
    };

    // Validation to enable or disable the submit button
    const handleCharacterLimit = (updatedFormData: formEntryData) => {
        const charLimit = 10;
        if (
            updatedFormData.patient_details.length >= charLimit &&
            updatedFormData.actions.length >= charLimit &&
            updatedFormData.situation.length >= charLimit
        ) {
            setValidationSuccess(true);
        } else {
            setValidationSuccess(false);
        }
    };

    // Handle the submission of the form
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);

        // Clear the form and remove it from localStorage after successful submission
        setFormData({
            form_type: "DISCHARGE",
            discharge_destination: "HOME_DISCHARGE",  // Reset to HOME_DISCHARGE
            severity: "Low",  // Reset to Low
            patient_details: "",
            actions: "",
            situation: ""
        });

        setValidationSuccess(false);

        // Optionally, clear the specific form entry from localStorage after submission
        setFormsToLocalStorage(patientId, {
            form_type: "DISCHARGE",
            discharge_destination: "HOME_DISCHARGE",
            severity: "Low",
            patient_details: "",
            actions: "",
            situation: ""
        }, turn);
    };

    // Handle the reset of the form
    const handleReset = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormData({
            form_type: "DISCHARGE",
            discharge_destination: "HOME_DISCHARGE",
            severity: "Low",
            patient_details: "",
            actions: "",
            situation: ""
        });
        setValidationSuccess(false);
    };

    return (
        <MainForm onReset={handleReset} onSubmit={handleSubmit}>
            <FormContainer>
                <InputFieldArea>
                    <label>
                        {translations.presentation}
                        <InfoLabel>
                            <FaInfoCircle size={12} />
                            {translations.presentationTip}
                        </InfoLabel>
                    </label>
                    <Textarea
                        rows={3}
                        name="patient_details"
                        value={formData.patient_details}
                        onChange={handleChange}
                    />
                </InputFieldArea>
                <InputFieldArea>
                    <label>{translations.action}</label>
                    <Textarea
                        rows={3}
                        name="actions"
                        value={formData.actions}
                        onChange={handleChange}
                    />
                </InputFieldArea>
                <InputFieldArea>
                    <label>{translations.follow}</label>
                    <Textarea
                        rows={3}
                        name="situation"
                        value={formData.situation}
                        onChange={handleChange}
                    />
                </InputFieldArea>
            </FormContainer>
            <ButtonArea>
                <ClearButton type="reset">{translations.clearButton}</ClearButton>
                <SubmitButton type="submit" disabled={!validationSuccess}>{translations.submitButton}</SubmitButton>
            </ButtonArea>
        </MainForm>
    );
};
