import { PartnersTranslations } from './types';

export const deTranslations: PartnersTranslations = {
    banner: {
        label: "Partnerschaften",
        title: "Neue Wege in der medizinischen Ausbildung mit Clinical Metrics",
        subtitle: "Helfen Sie uns dabei, die Ausbildung von Medizinstudenten im Umgang mit komplexen Notfallsituationen zu verbessern.",
        buttonText: "Partner werden",
        demoText: "Interesse an einer Partnerschaft? Buchen Sie eine Demo über den obigen Link.",
        imageAlt: "Medizinische Ausbildungspartnerschaft Illustration"
    },
    benefits: {
        title: "Vorteile",
        description: "Unsere Partner bieten eine Reihe von Werkzeugen, Dienstleistungen und Lösungen, um Teams zu unterstützen.",
        cards: {
            productivity: {
                title: "Steigern Sie Ihre Produktivität",
                description: "Erreichen Sie mehr mit weniger Ablenkung, indem Sie die täglich genutzten Tools Ihrer Teams integrieren."
            },
            efficiency: {
                title: "Verbessern Sie Ihre Effizienz",
                description: "Nutzen Sie die Fähigkeiten unserer Partner, um bessere Wege der Zusammenarbeit zu etablieren."
            },
            technology: {
                title: "Erweitern Sie Ihre technische Investition",
                description: "Verbinden Sie sich einfach mit Ihrer bestehenden IT-Infrastruktur durch unsere offene Plattform."
            }
        }
    },
    partnerships: {
        title: "Partnerschaften mit Clinical Metrics",
        description: "Clinical Metrics arbeitet mit führenden Organisationen zusammen, um innovative Lösungen für die medizinische Ausbildung anzubieten. Gemeinsam verbessern wir die Erfahrung im Umgang mit komplexen Erkrankungen in Notaufnahmen.",
        logoAlt: (name) => `${name} Logo`,
        partners: {
            bonn: {
                description: "Führende Forschungseinrichtung mit Fokus auf Innovation und Exzellenz in der Bildung."
            },
            goettingen: {
                description: "Renommierte Forschungsuniversität, bekannt für interdisziplinäre Zusammenarbeit und zukunftsweisende Forschung."
            },
            dfn: {
                description: "DFN ist das Deutsche Forschungsnetz, das Hochleistungsnetzwerkdienste für Forschung und Bildung bereitstellt."
            }
        }
    },
    currentUsers: {
        title: "Aktuelle Nutzer",
        description: "Wir sind stolz darauf, dass verschiedene Partner unsere Plattform nutzen, um die medizinische Ausbildung zu verbessern. Von renommierten medizinischen Einrichtungen bis hin zu innovativen Gesundheitsorganisationen - unsere Partner bewirken etwas in der Medizin.",
        logoAlt: (name) => `${name} Logo`,
        users: {
            bonn: {
                description: "Führende Forschungseinrichtung mit Fokus auf Innovation und Exzellenz in der Bildung."
            },
            goettingen: {
                description: "Renommierte Forschungsuniversität, bekannt für interdisziplinäre Zusammenarbeit und zukunftsweisende Forschung."
            },
            berlin: {
                description: "Die Universität Berlin ist eine prestigeträchtige Institution, bekannt für ihre reiche Geschichte und akademische Exzellenz. Mit vielfältigen Programmen und modernster Forschung bietet sie ein inspirierendes Umfeld."
            }
        }
    }
};