// External imports
import React from 'react';

// Internal imports
import {
    ReviewPageRightSection,
    ReviewPageFilterSection,
    ReviewPageRightSectionTitle,
    ReviewPageRightSectionHoldingText,
    ReviewPageParagraph
} from "./RightSection.styles";
import ReviewPageRightTableComponent from './TableComponent/TableComponent';
import { ReviewPageLeftTableRowProps, ReviewPageRightTableRowProps, FilterOptions } from '../ReviewTablesStaticData';

// Language Context
import { useLanguage } from "../../../../core/translations/LanguageContext";

// translations
import { RightSectionTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from "../../../../core/translations/supportedLanguages";

/**
 * CONSTANTS for translations
 */
const TRANSLATIONS: Record<SupportedLanguage, RightSectionTranslations> = {
    en: enTranslations,
    de: deTranslations,
};


/**
 * Props for ReviewClassRightSection component
 */
export interface ReviewClassRightSectionProps {
    tableData: Array<ReviewPageRightTableRowProps>,
    selectedRow?: ReviewPageLeftTableRowProps
}

/**
 * Right section of the ReviewClass page
 */
const ReviewClassRightSection: React.FC<ReviewClassRightSectionProps> = ({
    tableData,
    selectedRow
}) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    return (
        <ReviewPageRightSection>
            {selectedRow?.disease ?
                <>
                    <ReviewPageRightSectionTitle>{selectedRow?.disease}</ReviewPageRightSectionTitle>
                    <ReviewPageRightTableComponent tableData={tableData} />
                </> :
                <ReviewPageParagraph>{translations.selectStatement}</ReviewPageParagraph>
            }
        </ReviewPageRightSection>
    );
}

export default ReviewClassRightSection;
