import styled from "@emotion/styled";

export const ExamSidebarSectionStyle = styled.div`
    display: flex;
    padding: 1rem;
    flex-direction: column;
    font-family: "Helvetica", Arial, sans-serif;
`;

export const ExamSidebarSectionHeading = styled.h2`
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    padding: 0.5rem 0.75rem;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
    color: #000000;
    background-color: #f9fafb;
    z-index: 500;
    font-size: 18px;
`;
