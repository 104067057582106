import styled from "@emotion/styled";

export const ReviewPageHeadTitleMain = styled.div`
    align-items: center;
    display: flex;
    gap: 10px;
    width: 50%;
    justify-content: space-between;
    @media (min-width: 1380px) {
        width: 35%;
      }
`;
export const ReviewPageSwitchSection = styled.div`
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
`;
export const ReviewPageHeadTitle = styled.span`
    background: #0084c7;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: medium;
`;
export const ReviewPageHeadTitleParagraph = styled.p`
    display: flex;
    gap: 5px;
    align-items: center;
    text-align: left;
`;
export const ReviewPageSwitchLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    & > input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
`;
export const ReviewPageRoundedSlide = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
      }
`;
export const ReviewPageSwitchInput = styled.input`
    &:checked + span{
        background-color: #2196F3;
    }
    &:focus + span {
        box-shadow: 0 0 1px #2196F3;
    }
    &:checked + span:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
`;

export const ReviewPageContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width:767px) {
        flex-direction: column
      }
`;

export const ReviewPageListingLabels = styled.p`
    font-family: Inter, sans-serif;
    font-weight: 600;
`;

export const BackButton = styled.button`
    display: flex;
    align-items: center;
    gap: 5px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    font-size: 16px;
    padding: 10px;
    &:hover {
        color: #333;
    }
`;
