import { SymptomsTranslations } from "./types";

export const deSymptomsTranslations:  SymptomsTranslations = {
    ABDOMINAL_PAIN: "Bauchschmerzen",
    ABNORMAL_SPUTUM: "Ungewöhnlicher Auswurf",
    ACOUSTIC_BLACKOUT: "Hörverlust",
    ANKLE_SWELLING: "Geschwollene Knöchel",
    ANXIETY: "Angstzustände",
    ARM_PAIN: "Armschmerzen",
    BACK_PAIN: "Rückenschmerzen",
    BREATHLESSNESS: "Atemnot",
    CHEST_PAIN: "Brustschmerzen",
    COLD_LEG: "Kaltes Bein",
    CONFUSION: "Verwirrtheit",
    CONSTIPATION: "Verstopfung",
    COUGH: "Husten",
    DARK_URINE: "Dunkler Urin",
    DIARRHOEA: "Durchfall",
    DOUBLE_IMAGES: "Doppelbilder",
    DYSPHAGIA: "Schluckbeschwerden",
    DYSURIA: "Schmerzhaftes Wasserlassen",
    EPISTAXIS: "Nasenbluten",
    EXANTHEMA: "Hautausschlag",
    FACIAL_PARESIS: "Gesichtslähmung",
    FATIGUE: "Müdigkeit",
    FEVER: "Fieber",
    FLANK_PAIN: "Flankenschmerzen",
    FOCAL_DEFICITS: "Körperliche Schwäche",
    FOCAL_WEAKNESS_ARM: "Armschwäche",
    FOCAL_WEAKNESS_FACE: "Gesichtsschwäche",
    FOCAL_WEAKNESS_LEG: "Beinschwäche",
    GAZE_PREFERENCES: "Blickstörungen",
    HAEMATURIA: "Blut im Urin",
    HAEMOPTYSIS: "Bluthusten",
    HAIR_LOSS: "Haarausfall",
    HEAD_CONGESTION: "Kopfstauung",
    HEADACHE: "Kopfschmerzen",
    HEARTBURN: "Sodbrennen",
    HEMATOCHEZIA: "Blutiger Stuhl",
    HEMIPARESIS: "Halbseitenlähmung",
    HOARSE_VOICE: "Heiserkeit",
    INSOMNIA: "Schlafstörungen",
    JAUNDICE: "Gelbsucht",
    JAW_PAIN: "Kieferschmerzen",
    LEG_PAIN: "Beinschmerzen",
    LOSS_OF_APPETITE: "Appetitlosigkeit",
    LOSS_OF_CONSCIOUSNESS: "Bewusstlosigkeit",
    MELAENA: "Schwarzer Stuhl",
    MEMORY_IMPAIRMENTS: "Gedächtnisstörungen",
    MUMBLING: "Undeutliches Sprechen",
    MYALGIA: "Muskelschmerzen",
    MYDRIASIS: "Erweiterte Pupillen",
    MYOPATHY: "Muskelschwäche",
    NAUSEA: "Übelkeit",
    NECK_PAIN: "Nackenschmerzen",
    NECK_STIFFNESS: "Nackenstarre",
    NYKTURIA: "Nächtliches Wasserlassen",
    OSCILLOPSY: "Sehstörungen",
    OVERFLOW_INCONTINENCE: "Überlaufinkontinenz",
    PALE_STOOL: "Heller Stuhl",
    PALENESS_OF_THE_LEG: "Blasses Bein",
    PALPITATIONS: "Herzrasen",
    PARAESTHESIA: "Kribbeln",
    PHONOPHOBIA: "Geräuschempfindlichkeit",
    PHOTOPHOBIA: "Lichtempfindlichkeit",
    PLUGGED_NOSE: "Verstopfte Nase",
    PRESYNCOPE: "Beinahe-Ohnmacht",
    POLYURIA: "Häufiges Wasserlassen",
    PRURITUS: "Juckreiz",
    REDUCED_EXERCISE_TOLERANCE: "Belastungsschwäche",
    REGURGITATION: "Aufstoßen",
    RESTLESSNESS: "Unruhe",
    RUNNY_NOSE: "Laufende Nase",
    SENSORY_AURA: "Sinnesstörungen",
    SENSORY_HEMIDEFICIT: "Gefühlsstörungen",
    SHAKING_CHILLS: "Schüttelfrost",
    SHOULDER_PAIN_LEFT: "Linksseitige Schulterschmerzen",
    SNEEZING: "Niesen",
    SORE_THROAT: "Halsschmerzen",
    SPEECH_DISTURBANCES: "Sprachstörungen",
    STRESS: "Stress",
    SWEATING: "Schwitzen",
    ULCUS_CRURIS_ARTERIOSUM: "Beingeschwür",
    URINARY_RETENTION: "Harnverhalt",
    VAGINAL_BLEEDING: "Scheidenblutung",
    VERTIGO: "Schwindel",
    VISUAL_AURA: "Sehstörungen",
    VISUAL_IMPAIRMENT: "Sehschwäche",
    VOMITING: "Erbrechen",
    WEIGHT_GAIN: "Gewichtszunahme",
    WEIGHT_LOSS: "Gewichtsverlust",
    WHEEZING: "Atemgeräusche",
    WORD_FINDING_DISORDER: "Wortfindungsstörungen",
    KNEE_PAIN_IN_OA: "Knieschmerzen",
    MORNINGSTIFFNESS: "Morgendliche Steifheit",
    REDUCED_KNEE_MOTION: "Eingeschränktes Knie",
    REDUCED_STEP_LENGTH: "Gangstörung",
    LL_MUSCLE_WEAKNESS: "Beinschwäche",
    HIP_AND_GROIN_PAIN: "Hüftschmerzen",
    REDUCED_HIP_MOVEMENT_OA: "Eingeschränkte Hüfte",
    LEG_EXTERNALLY_ROTATED: "Auswärtsgedrehtes Bein",
}