import { ChatPageTranslations } from "./types";

export const enTranslations: ChatPageTranslations = {
    title: "Chat",
    loader: {
        firstMessage: "Preparing your medical chat history...!",
        secondMessage: "Optimising scenario. Please wait...",
        thirdMessage: "Loading real-time data ...",
        fourthMessage: "It is almost ready...",
        completing: "Completing....",
        completed: "Completed",
    },
    form: {
        placeHolder: "Type a message...",
        submitButton: "Send",
    },
};
