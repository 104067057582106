// External imports
import axios, { AxiosResponse } from "axios";

// Internal imports
import { ClassesUrl } from "./AUrl";
import { ApiResponse } from "../interfaces";
import { ApiPatientData } from "./start";

/*
The payload for getting new patients.

@session_id: The id of the session to get patients for.
@patients: The number of patients to get.
@language: The language.
*/
export interface GetNewPatientPayload {
    session_id: number;
    patients: number;
    language: string;
    turn: number;
}

/*
The response containing the new patients data
*/
export interface NewPatientsResponse {
    patients: ApiPatientData[];
}

export type GetNewPatientType = (
    jwt: String,
    payload: GetNewPatientPayload
) => Promise<ApiResponse<NewPatientsResponse>>;

/*
API call to get new patients for a session.

@jwt: The authentication token
@payload: The request payload containing session_id, number of patients, and language
Returns a promise containing the API response with new patient data
*/
export async function getNewPatient(
    jwt: String,
    payload: GetNewPatientPayload
): Promise<ApiResponse<NewPatientsResponse>> {
    const url = new ClassesUrl().newPatient;

    try {
        const response = await axios.post<NewPatientsResponse | string>(
            url,
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                    //@ts-ignore
                    token: jwt,
                },
                // Resolve the promise for all HTTP status codes
                validateStatus: () => true,
            }
        );

        if (response.status === 201) {
            return {
                status: response.status,
                data: response.data as NewPatientsResponse,
            };
        } else {
            return {
                status: response.status,
                error: response.data as string,
            };
        }
    } catch (error) {
        return {
            status: 500,
            error: error as string,
        };
    }
}
