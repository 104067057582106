// External Imports
import React from "react";
import { FaPencilAlt } from "react-icons/fa";

// Internal imports
import { PencilButtonContainer } from "./PencilButton.styles";

/**
 * PencilButtonProps
 * 
 * This is the props interface for the PencilButton component.
 * 
 * @param tab - The current tab.
 * @param notesTabActive - Whether the notes tab is active.
 * @param setTab - The function to set the tab.
 */
interface PencilButtonProps {
    tab: string;
    notesTabActive: boolean;
    setTab: React.Dispatch<React.SetStateAction<string>>;
}

export const PencilButton: React.FC<PencilButtonProps> = ({
    tab,
    notesTabActive,
    setTab,
}: PencilButtonProps) => {
    return (
        <PencilButtonContainer
            type="button"
            notesTabActive={notesTabActive}
            onClick={() => setTab(tab === "main" ? "notes" : "main")}
        >
            <FaPencilAlt aria-hidden="true" />
        </PencilButtonContainer>
    );
};