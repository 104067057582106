// Defines the user routes
import React from 'react';
import RegisterPage from './register/RegisterPage';
import LoginPage from './login/LoginPage';
import { ConfirmPage } from './confirm/ConfirmPage';
import { ForgotPassword } from './forgot-password/ForgotPassword';

// dependencies for the login page
import { loginUser } from "../../core/ServerApiLayer/VOne/auth/login";
import { updateRole } from "../../core/ServerApiLayer/VOne/auth/updateRole";
import { setFromApiData } from "../../core/LocalStorage/AuthSession/core";

// dependencies for the register page
import { CreateUser } from '../../core/ServerApiLayer/VOne/auth/register';

// dependencies for the forgot-password page
import { passwordResetRequest } from '../../core/ServerApiLayer/VOne/auth/passwordResetRequest';

// dependencies for the confirm page
import { ConfirmUser } from '../../core/ServerApiLayer/VOne/auth/confirm';

import ProfilePage from './profile/ProfilePage';

export const UserRoutes = [
    {
        path: "/register",
        element: <RegisterPage
            createUserApiCall={CreateUser}
        />,
    },
    {
        path: "/login",
        element: <LoginPage
            setSessionData={setFromApiData}
            loginApiCall={loginUser}
            updateRoleApiCall={updateRole}
        />,
    },
    // {
    //     path: "/verify/:uuid",
    //     element: <ConfirmPage />,
    // },
    {
        path: "/forgot-password",
        element: <ForgotPassword
            passwordResetRequestApiCall={passwordResetRequest}
        />,
    },
    {
        path: "/confirm/:uuid",
        element: <ConfirmPage
            confirmUserApiCall={ConfirmUser}
        />,
    },
    {
        path: "/profile",
        element: <ProfilePage />,
    },
]
