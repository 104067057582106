// External imports
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Internal imports
import { NotesListContainer, NotesListFlexContainer } from "./Notes.styles";
import { NotesListItem } from "./components/NotesListItem/NotesListItem";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import { noteData } from "../../../core/LocalStorage/patients/interface";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Dependency Injection for Page only
import { GetAllNotesType } from "../../../core/ServerApiLayer/VOne/patients/getAllNotes";
import {
    GetAllDocumentationFromLocalStorageType,
    SetDocumentationToLocalStorageType,
} from "../../../core/LocalStorage/patients/notes";

// import translation
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { NotesTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";

/**
 * NotesPageProps
 */
interface NotesProps extends WithPatientProps {
    getNotesLocalStorage: GetAllDocumentationFromLocalStorageType;
    getAllNotesApiCall: GetAllNotesType;
    setNotesLocalStorage: SetDocumentationToLocalStorageType;
    isLayout: boolean;
}

// Types for combined props
type CombinedProps = NotesProps & WithGameDependenciesProps;

/**
 * Translations for Page
 */
const TRANSLATIONS: Record<SupportedLanguage, NotesTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const NotesPageBase: React.FC<CombinedProps> = ({
    getNotesLocalStorage,
    getAllNotesApiCall,
    setNotesLocalStorage,
    isLayout = true,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount() || 0;
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Translations for the ExamPage
    const { language } = useLanguage();
    const pageTranslations = TRANSLATIONS[language];

    //@ts-ignore
    const [notesData, setNotesData] = useState<NotesListItem[] | null>(null);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [error, setError] = useState<string | null>(null); // Error state

    // Function to fetch notes data from localStorage or API. It first
    // attempts to get the notes from localStorage, and if not found,
    // makes an API call to fetch them. It then transforms the raw notes
    // and sets them in the state.
    const fetchNotes = async () => {
        try {
            // First, attempt to get the notes from localStorage
            const notesDataRaw = getNotesLocalStorage(Number(patientId));

            if (
                notesDataRaw &&
                (Object.keys(notesDataRaw.bloods).length > 0 ||
                    Object.keys(notesDataRaw.consultations).length > 0 ||
                    Object.keys(notesDataRaw.documentation).length > 0 ||
                    Object.keys(notesDataRaw.drugs).length > 0 ||
                    Object.keys(notesDataRaw.forms).length > 0 ||
                    Object.keys(notesDataRaw.investigations).length > 0 ||
                    Object.keys(notesDataRaw.treatments).length > 0 ||
                    Object.keys(notesDataRaw.additional_tests).length > 0)
            ) {
                const transformedNotes = transformNotesData(notesDataRaw);
                setNotesData(transformedNotes);
            } else {
                //@ts-ignore
                const jwt = getJwt();
                // If no notes in localStorage, make an API call to fetch them
                //@ts-ignore
                const response = await getAllNotesApiCall(jwt, {
                    patient_id: Number(patientId),
                });

                if (response.status === 201) {
                    // Set the notes data in localStorage
                    //@ts-ignore
                    setNotesLocalStorage(Number(patientId), response.data);

                    // Transform the notes data and set it in the state
                    //@ts-ignore
                    const transformedNotes = transformNotesData(response.data);

                    setNotesData(transformedNotes);
                } else {
                    setError(
                        `Failed to fetch notes from API for patient ID: ${patientId}`
                    );
                }
            }
        } catch (err) {
            setError(`Failed to fetch notes for patient ID: ${patientId}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Trigger the fetchNotes function when the component mounts
    useEffect(() => {
        fetchNotes();
    }, [patientId, getNotesLocalStorage, getAllNotesApiCall]);

    // This function transforms the raw notes data into a list of notes items, and
    // sorts them by turn number.
    // We have specific information that we want to display for each note type, and
    // we want to exclude for example exam data.
    //@ts-ignore
    const transformNotesData = (notesDataRaw: noteData): NotesListItem[] => {
        //@ts-ignore
        const notesList: NotesListItem[] = [];

        // Process documentation data
        Object.keys(notesDataRaw.documentation).forEach((turn) => {
            const documentationItem = notesDataRaw.documentation[turn];
            notesList.push({
                type: "NOTE",
                turns: Number(turn),
                content: documentationItem.text_body,
            });
        });

        // Process bloods data
        Object.keys(notesDataRaw.bloods).forEach((turn) => {
            const bloodsItem = notesDataRaw.bloods[turn];
            notesList.push({
                type: "BLOOD_ORDER",
                turns: Number(turn),
                content: bloodsItem.blood_list.join(", "), // String of blood list
            });
        });

        // Process consultations data
        Object.entries(notesDataRaw.consultations).forEach(([specialty, consultationData]) => {
            console.log("specialty:", specialty, "data:", consultationData);
            notesList.push({
                type: "CONSULTATION_ORDER",
                turns: Number(consultationData.turn_ordered),
                content: `Referral to ${consultationData.specialty}. Patient Summary: ${consultationData.summary}. Clinical Question: ${consultationData.reason}`,
            });
        });

        // Process additional tests data
        Object.keys(notesDataRaw.additional_tests).forEach((turn) => {
            const additionalTestsItem = notesDataRaw.additional_tests[turn];
            notesList.push({
                type: "ADDITIONAL_TEST",
                turns: Number(turn),
                content: `Additional tests ordered: ${additionalTestsItem.professional}. Reason: ${additionalTestsItem.reason}. Summary: ${additionalTestsItem.summary}`,
            });
        });

        // Process drugs data
        Object.keys(notesDataRaw.drugs).forEach((turn) => {
            const drugItem = notesDataRaw.drugs[turn];
            notesList.push({
                type: "DRUG_PRESCRIPTION",
                turns: Number(turn),
                content: `${drugItem.drug_name} prescribed`,
            });
        });

        // Process forms data
        Object.keys(notesDataRaw.forms).forEach((turn) => {
            const formItem = notesDataRaw.forms[turn];
            let formType;
            if (formItem.form_type.toUpperCase() === "ADMISSION") {
                formType = "ADMISSION_FORM";
            } else if (formItem.form_type.toUpperCase() === "DISCHARGE") {
                formType = "DISCHARGE_FORM";
            }
            notesList.push({
                type: formType || "DISCHARGE FORM", // Fall back to just a dischagre form
                turns: Number(turn),
                content: `${formItem.form_type.toLowerCase()} form completed`,
            });
        });

        // Process investigations data
        Object.keys(notesDataRaw.investigations).forEach((turn) => {
            const investigationItem = notesDataRaw.investigations[turn];
            notesList.push({
                type: "INVESTIGATION_ORDER",
                turns: Number(turn),
                content: `${investigationItem.investigation} ordered.`,
            });
        });

        // Process treatments data
        Object.keys(notesDataRaw.treatments).forEach((turn) => {
            const treatmentItem = notesDataRaw.treatments[turn];
            notesList.push({
                type: "TREATMENT_ORDER",
                turns: Number(turn),
                content: `${treatmentItem.treatment} ordered. priority: ${treatmentItem.priority}`,
            });
        });

        // Sort the list by turn number
        notesList.sort((a, b) => a.turns - b.turns);

        // Return the transformed notes list
        return notesList;
    };

    const RenderNoteList = () => {
        if (isLoading) return <p>Loading notes...</p>;
        if (error) return <p>{error}</p>;

        return (
            <>
                {notesData && notesData.length > 0 ? (
                    notesData.map((noteItem, index) => (
                        <NotesListItem
                            key={index}
                            noteItem={noteItem}
                            isLast={index === notesData.length - 1}
                        />
                    ))
                ) : (
                    <p>{pageTranslations.noNotes}</p>
                )}
            </>
        );
    };

    return (
        <>
            {isLayout ? (
                <GamePageLayout
                    turnCount={turnCount}
                    unreadNotifications={unreadNotifications}
                    pageTitle={pageTranslations.heading}
                    patient={patient}
                    translations={translations}
                    documentationDependencies={sidebarDependencies}
                    turnDependencies={turnDependencies}
                    demographicsDependencies={demographicsDependencies}
                    useLanguage={useLanguage}
                    refreshPage={refreshPage}
                >
                    <NotesListFlexContainer>
                        <NotesListContainer role="list">
                            <RenderNoteList />
                        </NotesListContainer>
                    </NotesListFlexContainer>
                </GamePageLayout>
            ) : (
                <RenderNoteList />
            )}
        </>
    );
};

export const NotesPage = withGameDependencies<NotesProps>(NotesPageBase);
