import React, { useState, ReactElement } from 'react';
// @ts-ignore
import {  Button, LeftSidePanel, TabsButtons, TabSection } from './Tab.styles';

/*

The data needed to be pass as props of this component to show the different different components in tabs.

@tabsTitle: Heading that will get displayed at the top of tabs.
@defaultActiveTab: Id of tab which will be shown as default active from all tabs.
@tabs: Array of all tabs containing unique if, icon, title of tab along with component in child that will get displayed on tab selection

*/

export interface TabsProps {
    tabsTitle: string,
    defaultActiveTab: number,
    tabs: Array<{
        id: number,
        icon: ReactElement,
        title: string,
        child: ReactElement
    }>

}
const Tabs: React.FC<TabsProps> = ({ tabsTitle, tabs, defaultActiveTab }) => {
    const [activeTab, setActiveTab] = useState<number>(defaultActiveTab);
    return (
        <>
            <TabSection>
                <h2>{tabsTitle}</h2>
                <TabsButtons>
                    {tabs.map((tabData, i) => (
                        <Button key={i} className={`${activeTab == tabData.id ? "activeTab" : "tabButton"}`} onClick={() => setActiveTab(tabData.id)}>
                            {tabData.icon}
                            <span>{tabData.title}</span>
                        </Button>
                    ))}
                </TabsButtons>
            </TabSection>
            <LeftSidePanel>
                {tabs.map((tabData, i) => (
                    activeTab == tabData.id &&
                    tabData.child
                ))}
            </LeftSidePanel>
        </>
    );
}

export default Tabs;
