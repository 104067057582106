import {UserUrl} from "./AUrl";
import axios, {AxiosResponse} from "axios";
import { getJwt } from "../../../LocalStorage/AuthSession/core";
import { UserSession } from "./login";


/*
The interface needed to update the role of the user session.

@role: The role to be updated to.
@entity_type: The type of entity that the role is associated with.
@entity_id: The id of the entity that the role is associated with.
 */
export interface UpdateSessionRoleSchema {
    role: string;
    entity_type: "institution" | "department";
    entity_id: number;
}


/*
The interface for the response from the update role endpoint.

@status: The status code of the response.
@error: The error message if the response was unsuccessful.
@data: The UserSession if the response was successful (should be inserted into local storage auth core).
 */
export interface ApiResponse {
    status: number;
    error?: string;
    data?: UserSession; // Optional body
}


export type UpdateRoleType = (payload: UpdateSessionRoleSchema) => Promise<ApiResponse>;


/*
The function to update the role of the user session.

It must be noted that the JWT and refresh token is not updated with this API call, the user
session is essentially the same, but the role is updated.

@payload: The data needed to update the role of the user session.

@returns: The ApiResponse from the update role endpoint which is the entire metadata of the user session
          without the JWT and refresh token.
 */
export async function updateRole(payload: UpdateSessionRoleSchema): Promise<ApiResponse> {
    const url = new UserUrl().updateRole;
    const jwt = getJwt();
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    let response: AxiosResponse<any>;
    try {
        response = await axios.post<ApiResponse | string>(url, payload, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            // Resolve the promise for all HTTP status codes
            validateStatus: () => true
        });

    } catch (error) {
        // Handle network or other Axios-related errors
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 200) {
        // Successful response with UserSession
        return {
            status: response.status,
            data: response.data as UserSession
        };
    } else {
        // Other response codes with a string message
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
