import styled from "@emotion/styled";

export const GuidelinesModalContainer = styled.div`
    width: 100%;
    gap: 1rem;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const GuidelinesModalContentContainer = styled.div`
    width: 100%;
    overflow-y: auto;
    max-height: 35em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    button {
        width: 100% !important;
    }
`;

export const GuidelinesModalInputField = styled.input`
    display: block;
    border-radius: 0.375rem;
    border: 1px solid #f3f4f6;
    width: 100%;
    color: #374151;
    padding: 0.5rem 1rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    @media (min-width: 640px) {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
`;

export const GuidelinesModalSearchContentContainer = styled.div`
    overflow-y: auto;
    width: 50em;
    height: 24rem;
`;

export const GuidelinesModalNotFoundContainer = styled.div`
    margin-top: 10rem;
    text-align: center;
    color: #6b7280;
`;
