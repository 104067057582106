// External Imports
import React, { useState } from "react";

// Internal Imports
import {
    OrderConsultationResultListItemContainer,
    OrderConsultationResultListItemContent,
    OrderConsultationResultListItemContentContainer,
    OrderConsultationResultListItemContentTitle,
    StyledOrderConsultationResultListItem,
} from "./OrderConsultationResultListItem.styles";
import TurnTablet from "../../../../notePage/components/TurnTablet/TurnTablet";
import WebsiteButton from "../../../../../../components/core/button/WebsiteButton";
import OrderConsultationReportModal from "../orderConsultationReportModal/OrderConsultationReport";
import { turnsToTime } from "../../../../../../core/LocalStorage/turnLogic/core";
import { formatGameTime } from "../../../../../../core/gameTime/formatTime";

/**
 * OrderConsultationResultListItemProps
 */
interface OrderConsultationResultListItemProps {
    title: string;
    turn_ordered: number;
    details: string;
    complete: boolean;
    notes?: string;
    allowNotes?: boolean;
    reason?: string;
}

/**
 * OrderConsultationResultListItem
 *
 * This component is a list item for the consultation results.
 */
export const OrderConsultationResultListItem = ({
                                                    title,
                                                    turn_ordered,
                                                    details,
                                                    complete,
                                                    allowNotes,
                                                    notes,
                                                    reason
                                                }: OrderConsultationResultListItemProps) => {
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);

    return (
        <>
            <StyledOrderConsultationResultListItem>
                <OrderConsultationResultListItemContainer>
                    <OrderConsultationResultListItemContentContainer>
                        <OrderConsultationResultListItemContentTitle>
                            {title}
                        </OrderConsultationResultListItemContentTitle>
                        <OrderConsultationResultListItemContent>
                            {complete ? (
                                <WebsiteButton onClick={() => setIsReportModalOpen(true)}>
                                    View Consultation Report
                                </WebsiteButton>
                            ) : (
                                <TurnTablet turn={formatGameTime(turnsToTime(turn_ordered))} />
                            )}
                        </OrderConsultationResultListItemContent>
                    </OrderConsultationResultListItemContentContainer>
                </OrderConsultationResultListItemContainer>
            </StyledOrderConsultationResultListItem>
            {isReportModalOpen && (
                <OrderConsultationReportModal
                    title={title}
                    details={details}
                    allowNotes={allowNotes}
                    turn_ordered={turn_ordered}
                    notes={notes}
                    onClose={() => setIsReportModalOpen(false)}
                    reason={reason}
                />
            )}
        </>
    );
};
