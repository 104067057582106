import { ScoringTranslations } from './types';

export const enTranslations: ScoringTranslations = {
    labels: {
        protocol: "Protocol",
        diagnosis: "Diagnosis",
        neverEvents: "Never-events"
    },
    ariaLabels: {
        protocolLock: "Toggle protocol score lock",
        diagnosisLock: "Toggle diagnosis score lock",
        neverEventsLock: "Toggle never events score lock",
        protocolScore: "Protocol score",
        diagnosisScore: "Diagnosis score",
        neverEventsScore: "Never events score"
    }
};