import React from "react";
import {
    FeedbackDiagnosisBoxContainer,
    FeedbackDiagnosisBoxContent,
    FeedbackDiagnosisBoxTitle,
} from "./FeedbackDiagnosisBox.styles";

/**
 * FeedbackDiagnosisBoxProps props.
 * @interface FeedbackDiagnosisBoxProps
 * @property {string} [title] - The title for the diagnosis box.
 * @property {string} [diagnosis] - The diagnosis to display in the box.
 * @property {boolean} [isCorrect] - Whether the diagnosis is correct or not.
 */
interface FeedbackDiagnosisBoxProps {
    title?: string;
    diagnosis?: string;
    isCorrect?: boolean;
}

/**
 * FeedbackDiagnosisBox component.
 * @component FeedbackDiagnosisBox
 * @description A box component that displays a diagnosis.
 * @param {FeedbackDiagnosisBoxProps} props - The component props.
 * @returns {JSX.Element} The rendered FeedbackDiagnosisBox component.
 */
function FeedbackDiagnosisBox({
    title,
    diagnosis,
    isCorrect,
}: FeedbackDiagnosisBoxProps) {
    return (
        <FeedbackDiagnosisBoxContainer>
            <FeedbackDiagnosisBoxTitle>{title}</FeedbackDiagnosisBoxTitle>
            <FeedbackDiagnosisBoxContent isCorrect={isCorrect ?? false}>
                {diagnosis}
            </FeedbackDiagnosisBoxContent>
        </FeedbackDiagnosisBoxContainer>
    );
}

export default FeedbackDiagnosisBox;
