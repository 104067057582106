import styled from "@emotion/styled";

export const ReviewPageTable = styled.table`
    border-spacing: 0px;
    margin-top: 5px;
    min-width: 100%;
    cursor: pointer;
`;
export const ReviewPageTableHead = styled.thead`
    text-align: left;
`;
export const ReviewPageTableBody = styled.tbody`
`;
export const ReviewPageTableRow = styled.tr`
    font-size: 14px;
    text-align: center;
    &:nth-child(odd) {
        background-color: #f3f4f6;
        color: #000;
    }
    &:nth-child(even) {
        background-color: #e5e7eb;
        color: #000;
    }
`
export const ReviewPageTableData = styled.td`
    padding: 15px 20px;
`
export const ReviewPageTableRowHead = styled.th`
    padding: 10px 14px;
`