import React from "react";
import {
    OrderTreatmentResultContainer,
    OrderTreatmentResultList,
    OrderTreatmentResultTitle,
} from "./OrderTreatmentsResults.styles";
import { OrderTreatmentListItem } from "../orderTreatmentListItem/OrderTreatmentListItem";

/**
 * OrderTreatmentResultProps
 */
interface OrderTreatmentResultProps {
    data: any[];
    translations: any;
    treatmentTranslations: any;
}

/**
 * OrderTreatmentResult
 *
 * This component is a list of investigation results. It displays the investigation results that are
 * available or pending. There are two statuses: available and pending.
 */
export const OrderTreatmentsResults = ({
    data,
    translations,
    treatmentTranslations,
}: OrderTreatmentResultProps) => {
    return (
        <OrderTreatmentResultContainer>
            <OrderTreatmentResultTitle>
                {translations.orderedTreatments}
            </OrderTreatmentResultTitle>
            <OrderTreatmentResultList>
                {data && data.length > 0
                    ? data.map((item, index) => (
                          <OrderTreatmentListItem
                              key={index}
                              title={treatmentTranslations[item.treatment]}
                              turn_ordered={item.turn_ordered}
                              reason={item.reason}
                          />
                      ))
                    : null}
            </OrderTreatmentResultList>
        </OrderTreatmentResultContainer>
    );
};
