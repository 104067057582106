import styled from "@emotion/styled";

/**
 * Main page container component
 *
 * Displays the main page content with a background image
 *
 * @param backgroundImage - URL of the background image
 */
interface MainPageProps {
    backgroundImage: string;
  }


export const MainPage = styled.div<MainPageProps>`
    display: flex;
    justify-content: center; // Center horizontally
    align-items: center; // Center vertically
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: calc(100vh - 140px); // Adjust this based on your header and footer heights
    overflow: hidden;
`;