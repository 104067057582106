import styled from '@emotion/styled';

// Container for the whole page
export const Container = styled.div<{ backgroundImage: string }>`
  flex-grow: 1;
  background-color: #e0e0e0; /* Background color */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
`;

// Left panel style for the login form section
export const LeftPanel = styled.div`
  font-family: "Helvetica", "Arial", sans-serif;
  padding: 20px;
  width: 40%;
  min-width: 300px;
  max-width: 600px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
`;

// Style for the heading inside the left panel
export const LeftPanelHeading = styled.h1`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
`;

// Logo image style in the left panel
export const LogoImage = styled.img`
  max-width: 200px;
  margin-bottom: 20px; /* Add space between the logo and the title */
  cursor: pointer;
`;

// Right panel style (you can add content here)
export const RightPanel = styled.div`
  flex-grow: 1;
  background-color: #e0e0e0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export const DivArea = styled.div`
    min-width: 240px;
`;
export const SectionWithZeroMargin = styled.div`
    margin: 0px;
`;

export const Heading = styled.h1`
padding-left:0px;
`;
