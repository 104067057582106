import { ConsultationPageTranslations } from "./types";

export const enTranslations: ConsultationPageTranslations = {
    specialtyLabel: "Specialty",
    specialtyOptions: [
        { value: "cardiothoracic", label: "Cardiothoracic" },
        { value: "ent", label: "ENT" },
        { value: "general_surgery", label: "General Surgery" },
        { value: "neurosurgery", label: "Neurosurgery" },
        { value: "psychiatry", label: "Psychiatry" },
        { value: "trauma_ortho_surgery", label: "Orthopaedics" },
        { value: "urology", label: "Urology" },
    ],
    summaryLabel: "Summary of Patient",
    reasonLabel: "Reason for Referral of Patient",
    clearButtonText: "Clear",
    submitButtonText: "Submit",
    pending: "Pending",
    available: "Available",
    consultations: "Consultations",
};
