// External Imports
import React from 'react';

// Internal Imports
import WaitingRoomImage from '../../../assets/waiting_room.jpg';
import MainPageLayout from '../../../components/core/header/MainPageLayout';
import { MainPage } from '../../../components/core/mainPage/MainPage.styles';
import { CentralButtonComponent, CentralButtonComponentProps } from '../../../components/core/centralButtonsComponent/CentralButtonComponent';

// Translations
import { useLanguage } from '../../../core/translations/LanguageContext';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';
import { FrontPageTranslations } from './translations/types';
import { deTranslations } from './translations/de';
import { enTranslations } from './translations/en';

// CONSTANTS FOR TRANSLATIONS
const TRANSLATIONS: Record<SupportedLanguage, FrontPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
}


/**
 * Student Front Page Component
 * 
 * This is the front page for the student. Currently there are only two options for the student:
 * 1. Join a class
 * 2. Review previous classes
 * 
 * In the future, we may want to add more options for the student
 */
export const FrontPage: React.FC = () => {

    // Get the language from the context
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    // Define the buttons for the student front page
    const buttons: CentralButtonComponentProps["buttons"] = [
        {
            text: translations.joinClass,
            href: "/student/join-class",
        },
        {
            text: translations.reviewPreviousGames,
            href: "/student/previous-classes",
        },
    ];

    // Return the student front page
    return (
        <MainPageLayout>
            <MainPage backgroundImage={WaitingRoomImage}>
                <CentralButtonComponent buttons={buttons} />
            </MainPage>
        </MainPageLayout>
    );
}