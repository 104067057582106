export const PressData = [
    {
        title: "Platform",
        quotes: [
            {
                subtitle: "Award by German Medical Society",
                description:
                    "DIVINA was awarded the 2020 German Medical Award for its innovative approach to teaching medical students how to manage complex conditions in emergency rooms.",
                // highlightText:
                //     "The pandemic has spawned a new way to study medical records.",
                // quote: "In normal circumstances, merely obtaining permission to look at such a trove of sensitive health data would take months, perhaps years, of jumping through hoops held by ethics committees, computer-security checkers and so on. Running the analysis and getting it published might take months more. These are not, though, normal circumstances, and in fact it took Opensafely a mere 42 days to go from idea to publication.",
            },
            // {
            //     subtitle: "Zdnet",
            //     description:
            //         "In this article, Jo Best, interviews Dr. Ben Goldacre to provide a detailed description and analysis of the technical design of the platform, summarizing:",
            //     highlightText:
            //         "This open source project is using Python, SQL and Docker to understand coronavirus health data",
            //     quote: null,
            //     image: "https://static.javatpoint.com/htmlpages/images/html-blockquote-tag.png",
            // },
        ],
    },
];
