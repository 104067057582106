import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import WebsiteButton from "../../core/button/WebsiteButton";
import Logo from "../../core/logo/Logo";
import ProfileDropdown from "../../core/profile-dropdown/ProfileDropdown";
import {
    NotificationBell,
    Notification,
} from "./NotifPopup/NotificationBell/NotificationBell";
import { GameOnboarding } from "./Onboarding/GameOnboarding";
import { TurnCounter } from "./TurnCounter/TurnCounter";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Nav,
    Container,
    FlexContainer,
    FlexItem,
    FlexItemCenter,
    FlexItemShrink,
    StyledAiFillQuestionCircle,
    EndGameButton,
} from "./GameNavBar.styles";
import { useLanguage } from "../../../core/translations/LanguageContext";

import LogoutModal from "../../core/modal/logout-modal/LogoutModal";
import ExitGameModal from "../../core/modal/exit-game-modal/ExitGameModal";

/*
The props for the GameNavBar component.

unreadNotifications: An array of unread notifications.
*/
export interface GameNavBarProps {
    unreadNotifications: Notification[];
    turnCount: number;
    translations: any;
    turnDependencies: any;
}

/**
 * The navigation bar for the game. This component is used to display the navigation
 * bar at the top of the game screen. It includes the logo, a notification bell, a turn
 * counter, a button to end the game, a help button, and a profile dropdown.
 *
 * @returns The GameNavBar component.
 */
export const GameNavBar: React.FC<GameNavBarProps> = ({
    unreadNotifications,
    turnCount,
    translations,
    turnDependencies,
}) => {
    // If the url is the dashboard, set isOnDashboard to true
    const location = useLocation();
    const isOnDashboard = location.pathname === "/dashboard";

    const { language } = useLanguage();
    const navigate = useNavigate();

    // State variables for the help modal and logout modal
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    const [showExitGamePopup, setShowExitGamePopup] = useState(false);

    // Function to handle dismissing notifications
    const handleDismissNotifications = (id: string) => {
        turnDependencies.markNotificationAsRead(id);
        window.location.reload();
    };

    const onExitGameClose = (): void => {
        setShowExitGamePopup(false);
    };

    /**
     * Check if key is a patient id or not
     */
    const isKeyNumberAsString = (key: string): boolean => {
        // Try to convert the key to a number
        let num = parseFloat(key); // Use parseInt if you need an integer comparison

        // Check if the parsed number is valid and if the key is purely numeric
        return !isNaN(num) && key.trim() === num.toString();
    };

    /**
     * Show leave game popup
     */
    const openExitGamePopup = (): void => {
        setShowExitGamePopup(true);
    };
    /**
     * Remove patients data, class data and session data if user submitted the Leave game button
     */
    const onSubmitExitGame = (): void => {
        onExitGameClose();
        localStorage.removeItem("sessionData");
        localStorage.removeItem("classData");
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i); // Get the key

            if (key && isKeyNumberAsString(key)) {
                localStorage.removeItem(key);
            }
        }
        navigate("/student/join-class");
    };

    /**
     * Logout close popup
     */
    const onRequestClose = (): void => {
        setShowLogoutPopup(false);
    };

    /**
     * Logout open popup
     */
    const openLogoutPopup = (): void => {
        setShowLogoutPopup(true);
    };

    /**
     * Logout submit button callback function
     */
    const onSubmitLogout = (): void => {
        const cookiesSeen = localStorage.getItem("cookiesSeen");

        localStorage.clear();
        localStorage.setItem("language", language);
        if (cookiesSeen !== null) {
            localStorage.setItem("cookiesSeen", cookiesSeen);
        }
        setShowLogoutPopup(false);
        navigate("/");
    };

    return (
        <>
            <Nav>
                <Container>
                    <FlexContainer>
                        {isOnDashboard ? null : (
                            <WebsiteButton
                                className="mr-8"
                                icon={<FaChevronLeft />}
                                href="/dashboard"
                                onClick={turnDependencies.compareTurns}
                                gameNavBar
                            >
                                {translations.header.backToDashboard}
                            </WebsiteButton>
                        )}
                        <div className="flex items-center justify-center sm:items-stretch sm:justify-start hidden sm:block">
                            <Logo alt />
                            <div className="sm:ml-6"></div>
                        </div>
                        <FlexItem />
                        <>
                            <FlexItemCenter />
                            <NotificationBell
                                unreadNotifications={unreadNotifications}
                                onDismiss={handleDismissNotifications}
                                translations={translations}
                            />
                            <TurnCounter turns={turnCount} />
                            <FlexItemShrink>
                                <EndGameButton onClick={openExitGamePopup}>
                                    {translations.header.endGame}
                                </EndGameButton>
                            </FlexItemShrink>
                        </>
                        <ProfileDropdown
                            navigation={[
                                {
                                    href: "/profile",
                                    name: translations.header.dropdown.profile,
                                },
                                {
                                    href: "/badges",
                                    name: translations.header.dropdown.badges,
                                },
                                {
                                    href: "",
                                    name: translations.header.dropdown.logout,
                                    onClick: openLogoutPopup,
                                },
                            ]}
                        />
                    </FlexContainer>
                </Container>
            </Nav>
            {showLogoutPopup && (
                <LogoutModal
                    isGame={true}
                    onRequestClose={onRequestClose}
                    onSubmitLogout={onSubmitLogout}
                />
            )}
            {showExitGamePopup && (
                <ExitGameModal
                    onExitGameClose={onExitGameClose}
                    onSubmitExitGame={onSubmitExitGame}
                />
            )}
        </>
    );
};
