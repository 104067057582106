// External imports
import React, { useState, useRef } from "react";

// Internal imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import WebsiteButton from "../../../components/core/button/WebsiteButton";
import { LabelInput } from "./LabelInput";
import scrubs from "./scrubs.jpg";
import {
    Title,
    Description,
    Form,
    ButtonContainer,
    Message,
    Container,
    ContentContainer,
    ImageContainer,
    ImageStyle,
} from "./ContactPage.styles";

// Language Context
import { useLanguage } from '../../../core/translations/LanguageContext';

// Translations
import { ContactTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

const TRANSLATIONS: Record<SupportedLanguage, ContactTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Interface for form data
 */
interface ContactFormData {
    name: string;
    email: string;
    message: string;
}

/**
 * ContactPage component
 * Displays a contact form and handles form submission
 */
const ContactPage: React.FC = () => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const [sent, setSent] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const formRef = useRef<HTMLFormElement>(null);

    /**
     * Handles form submission
     * @param formData - The form data to be sent
     */
    const sendContactForm = async (formData: ContactFormData) => {
        try {
            const response = await fetch('https://fabform.io/f/nusEBQB', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log("Form sent successfully");
                setSent(true);
                setError(null);
                // Clear the form
                formRef.current?.reset();
                // Optional: Add a timeout to reset the success message
                setTimeout(() => {
                    setSent(false);
                }, 5000);  // Reset after 5 seconds
            } else {
                console.error('Failed to send form data', response.statusText);
                setError('Failed to send message. Please try again.');
            }
        } catch (error) {
            console.error('An error occurred while sending the form data', error);
            setError('An error occurred. Please try again later.');
        }
    };

    /**
     * Handles form submission event
     * @param e - Form submission event
     */
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            name: { value: string };
            email: { value: string };
            message: { value: string };
        };

        sendContactForm({
            name: target.name.value,
            email: target.email.value,
            message: target.message.value,
        });
    };

    const content = (
        <>
            <Title>{translations.title}</Title>
            <Description>{translations.description}</Description>
            <Form ref={formRef} onSubmit={handleSubmit} method="POST">
                <LabelInput
                    label={translations.form.name}
                    id="name"
                    type="text"
                    name="name"
                    required
                />
                <LabelInput
                    label={translations.form.email}
                    id="email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                />
                <LabelInput
                    label={translations.form.helpMessage}
                    id="message"
                    type="textarea"
                    name="message"
                    ariaDescribedby="how-can-we-help-description"
                    rows={8}
                    maxLength={500}
                    helperText={translations.form.charLimit}
                    required
                    defaultValue=""
                />
                <ButtonContainer>
                    {!sent && (
                        <WebsiteButton type="submit" tag="button">
                            {translations.form.submit}
                        </WebsiteButton>
                    )}
                    {sent && <Message>{translations.form.thankYou}</Message>}
                    {error && <Message error>{error}</Message>}
                </ButtonContainer>
            </Form>
        </>
    );

    return (
        <MainPageLayout>
            <Container>
                <ContentContainer>{content}</ContentContainer>
                <ImageContainer>
                    <ImageStyle
                        src={scrubs}
                        alt="Medical professional in scrubs"
                    />
                </ImageContainer>
            </Container>
        </MainPageLayout>
    );
};

export default ContactPage;