import { ClassesUrl } from "./AUrl";
import { ApiResponse } from "../interfaces";
import axios, { AxiosResponse } from "axios";

/*
The payload for creating a session.

@class_id: The id of the class.
@name: The name of the session.
@language: The language.
*/
export interface startGamePayload {
    class_id: number;
    name: string;
    language: string;
}

/*
The patients for a session.

@id: The id of the patient.
@first_name: The patients first name
@last_name: The patients last name.
@age: The patients age.
@gender: The patients gender.
@presenting_complaint: The patients presenting complaint.
@arrival_turn: Turn of arrival.
@location: The patients location.
@location_type: The location type.
@face_image_path: The path to the face image of the patient.
@status: The status of the patient.
@diagnosis: The diagnosis of the patient. Can be blank.
*/
export interface ApiPatientData {
    id: number;
    first_name: string;
    last_name: string;
    age: number;
    gender: string;
    presenting_complaint: string;
    arrival_turn: number;
    location: string;
    location_type: string;
    face_image_path: string;
    status: string;
    diagnosis: string | null;
    patient_prompt: string | null;
}

/*
The response for the patients for a session.
 */
export interface Session {
    session_id: number;
    patients: ApiPatientData[];
    patientIntervals: number;
}

export type CreateSessionType = (
    jwt: String,
    startGamePayload: startGamePayload
) => Promise<ApiResponse<Session>>;

/*
Calling the API to start a session for a student.

@token: The token for the user.
@class_id: The id of the class that the session is for.
@name: The name of the session.
@language: The language.

Returns a promise of the API response which is the session that was started.
 */
export async function startSession(
    jwt: String,
    startGamePayload: startGamePayload
): Promise<ApiResponse<Session>> {
    const url = new ClassesUrl().startSession;
    let response: AxiosResponse<any>;
    try {
        response = await axios.post<Session | string>(
            url,
            {
                class_id: startGamePayload.class_id,
                name: startGamePayload.name,
                language: startGamePayload.language,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    // @ts-ignore
                    token: jwt,
                },
                // Resolve the promise for all HTTP status codes
                validateStatus: () => true,
            }
        );
        if (response.status === 201) {
            return {
                status: response.status,
                data: response.data as Session,
            };
        } else {
            return {
                status: response.status,
                error: response.data as string,
            };
        }
    } catch (error) {
        return {
            status: 500,
            error: error as string,
        };
    }
}
