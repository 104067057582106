import { ChatPageTranslations } from "./types";

export const deTranslations: ChatPageTranslations = {
    title: "Anamnese",
    loader: {
        firstMessage: "Preparing your medical chat history...!",
        secondMessage: "Optimising scenario. Please wait...",
        thirdMessage: "Loading real-time data ...",
        fourthMessage: "It is almost ready...",
        completing: "Completing....",
        completed: "Completed",
    },
    form: {
        placeHolder: "Geben Sie hier Ihre Frage ein...",
        submitButton: "Frage stellen",
    },
};
