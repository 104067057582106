import { SymptomsTranslations } from "./types";

export const enSymptomsTranslations:  SymptomsTranslations = {
    ABDOMINAL_PAIN: "Stomach pain",
    ABNORMAL_SPUTUM: "Unusual mucus",
    ACOUSTIC_BLACKOUT: "Hearing loss",
    ANKLE_SWELLING: "Swollen ankles",
    ANXIETY: "Anxiety",
    ARM_PAIN: "Arm pain",
    BACK_PAIN: "Back pain",
    BREATHLESSNESS: "Shortness of breath",
    CHEST_PAIN: "Chest pain",
    COLD_LEG: "Cold leg",
    CONFUSION: "Confusion",
    CONSTIPATION: "Constipation",
    COUGH: "Cough",
    DARK_URINE: "Dark urine",
    DIARRHOEA: "Diarrhea",
    DOUBLE_IMAGES: "Double vision",
    DYSPHAGIA: "Difficulty swallowing",
    DYSURIA: "Painful urination",
    EPISTAXIS: "Nosebleed",
    EXANTHEMA: "Skin rash",
    FACIAL_PARESIS: "Facial muscle weakness",
    FATIGUE: "Tiredness",
    FEVER: "Fever",
    FLANK_PAIN: "Side pain",
    FOCAL_DEFICITS: "Weakness",
    FOCAL_WEAKNESS_ARM: "Arm weakness",
    FOCAL_WEAKNESS_FACE: "Face weakness",
    FOCAL_WEAKNESS_LEG: "Leg weakness",
    GAZE_PREFERENCES: "Eye movement problems",
    HAEMATURIA: "Blood in urine",
    HAEMOPTYSIS: "Coughing up blood",
    HAIR_LOSS: "Hair loss",
    HEAD_CONGESTION: "Head congestion",
    HEADACHE: "Headache",
    HEARTBURN: "Heartburn",
    HEMATOCHEZIA: "Blood in stool",
    HEMIPARESIS: "Weakness",
    HOARSE_VOICE: "Hoarse voice",
    INSOMNIA: "Difficulty sleeping",
    JAUNDICE: "Yellow skin",
    JAW_PAIN: "Jaw pain",
    LEG_PAIN: "Leg pain",
    LOSS_OF_APPETITE: "Loss of appetite",
    LOSS_OF_CONSCIOUSNESS: "Passing out",
    MELAENA: "Black stool",
    MEMORY_IMPAIRMENTS: "Memory problems",
    MUMBLING: "Unclear speech",
    MYALGIA: "Muscle pain",
    MYDRIASIS: "Enlarged pupils",
    MYOPATHY: "Muscle weakness",
    NAUSEA: "Nausea",
    NECK_PAIN: "Neck pain",
    NECK_STIFFNESS: "Stiff neck",
    NYKTURIA: "Nnighttime urination",
    OSCILLOPSY: "Vision problems",
    OVERFLOW_INCONTINENCE: "Bladder overflow",
    PALE_STOOL: "Unusual stools",
    PALENESS_OF_THE_LEG: "Pale leg",
    PALPITATIONS: "Heart pounding",
    PARAESTHESIA: "Tingling sensation",
    PHONOPHOBIA: "Sensitivity to sound",
    PHOTOPHOBIA: "Sensitivity to light",
    PLUGGED_NOSE: "Stuffy nose",
    PRESYNCOPE: "Near-fainting",
    POLYURIA: "Excessive urination",
    PRURITUS: "Itching",
    REDUCED_EXERCISE_TOLERANCE: "Fatigue",
    REGURGITATION: "FRegurgitation",
    RESTLESSNESS: "Restlessness",
    RUNNY_NOSE: "Runny nose",
    SENSORY_AURA: "Strange sensations",
    SENSORY_HEMIDEFICIT: "Loss of sensation",
    SHAKING_CHILLS: "Severe shivering",
    SHOULDER_PAIN_LEFT: "Left shoulder pain",
    SNEEZING: "Sneezing",
    SORE_THROAT: "Sore throat",
    SPEECH_DISTURBANCES: "Speech problems",
    STRESS: "Stress",
    SWEATING: "Sweating",
    ULCUS_CRURIS_ARTERIOSUM: "Leg ulcer",
    URINARY_RETENTION: "Unable to urinate",
    VAGINAL_BLEEDING: "Vaginal bleeding",
    VERTIGO: "Dizziness",
    VISUAL_AURA: "Visual disturbances",
    VISUAL_IMPAIRMENT: "Vision problems",
    VOMITING: "Vomiting",
    WEIGHT_GAIN: "Weight gain",
    WEIGHT_LOSS: "Weight loss",
    WHEEZING: "Wheezing",
    WORD_FINDING_DISORDER: "Difficulty finding words",
    KNEE_PAIN_IN_OA: "Knee pain",
    MORNINGSTIFFNESS: "Stiffness",
    REDUCED_KNEE_MOTION: "Limited knee movement",
    REDUCED_STEP_LENGTH: "Gait problems",
    LL_MUSCLE_WEAKNESS: "Leg weakness",
    HIP_AND_GROIN_PAIN: "Hip and groin pain",
    REDUCED_HIP_MOVEMENT_OA: "Limited hip movement",
    LEG_EXTERNALLY_ROTATED: "Outward turned leg",
}