import styled from "@emotion/styled";

export const ClassCreatedModalContainer = styled.div`
    width: 100%;
    gap: 1rem;
    color: #000000;
    position: relative;
    border-radius: 0.75rem;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const ClassCreatedModalContentContainer = styled.div`
    overflow-y: auto;
    max-height: 35em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
`;

export const ClassCreatedModalTitle = styled.h1`
    font-size: 22px;
    margin-top: 5px;
`;

export const ClassCreatedModalSubTitle = styled.h5`
    font-size: 15px;
`

export const ClassCreatedModalContentWrapper = styled.div`
    margin-top: 1rem;
    background: #334155;
    color: #FFF;
    width: min-content;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 0.8em;
    svg{
        padding: 10px;
        font-size: 40px;
        cursor: pointer;
    }
`;

export const ClassCreatedModalText = styled.p`
    padding: 15px;
    width: 100%px;
    text-align: center;
`;

export const ClassCreatedModalSpan = styled.span`
    color: #334155;
`;
