// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { ApiPatientData } from "../sessions/start";

import { getJwt } from "../../../LocalStorage/AuthSession/core";

/*
The interface for the payload for getting all patients.
 */
export interface GetAllPatientsPayload {
    session_id: number;
    filter: String;
}


/*
The interface for the response from the get all classes endpoint.

@status: The HTTP status code.
@error: The error message.
@data: The data returned from the endpoint.
 */
export interface GetAllPatientsResponse {
    status: number;
    error?: string;
    data?: {
        patients: ApiPatientData[];
    };
}

export type GetAllPatientsType = (jwt: string, getPatientsPayload: GetAllPatientsPayload) => Promise<GetAllPatientsResponse>;


/*
The function to get all patients

@jwt: The JWT of the user requesting the classes.

@returns: The ApiResponse from the get all classes endpoint.
 */
export async function getAllPatients(jwt: string, getPatientPayload: GetAllPatientsPayload): Promise<GetAllPatientsResponse> {
    const url = new PatientUrl().getAll;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    let response: AxiosResponse<any>;
    try {
        response = await axios.post<GetAllPatientsResponse | string>(url, {
            session_id: getPatientPayload.session_id,
            filter: getPatientPayload.filter
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },

            // Resolve the promise for all HTTP status codes
            validateStatus: () => true

        });
        console.log(response);

    } catch (error) {
        // Handle network or other Axios-related errors
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 200) {
        // Successful response
        return {
            status: response.status,
            data: response.data as GetAllPatientsResponse['data']
        };
    } else {
        // Other response codes with a string message
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
