// External Imports
import React, { useState, FormEvent, useEffect } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";

// Internal Imports
// @ts-ignore
import GlobalSmallPopup from "../../../../../../components/core/modal/terms-modal/GlobalSmallPopup";
import { SelectOption } from "../../../../../../pages/students/treatmentOrderPage/config/OrderTreatment.type";
import { InputFieldArea, PopupButton, PopupForm, PopupSelectField } from "./PopupFormComponent.styles";

export interface PopupFormProps {
    translations: any;
    diseaseTranslations: Record<string, string>;
    getDiagnosisFromLocalStorage: (patientId: number) => string | null;
    updateDiagnosisFromLocalStorage: (
        patientId: number,
        diagnosis: string
    ) => void;
}

/**
 * Popup form component for diagnosis selection
 *
 * @param translations
 * @param diseaseTranslations
 * @param getDiagnosisFromLocalStorage
 * @param updateDiagnosisFromLocalStorage
 * @constructor
 */
export const PopupFormComponent: React.FC<PopupFormProps> = ({
    translations,
    diseaseTranslations,
    getDiagnosisFromLocalStorage,
    updateDiagnosisFromLocalStorage,
}) => {
    const [selectedDiagnosis, setSelectedDiagnosis] = useState<string>("");
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const { id: patientId } = useParams<{ id: string }>();

    useEffect(() => {
        const diagnosis = getDiagnosisFromLocalStorage(Number(patientId));

        if (diagnosis && diagnosis.trim() !== "") {
            setShowPopup(false);
        } else {
            setShowPopup(true);
        }
    }, [getDiagnosisFromLocalStorage, patientId]);

    // Convert disease translations directly to options
    const diagnosisOptions: SelectOption[] = Object.entries(diseaseTranslations)
        .map(([key, label]) => ({
            value: key,
            label: label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const handleSelectChange = (selected: SelectOption | null) => {
        if (selected) {
            setSelectedDiagnosis(selected.value);
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (selectedDiagnosis !== "") {
            updateDiagnosisFromLocalStorage(
                Number(patientId),
                selectedDiagnosis
            );
            setShowPopup(false);
        }
    };

    const onRequestClose = () => {
        console.log("Do not close the popup before getting response");
    };

    return (
        showPopup && (
            <GlobalSmallPopup onRequestClose={onRequestClose}>
                <PopupForm onSubmit={handleSubmit}>
                    <InputFieldArea>
                        <PopupSelectField>
                            <label>{translations.titleDiagnosis}</label>
                            <Select<SelectOption>
                                options={diagnosisOptions}
                                placeholder={translations.selectPlaceholder}
                                onChange={handleSelectChange}
                                value={
                                    selectedDiagnosis
                                        ? diagnosisOptions.find(
                                              (option) =>
                                                  option.value ===
                                                  selectedDiagnosis
                                          )
                                        : null
                                }
                                isSearchable={true}
                            />
                        </PopupSelectField>
                        <PopupButton
                            type="submit"
                            disabled={!selectedDiagnosis}
                        >
                            {translations.diagnosisButton}
                        </PopupButton>
                    </InputFieldArea>
                </PopupForm>
            </GlobalSmallPopup>
        )
    );
};
