// src/pages/analytics/translations/en.ts
import { AnalyticsTranslations } from './types';

export const enTranslations: AnalyticsTranslations = {
    title: "ANALYTICS INSIGHTS",
    sections: [
        {
            text: "Clinical Metrics is a Medical Simulation Product where users play the role of a junior doctor managing a caseload of emergency room patients. The simulation is designed to help junior doctors and medical students practice their clinical reasoning and learn how to prioritise patients in a busy emergency room. Yellow Bird Consulting owns this product and we are responsible for the full product design and development."
        },
        {
            text: "Virtual Patients are presented to the user in a simulated emergency room. The user is able to interact with the patient by asking questions (via a Chatbot), ordering tests, and prescribing medications. Patients get worse with time if left untreated. The user must manage their time and resources to treat as many patients as safely as possible."
        },
        {
            text: "The Virtual Patients are generated from underlying epidemiological data taken from published studies and expert opinion. The Virtual Patients are designed to be as realistic as possible, and the simulation is designed to be as close to real life as possible. The simulation is designed to be fun and engaging, while also being educational."
        },
        {
            text: "The process of developing this product taught as a lot as a company as we had to develop cutting edge processes for developing virtual patients, managing high throughput of users (classes of users all log in the same time), and managing the complexity of the simulation. We also had to develop a robust and scalable infrastructure to support the simulation. For this reason, we developed in-house development tools, concreted our practices in continous integration and testing, and moved to using Rust as our primary language for backend development."
        },
        {
            text: "For more information on this product, please visit the",
            includesLink: true
        }
    ],
    websiteLink: "#",
    websiteLinkText: "Clinical Metrics website",
    imageAlts: {
        clinicalMetrics: "Clinical Metrics platform interface",
        insideSim: "Inside the simulation environment",
        ecg: "ECG monitoring display",
        dashboard: "Analytics dashboard"
    }
};