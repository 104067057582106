import React, { useState } from "react";
import { LoginForm, FormData } from "./FormComponent/FormComponent";
import {
    ChosenRole,
    RoleChoices,
} from "./RoleChoiceComponent/RoleChoiceComponent";
import { RolesResponse } from "../../../core/ServerApiLayer/VOne/auth/login";
import {
    LoginResponse,
    loginUser,
    LoginUserType,
} from "../../../core/ServerApiLayer/VOne/auth/login";
import {
    updateRole,
    UpdateSessionRoleSchema,
    UpdateRoleType,
} from "../../../core/ServerApiLayer/VOne/auth/updateRole";
import {
    setFromApiData,
    SetFromApiDataType,
} from "../../../core/LocalStorage/AuthSession/core";

// @ts-ignore
import myImage from "../../../assets/outside.jpg";
// @ts-ignore
import logoImage from "../../../assets/clinical_metrics.png";
import { useNavigate } from "react-router-dom";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { LoginPageTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { useLanguage } from "../../../core/translations/LanguageContext";
import { Container, DivArea, Heading, LeftPanel, LogoImage, RightPanel, SectionWithZeroMargin } from "./LoginPage.styles";

/*
The props for the LoginPage component.

@prop setSessionData: a function that sets the session data in the local storage
@prop loginApiCall: a function that makes the login request to the backend
@prop updateRoleApiCall: a function that makes the update role request to the backend
 */
export interface LoginPageProps {
    setSessionData: SetFromApiDataType;
    loginApiCall: LoginUserType;
    updateRoleApiCall: UpdateRoleType;
}

const TRANSLATIONS: Record<SupportedLanguage, LoginPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const LoginPage: React.FC<LoginPageProps> = ({
    setSessionData,
    loginApiCall,
    updateRoleApiCall,
}) => {
    // useNavigate is a hook from react-router-dom that allows us to navigate to different pages
    const navigate = useNavigate();

    // role choices to pass into the role choice component
    const [roleChoices, setRoleChoices] = useState<RolesResponse>({
        institution_roles: [],
        department_roles: [],
    });

    // if login is true, we render the login form, otherwise we render the role choice form
    const [login, setLogin] = useState<boolean>(true);

    // define the state for the form data (will be passed into the login API call)
    const [formStatus, setFormStatus] = useState<FormData>({
        email: "",
        password: "",
        success: false,
        failure: false,
        error: "",
        loading: false,
    });

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    // handle the form submit event by calling the backend to login
    const handleFormSubmit = async (userData: FormData) => {
        // set the form to loading while the request is being made as hashing the password can take a while
        setFormStatus({ ...userData, loading: true });

        // login the user
        const response = await loginApiCall(userData.email, userData.password);
        if (response.status === 200 && response.data) {
            const loginResponse: LoginResponse = response.data;
            // store the token in the local storage
            setSessionData(
                loginResponse.user_session,
                loginResponse.jwt,
                loginResponse.refresh_token
            );

            // check to see if the user has more roles prompting role choice if multiple roles
            if (!loginResponse.roles) {
                setFormStatus({
                    ...userData,
                    success: true,
                    failure: false,
                    error: "",
                });
                return null;
            }
            // the user has multiple roles so one role needs to be chosen
            else {
                setRoleChoices({
                    institution_roles: loginResponse.roles.institution_roles,
                    department_roles: loginResponse.roles.department_roles,
                });
                setLogin(false);
                return null;
            }
        }
        // the initial login request failed
        else {
            // @ts-ignore
            setFormStatus({
                ...userData,
                success: false,
                failure: true,
                error: response.error ?? "",
                loading: false,
            });
            return null;
        }
    };

    // handle the role choice submit event by calling the backend to update the user's role for the session
    const handleRoleChoiceSubmit = async (data: ChosenRole) => {
        if (!data.role) {
            return null;
        }

        let schema: UpdateSessionRoleSchema;
        // is a department role
        if ("department_id" in data.role) {
            schema = {
                role: data.role.role_name,
                entity_type: "department",
                entity_id: data.role.department_id,
            };
        }
        // is an institution roles
        else if ("institution_id" in data.role) {
            schema = {
                role: data.role.role_name,
                entity_type: "institution",
                entity_id: data.role.institution_id,
            };
        } else {
            return null;
        }
        const response = await updateRoleApiCall(schema);
        if (response.status === 200 && response.data) {
            setFormStatus({
                ...formStatus,
                success: true,
                failure: false,
                error: "",
                loading: false,
            });
            setLogin(true);
            return null;
        } else {
            // @ts-ignore
            setFormStatus({
                ...formStatus,
                success: false,
                failure: true,
                error: response.error ?? "",
            });
            return null;
        }
    };

    // render the standard login form
    return (
        <Container backgroundImage={myImage}>
            <LeftPanel>
                <DivArea>
                    <LogoImage
                        src={logoImage}
                        alt="description"
                        onClick={() => navigate("/")}
                    />
                    {!formStatus.success && !formStatus.loading && (
                        <Heading>{translations.heading}</Heading>
                    )}
                </DivArea>
                {login && (
                    <SectionWithZeroMargin>
                        <LoginForm
                            onSubmit={handleFormSubmit}
                            initialFormData={formStatus}
                        />
                    </SectionWithZeroMargin>
                )}
                {!login && (
                    <SectionWithZeroMargin>
                        <RoleChoices
                            onSubmit={handleRoleChoiceSubmit}
                            initialRoleChoicesData={roleChoices}
                        />
                    </SectionWithZeroMargin>
                )}
            </LeftPanel>
            <RightPanel>{/*    */}</RightPanel>
        </Container>
    );
};

export default LoginPage;
