import { BloodTestPageTranslations } from "./types";

export const enTranslations: BloodTestPageTranslations = {
    pageTitle: "Blood Results",
    unitsTitle: "Units",
    normalRangeTitle: "Normal Range",
    timeTitle: "Time",
    units: {
        FL: "fl",
        GDL: "g/dl",
        GL: "g/l",
        LL: "L/L",
        MCGL: "µg/l",
        MCML: "µmol/l",
        MGDL: "mg/dl",
        MGL: "mg/l",
        MMHG: "mmHg",
        MMOL: "mmol/l",
        MMOLMOL: "mmol/mol",
        MLMIN: "ml/min",
        MUL: "mU/l",
        NGDL: "ng/dl",
        NGL: "ng/l",
        NGML: "ng/ml",
        NMOLL: "nmol/l",
        NOUNITS: "-",
        PERCENT: "%",
        PERMILLE: "‰",
        PG: "pg",
        PGML: "pg/ml",
        PMOLL: "pmol/l",
        SECONDS: "seconds",
        TEN9L: "10^9/l",
        UL: "U/l",
    },
};