// GAME LAYOUT TRANSLATIONS

// Internal imports
import { GameLayoutTranslations } from "../../components/game/GamePageLayout/translations/types";
import { enGameLayoutTranslations } from "../../components/game/GamePageLayout/translations/en";
import { deGameLayoutTranslations } from "../../components/game/GamePageLayout/translations/de";
import { SymptomsTranslations } from "./symptoms/types";
import { enSymptomsTranslations } from "./symptoms/en";
import { deSymptomsTranslations } from "./symptoms/de";
import { DiagnosisTypesTranslations } from "./diagnosis/types";
import { enDiagnosisTranslations } from "./diagnosis/en";
import { deDiagnosisTranslations } from "./diagnosis/de";
import { LocationTypesTranslations } from "./locations/types";
import { enLocationTranslations } from "./locations/en";
import { deLocationTranslations } from "./locations/de";
import { SupportedLanguage } from "./supportedLanguages";

// Define the translations for the game layout component, which
// includes the sidebar and the navbar
export const GAME_TRANSLATIONS: Record<
    SupportedLanguage,
    GameLayoutTranslations
> = {
    en: enGameLayoutTranslations,
    de: deGameLayoutTranslations,
};

// Define the translations for the game layout
export const SYMPTOMS_TRANSLATIONS: Record<
    SupportedLanguage,
    SymptomsTranslations
> = {
    en: enSymptomsTranslations,
    de: deSymptomsTranslations,
};

// Define the translations for the diagnosis
export const DIAGNOSIS_TRANSLATIONS: Record<
    SupportedLanguage,
    DiagnosisTypesTranslations
> = {
    en: enDiagnosisTranslations,
    de: deDiagnosisTranslations,
};

// Define the translations for the locations
export const LOCATION_TRANSLATIONS: Record<
    SupportedLanguage,
    LocationTypesTranslations
> = {
    en: enLocationTranslations,
    de: deLocationTranslations,
};
