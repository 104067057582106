// External imports
import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";

// Internal imports
import {
    ExitGameModalContainer,
    ExitGameModalContentContainer,
    ExitGameModalTitle,
    ExitGameModalSubTitle,
    ExitGameButton,
    CancelButton,
    ButtonContainer,
} from "./ExitGameModal.styles";

// Translation imports
import { useLanguage } from '../../../../core/translations/LanguageContext';
import { SupportedLanguage } from '../../../../core/translations/supportedLanguages';
import { ExitGameModalTranslations } from "../../../core/modal/exit-game-modal/translations/types";
import { enTranslations } from "../../../core/modal/exit-game-modal/translations/en";
import { deTranslations } from "../../../core/modal/exit-game-modal/translations/de";
import GlobalSmallPopup from "../../../core/modal/GlobalSmallPopup";

// Constants
const TRANSLATIONS: Record<SupportedLanguage, ExitGameModalTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Props interface for the ExitGameModal component
 * @interface ExitGameModalProps
 */
interface ExitGameModalProps {
    onExitGameClose: () => void;
    onSubmitExitGame: () => void;
}

/**
 * ExitGameModal component
 * Displays a modal with the class authentication code and copy functionality
 * @param {ExitGameModalProps} props - Component props
 * @returns {React.ReactElement} Rendered component
 */
const ExitGameModal: React.FC<ExitGameModalProps> = ({
    onSubmitExitGame,
    onExitGameClose,
                                                             }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];    

    return (
        <GlobalSmallPopup onRequestClose={onExitGameClose}>
            <ExitGameModalContainer>
                <ExitGameModalContentContainer>
                    <ExitGameModalTitle>
                        {translations.title}
                    </ExitGameModalTitle>
                    <ExitGameModalSubTitle>
                        {translations.subtitle}
                    </ExitGameModalSubTitle>
                    <ButtonContainer>
                    <ExitGameButton onClick={onSubmitExitGame}>{translations.leaveText}</ExitGameButton>
                    <CancelButton onClick={onExitGameClose}>{translations.cancelText}</CancelButton>
                    </ButtonContainer>
                </ExitGameModalContentContainer>
            </ExitGameModalContainer>
        </GlobalSmallPopup>
    );
};

export default ExitGameModal;