// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { noteData } from "../../../LocalStorage/patients/interface";

/*
The payload for posting a note.
 */
export interface PostNotePayload {
    turn: number;
    patient_id: number;
    entry_type: string;
    text_body: string;
}

/*
The interface for the response from the post note endpoint.
 */
export interface PostNoteResponse {
    status: number;
    error?: string;
    data?: noteData["documentation"];
}

export type PostNoteType = (
    jwt: string,
    postNotePayload: PostNotePayload
) => Promise<PostNoteResponse>;

/*
The function to post a note for a patient.

@jwt: The JWT of the user requesting the post.
@postNotePayload: The payload containing the turn, patient ID, note details, and entry type.

@returns: The ApiResponse from the post note endpoint. This contains all the previous notes and the new note.
 */
export async function postNote(
    jwt: string,
    postNotePayload: PostNotePayload
): Promise<PostNoteResponse> {
    const url = new PatientUrl().postNote;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<noteData | string>(
            url,
            {
                turn: postNotePayload.turn,
                patient_id: postNotePayload.patient_id,
                entry_type: postNotePayload.entry_type,
                text_body: postNotePayload.text_body,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    token: jwt,
                },
                validateStatus: () => true,
            }
        );
    } catch (error) {
        return {
            status: 500,
            error: "Network or other error occurred",
        };
    }

    if (response.status === 201) {
        return {
            status: response.status,
            data: response.data.documentation,
        };
    } else {
        return {
            status: response.status,
            error: response.data as string,
        };
    }
}
