import React from "react";
import { Modal } from "../Modal";
import {
    GuidelinesModalContainer,
    GuidelinesModalContentContainer,
    GuidelinesModalInputField,
    GuidelinesModalNotFoundContainer,
    GuidelinesModalSearchContentContainer,
} from "./GuidelinesModal.styles";
import Spinner from "../../spinner/Spinner";
import useResources from "./hooks/useResources";
import GuidelineItem from "./components/GuidelineItem/GuidelineItem";
import { SupportedLanguage } from "../../../../core/translations/supportedLanguages";
import { Guidelines } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { useLanguage } from "../../../../core/translations/LanguageContext";

/**
 * GuidelinesModal Props.
 * @interface GuidelinesModalProps
 * @property {() => void} onClose - The function to close the modal.
 */
interface GuidelinesModalProps {
    onClose: () => void;
}

/**
 * GuidelinesModal component.
 * @component GuidelinesModal
 * @description A modal component for displaying guidelines.
 * @param {GuidelinesModalProps} props - The component props.
 * @returns {JSX.Element} The rendered GuidelinesModal component.
 */
function GuidelinesModal({ onClose }: GuidelinesModalProps) {
    // State to store the input text.
    const [text, setText] = React.useState("");

    // State to store the loading state.
    const [loading, setLoading] = React.useState(false);

    const TRANSLATIONS: Record<SupportedLanguage, Guidelines> = {
        en: enTranslations,
        de: deTranslations,
    };
    
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    // Function to handle the input change.
    const onSearchText = (event: React.ChangeEvent<HTMLInputElement>) =>
        setText(event.target.value);

    const { resources } = useResources({ search: text });

    return (
        <Modal width="fit-content" onRequestClose={onClose}>
            <GuidelinesModalContainer>
                <GuidelinesModalContentContainer>
                    <GuidelinesModalInputField
                        type="search"
                        autoFocus
                        placeholder={translations.searchPlaceholder}
                        value={text}
                        onChange={onSearchText}
                    />

                    <GuidelinesModalSearchContentContainer>
                        {loading ? (
                            <Spinner />
                        ) : translations.guideline.length > 0 ? (
                            translations.guideline.map((res) =>
                                res.resources.map((res) => (
                                    <GuidelineItem key={res.url} {...res} />
                                ))
                            )
                        ) : (
                            <GuidelinesModalNotFoundContainer>
                                {translations.noResult}
                            </GuidelinesModalNotFoundContainer>
                        )}
                    </GuidelinesModalSearchContentContainer>
                </GuidelinesModalContentContainer>
            </GuidelinesModalContainer>
        </Modal>
    );
}

export default GuidelinesModal;
