import axios, { AxiosResponse } from "axios";
import { ClassUrl } from "./AUrl";
import { getJwt } from "../../../LocalStorage/AuthSession/core";

/*
The interface for the response data containing information about a class.

@id: The id of the class.
@name: The name of the class.
@teacher_id: The id of the teacher that created the class.
@auth_code: The auth code for the class for students to join.
@entity_id: The id of the institution/department the class is associated with.
@entity_type: The type of entity the class is associated with (institution or department).
@students_enrolled: The number of students enrolled in the class.
@games_played: The number of games played by the class.
@date_created: The date the class was created.
@date_opened: The date the class was opened.
@date_closed: The date the class was closed.
 */
export interface ClassRegistry {
    id: number;
    name: string;
    teacher_id: number;
    auth_code: string;
    entity_id: number;
    entity_type: string;
    students_enrolled: number;
    games_played: number;
    date_created: string; // Assuming this will be a string in the format of NaiveDateTime
    date_opened?: string; // Nullable fields for optional dates
    date_closed?: string;
}

/*
The interface for the response from the get all classes endpoint.

@status: The HTTP status code.
@error: The error message.
@data: The data returned from the endpoint.
 */
export interface GetAllClassesResponse {
    status: number;
    error?: string;
    data?: ClassRegistry[];
}


// Function Signature
export type GetAllClassesType = (jwt: string) => Promise<GetAllClassesResponse>;

/*
The function to get all classes.

@jwt: The JWT of the user requesting the classes.

@returns: The ApiResponse from the get all classes endpoint.
 */
export async function getAllClasses(jwt: string): Promise<GetAllClassesResponse> {
    const url = new ClassUrl().getAll;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    let response: AxiosResponse<any>;
    try {
        response = await axios.get<GetAllClassesResponse | string>(url, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            // Resolve the promise for all HTTP status codes
            validateStatus: () => true
        });

    } catch (error) {
        // Handle network or other Axios-related errors
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 200) {
        // Successful response
        return {
            status: response.status,
            data: response.data as ClassRegistry[]
        };
    } else {
        // Other response codes with a string message
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
