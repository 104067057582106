import { Patient, ObservationReadings, ObservationData } from "./interface";
import { getSpecificPatientData, updatePatientData } from "./core";

// Function signature type
export type SetObservationDataType = (
    patientId: number,
    observationData: ObservationReadings
) => void;

/*
Set the observation data for a patient in localStorage. This updates the patient's observation data
for the specified turn.

@patientId: The ID of the patient whose observation data we want to update.
@turn: The turn number for which the observation data was recorded.
@observationData: The observation data to store.
*/
export function setObservationData(
    patientId: number,
    observationData: ObservationReadings
): void {
    // Get the current patient data from localStorage
    let patient: Patient | null = getSpecificPatientData(patientId);
    if (patient) {
        // If the patient already has observations, update them; otherwise, initialize the observations
        if (!patient.observations) {
            patient.observations = {};
        }
        patient.observations = observationData;

        // Update the patient data in localStorage
        updatePatientData(patientId, patient);
    } else {
        console.error(
            `Patient with ID ${patientId} not found in localStorage.`
        );
    }
}

// Function signature type
export type GetObservationDataType = (
    patientId: number
) => ObservationReadings | null;

/*
Get the observation data for a specific patient and turn.

@patientId: The ID of the patient whose observation data we want to retrieve.
@turn: The turn number for which the observation data was recorded.

@return: The observation data for the given patient and turn, or null if not found.
*/
export function getObservationData(patientId: number): ObservationReadings {
    // Get the current patient data from localStorage
    const patient: Patient | null = getSpecificPatientData(patientId);

    // If patient data or observations are missing, return an empty object
    if (!patient || !patient.observations) {
        return {};
    }

    // Return the observation data for the patient
    return patient.observations;
}
