// External imports
import React, { useEffect, useRef, useState } from "react";

// Internal imports
// @ts-ignore
import { ChatComponent, FormData } from "../FormComponent/FormComponent";
import ChatLayout from "./ChatLayout/ChatLayout";
import LoadingScreen from "./LoadingScreens/LoadingScreen";
import { FormSection, MessagesContainer } from "./ChatLayoutWithForm.styles";

/**
 * Interface for the ChatLayoutWithForm component
 */
export interface ChatProps {
    messagesHistory: Array<any>;
    onSubmit: (data: FormData) => void;
    isDisabled: boolean;
    translations: any;
}

const ChatLayoutChatLayoutWithForm: React.FC<ChatProps> = ({
    messagesHistory,
    onSubmit,
    isDisabled,
    translations,
}) => {
    const [formStatus, setFormStatus] = useState<FormData>({ message: "" });

    // This function is called when the form is submitted
    const handleFormSubmit = (userData: FormData) => {
        // Call the parent submission handler
        onSubmit(userData);
        // Reset local form status
        setFormStatus({ message: "" });
    };

    useEffect(() => {
        const element = document.getElementById("chat-end"); // Target the element by its id
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, [messagesHistory]);

    return isDisabled ? (
        <LoadingScreen translations={translations} />
    ) : (
        <FormSection>
            <MessagesContainer className={`${
                    messagesHistory.length === 0 && 'noScroller'
                }`}>
                <ChatLayout messagesHistory={messagesHistory} />
            </MessagesContainer>
            <ChatComponent
                onSubmit={handleFormSubmit}
                initialFormData={formStatus}
                isDisabled={isDisabled}
                translations={translations}
            />
        </FormSection>
    );
};

export default ChatLayoutChatLayoutWithForm;
