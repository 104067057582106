// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { ClassUrl } from "./AUrl";

/*
The payload for reviewing a class.
 */
export interface ReviewClassPayload {
    class_id: number;
}

/*
The interface for the response from the review class endpoint.

@status: The HTTP status code.
@error: The error message.
@data: The object containing the review details for the class.
 */
export interface ReviewClassResponse {
    status: number;
    error?: string;
    data?: any;
}

/* Function Signature */
export type ReviewClassType = (jwt: string, reviewClassPayload: ReviewClassPayload) => Promise<ReviewClassResponse>;

/*
The function to review a class.

@jwt: The JWT of the user requesting the review.
@reviewClassPayload: The payload containing the class ID.

@returns: The ApiResponse from the review class endpoint. This contains the review details for the class.
 */
export async function reviewClass(jwt: string, reviewClassPayload: ReviewClassPayload): Promise<ReviewClassResponse> {
    const url = new ClassUrl().reviewClass;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired or is missing" };
    }
    try {
        response = await axios.post<ReviewClassData | string>(url, reviewClassPayload, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });
    } catch (error) {
        return { status: 500, error: "Network or other error occurred" };
    }

    // Check the response status, and return the appropriate response.
    if (response.status === 200) {
        return {
            status: response.status,
            data: response.data as ReviewClassData
        };
    } else if (response.status === 403) {
        return {
            status: response.status,
            error: response.data === "User is not the teacher of the class"
                ? "User is not the teacher of the class"
                : "User is not logged in as a teacher"
        };
    } else if (response.status === 500 && typeof response.data === "string" && response.data.includes("Class ID not recognised")) {
        return {
            status: response.status,
            error: `Class ID not recognised: ${reviewClassPayload.class_id}`
        };
    } else {
        return {
            status: response.status,
            error: response.data
        };
    }
}
