import styled from "@emotion/styled";

export const GuidesPageContainer = styled.div`
    max-width: 80rem;
    padding: 1rem 2rem;
    margin: 20px auto;
`;
export const GuidesPageTitle = styled.h1`
    color: #173b5e;
    @media (max-width:767px) {
        padding-bottom: 15px;
    }
`;
export const GuidesPageHeadingMain = styled.div`
`;
export const GuidesPageHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:767px) {
        display: block;
        text-align: center;
    }
`;
export const GuidesPageSearchField = styled.div`
    position: relative;
`;
export const GuidesPageSearchFieldSpan = styled.span`
    position: absolute;
    width: 60px;
    height: 40px;
    border-radius: 20px;
    background: #d9e8f8;
    & > svg {
        position: absolute;
        top: 10px;
        left: 20px;
        color: #aebac6; 
        @media (min-width:768px) and (max-width:1024px) {
            left: 60px;
        }
        @media (max-width:767px) {
            left: 60px;
        }
        @media (max-width:360px) {
            left: 15px; 
        }
    }
`;
export const GuidesPageSearchFieldInput = styled.input`
    background: #d9e8f8;
    height: 40px; 
    border-radius: 30px;
    border: none;
    width: 288px;
    font-size: .98em;
    padding: 10px;
    text-align: center;
    &::-webkit-input-placeholder {
        color: #173b5e;
    }
    &:focus {
        border: none;
        outline-color: #173b5e;
        outline-style: solid;
    }
    @media (max-width:360px) {
        width: 100%;
    }
`;
export const GuidesPageBackgroundSection = styled.div`
    background: #e0ecf0;
`;
export const GuidesPageCardContainer = styled.div`
    display: flex;
    gap: 40px;
    margin: 15px 0px;
    flex-wrap: wrap;
`;
export const GuidesPageCard = styled.div`
    flex: 0 0 31.1%;
    a{
        text-decoration: none;
        color: inherit;
    }
    @media (min-width:768px) and (max-width:1024px) {
        flex: 0 0 29%;
    }
    @media (max-width:767px) {
        flex: 0 0 100%; 
    }
`;
export const GuidesPageCardFigure = styled.div`
    background: white;
    border-radius: 20px;
`;
export const GuidesPageCardFigureImage = styled.img`
    width: 100%;
    height: 13rem;
    border-radius: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @media (min-width:768px) and (max-width:1024px) {
        height: 13rem; 
        object-fit: cover; 
    }
    @media (max-width:767px) {
        object-fit: cover;
        height: 10rem;
    }
`;
export const GuidesPageCardTitle = styled.h5`
    color: #1681ff;
    padding-bottom: 15px;
    font-family: 'Inter';
`;
export const GuidesPageCardDescription = styled.div`
    padding: 20px 22px;
    border-radius: 25px;
    position: relative;
    background: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
    & > svg {
        position: absolute;
        right: 15px;
        top: 13px;
        font-size: 25px;
        @media (min-width:834px) and (max-width:900px) {
            position: absolute;
            right: 9px;
            top: 46px;
            font-size: 25px;
        }
        @media (max-width:767px) {
            right: 7px;
            top: 48px;
        }
    }
`;
export const GuidesPageCardDescriptionParagraph = styled.p`
    line-height: 1.5rem;
    font-family: 'Inter';
    padding-top: 15px;
`;
export const GuidesPageCardDescriptionSubTitle = styled.h2`
    display: -webkit-box;
    font-family: 'Inter';
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #232242;
    font-size: 1.25rem;
    overflow: hidden;
`;