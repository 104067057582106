import React from "react";
import Select, { Props } from "react-select";

export interface SelectOption {
    label: string;
    value: any;
}

interface SelectBoxProps extends Props {
    defaultValue?: SelectOption[];
    value?: any;
    options: SelectOption[];
    isMulti?: boolean;
    placeholder?: string;
    onChange?: (value: any) => void;
}

function SelectBox({
    defaultValue,
    value,
    isMulti = false,
    placeholder,
    options,
    onChange,
}: SelectBoxProps) {
    return (
        <Select
            menuPosition="fixed"
            isMulti={isMulti}
            options={options}
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            onChange={(state: any) => (onChange ? onChange(state) : () => {})}
            styles={{
                menu: (provided, state) => ({
                    ...provided,
                    color: "black",
                }),
                menuPortal: ({ left, top, ...provided }, state) => ({
                    ...provided,
                }),
            }}
        />
    );
}

export default SelectBox;
