import styled from "@emotion/styled";

export const ScrubberContainer = styled.div`
    position: relative;
    cursor: ns-resize;
    background-color: white;
    width: 4rem;
    border: 1px solid gray;
    &:focus {
        border-color: blue;
        outline: none;
    }
    border-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const ScrubberInputField = styled.input`
    padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    color: black;
    background-color: white;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-width: 1px;
`;
