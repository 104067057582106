import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

export interface SpinnerProps {
    size?: string;
    style?: React.CSSProperties;
}

const SpinAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const SpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SpinnerDiv = styled.div<{ size: string }>`
    display: inline-block;
    animation: ${SpinAnimation} 1.5s linear infinite;
    border-radius: 50%;
    border: 2px solid currentColor;
    border-right-color: transparent;
    width: ${(props) => props.size}em;
    height: ${(props) => props.size}em;
`;

const SpinnerText = styled.span`
    position: absolute;
    margin: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    padding: 0;
    clip: rect(0, 0, 0, 0);
`;

export default function Spinner(props: SpinnerProps) {
    const size = props.size ?? "4";
    return (
        <SpinnerWrapper style={props.style}>
            <SpinnerDiv size={size} role="status">
                <SpinnerText>Loading...</SpinnerText>
            </SpinnerDiv>
        </SpinnerWrapper>
    );
}
