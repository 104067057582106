import React from "react";
import { DiseaseTranslations, DiseaseName, DiseaseCategory } from '../../../../../../core/translations/diseases/types';
import { DiseaseChoicesCheckbox } from "../DiseaseChoicesCheckbox/DiseaseChoicesCheckbox";
import { CategoryButtonContainer } from "../DiseaseChoicesComponent.styles";
import { DiseaseChoiceState } from "../../../../../../core/metaDataAccess/classes/diseaseChoices/state";

interface CategoryListProps {
    ChangeStateHandle: () => void;
    DiseaseState: DiseaseChoiceState;
    ChosenDisease: DiseaseCategory;
    diseaseTranslations: DiseaseTranslations;
}

export const CategoryList: React.FC<CategoryListProps> = ({
                                                              ChangeStateHandle,
                                                              DiseaseState,
                                                              ChosenDisease,
                                                              diseaseTranslations
                                                          }) => {
    const handleCategoryChange = (category: DiseaseCategory) => {
        DiseaseState.changeCategory(category);
        ChangeStateHandle();
    };

    const handleAllCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, category: DiseaseCategory) => {
        DiseaseState.getCategory(category).checkAll();
        ChangeStateHandle();
    };

    return (
        <div>
            {Array.from(DiseaseState.categories.values()).map((category) => (
                <CategoryButtonContainer
                    key={category.category}
                    selected={DiseaseState.selectedCategory === category.category}
                    onClick={() => handleCategoryChange(category.category)}
                >
                    <DiseaseChoicesCheckbox
                        label={diseaseTranslations.categories[category.category]}
                        isGroup
                        checked={
                            category.allChecked
                                ? true
                                : category.getChecked().length > 0
                                    ? "partial"
                                    : false
                        }
                        onChange={(e) => {
                            e.stopPropagation();
                            handleAllCheckboxChange(e, category.category);
                        }}
                    />
                </CategoryButtonContainer>
            ))}
        </div>
    );
};