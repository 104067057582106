import styled from "@emotion/styled";

export const Label = styled.label`
    padding:20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius:20px;
    user-select: none;
    font-size: 1.2em;
    gap: 10px;
`;
export const LabelText = styled.span`
    font-weight: 600;
    color: #444;
`;
export const Description = styled.p`
    font-size: 0.875em;
    line-height: 1.5em;
    color: gray;
    max-width: 26em;
`;
export const Flex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const Container = styled.div`
    padding: 4em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3em;
    width: 100%;
`;
