// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";

// Payload structure for posting blood orders
export interface PostBloodsPayload {
    patient_id: number;
    turn: number;
    blood_list: string[];
    reason?: string; // Reason is optional
}

// Response structure for the postBloods request
export interface PostBloodsResponse {
    status: number;
    error?: string;
    data?: {
        blood_order: {
            tests: {
                [turn: string]: {
                    results: {
                        [test: string]: {
                            value: number;
                            units: string;
                            status: string;
                        };
                    };
                };
            };
        };
        docs: {
            [turn: string]: {
                blood_list: string[];
                reason: string;
            };
        };
    };
}

/* Function Signature */
export type PostBloodsType = (jwt: string, postBloodsPayload: PostBloodsPayload) => Promise<PostBloodsResponse>;

/*
Function to post blood orders for a patient.

@jwt: The JWT token of the user.
@postBloodsPayload: The payload containing patient_id, turn number, blood_list, and an optional reason.

@returns: The ApiResponse from the post blood order endpoint.
*/
export async function postBloods(jwt: string, postBloodsPayload: PostBloodsPayload): Promise<PostBloodsResponse> {
    const url = new PatientUrl().postBlood;
    let response: AxiosResponse<any>;

    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    try {
        response = await axios.post(url, {
            patient_id: postBloodsPayload.patient_id,
            turn: postBloodsPayload.turn,
            blood_list: postBloodsPayload.blood_list,
            reason: postBloodsPayload.reason || "No reason provided"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });
    } catch (error) {
        return { status: 500, error: "Network or other error occurred" };
    }

    if (response.status === 201) {
        return {
            status: response.status,
            data: response.data
        };
    } else {
        return {
            status: response.status,
            error: response.data
        };
    }
}
