import { bloodData, individualBloodData } from "./interface";
import { getSpecificPatientData, updatePatientData } from "./core";


// Function signature type for setting to local storage
export type SetBloodsToLocalStorageType = (patient_id: number, newBloodData: bloodData) => void;


/*
Set the blood data for a patient in localStorage.
 */
export function setBloodsToLocalStorage(patient_id: number, newBloodData: bloodData): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // change all the turn numbers into integers so they can be sorted
    let newBloodDataKeys = Object.keys(newBloodData).map((key) => parseInt(key));

    // sort the turn numbers
    newBloodDataKeys.sort((a, b) => a - b);

    // create a new blood data object
    let newBloodDataObject: bloodData = {};
    newBloodDataKeys.forEach((key) => {
        newBloodDataObject[key] = newBloodData[key];
    });

    // Save the new blood data
    patientData.bloods = newBloodDataObject;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}


/*
Function signature type for getting from local storage
 */
export type GetBloodsFromLocalStorageType = (patient_id: number) => bloodData | null;


// Function to get the blood data for a patient from localStorage
export function getBloodsFromLocalStorage(patient_id: number): bloodData | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // Return the blood data, or null if no data is found
    return patientData?.bloods || null;
}

