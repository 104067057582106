import { Guidelines } from "./types";

export const deTranslations: Guidelines = {
    guideline: [
        {
            guideline_key: "HEART_FAILURE",
            searchTerms: "Herzinsuffizienz",
            alternativeSearchTerms:
                "kongestive Herzinsuffizienz, linksseitige Herzinsuffizienz",
            resources: [
                {
                    name: "Patient.Info",
                    disease: "Herzinsuffizienz",
                    url: "https://patient.info/doctor/heart-failure-diagnosis-and-investigation",
                    year: 2018,
                    tags: ["patient"],
                },
                {
                    name: "NICE Richtlinien",
                    disease: "Chronische Herzinsuffizienz",
                    url: "https://www.nice.org.uk/guidance/ng106",
                    year: 2018,
                    tags: ["nice"],
                },
            ],
        },
        {
            guideline_key: "ISCHAEMIC_STROKE",
            searchTerms: "Schlaganfall",
            alternativeSearchTerms: "ischämischer Schlaganfall",
            resources: [
                {
                    name: "NICE Richtlinien zur Initialbehandlung",
                    disease: "Ischämischer Schlaganfall",
                    url: "https://www.nice.org.uk/guidance/ng128/chapter/Recommendations#initial-management-of-suspected-and-confirmed-tia",
                    year: 2019,
                    tags: ["nice"],
                },
            ],
        },
    ],
    searchPlaceholder:"Nach einem Begriff suchen...",
    noResult: "Keine Ergebnisse gefunden",
};
