// External Imports
import React, { useRef } from "react";

// Internal Imports
import { Modal } from "../../../../components/core/modal/Modal";
import {
    DisclaimerModalBoldText,
    DisclaimerModalContainer,
    DisclaimerModalContentContainer,
    DisclaimerModalParagraph,
    DisclaimerModalTitle,
    DisclaimerModalBtns,
    PrintableArea
} from "./DisclaimerModal.styles";
import WebsiteButton from "../../../../components/core/button/WebsiteButton";
import PrintButton from "../../../../components/core/button/print/PrintButton";


/**
 * Props for the DisclaimerModal component
 */
interface DisclaimerModalProps {
    onRequestClose: () => void;
}


/**
 * A modal that displays a disclaimer. This contains a disclaimer for the game, with 
 * a single button to close the modal.
 * 
 * @param onClose - A function to call when the modal is closed
 */
export const DisclaimerModal: React.FC<DisclaimerModalProps> = ({ onRequestClose }) => {

    /**
 * Include useRef at the top of the page and create const from it to include on page section.
 * After adding this to printable section pass the same in PrintButton component 
 * Then print button will print the section provided to it.
 */

    const componentRef = useRef();
    // Logic to close the modal
    const closeModal = () => {
        onRequestClose();
    }

    return (
        <Modal onRequestClose={closeModal}>
            <DisclaimerModalContainer>
                <DisclaimerModalContentContainer>
                    {/* @ts-ignore */}
                    <PrintableArea ref={componentRef}>
                        <DisclaimerModalTitle>Disclaimer</DisclaimerModalTitle>
                        <DisclaimerModalParagraph>
                            All information made available by and through the{" "}
                            <DisclaimerModalBoldText>
                                Clinical Metrics
                            </DisclaimerModalBoldText>{" "}
                            Website is maintained and updated in good faith for
                            general guidance and educational purposes only and does
                            not guarantee any specific results with regards to
                            assisting or improving any particular learning,
                            training, or educational outcomes. All simulated patient
                            data that is made available in the virtual emergency
                            department is computer generated and completely
                            fictitious, and any similarity of the same with real
                            life patient or patient data is purely coincidental.
                        </DisclaimerModalParagraph>
                        <DisclaimerModalParagraph>
                            <DisclaimerModalBoldText>
                                Clinical Metrics
                            </DisclaimerModalBoldText>{" "}
                            (including its directors, shareholders, employees and
                            agents) is{" "}
                            <DisclaimerModalBoldText>NOT</DisclaimerModalBoldText>{" "}
                            registered as a licensed medical practitioner with any
                            regulatory authority in any jurisdiction and{" "}
                            <DisclaimerModalBoldText>
                                DOES NOT
                            </DisclaimerModalBoldText>{" "}
                            provide medical advice, treatment or diagnosis under any
                            circumstances whatsoever. You are solely responsible for
                            determining whether the Website contents are appropriate
                            for you or your specific purpose, based on your personal
                            objectives and circumstances.
                        </DisclaimerModalParagraph>
                        <DisclaimerModalParagraph>
                            <DisclaimerModalBoldText>
                                Clinical Metrics
                            </DisclaimerModalBoldText>{" "}
                            makes no representation as to the completeness or
                            accuracy of any information in the Website and nothing
                            contained therein shall be considered as a referral,
                            endorsement, recommendation, verification, warranty or
                            guarantee with respect to the {""}
                            {[
                                "validity",
                                "accuracy",
                                "availability",
                                "completeness",
                                "safety",
                                "legality",
                                "quality",
                                "applicability",
                            ].map((text, index) => (
                                <>
                                    {index > 0 && ", "}
                                    {index === 7 && " or "}
                                    <DisclaimerModalBoldText key={index}>
                                        {text}
                                    </DisclaimerModalBoldText>
                                </>
                            ))}{" "}
                            of any information contained in the Website.
                        </DisclaimerModalParagraph>
                        <DisclaimerModalParagraph>
                            In case the Website contains any external weblinks,{" "}
                            <DisclaimerModalBoldText>
                                Clinical Metrics
                            </DisclaimerModalBoldText>{" "}
                            attempts but does not guarantee that such links will be
                            investigated, monitored, or checked for accuracy,
                            adequacy validity, reliability, availability or
                            completeness at all times. You should neither act, nor
                            refrain from acting, solely on the basis of any
                            information available on the Website and/or any of its
                            affiliated external weblinks.
                        </DisclaimerModalParagraph>
                        <DisclaimerModalParagraph>
                            <DisclaimerModalBoldText>
                                Clinical Metrics
                            </DisclaimerModalBoldText>{" "}
                            cannot and will not have any liability to you for any
                            loss or damage of any kind incurred as a result of the
                            use (or inability to use) of the Website or reliance on
                            any content and information provided on the Website.{" "}
                            <DisclaimerModalBoldText>
                                YOUR USE OF THE WEBSITE AND YOUR RELIANCE ON ANY
                                CONTENT THEREIN IS SOLELY AND COMPLETELY AT YOUR OWN
                                RISK.
                            </DisclaimerModalBoldText>
                        </DisclaimerModalParagraph>
                        <DisclaimerModalParagraph>
                            By continuing to use and access the{" "}
                            <DisclaimerModalBoldText>
                                Clinical Metrics
                            </DisclaimerModalBoldText>{" "}
                            Website you hereby acknowledge and agree with all of the
                            above. For further details about the Website and its
                            usage, we encourage you to take a look at our{" "}
                            <DisclaimerModalBoldText>
                                Terms & Conditions, Privacy Policy and Cookies
                                Policy{" "}
                            </DisclaimerModalBoldText>
                            as well.
                        </DisclaimerModalParagraph>
                    </PrintableArea>
                    <DisclaimerModalBtns>
                        <WebsiteButton tag="button" onClick={onRequestClose}>
                            Close
                        </WebsiteButton>
                        {/* @ts-ignore */}
                        <PrintButton printableSection={componentRef} />
                    </DisclaimerModalBtns>
                </DisclaimerModalContentContainer>
            </DisclaimerModalContainer>
        </Modal>
    );
}

export default DisclaimerModal;
