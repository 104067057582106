// External imports
import React from "react";

// Internal imports
import ScrubberInput from "../../../../../components/core/scrubber-input/ScrubberInput";
import { Flex, StyledInput } from "./NewClassFormDetails.styles";
import { NewClassFormLabelledField } from "./NewClassFormLabelledField";

// Translation imports
import { useLanguage } from '../../../../../core/translations/LanguageContext';
import { FormDetailsTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../../../core/translations/supportedLanguages';

// Constants
const TRANSLATIONS: Record<SupportedLanguage, FormDetailsTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const DEFAULT_PATIENT_COUNT = 3;
const DEFAULT_ARRIVAL_INTERVAL = 20;

/**
 * Props interface for the NewClassFormDetails component
 * @interface FormDetailsProps
 */
interface FormDetailsProps {
    name: string;
    setName: (value: string) => void;
    patientCount: number;
    handlePatientCountChange: (eventish: React.ChangeEvent<HTMLInputElement>) => void;
    patientArrivalInterval: number;
    handlePatientArrivalIntervalChange: (eventish: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * NewClassFormDetails component
 * Handles input for class name, patient count, and patient arrival interval
 * Default values: 3 patients, 20 time units between arrivals
 *
 * @param {FormDetailsProps} props - Component props
 * @returns {React.ReactElement} Rendered component
 */
const NewClassFormDetails: React.FC<FormDetailsProps> = ({
                                                             name,
                                                             patientCount = DEFAULT_PATIENT_COUNT,
                                                             handlePatientCountChange,
                                                             setName,
                                                             patientArrivalInterval = DEFAULT_ARRIVAL_INTERVAL,
                                                             handlePatientArrivalIntervalChange
                                                         }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    /**
     * Ensures numeric values are valid, replacing NaN with defaults
     */
    const safePatientCount = isNaN(patientCount) ? DEFAULT_PATIENT_COUNT : patientCount;
    const safeArrivalInterval = isNaN(patientArrivalInterval) ? DEFAULT_ARRIVAL_INTERVAL : patientArrivalInterval;

    return (
        <Flex>
            <NewClassFormLabelledField label={translations.classNameLabel}>
                <StyledInput
                    type="text"
                    name="className"
                    placeholder={translations.classNamePlaceholder}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    id="class-name-input"
                />
            </NewClassFormLabelledField>
            <NewClassFormLabelledField label={translations.patientCountLabel}>
                <ScrubberInput
                    name="patientCount"
                    min={1}
                    max={100}
                    value={safePatientCount}
                    onChange={handlePatientCountChange}
                    id="patient-count-input"
                />
            </NewClassFormLabelledField>
            <NewClassFormLabelledField label={translations.arrivalIntervalLabel}>
                <ScrubberInput
                    name="patientArrivalInterval"
                    min={1}
                    max={100}
                    value={safeArrivalInterval}
                    onChange={handlePatientArrivalIntervalChange}
                    id="patient-arrival-interval"
                />
            </NewClassFormLabelledField>
        </Flex>
    );
};

export default NewClassFormDetails;