import styled from "@emotion/styled";

export const ObservationsPageContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;
export const ObservationsContainer = styled.div`
    font-family: "Helvetica", "Arial", sans-serif;
    width: 100%;
`;
export const GraphContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
`;
