import styled from "@emotion/styled";

export const AllergyModalContainer = styled.div`
    width: 100%;
    gap: 1rem;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const AllergyModalContentContainer = styled.div`
    overflow-y: auto;
    max-height: 35em;
`;

export const AllergyModalTitle = styled.h1`
    font-size: 22px;
    margin-top: 5px;
`;

export const AllergyModalContentWrapper = styled.div`
    margin-top: 1.5rem;
`;

export const AllergyModalButtonContainer = styled.div`
    display: flex;
    gap: 5px;
    justify-content: center;
`;

export const AllergyModalButtonYes = styled.button`
    background-color: #0284d2;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    color: white;
`;

export const AllergyModalButtonNo = styled.button`
    background-color: #99a1a5;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    color: white;
`;

export const InputAreaContainer = styled.div`
        margin-bottom: 15px;
`;

export const AllergyTextArea = styled.textarea`
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    resize: none;
    &:focus {
        border-color: #888;
        outline: none;
    }
`;