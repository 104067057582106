import { InvestigationPageTranslations } from './types';

export const enTranslations: InvestigationPageTranslations = {
    title: "Investigation",
    investigation: "Investigations",
    priority: "Priority",
    justification: "Justification",
    submit: "Order Investigation",
    clear: "Clear",
    pendingResults: "Pending Results",
    availableResults: "Available Results",
    viewImage: "View Image",
    viewReport: "View Report",
    noReason: "No reason provided",
    investigationReport: "Investigation Report for",
    reasonForInvestigation: "Reason for Investigation",
    report: "Report:",
    imageAvailable: "Image Available:",
    clickToView: "Click to view",
    notesSection: "Notes Section",
    addAnyNotes: "Add any notes",
    save: "Save",
    review: "Reviewed",
    clickButtonToViewImage: "Click button to view image",
    timeOrdered: "Time Ordered",
    getRadiologyHelp: "Get Radiology Help",
};