import styled from "@emotion/styled";

export const PressContainer = styled.div`
    margin-bottom: 3rem;
    background: #f1f7ff;
    padding-bottom: 3rem;
`;

export const PressBreadcrumbContainer = styled.div`
    background-color: rgb(241 247 255 / 1);
    padding-top: 2.5rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    max-width: 80%;
`;

export const PressBreadcrumbChild = styled.div`
    margin-left: 18em;
`;

export const PressBreadcrumbList = styled.ul`
    display: flex;
    gap: 0.5rem;
    list-style-type: none;

    li + li + li {
        color: #adb2b9;
        text-decoration: none;
        font-size: 14px;
    }

    li + li {
        text-decoration: none;
        color: #e5e7eb;
        font-size: 14px;
    }
`;

export const PressBreadcrumbListItem = styled.li`
    color: #1266c5a8;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        text-decoration-line: underline;
        text-decoration-color: #1266c5;
        color: #1266c5;
    }
`;

export const PressBreadcrumbTitle = styled.h1`
    color: rgb(0 33 71);
    margin-top: 1rem;
    font-size: 3em;
`;

export const PressContentContainer = styled.div`
    max-width: 1280px;
    margin: 0px auto;
    margin-top: 2rem;
    background: #f1f7ff;
`;

export const PressContentWrapper = styled.div`
    margin: 0px auto;
    max-width: 80%;
`;

export const PressContentTitle = styled.h1`
    font-size: 1.4rem;
`;

export const PressContentSubtitle = styled.h2`
    color: #0058be;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1.2rem;

    &:hover {
        color: black;
    }
`;

export const PressContentDescription = styled.p`
    margin-top: 1rem;
    line-height: 1.7rem;
`;

export const PressContentHighlightText = styled.span`
    font-weight: 600;
`;

export const PressContentBlockquote = styled.blockquote`
    font-weight: 400;
    border-left: 0.25rem solid #cbd5e1;
    quotes: "\\201C""\\201D""\\2018""\\2019";
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
    line-height: 1.7rem;
`;

export const PressContentImage = styled.img`
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    width: 100%;
`;
