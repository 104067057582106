import styled from "@emotion/styled";

export const MainForm = styled.form`
    display: flex; /* Include flex box */
    gap: 10px; /* Add some gap between fields */
    flex-direction: column; /* Align fields and label column wise */
    padding-bottom: 20px; /* Add padding between bottom border and form buttons */
    border-bottom: 1px solid rgb(231, 226, 226); /* Add a border line at the bottom of the form */
    label { 
    font-family: Inter, sans-serif; /* Include font family */
    font-weight: 600; /* Make the label bold */
    }
    `;
export const SelectParent =styled.div`
    display: flex; /* Include flexbox */
    flex-wrap: wrap; /* Wrap flexbox */
    gap: 10px; /* Add some space */
    position: relative; /* set the positioning to add border between select field and select icon*/
    width: 100%; /* assign the width to select field */
    flex-direction: column; /* Align the label and select field in vertical format */
`;

export const InputFieldArea = styled.div`
    gap: 10px; /* Add some space */
    display: flex; /* Include flexbox */
    flex-wrap: wrap; /* Wrap flexbox */
`;

export const SubmitButton =styled.button`
    background-color: #99a1a5; /* set background color of submit button*/
    color: white; /* Set color */
    font-weight: 600; /* Add some font-weight to button text */
    width: 100%; /* Align full width */
    cursor: pointer; /* Change cursor to pointer */
    border: none; /* Remove border */
    border-radius: 4px; /* Add some border radius */
    padding: 10px; /* Add some padding to button */
    font-size: 16px; /* Provide font size to button text */
`;
