import { TreatmentTypesTranslations } from "./types";

export const enTreatmentTranslations: TreatmentTypesTranslations = {
    CARDIOVERSION: "External Cardioversion",
    NON_INVASIVE_VENTILATION: "CPAP",
    VALSAVA: "Valsalva Maneuver",
    SUPRAPUBIC_CATHETER: "Suprapubic Catheter",
    URETHRAL_CATHETER: "Transurethral Catheter",
    CHEST_DRAIN: "Insertion of Chest Drain",
    REBREATH_BAG: "Breath into Paper Bag",
    GASTRIC_TUBE: "Insertion of Gastric Tube",
    LINTON_PROBE: "Transoeseophageal Balloon Tamponade",
    PARTICLE_REPOSITIONING: "Epley Maneuver",
};
