import { DiseaseListInterface } from './interfaces';
import { DiseaseCategory, DiseaseName } from "../../../../core/translations/diseases/types";

const DISEASE_LISTS: Record<DiseaseCategory, DiseaseName[]> = {
    'CARDIOVASCULAR': [
        'STEMI_ANTERIOR', 'STEMI_POSTERIOR', 'NSTEMI',
        'ATRIAL_FIBRILLATION', 'CONGESTIVE_HEART_FAILURE',
        'ORTHOSTATIC_SYNCOPE', 'HYPERTENSIVE_CRISIS', 'AVNRT',
        'AORTIC_STENOSIS', 'LEFT_SIDED_ENDOCARDITIS',
        'AORTIC_DISSECTION', 'AV_BLOCK_COMPLETE',
        'HYPERTENSIVE_EMERGENCY', 'PERIPHERAL_ARTERY_DISEASE'
    ],
    'RESPIRATORY': [
        'NON_INFECTIVE_EXACERBATION_OF_ASTHMA',
        'NON_INFECTIVE_EXACERBATION_OF_COPD',
        'INFECTIVE_EXACERBATION_OF_COPD',
        'COMMUNITY_ACQUIRED_PNEUMONIA',
        'PULMONARY_EMBOLISM',
        'CHEST_SEPSIS'
    ],
    'NEUROLOGICAL': [
        'ISCHAEMIC_STROKE', 'MIGRAINE', 'SEIZURE',
        'BENIGN_PAROXYSMAL_POSITIONAL_VERTIGO',
        'SUBARACHNOID_HEMORRHAGE', 'SUBDURAL_HEMATOMA',
        'TRANSIENT_ISCHAEMIC_ATTACK'
    ],
    'ENDOCRINOLOGY': [
        'HYPERGLYCAEMIA', 'HYPERKALEMIA',
        'HYPERTHYROIDISM', 'HYPOGLYCAEMIA'
    ],
    'UROLOGY': [
        'UROSEPSIS', 'ACUTE_URINARY_RETENTION',
        'KIDNEY_STONES'
    ],
    'GENERAL_SURGERY': [
        'ACUTE_APPENDICITIS', 'ACUTE_CHOLECYSTITIS'
    ],
    'GASTROENTEROLOGY': [
        'ACIDIC_REFLUX', 'INFECTIVE_GASTROENTERITIS',
        'UPPER_GI_BLEED', 'LOWER_GI_BLEEDING',
        'MESENTERIC_ISCHAEMIA', 'SIGMOID_DIVERTICULITIS'
    ],
    'OBS_GYNAE': [
        'ECTOPIC_PREGNANCY', 'OVARIAN_TORSION',
        'PREECLAMPSIA'
    ],
    'ORTHOPAEDICS': [
        'KNEE_OSTEOARTHRITIS', 'HIP_FRACTURE',
        'HIP_OSTEOARTHRITIS', 'INFECTION_OF_PROSTHETIC_JOINT'
    ],
    'OTHER': [
        'MUSCULOSKELETAL_CHEST_PAIN'
    ]
};

export class DiseaseChoiceState {
    categories: Map<DiseaseCategory, CategoryState>;
    selectedCategory: DiseaseCategory;
    allCategories: CategoryState[];

    constructor() {
        this.categories = new Map();
        this.selectedCategory = 'CARDIOVASCULAR';

        Object.keys(DISEASE_LISTS).forEach(category => {
            const diseaseCategory = category as DiseaseCategory;
            const categoryState = new CategoryState(diseaseCategory);
            categoryState.initializeDiseases();
            this.categories.set(diseaseCategory, categoryState);
        });

        this.allCategories = Array.from(this.categories.values());
    }

    changeCategory(category: DiseaseCategory) {
        this.selectedCategory = category;
    }

    getCategory(category: DiseaseCategory): CategoryState {
        const foundCategory = this.categories.get(category);
        if (!foundCategory) {
            throw new Error(`Category ${category} not found`);
        }
        return foundCategory;
    }

    checkDisease(category: DiseaseCategory, diseaseName: DiseaseName) {
        this.getCategory(category).checkDisease(diseaseName);
    }

    checkAll(category: DiseaseCategory) {
        this.getCategory(category).checkAll();
    }

    getAllChecked(): DiseaseListInterface[] {
        const checkedDiseases: DiseaseListInterface[] = [];
        this.allCategories.forEach(category => {
            category.diseases.forEach(disease => {
                if (disease.checked) {
                    checkedDiseases.push(disease.data);
                }
            });
        });
        return checkedDiseases;
    }

    getAllForApi(): DiseaseName[] {
        const checkedDiseases: DiseaseName[] = [];
        this.allCategories.forEach(category => {
            category.diseases.forEach(disease => {
                if (disease.checked) {
                    checkedDiseases.push(disease.data.name);
                }
            });
        });
        return checkedDiseases;
    }
}

export class CategoryState {
    category: DiseaseCategory;
    diseases: Map<DiseaseName, DiseaseStateEntry>;
    allChecked: boolean;

    constructor(category: DiseaseCategory) {
        this.category = category;
        this.diseases = new Map();
        this.allChecked = false;
    }

    initializeDiseases() {
        const diseases = DISEASE_LISTS[this.category] || [];
        diseases.forEach(name => {
            const disease: DiseaseListInterface = {
                name,
                category: this.category,
                translations: {}
            };
            this.diseases.set(name, new DiseaseStateEntry(disease));
        });
    }

    checkDisease(diseaseName: DiseaseName) {
        const diseaseState = this.diseases.get(diseaseName);
        if (!diseaseState) {
            throw new Error(`Disease ${diseaseName} not found`);
        }
        diseaseState.checked = !diseaseState.checked;
        this.updateAllCheckedState();
    }

    checkAll() {
        this.allChecked = !this.allChecked;
        this.diseases.forEach(disease => {
            disease.checked = this.allChecked;
        });
    }

    getChecked(): DiseaseListInterface[] {
        const checkedDiseases: DiseaseListInterface[] = [];
        this.diseases.forEach(disease => {
            if (disease.checked) {
                checkedDiseases.push(disease.data);
            }
        });
        return checkedDiseases;
    }

    private updateAllCheckedState() {
        this.allChecked = Array.from(this.diseases.values()).every(disease => disease.checked);
    }
}

export class DiseaseStateEntry {
    data: DiseaseListInterface;
    checked: boolean;

    constructor(data: DiseaseListInterface, checked: boolean = false) {
        this.data = data;
        this.checked = checked;
    }
}