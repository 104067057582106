import { TreatmentPageTranslations } from "./types";

export const enTranslations: TreatmentPageTranslations = {
    pageTitle: "Order Treatment",
    treatment: "Treatment",
    priority: "Priority",
    justification: "Justification for Treatment",
    orderTreatment: "Order Treatment",
    clearForm: "Clear Form",
    orderedTreatments: "Ordered Treatments",
};
