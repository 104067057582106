// The factory ensures that patients being processed through it adhere to the flyweight pattern
// Functions can also subscribe to the change in the state of the patients in the factory
// using callbacks
import { Patient } from './patient';
import { PatientStorageInterface } from "./storageInterface";


// the function signature for the callback for subscribing to the PatientFactory
export type SubscriberCallback = () => void;


/*
Handles the creation and memory management of patients with callback notifications for changes
in the state of all patients in the factory.

@static patients: Map<string, Patient> - A map of all patients in the factory
@static subscribers: SubscriberCallback[] - An array of all subscribers to the factory
 */
export class PatientFactory {

    static patients: Map<number, Patient> = new Map();
    static subscribers: SubscriberCallback[] = [];
    static idInstances: number[] = [];

    /*
    Creates a new patient and adds it to the factory. If the patient already exists, it will
    return the existing patient (this is the function that ensures the flyweight pattern).

    @param patient: Patient - The patient to add to the factory
    @param handle: PatientStorageInterface - The handle to the storage to add the patient to

    @returns Patient - The patient that was created or already exists
     */
    static setPatient(
        patient: Patient,
        handle: PatientStorageInterface): Patient {
        handle.setPatient(patient);
        if (!this.patients.has(patient.id)) {
            this.patients.set(patient.id, patient);
            this.notifySubscribers();
        }
        if (!this.idInstances.includes(patient.id)) {
            this.idInstances.push(patient.id);
        }
        return this.patients.get(patient.id)!;
    }

    /*
    Gets a patient by their id.

    @param id: string - The id of the patient

    @returns Patient | undefined - The patient if it exists, otherwise undefined
     */
    static getById(id: number): Patient | undefined {
        return this.patients.get(id);
    }

    /*
    Updates a patient in the factory (must have the same ID but can have any different attributes).

    @param patient: Patient - The patient to update with the new attributes
    @param handle: PatientStorageInterface - The handle to the storage to update the patient in

    @returns void
     */
    static updatePatient(
        patient: Patient,
        handle: PatientStorageInterface): void {
        this.patients.set(patient.id, patient);
        handle.updatePatient(patient);
        this.notifySubscribers();
    }

    /*
    Deletes a patient from the factory by their id.

    @param id: string - The id of the patient to delete
    @param handle: PatientStorageInterface - The handle to the storage to delete the patient from

    @returns void
     */
    static deleteById(
        id: number,
        handle: PatientStorageInterface): void {
        this.patients.delete(id);
        handle.deletePatient(id);
        let index = this.idInstances.indexOf(id);
        if (index !== -1) {
            this.idInstances.splice(index, 1);
        }
        this.notifySubscribers();
    }

    /*
    Gets all patients in the factory.

    @returns Map<string, Patient> - A map of all patients in the factory
     */
    static getAllPatients(): Map<number, Patient> {
        return this.patients
    }

    /*
    Updates the entire cache of patients in the factory (will wipe the old cache).

    @param patientsMap: Map<string, Patient> - A map of all patients in the factory (with new IDs or attributes)
    @param handle: PatientStorageInterface - The handle to the storage to update the patients in

    @returns void
     */
    static updateEntireCache(
        patientsMap: Map<number, Patient>,
        handle: PatientStorageInterface): void {
        this.patients = patientsMap;
        this.idInstances = Array.from(patientsMap.keys());

        handle.wipePatients();
        handle.wipeIndexes();
        patientsMap.forEach((patient) => {
            handle.setPatient(patient);
        });
        this.notifySubscribers();
    }

    /*
    Notifies all subscribers of a change in the state of the factory.

    @returns void
     */
    private static notifySubscribers(): void {
        this.subscribers.forEach((callback) => callback());
    }

    /*
    Subscribes to the factory and returns an unsubscribe function.

    @param callback: SubscriberCallback - The callback to call when the state of the factory changes

    @returns () => void - The unsubscribe function
     */
    static subscribe(callback: SubscriberCallback): () => void {
        this.subscribers.push(callback);
        // Return an unsubscribe function
        return () => {
            this.subscribers = this.subscribers.filter((sub) => sub !== callback);
        };
    }
}
