// External imports
import { uniq } from 'lodash';

// Internal imports
import { PatientExamination } from '../types/PatientExamTypes';
import PatientExamConfig from '../config/PatientExamConfig';

/**
 * PatientExamState
 *
 * This class is used to manage the state of the patient examination page. This
 * includes which cards have been examined and which card is currently selected.
 *
 */
class PatientExamState {
    // Initially, no cards are marked as examined
    examinedCardIds: string[] = [];

    // The first card is selected by default
    selectedCardId = PatientExamConfig.examinations[0].id;

    // Method to set the selected card
    setSelectedCard(card: PatientExamination) {
        this.selectedCardId = card.id;
    }

    // Method to mark a card as examined
    setCardExamined(card: PatientExamination) {
        // Add the card to the list of examined cards, avoiding duplicates
        this.examinedCardIds = uniq(this.examinedCardIds.concat(card.id));
    }

    // Check if a card is the selected card
    isCardSelected(card: PatientExamination) {
        return this.selectedCardId === card.id;
    }

    // Check if a card has been examined
    isCardExamined(card: PatientExamination) {
        return this.examinedCardIds.includes(card.id);
    }
}

export default new PatientExamState();
