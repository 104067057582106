// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { individualConsultationData } from "../../../LocalStorage/patients/interface";


/*
The payload for posting a consultation.
 */
export interface PostConsultationPayload {
    turn: number;
    patient_id: number;
    specialty: string;
    summary: string;
    reason: string;
}


/*
The interface for the response from the post consultation endpoint.
@status: The HTTP status code.
@error: The error message.
@data: The object containing the consultation details.
 */
export interface PostConsultationResponse {
    status: number;
    error?: string;
    data?: individualConsultationData;
}

export type PostConsultationType = (jwt: string, postConsultationPayload: PostConsultationPayload) => Promise<PostConsultationResponse>;

/*
The function to post a consultation for a patient.

@jwt: The JWT of the user requesting the post.
@postConsultationPayload: The payload containing the turn, patient ID, specialty, summary, and reason.
@returns: The ApiResponse from the post consultation endpoint.
 */
export async function postConsultation(jwt: string, postConsultationPayload: PostConsultationPayload): Promise<PostConsultationResponse> {
    const url = new PatientUrl().postConsultation;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<individualConsultationData | string>(url, {
            turn: postConsultationPayload.turn,
            patient_id: postConsultationPayload.patient_id,
            specialty: postConsultationPayload.specialty,
            summary: postConsultationPayload.summary,
            reason: postConsultationPayload.reason
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });

    } catch (error) {
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 201) {
        console.log("Consultation posted successfully:", response.data);
        return {
            status: response.status,
            data: response.data as individualConsultationData
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
