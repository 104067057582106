// External imports
import React from "react";

// Internal imports
import { TurnTabletTitle } from "./TurnTablet.styles";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";
import { NotesTranslations } from "../../translations/types";
import { enTranslations } from "../../translations/en";
import { deTranslations } from "../../translations/de";
import { useLanguage } from "../../../../../core/translations/LanguageContext";

/**
 * TurnTabletProps.
 * 
 * This is the props interface for the TurnTablet component.
 */
interface TurnTabletProps {
    turn: string;
}
const TRANSLATIONS: Record<SupportedLanguage, NotesTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * TurnTablet Component
 * 
 * This component is used to display the turn tablet, showing the current turn count.
 */
export const TurnTablet: React.FC<TurnTabletProps> = ({ turn }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    return (
        <TurnTabletTitle>
            {turn}
        </TurnTabletTitle>
    );
}

export default TurnTablet;
