// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { bloodData } from "../../../LocalStorage/patients/interface";


/*
The payload for getting all bloods.

@patient_id: The id of the patient.
 */
export interface GetAllBloodsPayload {
    patient_id: number;
}


/*
The interface for the response from the get all bloods endpoint.

@status: The HTTP status code.
@error: The error message.
@data: The object containing all the bloods.
 */
export interface GetAllBloodsResponse {
    status: number;
    error?: string;
    data?: bloodData;
};


/* Function Signature */
export type GetAllBloodsType = (jwt: string, getAllBloodsPayload: GetAllBloodsPayload) => Promise<GetAllBloodsResponse>;


/*
The function to get all bloods for a patient.

@jwt: The JWT of the user requesting the bloods.
@getAllBloodsPayload: The payload containing the patient ID.

@returns: The ApiResponse from the get all bloods endpoint. This contains all
the bloods for the patient. They are indexed by turn number and test name.
 */
export async function getAllBloods(jwt: string, getAllBloodsPayload: GetAllBloodsPayload): Promise<GetAllBloodsResponse> {
    const url = new PatientUrl().getAllBloods;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<bloodData | string>(url, {
            patient_id: getAllBloodsPayload.patient_id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });
    } catch (error) {
        return { status: 500, error: "Network or other error occurred" };
    }
    if (response.status === 201) {
        console.log(response.data.blood_order.tests);
        return { status: response.status, data: response.data.blood_order.tests as bloodData };
    } else {
        return { status: response.status, error: response.data };
    }
}
