import styled from "@emotion/styled";

export const PencilButtonContainer = styled.button`
    display: flex;
    margin-left: 1rem;
    shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    color: #9ca3af;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;

    &:focus {
        outline-style: none;
        box-shadow: var(--tw-ring-inset) 0 0 0
            calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        --ring-color: #6366f1;
    }

    ${({ notesTabActive }: { notesTabActive: boolean }) =>
        notesTabActive
            ? `
            background-color: #9CA3AF; 
            `
            : `
            &:hover {
                color: #6B7280; 
                background-color: #F3F4F6; 
            }
            `};

    svg {
        width: 1.25rem;
        height: 1.25rem;

        ${({ notesTabActive }: { notesTabActive: boolean }) =>
            notesTabActive
                ? `
                color: #ffffff; 
                `
                : ``};
    }
`;
