import { InvestigationTypesTranslations } from './types';

export const enInvestigationTranslations: InvestigationTypesTranslations = {
    BLOOD_CULTURE: "Blood Culture",
    URINE_CULTURE: "Urine Culture",
    SPUTUM_CULTURE: "Sputum Culture",
    WOUND_CULTURE: "Wound Culture",
    THROAT_CULTURE: "Throat Culture",
    GENITAL_CULTURE: "Genital Culture",
    PEAK_FLOW: "Peak Flow",
    URINE_DIPSTICK: "Urine Dipstick",
    VENOUS_DUPLEX: "Venous Duplex",
    ECG: "ECG",
    ECHO: "Trans-thoracic Echocardiogram",
    CT_CHEST: "CT Chest",
    CT_ABDO: "CT Abdo",
    ABDO_USS: "Abdo USS",
    TV_USS: "TV USS",
    ABDO_X_RAY: "Abdo X Ray",
    CHEST_X_RAY: "Chest X Ray",
    LUMBAR_X_RAY: "Lumbar X Ray",
    ASCITIC_TAP: "Ascitic Tap",
    THORACOCENTESIS_DIAGNOSTIC: "Thoracocentesis Diagnostic",
    CATH_LAB: "Cath Lab",
    CT_HEAD: "CT Head",
    CT_AORTA: "CT Aorta",
    LUMBAR_PUNCTURE: "Lumbar Puncture",
    THYROID_USS: "Thyroid USS",
    CTG: "CTG",
    DIX_HALLPIKE_MANEUVER: "Dix-Hallpike Maneuver",
    PREGNANCY_TEST: "Pregnancy Test",
    ARTERIAL_DUPLEX: "Arterial Duplex",
    OGD: "OGD",
    TOE: "Transoesophageal Echocardiogram",
};