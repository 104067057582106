import styled from "@emotion/styled";

export const GuidesTemplatePageContainer = styled.div`
    width: 65%;
    margin: auto;
    @media screen and (max-width:768px) {
        width: 90%;
    }
`;

export const GuidesTemplateTitleAndButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const GuidesTemplatePageTitle = styled.h1`
    text-align: center;
    width: 90%;
    margin: auto;
`;
export const GuidesTemplateFeatureArea = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    flex-direction: column;
    gap: 20px;
`;
export const GuidesTemplateFeatureAreaIFrame = styled.iframe`
    height: 675px;
    @media screen and (max-width:1366px) {
        height: 260px;
    }
    @media screen and (max-width:768px) {
        height: 200px;
    }
`;
export const GuidesTemplateFeatureAreaImage = styled.img`
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    @media screen and (max-width:1366px) {
        width: inherit;
    }
`;
export const GuidesTemplateContentMainSection = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
    @media screen and (max-width:1366px) {
        flex-direction: column;
    }
`;
export const GuidesTemplateContentMenu = styled.div`
    background-color: #fff;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: fit-content;
    min-height: 150px;
    padding: 20px;
    font-family: Inter, sans-serif;
    display: flex;
    border-radius: 5px;
`;
export const GuidesTemplateContentMenuList = styled.ul`
    list-style-type: disclosure-closed;
    color: rgb(25, 80, 132);
    padding: 10px;
`;
export const GuidesTemplateContentMenuListItem = styled.li`
    margin: 10px;
`;
export const GuidesTemplateContentMenuAnchor = styled.a`
    color: rgb(25, 80, 132);
    text-decoration: none;
`;
export const GuidesTemplateDescription = styled.div`
    text-align: justify;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: regular;
    color: #173b5e;
    line-height: 1.7em;
`;
export const GuidesTemplateDescriptionTitle = styled.h4`
`;
export const GuidesTemplateDescriptionParagraph = styled.p`
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-size: 1.063em;
    line-height: 1.6em;
`;
export const GuidesTemplateDescriptionImage = styled.img`
    width: 100%;
`;
export const GuidesTemplateDescriptionIFrame = styled.iframe`
    height: 450px;
    @media screen and (max-width:1366px) {
        height: 300px;
    }
    @media screen and (max-width:768px) {
        height: 200px;
    }
`;
export const GuidesTemplateDivSection = styled.div`
`;

export const GuideTemplateGoBackButton = styled.a`
    font-size: 0.938rem;
    white-space: nowrap;
    font-family: Helvetica, Arial, sans-serif;
    padding: 0.6em 1.3em;
    border-radius: 0.8em;
    cursor: pointer;
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 0.5em;
    font-weight: 500;
    width: fit-content;
    border: none;
    background-color: rgb(22, 59, 93);
    color: white;
    text-decoration: none;
`;