//@ts-ignore
import styled from "@emotion/styled";

export const Container = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: "Helvetica", Arial, sans-serif;
    &,
    * {
        box-sizing: border-box;
    }
`;
export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1;
`;
export const Main = styled.main<{ width: string }>`
    overflow-x: hidden;
    overflow-y: auto;
    width: ${(props: any) => props.width};
    height: 89vh;
`;

export const Content = styled.div<{ noPadding?: boolean }>`
    background-color: white;
    overflow-y: auto;
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    padding: ${(props: any) => (props.noPadding ? "0" : "5px 7px")};
    padding: 0px 20px;
    padding-bottom: 1rem;
`;
export const Modal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
`;
export const GamePageTitle = styled.h1`
    color: black;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 7px;
    padding: 20px;
    padding-bottom: 10px;
`;
