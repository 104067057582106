import { GuidePageTranslations } from "./types";
import featureImage from "../guides/content/placeholder_img.png";
import sectionImage from "../guides/content/scrubs.jpg";

export const enTranslations: GuidePageTranslations = {
    main: {
        title: "Guides",
        searchPlaceholder: "Search guides",
        notFoundText: "Guide not found"
    },
    sectionArea: [
        {
            slug: "creating-class",
            title: "Revolutionary platform allows medical students to step into the shoes of a junior doctor",
            featureImage: featureImage,
            featureVideo:
                "https://www.youtube.com/embed/3UEOptwfkgg?si=MAhHsgA0nY7nHJk6",
            summary:
                "This revolutionary platform allows medical students to step into the shoes of a junior doctor",
            sections: [
                {
                    id: "create-class",
                    title: "Creating a Class",
                    paragraphs: [
                        "This revolutionary platform allows medical students...",
                        "We're seeking volunteers to engage with the simulation...",
                    ],
                    videoEmbed:
                        "https://www.youtube.com/embed/3UEOptwfkgg?si=MAhHsgA0nY7nHJk6",
                },
            ],
        },
        {
            slug: "reviewing-class",
            title: "Reviewing a Class",
            featureImage: featureImage,
            featureVideo:
                "https://www.youtube.com/embed/3UEOptwfkgg?si=MAhHsgA0nY7nHJk6",
            summary:
                "This revolutionary platform allows medical students to step into the shoes of a junior doctor",
            sections: [
                {
                    id: "reviewing-class",
                    title: "Reviewing a Class",
                    paragraphs: [
                        "This revolutionary platform allows medical students...",
                        "We're seeking volunteers to engage with the simulation...",
                    ],
                    image: sectionImage,
                },
            ],
        },
    ],
};
