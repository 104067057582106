import React from "react";
import { Container, TurnsText, TurnsCount } from "./TurnCounter.styles";
import DigialClock from "./DigialClock/DigialClock";
/*
The props passed into the component.
*/
export interface TurnCounterProps {
    /*
    * The number of turns that have passed.
   */
    turns: number;
}


/**
 * A simple component that displays the number of turns that have passed.
 *
 * @param props The TurnCounterProps for the component.
 * @returns The TurnCounter component.
 *
 * @example
 * ```tsx
 * <TurnCounter turns={10} />
 * ```
 */
export const TurnCounter: React.FC<TurnCounterProps> = ({ turns }) => {
    
    // Render the turn counter
    return (
        <Container>
            {/* <TurnsText>Turns</TurnsText> */}
            <TurnsCount>
                <DigialClock />
            </TurnsCount>
            
        </Container>
    );
}
