import styled from "@emotion/styled";

export const PatientDetailContainer = styled.div`
    width: 24rem;
    max-height: 89.5vh;
    overflow-y: auto;
    background-color: white;
    padding: 1rem;
    border-left: 1px solid #e5e7eb;
    font-family: "Helvetica", Arial, sans-serif;

    @media (max-width: 1024px) {
        display: block;
    }

    @media (max-width: 640px) {
        padding: 1rem;
        width: 100%;
    }
`;

export const PatientDetailInfoContainer = styled.div`
    padding-bottom: ${({ isDesktop }: { isDesktop: boolean }) =>
        isDesktop ? "1rem" : "0px"};
`;
