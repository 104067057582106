import React from "react";
import {
    PopupContainer,
    PopupContent,
    NoNotificationsText,
} from "./NotificationPopup.styles";
import { NotificationInfoProps } from "../NotificationBell/NotificationBell";
import { NotificationItem } from "./NotificationItem/NotificationItem";

/**
 * A simple component that displays the notifications for the user.
 * This component is used in the GameNavBar component, and it displays
 * a list of notifications to the user if there are any.
 *
 * @returns The NotificationPopup component.
 *
 * @example
 * ```tsx
 * <NotificationPopup unreadNotifications={notifications} />
 * ```
 */
export const NotificationPopup: React.FC<
    NotificationInfoProps & { onDismiss: (id: string) => void }
> = ({ unreadNotifications, onDismiss, translations }) => {
    return (
        <PopupContainer>
            <PopupContent>
                {unreadNotifications.length === 0 ? (
                    <NoNotificationsText>
                        {translations.notifications.noNotifications}
                    </NoNotificationsText>
                ) : (
                    unreadNotifications.map((notification) => (
                        <NotificationItem
                            key={notification.id}
                            notification={notification}
                            onDismiss={onDismiss}
                            translations={translations}
                        />
                    ))
                )}
            </PopupContent>
        </PopupContainer>
    );
};
