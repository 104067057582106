import { Blog } from "./types";
import { isBlog } from "./validator";

// Import raw YAML files
import firstBlogEn from "./content/en/first.yaml";
import firstBlogDe from "./content/de/first.yaml";

// Validate and typecast YAML to the Blog type
function validateBlog(data: unknown): Blog {
    if (!isBlog(data)) {
        throw new Error(`Invalid blog structure: ${JSON.stringify(data)}`);
    }
    return data;
}

// Create the blog map with validated data
export const blogsMap: Record<string, Record<string, Blog>> = {
    en: {
        first: validateBlog(firstBlogEn),
    },
    de: {
        first: validateBlog(firstBlogDe),
    },
};
