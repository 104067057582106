import { CurrentSessionData } from "./interface";
import { setPatientData } from "../patients/core";
import {
    ApiPatientData,
    Session,
} from "../../ServerApiLayer/VOne/sessions/start";

// Function signature type
export type SetFromApiDataType = (apiData: Session) => void;

/*
Store the class data in localStorage directly from the assign class api call.

@apiData: The ClassData data from the assign class api call.
 */
export function setFromApiData(data: Session): void {
    const turn = 1;

    const sessionDataInfo: CurrentSessionData = {
        sessionID: data.session_id,
        turn: turn,
        patientIntervals: data.patientIntervals,
        nextPatientInterval: turn + data.patientIntervals,
    };

    // Set the session data here
    setSessionData(sessionDataInfo);

    // Set patient data here as well for the first time
    data.patients.forEach((patient: ApiPatientData) => {
        setPatientData(turn, patient);
    });
}

/*
Sets the session data in localStorage.

@sessionData: The SessionData to be stored in localStorage.

@returns: void
 */
export function setSessionData(sessionData: CurrentSessionData): void {
    localStorage.setItem("sessionData", JSON.stringify(sessionData));
}

/*
Get the session data from localStorage.

@returns: The session data from localStorage.
 */
export function getSessionData(): CurrentSessionData | null {
    const sessionData = localStorage.getItem("sessionData");
    if (sessionData) {
        return JSON.parse(sessionData);
    }
    return null;
}

/*
Get the session ID from sessionData
 */
export function getSessionID(): number {
    const sessionData = getSessionData();
    return sessionData ? sessionData.sessionID : 0;
}
