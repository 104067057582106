import styled from "@emotion/styled";

export const ChatPageMainForm = styled.form`
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(231, 226, 226); 
    display: flex;
    width: 100%;
    max-width: 100%;
    align-items: center;
    position: relative;
`;

export const ChatPageInputFieldArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; 
    & > input{
        min-height: 70px;
        border-radius: 15px;
        padding: 5px 20px;
        padding-right: 9%;
    }
`;

export const ChatPageButtonArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 5px;
    position: absolute;
    right: 20px; 
`;

export const ChatPageSubmitButton = styled.button`
    width: fit-content;
    background-color: #0284d2;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 5px;
    font-family: Inter,sans-serif;
    font-size: 16px;
    font-weight: 600;
    &:disabled{
        background-color: #0284d280;
    }
`;