import React from "react";
import {
    FeedbackPatientInfoAvatar,
    FeedbackPatientInfoContainer,
    FeedbackPatientInfoContentContainer,
    FeedbackPatientInfoSubtitle,
    FeedbackPatientInfoTitle,
} from "./FeedbackPatientInfo.styles";
import GenderIcon from "../../../../gender-icon/GenderIcon";
import { IPatientDetail } from "../../DischargePatient";

/**
 * FeedbackPatientInfoProps props.
 * @interface FeedbackPatientInfoProps
 * @property {IPatientDetail} patient - The patient to display info for.
 */
interface FeedbackPatientInfoProps {
    patient: IPatientDetail;
}

/**
 * FeedbackPatientInfo component.
 * @component FeedbackPatientInfo
 * @description A component that displays the patient's info.
 * @param {FeedbackPatientInfoProps} props - The component props.
 * @returns {JSX.Element} The rendered FeedbackPatientInfo component.
 */
function FeedbackPatientInfo({ patient }: FeedbackPatientInfoProps) {
    const patientAge = patient.demographics.split(" ")[0];
    const patientGender = patient.demographics.split(" ")[1];

    return (
        <FeedbackPatientInfoContainer>
            <FeedbackPatientInfoAvatar src={"https://divina-game-faces-bucket.s3.eu-west-2.amazonaws.com/"+patient.faceURL} alt="" />
            <FeedbackPatientInfoContentContainer>
                <FeedbackPatientInfoTitle>
                    {patient.name}
                </FeedbackPatientInfoTitle>
                <FeedbackPatientInfoSubtitle>
                    Age: {patientAge} | Sex:{" "}
                    <GenderIcon gender={patientGender} labelled={true} /> <br />
                    Symptoms: {patient.symptom}
                </FeedbackPatientInfoSubtitle>
            </FeedbackPatientInfoContentContainer>
        </FeedbackPatientInfoContainer>
    );
}

export default FeedbackPatientInfo;
