// External imports
import React from "react";
import { HiOutlineArrowRight } from "react-icons/hi2";

// Internal imports
import WebsiteButton from "../../components/core/button/WebsiteButton";
import MainPageLayout from "../../components/core/header/MainPageLayout";
//@ts-ignore
import outside from "./outside.jpg";
import {
  PageContainer,
  TextSection,
  Title,
  Subtitle,
  Description,
  ButtonContainer,
  ImageSection,
  Image,
} from "./FrontPage.styles";
import { getJwt } from "../../core/LocalStorage/AuthSession/core";

// Dependency injection
import { GetRoleType } from "../../core/LocalStorage/AuthSession/core";

// Language Context
import { useLanguage } from "../../core/translations/LanguageContext";

// Translations
import { FrontPageTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../core/translations/supportedLanguages';

/**
 * Props for the FrontPage component
 * @property getRoleApiCall - Function to fetch the user's role
 */
export interface FrontPageProps {
  getRoleApiCall: GetRoleType;
}

const TRANSLATIONS: Record<SupportedLanguage, FrontPageTranslations> = {
  en: enTranslations,
  de: deTranslations,
};

/**
 * Front page component of the website
 * Displays main content, hero section, and conditional navigation based on user auth state
 */
export const FrontPage: React.FC<FrontPageProps> = ({ getRoleApiCall }) => {
  // Use the language context instead of localStorage directly
  const { language } = useLanguage();
  const translations = TRANSLATIONS[language];

  // Check if the user is logged in
  const loggedIn = getJwt();

  // Get the user's role if logged in
  const role = getRoleApiCall();

  return (
      <MainPageLayout>
        <PageContainer>
          <TextSection>
            <Title>
              <span>{translations.simulationTraining}</span>{" "}
              <Subtitle>{translations.medicalProfessionals}</Subtitle>
            </Title>
            <Description>
              {translations.description}
            </Description>
            <ButtonContainer>
              <div>
                {loggedIn ? (
                    <WebsiteButton href="/home" tag="a">
                      {role === "teacher"
                          ? translations.buttons.manageClasses
                          : translations.buttons.joinClass}
                    </WebsiteButton>
                ) : (
                    <WebsiteButton href="/login" tag="a">
                      {translations.buttons.signIn}
                    </WebsiteButton>
                )}
              </div>

              <WebsiteButton
                  tag="a"
                  iconPosition="right"
                  icon={<HiOutlineArrowRight size="1.3em" />}
                  outline
                  href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3kXE_yTYrUVwtBS5daaSYzTxeadtWgEtTOo_ZsDPc-gThuC3qgVVaR5apz3nU2bZ8NaUr4Nonp"
              >
                {translations.buttons.bookDemo}
              </WebsiteButton>
            </ButtonContainer>
          </TextSection>
          <ImageSection>
            <Image src={outside} alt={translations.imageAlt} />
          </ImageSection>
        </PageContainer>
      </MainPageLayout>
  );
};