
import { ClassData } from "../../ServerApiLayer/VOne/classes/assign";
import { LocalStorageClassData} from "./interface";

// Function signature type
export type SetFromApiDataType = (apiData: ClassData, authCode: string) => void;


/*
Store the class data in localStorage directly from the assign class api call.

@apiData: The ClassData data from the assign class api call.
 */
export function setFromApiData(apiData: ClassData, authCode: string): void {
    const classDataInfo: LocalStorageClassData = {
        classData: apiData,
        authCode: authCode
    };
    setClassData(classDataInfo);
}



/*
Sets the class data in localStorage.

@classData: The ClassData to be stored in localStorage.

@returns: void
 */
export function setClassData(classData: LocalStorageClassData): void {
    localStorage.setItem('classData', JSON.stringify(classData));
}


/*
Gets the class id from localStorage.
 */
export function getClassId(): number | null {
    const classData = getClassData();
    if (classData) {
        return classData.classData.id;
    }
    return null;
}


/*
Gets the class data from localStorage.

 */
export function getClassData(): LocalStorageClassData | null {
    const classData = localStorage.getItem('classData');
    if (classData) {
        return JSON.parse(classData);
    }
    return null;
}