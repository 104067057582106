import styled from "@emotion/styled";

export const PatientExamContainer = styled.div`
    width: 100%;
    max-height: 75vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    font-family: "Helvetica", Arial, sans-serif;
`;

export const PatientExamCardContainer = styled.div`
    width: 29rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const ExaminationFindingHeading = styled.h3`
    margin: 0px;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: #000000;
    text-align:center;
`;

export const ExaminationFindingContent = styled.div`
    max-width: 100%;
    text-align: center;
    color: #000000;
    fontsize: ".98em";
`;


export const PatientExamContentContainer = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    flex-direction: column;
    gap: 0.25rem;
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
    transform: translateX(var(--tw-translate-x))
        translateY(var(--tw-translate-y));
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
`;

export const RequiredTurns = styled.p`
    max-width: 100%;
    text-align: justify;
    color: #000000;
`;

export const FakeTextStyle = styled.div`
    margin-bottom: 0.75rem;
    height: 1rem;
    background-color: #f3f4f6;
`;

export const FindingsFakeText = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TextCenteredArea = styled.div`
    text-align: center;
`;
