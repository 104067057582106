import { BlogPageTranslations } from "./types";

export const deTranslations: BlogPageTranslations = {
    main: {
        title: "Blog-Beiträge",
        titleForSingleEntiry: "Blog",
        searchPlaceholder: "Suche Blog-Beiträge",
        notFoundText: "Blog nicht gefunden. Bitte überprüfen Sie die URL oder versuchen Sie es später erneut.",
        selectCategory: "Kategorie auswählen",
        allPosts: "Alle Beiträge"
    }
};
