import React from "react";
import {
    ListContainer,
    ListTable,
    ListTableBody,
    ListTableData,
    ListTableHead,
    ListTableRow,
    ListTitle,
} from "./ItemListTable.styles";
import { FaCheck, FaTimes } from "react-icons/fa";

/**
 * TooltipProps interface.
 * @interface ItemListTableProps
 * @description The props for the ItemListTable component.
 * @property {string} [title] - The title for the list.
 * @property {any} [history] - The history to display in the list.
 * @property {any} [protocol] - The protocol to display in the list.
 */
interface ItemListTableProps {
    title?: string;
    history?: any;
    protocol?: any;
}

/**
 * ItemListTable component.
 * @component ItemListTable
 * @description A table component that displays a list of items.
 * @param {ItemListTableProps} props - The component props.
 * @returns {JSX.Element} The rendered ItemListTable component.
 */
function ItemListTable({ title, history, protocol }: ItemListTableProps) {
    return (
        <ListContainer>
            {title ? <ListTitle>{title}</ListTitle> : ""}
            <ListTable>
                <ListTableBody>
                    {history && (
                        <>
                            {history?.presenting_system?.actual_symptoms && (
                                <>
                                    <ListTableRow addBackground>
                                        <ListTableData colSpan={2}>
                                            Patient's Symptoms
                                        </ListTableData>
                                    </ListTableRow>
                                    {Object.entries(
                                        history?.presenting_system
                                            ?.actual_symptoms
                                    ).map(([symptom, value]) => (
                                        <ListTableRow key={symptom}>
                                            <ListTableData>
                                                {symptom}
                                            </ListTableData>
                                            <ListTableData
                                                addIconColor
                                                value={value}
                                            >
                                                {value ? (
                                                    <FaCheck />
                                                ) : (
                                                    <FaTimes />
                                                )}
                                            </ListTableData>
                                        </ListTableRow>
                                    ))}
                                </>
                            )}

                            {/* Past Medical History */}

                            <ListTableRow addBackground>
                                <ListTableData colSpan={2}>
                                    Others
                                </ListTableData>
                            </ListTableRow>
                            <ListTableRow>
                                <ListTableData>
                                    Past Medical History
                                </ListTableData>
                                <ListTableData
                                    addIconColor
                                    value={history?.past_medical_history}
                                >
                                    {history?.past_medical_history ? (
                                        <FaCheck />
                                    ) : (
                                        <FaTimes />
                                    )}
                                </ListTableData>
                            </ListTableRow>

                            {/* Drug History */}

                            <ListTableRow>
                                <ListTableData>Drug History</ListTableData>
                                <ListTableData
                                    addIconColor
                                    value={history?.drug_history}
                                >
                                    {history?.drug_history ? (
                                        <FaCheck />
                                    ) : (
                                        <FaTimes />
                                    )}
                                </ListTableData>
                            </ListTableRow>

                            {/* Smoking Status */}

                            <ListTableRow>
                                <ListTableData>Smoking Status</ListTableData>
                                <ListTableData
                                    addIconColor
                                    value={history?.smoking_status}
                                >
                                    {history?.smoking_status ? (
                                        <FaCheck />
                                    ) : (
                                        <FaTimes />
                                    )}
                                </ListTableData>
                            </ListTableRow>

                            {/* Alcohol */}

                            <ListTableRow>
                                <ListTableData>Alcohol</ListTableData>
                                <ListTableData
                                    addIconColor
                                    value={history?.alcohol}
                                >
                                    {history?.alcohol ? (
                                        <FaCheck />
                                    ) : (
                                        <FaTimes />
                                    )}
                                </ListTableData>
                            </ListTableRow>
                        </>
                    )}

                    {protocol &&
                        Object.entries(protocol ?? {}).map((item) =>
                            item[0] ? (
                                <ListTableRow key={item[0]}>
                                    <ListTableData>{item[0]}</ListTableData>
                                    <ListTableData
                                        addIconColor
                                        value={item[1] ? true : false}
                                    >
                                        {item[1] ? <FaCheck /> : <FaTimes />}
                                    </ListTableData>
                                </ListTableRow>
                            ) : null
                        )}
                </ListTableBody>
            </ListTable>
        </ListContainer>
    );
}

export default ItemListTable;
