import styled from "@emotion/styled";

export const OrderConsultationResultContainer = styled.div`
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    gap: 0.5rem;
    font-family: "Helvetica", Arial, sans-serif;
`;

export const OrderConsultationResultTitle = styled.h3`
    font-weight: 500;
    color: #000000;
`;

export const OrderConsultationResultList = styled.ul`
    overflow: hidden;
    border-radius: 0.5rem;
    background-color: #f3f4f6;
`;
