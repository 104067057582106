// External imports
import React from "react";

// Internal imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
//@ts-ignore
import clinicalmetrics from "./assets/clinicalmetrics.png";
import insideSim from "./assets/inside-sim.png";
import ecg from "./assets/ecg.png";
import dashboard from "./assets/dashboard.png";
import {
    PageContainer,
    TextSection,
    TextSectionAnchor,
    Title,
    Description,
    ImagesContainer,
    Image,
} from "./AnalyticsInsights.styles";

// Language Context
import { useLanguage } from '../../../core/translations/LanguageContext';

// Translations
import { AnalyticsTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

const TRANSLATIONS: Record<SupportedLanguage, AnalyticsTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Analytics Insights Page component
 * Displays detailed information about Clinical Metrics simulation product
 * and its technical implementation
 */
const AnalyticsInsightsPage: React.FC = () => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    return (
        <MainPageLayout>
            <PageContainer>
                <Title>
                    {translations.title}
                </Title>
                <Description>
                    {translations.sections.map((section, index) => (
                        <TextSection key={`section-${index}`}>
                            {section.includesLink ? (
                                <>
                                    {section.text}{" "}
                                    <TextSectionAnchor href={translations.websiteLink}>
                                        {translations.websiteLinkText}
                                    </TextSectionAnchor>
                                </>
                            ) : (
                                section.text
                            )}
                        </TextSection>
                    ))}
                </Description>
                <ImagesContainer>
                    <Image
                        src={clinicalmetrics}
                        alt={translations.imageAlts.clinicalMetrics}
                    />
                    <Image
                        src={insideSim}
                        alt={translations.imageAlts.insideSim}
                    />
                </ImagesContainer>
                <ImagesContainer>
                    <Image
                        src={ecg}
                        alt={translations.imageAlts.ecg}
                    />
                    <Image
                        src={dashboard}
                        alt={translations.imageAlts.dashboard}
                    />
                </ImagesContainer>
            </PageContainer>
        </MainPageLayout>
    );
};

export default AnalyticsInsightsPage;