// This file gets the handover data of the patient from the local storage

// Interface for the demographics object.
import { getSpecificPatientData } from "./core";
import { handoverData } from "./interface";

export type GetHandoverFromLocalStorageType = (
    patientId: number
) => handoverData | null;

/**
 * GetHandoverFromLocalStorage
 */
export const getHandoverFromLocalStorage: GetHandoverFromLocalStorageType = (
    patientId
) => {
    const patient = getSpecificPatientData(patientId);
    if (patient && patient.handover) {
        return patient.handover; // patient.handover already matches handoverData type
    }
    return null;
};
