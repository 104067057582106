import { StartGameTranslations } from "./types";

export const enTranslations: StartGameTranslations = {
    loginExpired: "Please login again. Login expired.",
    errorOccurred: "An error occurred: ",
    failedToStartGame: "Failed to start session",
    enterName: "Please enter a name for your Session",
    gameAnonymised:
        "Sessions are anonymised to the teacher view - however do not put an identifiable information , such as your name or student Id, and the name of session unless you intend to (for example clinical trial)",
    placeholder: "Cardiology 101",
    startGame: "Start",
    loaderText: "Please wait for a moment.."
};
