import { blogsMap } from './blogsMap';
import { Blog } from './types';

/**
 * Loads a blog by slug and language
 */
export async function loadBlog(slug: string, language: string): Promise<Blog | null> {
    try {
        const blog = blogsMap[language]?.[slug];
        if (!blog) {
            console.error(`Blog ${slug} not found for language ${language}`);
            return null;
        }
        return blog;
    } catch (error) {
        console.error(`Error loading blog ${slug} for language ${language}:`, error);
        return null;
    }
}

/**
 * Loads all blogs for a specific language
 */
export async function loadBlogs(language: string): Promise<Blog[]> {
    try {
        const blogsForLanguage = blogsMap[language];
        if (!blogsForLanguage) {
            console.error(`No blogs found for language ${language}`);
            return [];
        }

        // Convert the map of blogs to an array
        return Object.values(blogsForLanguage) as Blog[];
    } catch (error) {
        console.error(`Error loading blogs for language ${language}:`, error);
        return [];
    }
}