import styled from "@emotion/styled";

export const Td = styled.td`
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6b7280;
    white-space: nowrap;
    background-color: #f9fafb;
`;
