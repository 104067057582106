import { DashboardTableTranslations } from "./types";

export const enTranslations: DashboardTableTranslations = {
    dashboard: "Dashboard",
    table: {
        review: "Review",
        discharge: "Discharge",
        dischargeTooltip:
            "You can't discharge a patient without a diagnosis or a discharge form being completed",
        columns: {
            location: "Location",
            arrivalTime: "Arrival Time",
            name: "Name",
            ageGender: "Age/Gender",
            symptom: "Symptom",
            diagnosis: "Diagnosis",
            dischargeForms: "Discharge Forms",
        },
    },
};
