import { UserUrl } from "./AUrl";
import axios from 'axios';


/*
The interface for the response from the confirm user endpoint.

@status: The status code of the response.
@message: The message from the response.
 */
export interface ApiResponse {
    status: number;
    message: string;
}


export type ConfirmUserType = (token: string) => Promise<ApiResponse>;


/*
Confirms a user.

@token: The token to confirm the user.

@returns: The ApiResponse from the confirm user endpoint.
 */
export async function ConfirmUser(token: string): Promise<ApiResponse> {
    const url = new UserUrl().confirm + "/" + token;
    try {
        const response = await axios.get(url, {
            headers: {'Content-Type': 'application/json'},
        });

        // Check for status code 200
        if (response.status === 200) {
            return { status: response.status, message: "User confirmed successfully" };
        } else {
            // If the status code is not 200, return the status and the message from the response body
            return { status: response.status, message: response.data || `Unexpected response code: ${response.status}` };
        }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            // If it's an Axios error with a response, return the status and error message from the response body
            return { status: error.response.status, message: error.response.data || error.message };
        } else {
            // For other types of errors, return a generic message
            return { status: 500, message: "An error occurred" };
        }
    }
}
