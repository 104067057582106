import { InvestigationTypesTranslations } from './types';

export const deInvestigationTranslations: InvestigationTypesTranslations = {
    BLOOD_CULTURE: "Kultur - Blutkultur",
    URINE_CULTURE: "Kultur - Urinkultur",
    SPUTUM_CULTURE: "Kultur - Sputumkultur",
    WOUND_CULTURE: "Abstrich – Wundabstrich",
    THROAT_CULTURE: "Abstrich – Rachen",
    GENITAL_CULTURE: "Abstrich – äußeres Genitale",
    PEAK_FLOW: "Peak Flow",
    URINE_DIPSTICK: "UrinStix",
    VENOUS_DUPLEX: "Ultraschall – Venöse Duplexsonographie",
    ECG: "EKG",
    ECHO: "Ultraschall – Transthorakale Echokardiographie",
    CT_CHEST: "CT - Thorax",
    CT_ABDO: "CT - Abdomen",
    ABDO_USS: "Ultraschall – Abdomen",
    TV_USS: "Ultraschall – Transvaginaler",
    ABDO_X_RAY: "Röntgen - Abdomen",
    CHEST_X_RAY: "Röntgen - Thorax",
    LUMBAR_X_RAY: "Röntgen - LWS",
    ASCITIC_TAP: "Punktion - Ascitespunktion",
    THORACOCENTESIS_DIAGNOSTIC: "Punktion – Pleurapunktion",
    CATH_LAB: "Herzkatheter",
    CT_HEAD: "CT - Schädel",
    CT_AORTA: "CT Aorta",
    LUMBAR_PUNCTURE: "Punktion - Lumbalpunktion",
    THYROID_USS: "Ultraschall - Schilddrüse",
    CTG: "CTG",
    DIX_HALLPIKE_MANEUVER: "Dix-Hallpike-Test",
    PREGNANCY_TEST: "Schwangerschaftstest",
    ARTERIAL_DUPLEX: "Ultraschall – Arterielle Doppler/Duplex-Untersuchung",
    OGD: "OGD",
    TOE: "Transösophageale Echokardiographie",
};