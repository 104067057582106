import styled from "@emotion/styled";

export const TurnTabletTitle = styled.p`
    width: fit-content;
    height: fit-content;
    padding: 0.25rem 0.5rem;
    border-radius: 100px;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    color: #6b7280;
    background-color: #bae6fd;
    margin: 0px;
    margin-bottom: 5px;
    font-family: "Helvetica", Arial, sans-serif;
`;
