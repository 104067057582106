import React from "react";
import Tooltip from "../tooltip/Tooltip";
import { IconActionButtonContainer } from "./IconActionButton.styles";

interface IconActionButtonProps {
    tooltipText: string;
    selected: boolean;
    Icon: React.ElementType;
    onClick: () => void;
}

function IconActionButton({
    tooltipText,
    selected,
    Icon,
    onClick,
}: IconActionButtonProps) {
    return (
        <Tooltip text={tooltipText} placement="bottom">
            <IconActionButtonContainer selected={selected} onClick={onClick}>
                <Icon />
            </IconActionButtonContainer>
        </Tooltip>
    );
}
export default IconActionButton;
