// External imports
import React, { useEffect, useState } from "react";

// Internal imports
import {
    PatientDetailContainer,
    PatientDetailInfoContainer,
} from "./PatientDetail.styles";
import { PatientInfo } from "./PatientInfo/PatientInfo";
import { PatientInfoMain } from "./PatientInfoMain/PatientInfoMain";
import { PatientNotes } from "./PatientNotes/PatientNotes";
import { NotesPage } from "../../../pages/students/notePage/Notes";

// Shared dependencies
import {
    SidebarDocumentationDependencies,
    GameBarLayoutTranslations,
    DemographicsDependencies,
    TurnManagementDependencies,
} from "../../../core/sharedDependencies/types";

/**
 * Complete props for the PatientDetail component
 */
export interface PatientDetailProps {
    patient?: any;
    isDischargeFormOpen?: boolean;
    translations: GameBarLayoutTranslations;
    documentationDependencies: SidebarDocumentationDependencies;
    turnDependencies: TurnManagementDependencies;
    useLanguage: any;
    demographicsDependencies: DemographicsDependencies;
    refreshPage: () => void;
}

/**
 * PatientDetail component
 *
 * This is the component for the patient detail section in the patient sidebar.
 */
export const PatientDetail: React.FC<PatientDetailProps> = ({
    patient,
    isDischargeFormOpen = false,
    translations,
    documentationDependencies,
    turnDependencies,
    useLanguage,
    demographicsDependencies,
    refreshPage,
}: PatientDetailProps) => {
    const [tab, setTab] = useState("main");

    const isDesktop = true;

    // Dependency injections from shared dependencies. We are using the
    // destructuring assignment to extract the values from the object on this
    // occasion as we are reusing the NotesPage which requires these dependencies
    // to be passed in as props.
    const { getAllNotesApiCall, getNotesLocalStorage, setNotesLocalStorage } =
        documentationDependencies;

    const content =
        tab === "main" ? (
            <PatientInfoMain
                patient={patient}
                isDischargeFormOpen={isDischargeFormOpen}
                translations={translations}
                demographicsDependencies={demographicsDependencies}
                refreshPage={refreshPage}
            />
        ) : tab === "dischargeNotes" ? (
            <NotesPage
                getAllNotesApiCall={getAllNotesApiCall}
                getNotesLocalStorage={getNotesLocalStorage}
                setNotesLocalStorage={setNotesLocalStorage}
                isLayout={false}
            />
        ) : (
            <PatientNotes translations={translations.gameBar} />
        );

    return (
        patient && (
            <PatientDetailContainer>
                <PatientDetailInfoContainer isDesktop={isDesktop}>
                    <PatientInfo
                        patient={patient}
                        tab={tab}
                        setTab={setTab}
                        isDischargeFormOpen={isDischargeFormOpen}
                        translations={translations}
                    />
                </PatientDetailInfoContainer>
                {content}
            </PatientDetailContainer>
        )
    );
};

export default PatientDetail;
