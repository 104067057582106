import styled from "@emotion/styled";

const NewClassFormBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    background-color: #f3f4f6;
    padding: 1em;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    h1 {
        font-size: 1.2rem;
    }
`;

export default NewClassFormBox;
