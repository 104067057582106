import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { Modal } from "../../../../../../components/core/modal/Modal";
import {
    ShowInvestigationImageReportModalContentDescription,
    ShowInvestigationImageReportModalContentImage,
    ShowInvestigationImageReportModalFormFooter,
    ShowInvestigationReportModalContainer,
    ShowInvestigationReportModalContentContainer,
    ShowInvestigationReportModalContentLabel,
    ShowInvestigationReportModalContentWrapper,
    ShowInvestigationReportModalForm,
    ShowInvestigationReportModalFormTitle,
} from "./ShowInvestigationImageReport.styles";
import TextArea from "../../../../../../components/core/input-form/TextArea";
import WebsiteButton from "../../../../../../components/core/button/WebsiteButton";


// Translation
import { useLanguage } from "../../../../../../core/translations/LanguageContext";
import { enTranslations } from "./../../../translations/en";
import { deTranslations } from "./../../../translations/de";
import { InvestigationPageTranslations } from "./../../../translations/types";
import { SupportedLanguage } from "../../../../../../core/translations/supportedLanguages";
import { enInvestigationTranslations} from "../../../../../../core/translations/investigations/en";
import { deInvestigationTranslations} from "../../../../../../core/translations/investigations/de";
import { InvestigationTypesTranslations } from "../../../../../../core/translations/investigations/types";


// Set the translations for the page
const TRANSLATIONS: Record<SupportedLanguage, InvestigationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const INVESTIGATION_TRANSLATIONS: Record<SupportedLanguage, InvestigationTypesTranslations> = {
    en: enInvestigationTranslations,
    de: deInvestigationTranslations,
};


interface ShowInvestigationImageReportProps {
    investigateName: string;
    turn: number;
    url: string;
    secondUrl?: string;
    imageComment?: string;
    onClose: () => void;
}

export const ShowInvestigationImageReport = ({
                                                 investigateName,
                                                 turn,
                                                 url,
                                                 secondUrl,
                                                 imageComment,
                                                 onClose,
                                             }: ShowInvestigationImageReportProps) => {

    // Language translation
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const investigationTranslations = INVESTIGATION_TRANSLATIONS[language];

    const [comment, setComment] = useState(imageComment ?? "");
    const [displayedImage, setDisplayedImage] = useState(url); // State to manage displayed image

    const handleGetRadiologyHelp = () => {
        if (secondUrl) {
            setDisplayedImage(secondUrl); // Update the displayed image to the second URL
        }
    };

    return (
        <Modal onRequestClose={onClose}>
            <ShowInvestigationReportModalContainer>
                <MdOutlineClose onClick={onClose} />
                <ShowInvestigationReportModalFormTitle>
                    {translations.imageAvailable} {investigationTranslations[investigateName]}
                </ShowInvestigationReportModalFormTitle>
                <ShowInvestigationReportModalContentContainer>
                    <ShowInvestigationReportModalForm
                        onSubmit={(e: React.ChangeEvent<HTMLFormElement>) =>
                            e.preventDefault()
                        }
                    >
                        <ShowInvestigationReportModalContentWrapper
                            margin={false}
                        >
                            <ShowInvestigationReportModalContentLabel>
                                {translations.timeOrdered}: {turn}
                            </ShowInvestigationReportModalContentLabel>
                            <ShowInvestigationImageReportModalContentImage
                                src={displayedImage} // Display the currently selected image
                                alt="Investigation Report Image"
                            />
                        </ShowInvestigationReportModalContentWrapper>

                        {/* Conditionally render "Get radiology help" button if secondUrl exists and first image is displayed */}
                        {secondUrl && displayedImage !== secondUrl && (
                            <ShowInvestigationReportModalContentWrapper>
                                <WebsiteButton
                                    type="button"
                                    onClick={handleGetRadiologyHelp}
                                >
                                    {translations.getRadiologyHelp}
                                </WebsiteButton>
                            </ShowInvestigationReportModalContentWrapper>
                        )}

                        <ShowInvestigationReportModalContentWrapper>
                            <ShowInvestigationReportModalContentLabel>
                                {translations.notesSection}:
                            </ShowInvestigationReportModalContentLabel>
                            <TextArea
                                rows={2}
                                placeholder={translations.addAnyNotes}
                                value={comment}
                                onChange={(
                                    e: React.ChangeEvent<HTMLTextAreaElement>
                                ) => setComment(e.target.value)}
                            />
                        </ShowInvestigationReportModalContentWrapper>
                        <ShowInvestigationImageReportModalFormFooter>
                            <WebsiteButton type="submit" tag="button">
                                {translations.save}
                            </WebsiteButton>
                        </ShowInvestigationImageReportModalFormFooter>
                    </ShowInvestigationReportModalForm>
                </ShowInvestigationReportModalContentContainer>
            </ShowInvestigationReportModalContainer>
        </Modal>
    );
};

export default ShowInvestigationImageReport;
