// External imports
import React, { useState } from "react";

// Internal imports
import NewClassFormBox from "../NewClassFormBox";
import { LabelledSetting } from "./LabelledSettingComponent";
import { Container } from "./NewClassConfig.styles";

// Translation imports
import { useLanguage } from '../../../../../core/translations/LanguageContext';
import { FormConfigTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../../../core/translations/supportedLanguages';

// Dependency injection imports
import { NewClassDataStore } from "../../../../../core/metaDataAccess/classes/store";

// Constants
const TRANSLATIONS: Record<SupportedLanguage, FormConfigTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * NewClassFormConfig component
 * Allows configuration of ECG images and clinical reasoning prompts for a new class
 * @returns {React.ReactElement} Rendered component
 */
const NewClassFormConfig: React.FC = () => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    const [ecgImagesEnabled, setEcgImagesEnabled] = useState(
        NewClassDataStore.getEcgImagesEnabled()
    );
    const [clinicalReasoningPromptsEnabled, setClinicalReasoningPromptsEnabled] = useState(
        NewClassDataStore.getClinicalReasoningEnabled()
    );

    /**
     * Handles changes to the ECG images enabled setting
     * @param {boolean} checked - New checkbox state
     */
    const handleEcgImagesEnabled = (checked: boolean): void => {
        NewClassDataStore.setEcgImagesEnabled(checked);
        setEcgImagesEnabled(checked);
    };

    /**
     * Handles changes to the clinical reasoning prompts enabled setting
     * @param {boolean} checked - New checkbox state
     */
    const handleClinicalReasoningPromptsEnabled = (checked: boolean): void => {
        NewClassDataStore.setClinicalReasoningEnabled(checked);
        setClinicalReasoningPromptsEnabled(checked);
    };

    return (
        <NewClassFormBox>
            <Container>
                <LabelledSetting
                    label={translations.ecgImages.label}
                    description={translations.ecgImages.description}
                    value={ecgImagesEnabled}
                    onChangeChecked={handleEcgImagesEnabled}
                />
                <LabelledSetting
                    label={translations.clinicalReasoning.label}
                    description={translations.clinicalReasoning.description}
                    value={clinicalReasoningPromptsEnabled}
                    onChangeChecked={handleClinicalReasoningPromptsEnabled}
                />
            </Container>
        </NewClassFormBox>
    );
};

export default NewClassFormConfig;