// External imports
import React from "react";

// Internal imports
import {
    Flex,
    NoDiseases,
    Section,
    SectionHeader,
    SectionItems,
    SectionItem,
} from "./TrackerComponent.styles";
import { CategoryState } from "../../../../core/metaDataAccess/classes/diseaseChoices/state";

// Translation imports
import { useLanguage } from '../../../../core/translations/LanguageContext';
import { TrackerTranslations } from './translations/types';
import { DiseaseTranslations } from '../../../../core/translations/diseases/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { enDiseaseTranslations } from "../../../../core/translations/diseases/en";
import { deDiseaseTranslations } from '../../../../core/translations/diseases/de';
import { SupportedLanguage } from '../../../../core/translations/supportedLanguages';

// Dependency injection imports
import { DiseaseChoiceState } from "../../../../core/metaDataAccess/classes/diseaseChoices/state";

// Constants
const TRANSLATIONS: Record<SupportedLanguage, TrackerTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const DISEASE_TRANSLATIONS: Record<SupportedLanguage, DiseaseTranslations> = {
    en: enDiseaseTranslations,
    de: deDiseaseTranslations,
};

/**
 * Props interface for the TrackerComponent
 * @interface TrackerProps
 * @property {DiseaseChoiceState} ChosenDiseaseState - The state of diseases chosen by the user
 */
interface TrackerProps {
    ChosenDiseaseState: DiseaseChoiceState;
}

/**
 * TrackerComponent displays the list of diseases that have been chosen by the user,
 * organized by disease category
 * @param {TrackerProps} props - Component props
 * @returns {React.ReactElement} Rendered component
 */
export const TrackerComponent: React.FC<TrackerProps> = ({ ChosenDiseaseState }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const diseaseTranslations = DISEASE_TRANSLATIONS[language];

    const renderDiseaseCategory = (categoryState: CategoryState) => {
        const checkedDiseases = categoryState.getChecked();

        if (checkedDiseases.length === 0) {
            return null;
        }

        return (
            <Section key={categoryState.category}>
                <SectionHeader>
                    {diseaseTranslations.categories[categoryState.category]}
                </SectionHeader>
                <SectionItems>
                    {checkedDiseases.map((disease, index) => (
                        <SectionItem
                            key={`${disease.name}-${index}`}
                            title={diseaseTranslations.diseases[disease.name].name}
                        >
                            {diseaseTranslations.diseases[disease.name].name}
                            <div className="tooltip">
                                {diseaseTranslations.diseases[disease.name].name}
                            </div>
                        </SectionItem>
                    ))}
                </SectionItems>
            </Section>
        );
    };

    return (
        <Flex>
            <h4>{translations.header}</h4>
            {ChosenDiseaseState.getAllChecked().length === 0 && (
                <NoDiseases>{translations.noDiseases}</NoDiseases>
            )}
            {ChosenDiseaseState.allCategories.map((categoryState) =>
                renderDiseaseCategory(categoryState)
            )}
        </Flex>
    );
};