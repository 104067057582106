import styled from '@emotion/styled';

// Styled components for the layout

export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    height: 75vh; /* Full height of the viewport */
    overflow-y: hidden;
`;

export const MessagesContainer = styled.div`
    flex-grow: 1; /* Allows the container to grow and fill available space */
    overflow-y: auto; /* Enables scroll when messages overflow */
    padding: 1rem;
    height: 75vh; /* Fixed height for the chat section */
    &.noScroller {
        overflow-y: unset; /* Remove scroll bar when no messages */
    }
`;

export const ButtonArea = styled.div`
    padding: 1rem;
    background-color: #f0f0f0; /* Or any background color you want */
    position: sticky;
    bottom: 0; /* Sticks the button area to the bottom */
`;

// Custom scrollbar styles
export const Scrollbar = styled.div`
    ::-webkit-scrollbar {
        width: 7px; /* Customize scrollbar width */
        border-radius: 50%; /* Rounded borders for scrollbar */
    }

    ::-webkit-scrollbar-thumb {
        background: #3d3a3a; /* Color for the active scroll section */
        border-radius: 50px; /* Rounded borders for scrollbar */
    }

    ::-webkit-scrollbar-track {
        background: lightgray; /* Background for the scroll track */
    }
`;

