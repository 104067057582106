import styled from '@emotion/styled';

export const PopoutMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: calc(100% + 8px);
  top: 0;
  z-index: 10;
  gap: 4px; /* Gap between submenu items */
  overlay: visible;
  left: 130px !important;
`;

export const SubmenuNavItemsMain = styled.div`
  position: relative;
  &:hover{
    & > #mainPopoutSubmenu{
      display: flex;
    }
  } 
`;


export const SubmenuNavItemsMenu = styled.div`
  position: absolute;
  z-index: 4;
  top:0px;
  display: none;

`;

export const GutterContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-left: 10px;

`;


