// External imports
import React, { useState } from "react";

// Internal imports
import PricingCard from "./components/PricingCard/PricingCard";
import ToggleButton from "./components/ToggleButton/ToggleButton";
import { PRICING_CARD_DATA } from "./Pricing.config";
import {
    PricingCardContainer,
    PricingSectionContainer,
    PricingSectionContentContainer,
    PricingSectionDescription,
    PricingSectionTitle,
} from "./Pricing.styles";
import MainPageLayout from "../../../components/core/header/MainPageLayout";

// Language Context
import { useLanguage } from '../../../core/translations/LanguageContext';

// Translations
import { PricingTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

const TRANSLATIONS: Record<SupportedLanguage, PricingTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Pricing page component
 * Displays pricing plans with toggleable monthly/yearly pricing
 * Includes features and details for each plan
 */
const Pricing: React.FC = () => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const [isMonthly, setIsMonthly] = useState<boolean>(true);

    return (
        <MainPageLayout>
            <PricingSectionContainer>
                <PricingSectionContentContainer>
                    <PricingSectionTitle>
                        {translations.section.title}
                    </PricingSectionTitle>
                    <PricingSectionDescription>
                        {translations.section.description}
                    </PricingSectionDescription>
                </PricingSectionContentContainer>
                <div>
                    <ToggleButton
                        isMonthly={isMonthly}
                        onClick={() => setIsMonthly(!isMonthly)}
                        monthlyText={translations.section.toggleMonthly}
                        yearlyText={translations.section.toggleYearly}
                    />
                    <PricingCardContainer>
                        {PRICING_CARD_DATA.map((data) => (
                            <PricingCard
                                key={data.id}
                                title={translations.plans[data.id].title}
                                price={isMonthly ? data.monthlyPrice : data.yearlyPrice}
                                details={translations.plans[data.id].details}
                                liItems={translations.plans[data.id].features}
                                duration={isMonthly
                                    ? translations.section.perMonth
                                    : translations.section.perYear}
                            />
                        ))}
                    </PricingCardContainer>
                </div>
            </PricingSectionContainer>
        </MainPageLayout>
    );
};

export default Pricing;