import React, { useState, ChangeEvent, FormEvent } from "react";
import { Modal } from "../Modal";
import {
    AllergyModalContainer,
    AllergyModalContentContainer,
    AllergyModalContentWrapper,
    AllergyModalTitle,
    AllergyModalButtonContainer,
    AllergyModalButtonYes,
    AllergyModalButtonNo,
    AllergyTextArea,
    InputAreaContainer,
} from "./AllergyModal.styles";
import WebsiteButton from "../../button/WebsiteButton";

/**
 * AllergyModal Props.
 * @interface AllergyModalProps
 * @property {() => void} onClose - The function to close the modal.
 * @property {() => void} onSubmit - The function to submit the allergies.
 */
interface AllergyModalProps {
    onClose: () => void;
    onSubmit: (allergies: string[]) => void; // Function to submit allergies
    translations: any;
}

/**
 * AllergyModal component.
 * @component AllergyModal
 * @description A modal component for submitting allergy information.
 * @param {AllergyModalProps} props - The component props.
 * @returns {JSX.Element} The rendered AllergyModal component.
 */
function AllergyModal({ onClose, onSubmit, translations }: AllergyModalProps) {
    const [hasAllergy, setHasAllergy] = useState<boolean | null>(null); // To track if the patient has allergies
    const [formData, setFormData] = useState<string[]>([]); // To store allergy input

    // Handle text area changes
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const newFormData = e.target.value
            .split(",")
            .map((allergy) => allergy.trim());
        setFormData(newFormData);
    };

    // Handle form submission
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (hasAllergy) {
            onSubmit(formData); // Submit allergy data
        } else {
            onSubmit([]); // If no allergies, submit an empty array
        }
        onClose(); // Close modal after submission
    };

    // Handle allergy status update (Yes/No buttons)
    const updateAllergiesStatus = (status: boolean) => {
        setHasAllergy(status);
        if (!status) {
            onSubmit([]); // Immediately submit empty array if "No"
            onClose(); // Close modal
        }
    };

    return (
        <Modal width="35%" onRequestClose={onClose}>
            <AllergyModalContainer>
                <AllergyModalContentContainer>
                    <AllergyModalTitle>
                        {translations.doPatientHasAllergies}
                    </AllergyModalTitle>
                    <AllergyModalContentWrapper>
                        {hasAllergy === null && (
                            <div>
                                <AllergyModalButtonContainer>
                                    <AllergyModalButtonYes
                                        tag="button"
                                        onClick={() =>
                                            updateAllergiesStatus(true)
                                        }
                                    >
                                        {translations.yes}
                                    </AllergyModalButtonYes>

                                    <AllergyModalButtonNo
                                        tag="button"
                                        onClick={() =>
                                            updateAllergiesStatus(false)
                                        }
                                    >
                                        {translations.no}
                                    </AllergyModalButtonNo>
                                </AllergyModalButtonContainer>
                            </div>
                        )}

                        {hasAllergy === true && (
                            <form onSubmit={handleSubmit}>
                                <InputAreaContainer>
                                    <label>{translations.addAllergy}</label>
                                    <AllergyTextArea
                                        rows={2}
                                        name="allergies"
                                        value={formData.join(",")}
                                        onChange={handleChange}
                                    />
                                </InputAreaContainer>
                                <AllergyModalButtonContainer>
                                    <WebsiteButton tag="button" type="submit">
                                        {translations.submitAllergies}
                                    </WebsiteButton>
                                </AllergyModalButtonContainer>
                            </form>
                        )}
                    </AllergyModalContentWrapper>
                </AllergyModalContentContainer>
            </AllergyModalContainer>
        </Modal>
    );
}

export default AllergyModal;
