import styled from "@emotion/styled";
import WebsiteButton from "../../../../components/core/button/WebsiteButton";

export const ObservationsTable = styled.table`
    border-radius: 0.5rem;
    overflow: hidden;
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    td,
    th {
        padding: 0.8em 0.5em;
        text-align: center
    }
`;
export const TableHead = styled.thead`
    tr {
        background-color: #f8fafc;
    }
`;
export const TableBody = styled.tbody`
    background-color: #f8fafc;
`;
export const TableRow = styled.tr<{ isHovered: boolean }>`
    background-color: ${(props) => (props.isHovered ? "#e5e7eb" : "")};
`;
export const TableCell = styled.td``;
export const TakeObsButton = styled(WebsiteButton)`
    font-size: 0.9em;
`;