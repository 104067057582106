import styled from "@emotion/styled";

export const ConfirmPageMainForm = styled.form`
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(231, 226, 226);
`;

export const ConfirmPageMainFormTextArea = styled.textarea`
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 20px;
    width: 100%;
`;

export const ConfirmPageSelectParent = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
    width: 50%;
    flex-direction: column;
    @media (max-width:767px) {
        width: 100%;
      }
`

export const ConfirmPageLabel = styled.label`
    font-family: Inter, sans-serif;
`;

export const ConfirmPageSubmitButton = styled.button`
    padding: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    font-family: Inter, sans-serif;
    width: fit-content;
    background-color: #0284d2;
    color: white;
    &:disabled{
        background-color: #0284d280;
    }
`;

export const ConfirmPageResetButton = styled.button`
    padding: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    font-family: Inter, sans-serif;
    width: fit-content;
    background-color: #c6cccf;
    color: black; 
`;

export const ConfirmPageButtonArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 5px;
`;

export const ConfirmPageInputFieldArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;