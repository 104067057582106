// External imports
import React, { Fragment } from "react";

// Internal imports

import {
    PressBreadcrumbChild,
    PressBreadcrumbContainer,
    PressBreadcrumbList,
    PressBreadcrumbListItem,
    PressBreadcrumbTitle,
    PressContainer,
    PressContentBlockquote,
    PressContentContainer,
    PressContentDescription,
    PressContentHighlightText,
    PressContentImage,
    PressContentSubtitle,
    PressContentTitle,
    PressContentWrapper,
} from "./Press.styles";
import { PressData } from "./Press.config";
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import PlaceholderImage from "./red-white-cat.jpg";

// Translations
import { PressPageTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

// Language Context
import { useLanguage } from "../../../core/translations/LanguageContext";

/**
 * Translation record type for the press page
 */
const TRANSLATIONS: Record<SupportedLanguage, [PressPageTranslations]> = {
  en: enTranslations,
  de: deTranslations,
};

/**
 * The Press Page
 */
export const PressPage: React.FC = () => {
    //include selected language from header via hook
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    return (
        <MainPageLayout>
            <PressContainer>
                <PressBreadcrumbContainer>
                    <PressBreadcrumbChild>
                        <PressBreadcrumbList>
                            <PressBreadcrumbListItem>
                                Home
                            </PressBreadcrumbListItem>
                            <PressBreadcrumbListItem>/</PressBreadcrumbListItem>
                            <PressBreadcrumbListItem>
                                Press
                            </PressBreadcrumbListItem>
                        </PressBreadcrumbList>
                        <PressBreadcrumbTitle>Press</PressBreadcrumbTitle>
                    </PressBreadcrumbChild>
                </PressBreadcrumbContainer>
                <PressContentContainer>
                    <PressContentWrapper>
                        {PressData.map((data, index) => (
                            <Fragment key={index}>
                                <PressContentTitle>
                                    {translations[index].title}
                                </PressContentTitle>
                                {data.quotes.map((quote: any, i) => (
                                    <div key={i}>
                                        <PressContentSubtitle>
                                            {translations[index].quotes[i].subtitle}
                                        </PressContentSubtitle>
                                        <PressContentDescription>
                                            <PressContentHighlightText>
                                                {translations[index].quotes[i].highlightText}
                                            </PressContentHighlightText>{" "}
                                            {translations[index].quotes[i].description}
                                        </PressContentDescription>
                                        {quote.quote ? (
                                            <PressContentBlockquote>
                                                <em>
                                                    &ldquo;&ldquo;
                                                    {translations[index].quotes[i].quote}
                                                    &rdquo;&rdquo;
                                                </em>
                                            </PressContentBlockquote>
                                        ) : (
                                            ""
                                        )}
                                        {/* {quote.image ? ( */}
                                            <PressContentImage
                                                src={PlaceholderImage}
                                                alt="quote"
                                            />
                                        {/* ) : (
                                            ""
                                        )} */}
                                    </div>
                                ))}
                            </Fragment>
                        ))}
                    </PressContentWrapper>
                </PressContentContainer>
            </PressContainer>
        </MainPageLayout>
    );
};
