// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import {
    treatmentData,
    IndividualTreatmentData,
} from "../../../LocalStorage/patients/interface";

// The payload for posting a treatment
export interface PostTreatmentPayload {
    patient_id: number;
    turn: number;
    treatment_type: IndividualTreatmentData;
}

// The interface for the response from the post treatment endpoint
export interface PostTreatmentResponse {
    status: number;
    error?: string;
    data?: IndividualTreatmentData;
}

// The type for the postTreatment function
export type PostTreatmentType = (
    jwt: string,
    postTreatmentPayload: PostTreatmentPayload
) => Promise<PostTreatmentResponse>;

/*
The function to post a treatment for a patient.

@jwt: The JWT of the user requesting the post.
@postTreatmentPayload: The payload containing the patient ID, turn, treatment details, reason, and priority.

@returns: The ApiResponse from the post treatment endpoint.
 */
export async function postTreatment(
    jwt: string,
    postTreatmentPayload: PostTreatmentPayload
): Promise<PostTreatmentResponse> {
    const url = new PatientUrl().postTreatment;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<PostTreatmentResponse | string>(
            url,
            {
                patient_id: postTreatmentPayload.patient_id,
                turn: postTreatmentPayload.turn,
                treatment_type: postTreatmentPayload.treatment_type,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    token: jwt,
                },
                validateStatus: () => true,
            }
        );
    } catch (error) {
        return {
            status: 500,
            error: "Network or other error occurred",
        };
    }

    if (response.status === 201) {
        return {
            status: response.status,
            data: response.data as IndividualTreatmentData,
        };
    } else {
        return {
            status: response.status,
            error: response.data as string,
        };
    }
}
