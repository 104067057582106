import { IndividualTreatmentData, treatmentData } from "./interface";
import { getSpecificPatientData, updatePatientData } from "./core";


// Function signature type for setting to local storage
export type SetTreatmentToLocalStorageType = (patient_id: number, newTreatmentData: IndividualTreatmentData) => void;


/*
Set the treatment data for a patient in localStorage.
 */
export function setTreatmentToLocalStorage(patient_id: number, newTreatmentData: IndividualTreatmentData): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has treatment data, initialize if none
    let existingTreatments = patientData.treatments || {};

    // Get keys of the new treatment data
    const treatmentType = newTreatmentData.treatment;

    // Add or update the specific treatment type
    existingTreatments[treatmentType] = newTreatmentData;

    // Update the patient's treatment data
    patientData.treatments = existingTreatments;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}


export type GetTreatmentDataFromLocalStorageType = (patient_id: number) => treatmentData | null;


// Function to get the treatment data for a patient from localStorage
export function getTreatmentDataFromLocalStorage(patient_id: number): treatmentData | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // Return the treatment data, or null if no data is found
    return patientData?.treatments || null;
}




// Function signature type for getting from local storage
export type listOfTreatmentDataFromLocalStorageType = (patient_id: number) => IndividualTreatmentData[] | null;

/*
Function to get the treatment data for a patient from localStorage
 */
export function listOfTreatmentDataFromLocalStorage(patient_id: number): IndividualTreatmentData[] | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return null;
    }

    let existingTreatments = patientData.treatments || {};
    const treatmentKeys = Object.keys(existingTreatments);

    let treatments: IndividualTreatmentData[] = [];

    treatmentKeys.forEach((key) => {
        const treatment = existingTreatments[key];
        treatments.push(treatment);
    });

    return treatments;
}
