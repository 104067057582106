import styled from "@emotion/styled";

export const PageMain = styled.div`
    width: 100%;
`;

export const ListingLabels = styled.p`
    font-family: Inter, sans-serif; /* Include font family */
    font-weight: 600; /* Make the text bold */
    margin-top: 1rem; /* Add some margin in top */
`;
export const DrugsTable = styled.table`
    border-spacing: 0px 20px; /* Add some spacing between rows */
    font-family: Helvetica, Arial, sans-serif; /* Include font family */
    width: 100%; /* Assign full width to table */
    th {
        text-align: left; /* Align table headers left */
    }
`;
