import styled from "@emotion/styled";

export const GuidelineItemContainer = styled.div`
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-direction: column;
    gap: 0.5rem;
    border-bottom: 1px solid #e5e7eb;
    background-color: transparent;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #f3f4f6;
    }
`;

export const GuidelineItemSubTitle = styled.h5`
    color: #000000;
`;

export const GuidelineItemTitle = styled.a`
    color: #3b82f6;
    font-size: 0.875rem;
`;

export const GuidelineItemParagraph = styled.p`
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #000000;
`;
