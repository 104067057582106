import { DrugPageTranslations } from "./types";

export const enTranslations: DrugPageTranslations = {
    title: "Drug Chart",
    updateAllergies: "Update Allergies",
    doPatientHasAllergies: "Does the patient have any allergies?",
    yes: "Yes",
    no: "No",
    addAllergy: "Add Allergy (comma-separated)",
    submitAllergies: "Submit Allergies",
    drugType: "Drug Type",
    selectOrType: "Select or Type",
    prescribe: "Prescribe",
    time: "Time",
    drugName: "Drug Name",
    route: "Route",
    dose: "Dose",
};
