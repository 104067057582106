// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";

/*
The payload for getting all additional tests.
 */
export interface GetAllAdditionalTestsPayload {
    patient_id: number;
}

/*
The interface for a single additional test entry.
 */
export interface AdditionalTestEntry {
    professional: string;
    tests: string[];
    reason: string;
    summary: string;
}

/*
The interface for all additional tests response data.
 */
export interface AllAdditionalTestsData {
    additional_tests: {
        [key: string]: AdditionalTestEntry;
    };
}

/*
The interface for the response from the get all additional tests endpoint.
@status: The HTTP status code.
@error: The error message.
@data: The object containing all additional tests.
 */
export interface GetAllAdditionalTestsResponse {
    status: number;
    error?: string;
    data?: AllAdditionalTestsData;
}

export type GetAllAdditionalTestsType = (jwt: string, payload: GetAllAdditionalTestsPayload) => Promise<GetAllAdditionalTestsResponse>;

/*
The function to get all additional tests for a patient.

@jwt: The JWT of the user requesting the data.
@payload: The payload containing the patient ID.
@returns: The ApiResponse from the get all additional tests endpoint.
 */
export async function getAllAdditionalTests(jwt: string, payload: GetAllAdditionalTestsPayload): Promise<GetAllAdditionalTestsResponse> {
    const url = new PatientUrl().getAdditionalTests;
    let response: AxiosResponse<any>;

    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    try {
        response = await axios.post<AllAdditionalTestsData | string>(url, {
            patient_id: payload.patient_id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });

    } catch (error) {
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 200) {
        return {
            status: response.status,
            data: response.data as AllAdditionalTestsData
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}