import React from 'react';
import { MessageTextRight, InnerPreviewMessageRight } from './RightSideMessagePreview.styles';
export interface ChatProps {
    message: string
}
const RightSideMessagePreview: React.FC<ChatProps> = ({ message }) => {

    return (
        <MessageTextRight>
            <InnerPreviewMessageRight>
                {message}
            </InnerPreviewMessageRight>
        </MessageTextRight>
    )
}

export default RightSideMessagePreview;
