import styled from "@emotion/styled";

export const PatientExamCardContainer = styled.div<{
    isSidebar?: boolean;
    isCardSelected: boolean;
}>`
    display: flex;
    position: relative;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    color: #000000;
    background-color: #ffffff;
    user-select: none;
    ${(props) =>
        props.isSidebar
            ? `
    cursor: pointer;
    `
            : `border-radius: 0.75rem; 
    border-width: 1px;
    border-color: #E5E7EB;
    width: 15rem;
    margin-left: auto;
    margin-right: auto;
    `}
    ${(props) =>
        props.isSidebar && props.isCardSelected
            ? `
    background: #E6F0FF;
    `
            : ``}
`;

export const ExaminationName = styled.h2<{ isSideBar?: boolean }>`
    ${(props) =>
        props.isSideBar
            ? `
        text-align: center;
        `
            : `
        font-weight: 500; 
        text-align: center;
        font-size: 1rem;
        `}
`;
