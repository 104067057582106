import { ClassUrl } from "./AUrl";
import axios, {AxiosResponse} from "axios";
import { getJwt } from "../../../LocalStorage/AuthSession/core";
// import { UserSession } from "./login";


/*
The interface needed to assign a class to a user.

@auth_code: The auth code associated with the class to be assigned.
 */
export interface AssignClassSchema {
    auth_code: string;
}


/*
The metadata for the class assigned to the user.

@id: The id of the student roster record.
@class_id: The id of the class associated with the student roster record.
@student_id: The id of the student associated with the student roster record.
@date_assigned: The date the student was assigned to the class.
@game_sessions_started: The number of game sessions started by the student.
 */
export interface ClassData {
    id: number;
    class_id: number;
    student_id: number;
    date_assigned: string;
    game_sessions_started: number;
}


/*
The interface for the response from the update role endpoint.
 */
export interface ApiResponse {
    status: number;
    error?: string;
    data?: ClassData;
}


export type AssignClassType = (jwt: string, payload: AssignClassSchema) => Promise<ApiResponse>;


/*
The function to update assign a class to a user (usually a student).

@payload: The data needed to update the role of the user session.
@jwt: The JWT of the user being assigned the class.

@returns: The ApiResponse from the update role endpoint which is the entire metadata of the user session
          without the JWT and refresh token.
 */
export async function assignClass(jwt: string, payload: AssignClassSchema): Promise<ApiResponse> {
    const url = new ClassUrl().assign;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    let response: AxiosResponse<any>;
    try {
        response = await axios.post<ApiResponse | string>(url, payload, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            // Resolve the promise for all HTTP status codes
            validateStatus: () => true
        });

    } catch (error) {
        // Handle network or other Axios-related errors
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 201) {
        // Successful response with ClassData
        return {
            status: response.status,
            data: response.data as ClassData
        };
    } else {
        // Other response codes with a string message
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
