import { RewardsTranslations } from './types';

export const enTranslations: RewardsTranslations = {
    content: {
        title: "Badges",
        badgeLabel: "Name of the Badge",
        badges: {
            1: "Beginner",
            2: "Quick Learner",
            3: "Rising Star",
            4: "Problem Solver",
            5: "Diagnosis Expert",
            6: "Clinical Ace",
            7: "Medical Master",
            8: "Knowledge Seeker",
            9: "Care Champion",
            10: "Healthcare Hero",
            11: "Medical Virtuoso",
            12: "Grand Master"
        }
    }
};