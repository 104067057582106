// External Imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Internal Imports
import WaitingRoomImage from "../../../assets/waiting_room.jpg";
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import { MainPage } from "../../../components/core/mainPage/MainPage.styles";
import {
    NewGameFormComponent,
    NewGameState,
} from "./NewGameFormComponent/NewGameFormComponent";
import { getJwt } from "../../../core/LocalStorage/AuthSession/core";

// Dependency Injection Types
import { AssignClassType } from "../../../core/ServerApiLayer/VOne/classes/assign";
import { SetFromApiDataType } from "../../../core/LocalStorage/classSession/core";

// Translations
import { useLanguage } from "../../../core/translations/LanguageContext";
import { JoinClassTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";

// Consts for translations
const TRANSLATIONS: Record<SupportedLanguage, JoinClassTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/*
The props for the JoinClassPage component.

@prop joinClassApiCall: a function that assigns a class to the student by making an API request
@prop setSessionData: a function that sets the class data in local storage
 */
export interface JoinClassPageProps {
    joinClassApiCall: AssignClassType;
    setSessionData: SetFromApiDataType;
}

/**
 * Join Class Page
 *
 * This is the page that the student sees after they join a class
 * They will need to enter the authorisation code to join the class
 */
export const JoinClassPage: React.FC<JoinClassPageProps> = ({
    joinClassApiCall,
    setSessionData,
}) => {
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [failure, setFailure] = useState(false);

    // Get the language from the context
    const { language } = useLanguage();
    const translation = TRANSLATIONS[language];

    // Handle the submission of the form
    const handleNewClass = async (data: NewGameState) => {
        const jwt = getJwt();

        if (!jwt) {
            setError(translation.loginExpired);
            setFailure(true);
            return;
        }

        // Wipe all the previous patients data, and the session data from
        // local storage. Patients data is indexed by a number in local storage.
        // The session data is indexed by the string 'sessionData'.
        Object.keys(localStorage).forEach((key) => {
            if (!isNaN(parseInt(key))) {
                localStorage.removeItem(key);
            }
        });
        localStorage.removeItem("sessionData");

        // Construct the payload for API call
        const joinClassPayload = {
            auth_code: data.authCode,
        };

        try {
            const response = await joinClassApiCall(jwt, joinClassPayload);
            if (response.status === 201 && response.data) {
                // Use the injected setSessionData function to store class data in local storage
                setSessionData(response.data, data.authCode);
                navigate("/student/new-game");
            } else {
                setError(translation.errorOccurredAuthCode + response.error);
                setFailure(true);
            }
        } catch (error) {
            setError(translation.failedToJoinClass);
            setFailure(true);
        }
    };

    // Return the student front page
    return (
        <MainPageLayout>
            <MainPage backgroundImage={WaitingRoomImage}>
                <NewGameFormComponent
                    NewGame={true}
                    error={error}
                    failure={failure}
                    HandleNewGame={handleNewClass}
                    translations={translation}
                />
            </MainPage>
        </MainPageLayout>
    );
};
