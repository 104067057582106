// External imports
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { BiSolidLeftArrowCircle } from "react-icons/bi";

// Internal imports
import MainPageLayout from "../../../../components/core/header/MainPageLayout";
import {
    GuidesTemplatePageContainer,
    GuidesTemplateTitleAndButtonWrapper,
    GuidesTemplatePageTitle,
    GuidesTemplateFeatureArea,
    GuidesTemplateFeatureAreaIFrame,
    GuidesTemplateFeatureAreaImage,
    GuidesTemplateContentMainSection,
    GuidesTemplateContentMenu,
    GuidesTemplateContentMenuList,
    GuidesTemplateContentMenuListItem,
    GuidesTemplateContentMenuAnchor,
    GuidesTemplateDescription,
    GuidesTemplateDescriptionTitle,
    GuidesTemplateDivSection,
    GuidesTemplateDescriptionParagraph,
    GuidesTemplateDescriptionImage,
    GuidesTemplateDescriptionIFrame,
    GuideTemplateGoBackButton,
} from "./Guide.styles";
import { loadGuide } from "./guideLoader";

// Language Context
import { useLanguage } from "../../../../core/translations/LanguageContext";

// Types
import { Guide as GuideType } from './types';

/**
 * Guide component that displays a single guide's content
 */
export const Guide: React.FC = () => {
    const { language } = useLanguage();
    const { slug } = useParams();
    const [searchParams] = useSearchParams();
    const [guide, setGuide] = useState<GuideType | null>(null);
    const [loading, setLoading] = useState(true);
    const isVideo = searchParams.get("type") === "video";

    useEffect(() => {
        async function loadGuideContent() {
            if (!slug) return;

            try {
                const loadedGuide = await loadGuide(slug, language);
                setGuide(loadedGuide);
            } catch (error) {
                console.error("Failed to load guide:", error);
                setGuide(null);
            } finally {
                setLoading(false);
            }
        }

        loadGuideContent();
    }, [slug, language]);

    if (loading) {
        return (
            <MainPageLayout>
                <GuidesTemplatePageContainer>
                    <p>Loading...</p>
                </GuidesTemplatePageContainer>
            </MainPageLayout>
        );
    }

    if (!guide) {
        return (
            <MainPageLayout>
                <GuidesTemplatePageContainer>
                    <p>Guide not found. Please check the URL or try again later.</p>
                </GuidesTemplatePageContainer>
            </MainPageLayout>
        );
    }

    return (
        <MainPageLayout>
            <GuidesTemplatePageContainer>
                <GuidesTemplateTitleAndButtonWrapper>
                    <GuideTemplateGoBackButton href="/guides">
                        <BiSolidLeftArrowCircle />
                        <span>Go Back</span>
                    </GuideTemplateGoBackButton>
                    <GuidesTemplatePageTitle>{guide.title}</GuidesTemplatePageTitle>
                </GuidesTemplateTitleAndButtonWrapper>

                <GuidesTemplateFeatureArea>
                    {isVideo ? (
                        <GuidesTemplateFeatureAreaIFrame
                            width="100%"
                            src={guide.featureVideo}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    ) : (
                        <GuidesTemplateFeatureAreaImage
                            src={guide.featureImage}
                            alt={guide.title}
                        />
                    )}
                </GuidesTemplateFeatureArea>

                <GuidesTemplateContentMainSection>
                    <GuidesTemplateContentMenu>
                        <GuidesTemplateContentMenuList>
                            {guide.sections.map((section) => (
                                <GuidesTemplateContentMenuListItem key={section.id}>
                                    <GuidesTemplateContentMenuAnchor href={`#${section.id}`}>
                                        {section.title}
                                    </GuidesTemplateContentMenuAnchor>
                                </GuidesTemplateContentMenuListItem>
                            ))}
                        </GuidesTemplateContentMenuList>
                    </GuidesTemplateContentMenu>

                    <GuidesTemplateDescription>
                        {guide.sections.map((section) => (
                            <GuidesTemplateDivSection id={section.id} key={section.id}>
                                <GuidesTemplateDescriptionTitle>
                                    {section.title}
                                </GuidesTemplateDescriptionTitle>
                                {section.paragraphs.map((paragraph, index) => (
                                    <GuidesTemplateDescriptionParagraph
                                        key={`${section.id}-p-${index}`}
                                    >
                                        {paragraph}
                                    </GuidesTemplateDescriptionParagraph>
                                ))}
                                {section.videoEmbed && (
                                    <GuidesTemplateDescriptionIFrame
                                        width="100%"
                                        src={section.videoEmbed}
                                        title={`${section.title} video`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    />
                                )}
                                {section.image && (
                                    <GuidesTemplateDescriptionImage
                                        src={section.image}
                                        alt={`${section.title} illustration`}
                                    />
                                )}
                            </GuidesTemplateDivSection>
                        ))}
                    </GuidesTemplateDescription>
                </GuidesTemplateContentMainSection>
            </GuidesTemplatePageContainer>
        </MainPageLayout>
    );
};

export default Guide;
