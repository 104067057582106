// External Imports
import React from "react";
import { FaRegStickyNote } from "react-icons/fa";

// Internal Imports
import { ICON_TYPES, NOTES_TYPE } from "../../config/iconsMap";
import {
    ListContent,
    ListContentDescription,
    ListContentRightSide,
    ListContentTitle,
    ListIcon,
    ListIconContainer,
    ListItemContainer,
    ListItemContentContainer,
    NotesItem,
    VerticalLine,
} from "./NotesListItem.styles";
import TurnTablet from "../TurnTablet/TurnTablet";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";
import { NotesTranslations } from "../../translations/types";
import { enTranslations } from "../../translations/en";
import { deTranslations } from "../../translations/de";
import { useLanguage } from "../../../../../core/translations/LanguageContext";
import { formatGameTime } from "../../../../../core/gameTime/formatTime";
import { turnsToTime } from "../../../../../core/LocalStorage/turnLogic/core";

const TRANSLATIONS: Record<SupportedLanguage, NotesTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * NotesListProps.
 *
 * This is the props interface for the NotesListItem component.
 */
interface NotesListProps {
    noteItem: any;
    isLast?: boolean;
}

/**
 * NotesListItem Component
 *
 * This component is used to display the notes list item, showing the note type, the turn at which
 * the note was made, and the content of the note.
 */
export const NotesListItem: React.FC<NotesListProps> = ({
    noteItem,
    isLast,
}: NotesListProps) => {
    const { type, turns, content } = noteItem;

    // Get the icon for the note type
    const getIcon = (type: string) =>
        ICON_TYPES[type as keyof typeof ICON_TYPES] ?? {
            icon: FaRegStickyNote,
            color: "bg-yellow-500",
        };
    const Icon = getIcon(type);

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    return (
        <NotesItem>
            <ListItemContainer>
                {!isLast ? <VerticalLine aria-hidden="true" /> : null}
                <ListItemContentContainer>
                    <ListIconContainer>
                        <ListIcon color={Icon.color}>
                            <Icon.type aria-hidden="true" />
                        </ListIcon>
                    </ListIconContainer>
                    <ListContentRightSide>
                        <ListContent>
                            <ListContentTitle>
                                {
                                    translations.notesType[
                                        type as keyof typeof translations.notesType
                                    ]
                                }
                            </ListContentTitle>
                            <TurnTablet turn={formatGameTime(turnsToTime(turns))} />
                        </ListContent>
                        <ListContentDescription>
                            {content}
                        </ListContentDescription>
                    </ListContentRightSide>
                </ListItemContentContainer>
            </ListItemContainer>
        </NotesItem>
    );
};
