// External imports
import React from "react";
import { HiCheck } from "react-icons/hi";

// Internal imports
import {
    PricingCardItemCategoryList,
    PricingCardItemCategoryListContainer,
    PricingCardItemCategoryListItem,
    PricingCardItemContainer,
    PricingCardItemContentButton,
    PricingCardItemContentButtonContainer,
    PricingCardItemContentContainer,
    PricingCardItemContentDescription,
    PricingCardItemContentPrice,
    PricingCardItemContentPriceMonth,
    PricingCardItemContentTitle,
} from "./PricingCard.styles";

/**
 * Props for the PricingCard component
 * @property liItems - Array of features/items to display in the pricing card
 * @property price - Price value to display
 * @property title - Title of the pricing plan
 * @property details - Description or details of the plan
 * @property duration - Time period for the price (e.g., "month", "year")
 */
interface PricingCardProps {
    liItems: string[];
    price: string;
    title: string;
    details: string;
    duration: string;
}

/**
 * PricingCard component
 * Displays a pricing plan card with title, price, features, and action button
 *
 * @example
 * ```tsx
 * <PricingCard
 *   title="Basic Plan"
 *   price="19.99"
 *   details="Perfect for starters"
 *   duration="month"
 *   liItems={["Feature 1", "Feature 2"]}
 * />
 * ```
 */
const PricingCard: React.FC<PricingCardProps> = ({
                                                     liItems,
                                                     price,
                                                     title,
                                                     details,
                                                     duration,
                                                 }) => {
    return (
        <PricingCardItemContainer>
            <PricingCardItemContentContainer>
                <PricingCardItemContentTitle>
                    {title}
                </PricingCardItemContentTitle>
                <PricingCardItemContentDescription>
                    {details}
                </PricingCardItemContentDescription>
                <PricingCardItemContentPrice>
                    £{price}
                    <PricingCardItemContentPriceMonth>
                        /{duration}
                    </PricingCardItemContentPriceMonth>
                </PricingCardItemContentPrice>
                <PricingCardItemContentButtonContainer>
                    <PricingCardItemContentButton>
                        Buy plan
                    </PricingCardItemContentButton>
                </PricingCardItemContentButtonContainer>
            </PricingCardItemContentContainer>
            <PricingCardItemCategoryListContainer>
                <PricingCardItemCategoryList>
                    {liItems.map((item, index) => (
                        <PricingCardItemCategoryListItem
                            key={`feature-${index}-${item}`}
                        >
                            <HiCheck className="check-icon" />
                            {item}
                        </PricingCardItemCategoryListItem>
                    ))}
                </PricingCardItemCategoryList>
            </PricingCardItemCategoryListContainer>
        </PricingCardItemContainer>
    );
};

export default PricingCard;