import styled from "@emotion/styled";

export const CreateClassHeaderRow = styled.div`
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5em;
    h1 {
        font-size: 1.3rem;
    }
    margin-bottom: 2em;
    > :not(.spacer) {
        flex-grow: 0;
    }
    > .spacer {
        flex-grow: 1;
    }
`;

export const CreateClassPageContent = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: 46rem;
    gap: 1em;
    > :first-child {
        flex-grow: 1;
    }
    > * {
        height: 100%;
    }
    margin-top: 4.2em;

`;
export const LeftPanel = styled.div`
    flex: 0 0 75%; /* Do not grow, do not shrink, start at 25% of the container width */
    min-height: 660px;
    position: relative;
    background: #f3f4f6;
    border-radius: 0.5rem;
`;
export const RightPanel = styled.div`
    flex-shrink: 0;
    width: 22.5em;
`;
export const CreateClassPageWrap = styled.div`
    font-family: "Helvetica", Arial, sans-serif;
    &,
    * {
        box-sizing: border-box;
    }
`;
export const StyledHeader = styled.h1`
    margin: 0;
    padding: 0;
    font-size: 2em;
`;
export const StyledFlexGrow = styled.div`
    flex-grow: 1;
`;
