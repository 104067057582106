import { JoinClassTranslations } from "./types";

export const deTranslations: JoinClassTranslations = {
    errorOccurredAuthCode: "Ein Fehler ist aufgetreten: ",
    failedToJoinClass: "Fehler beim Beitritt zur Klasse",
    loginExpired: "Bitte melden Sie sich erneut an. Login abgelaufen.",
    nextPage: "weiter",
    pleaseEnterAuthCode: "Bitte geben Sie Ihren Spielcode ein",
    teacherWillProvideCode: "Der Lehrer wird dies bereitstellen",
    example: "z.B. MF128",
};
