import React, { SyntheticEvent, useState, useEffect } from "react";

// Internal Imports
import {
    AdditionalTestsFormGridContainer,
    AdditionalTestsFormContainer,
    AdditionalTestsFormGridItemBy6,
    AdditionalTestsFormLabel,
    AdditionalTestsFormFieldContainer,
    AdditionalTestsFormCheckboxContainer,
    GroupTitle,
} from "./OrderAdditionalTestsForm.styles";
import SelectBox, { SelectOption } from "../../../../../components/core/select-box/SelectBox";
import TextArea from "../../../../../components/core/input-form/TextArea";
import ClearSubmitFormButtons from "../../../../../components/core/clear-submit-form-buttons/ClearSubmitFormButtons";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";
import { AdditionalTestTranslations } from "../../translations/types";
import { enTranslations } from "../../translations/en";
import { deTranslations } from "../../translations/de";
import { useLanguage } from "../../../../../core/translations/LanguageContext";

// Professional dropdown options
const PROFESSIONAL_OPTIONS = [
    { value: "Physio", label: "Physio" },
    { value: "Nurse", label: "Nurse" },
];

// Test groups
const TEST_GROUPS = {
    detailedInspection: [
        { name: "ANTERIOR_VIEW", label: "Anterior View" },
        { name: "POSTERIOR_VIEW", label: "Posterior View" },
        { name: "SIDE_VIEW", label: "Side View" },
    ],
    mobility: [
        { name: "TRANSFERS", label: "Transfers" },
        { name: "AID_ASSESSMENT", label: "Aid Assessment" },
        { name: "STAIR_ASSESSMENT", label: "Stair Assessment" },
        { name: "GAIT_ANALYSIS", label: "Gait Analysis" },
    ],
    specialTests: [
        { name: "SLR", label: "SLR" },
        { name: "STEINMANN", label: "Steinmann 1" },
        { name: "FABERS_SIGN", label: "Faber's Sign" },
    ],
    adlRelatedAssessment: [
        { name: "TIP_TOEING", label: "Tip Toeing" },
        { name: "SQUAT", label: "Squat" },
        { name: "SINGLE_LEG_STANCE", label: "Single Leg Stance" },
    ],
    clinicalPrediction: [
        { name: "WELLS_CRITERIA", label: "Wells Criteria" },
        { name: "OTTAWA_ANKLE", label: "Ottawa Ankle" },
        { name: "OTTAWA_KNEE", label: "Ottawa Knee" },
        { name: "AMSTERDAM_WRIST", label: "Amsterdam Wrist" },
        { name: "CANADIAN_C_SPINE", label: "Canadian C-Spine" },
    ],
};

// OrderAdditionalTestsFormProps
interface OrderAdditionalTestsFormProps {
    onSubmit: (professional: string, assessments: any, reason: string, summary: string) => void;
}

export const OrderAdditionalTestsForm: React.FC<OrderAdditionalTestsFormProps> = ({ onSubmit }) => {
    const [professional, setProfessional] = useState<SelectOption>();
    const [reason, setReason] = useState<string>("");
    const [summary, setSummary] = useState<string>("");

    const [assessments, setAssessments] = useState<any>({
        detailedInspection: {},
        mobility: {},
        specialTests: {},
        adlRelatedAssessment: {},
        clinicalPrediction: {},
    });

    const TRANSLATIONS: Record<SupportedLanguage, AdditionalTestTranslations> = {
        en: enTranslations,
        de: deTranslations,
    };
    
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    // Handle checkbox changes
    const handleCheckboxChange = (group: string, name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setAssessments((prevState: any) => ({
            ...prevState,
            [group]: {
                ...prevState[group],
                [name]: e.target.checked,
            },
        }));
    };

    // Clear the form fields.
    const clearForm = () => {
        setProfessional(PROFESSIONAL_OPTIONS[1]);
        setReason("");
        setSummary("");
        setAssessments({
            detailedInspection: {},
            mobility: {},
            specialTests: {},
            adlRelatedAssessment: {},
            clinicalPrediction: {},
        });
    };

    const handleFormSubmit = async () => {
        if (professional && reason.trim().length >= 5 && summary.trim().length >= 5) {
            // Convert assessments to a flat array of selected assessment names
            const arrayAssessments = Object.keys(assessments).reduce((acc: string[], group: string) => {
                const selectedTests = Object.entries(assessments[group])
                    .filter(([_, isChecked]) => isChecked) // Filter checked assessments
                    .map(([name]) => name); // Extract names of checked assessments

                return acc.concat(selectedTests); // Add to accumulator
            }, []);

            const success = await onSubmit(professional.value, arrayAssessments, reason, summary);

        }
    };

    // Conditional rendering for professional
    const isNurse = professional?.value === "Nurse";

    return (
        <form onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
            <AdditionalTestsFormContainer>
                <AdditionalTestsFormGridContainer>
                    {/* Professional Selection */}
                    {/*<AdditionalTestsFormGridItemBy6>*/}
                    {/*    /!*<AdditionalTestsFormLabel htmlFor="professional">*!/*/}
                    {/*    /!*    {translations.professional}:*!/*/}
                    {/*    /!*</AdditionalTestsFormLabel>*!/*/}
                    {/*    <AdditionalTestsFormFieldContainer>*/}
                    {/*        <SelectBox*/}
                    {/*            value={[professional ? professional : translations.professionalOptions[0]]}*/}
                    {/*            options={translations.professionalOptions}*/}
                    {/*            onChange={(value: SelectOption) => setProfessional(value)}*/}
                    {/*        />*/}
                    {/*    </AdditionalTestsFormFieldContainer>*/}
                    {/*</AdditionalTestsFormGridItemBy6>*/}

                    {/* Assessments */}
                    {!isNurse && (
                        <>
                            {/* Detailed Inspection */}
                            <AdditionalTestsFormGridItemBy6>
                                <GroupTitle>{translations.deailedInspection}</GroupTitle>
                                <AdditionalTestsFormCheckboxContainer>
                                    {translations.detailedInspectionCheckbox.map((test) => (
                                        <label key={test.name}>
                                            <input
                                                type="checkbox"
                                                name={test.name}
                                                checked={assessments.detailedInspection[test.name] || false}
                                                onChange={handleCheckboxChange("detailedInspection", test.name)}
                                            />
                                            {test.label}
                                        </label>
                                    ))}
                                </AdditionalTestsFormCheckboxContainer>
                            </AdditionalTestsFormGridItemBy6>

                            {/* Special Tests */}
                            <AdditionalTestsFormGridItemBy6>
                                <GroupTitle>{translations.specialTests}</GroupTitle>
                                <AdditionalTestsFormCheckboxContainer>
                                    {translations.specialTestsCheckbox.map((test) => (
                                        <label key={test.name}>
                                            <input
                                                type="checkbox"
                                                name={test.name}
                                                checked={assessments.specialTests[test.name] || false}
                                                onChange={handleCheckboxChange("specialTests", test.name)}
                                            />
                                            {test.label}
                                        </label>
                                    ))}
                                </AdditionalTestsFormCheckboxContainer>
                            </AdditionalTestsFormGridItemBy6>

                            {/* Clinical Prediction */}
                            <AdditionalTestsFormGridItemBy6>
                                <GroupTitle>{translations.clinicalPrediction}</GroupTitle>
                                <AdditionalTestsFormCheckboxContainer>
                                    {translations.clinicalPredictionCheckbox.map((test) => (
                                        <label key={test.name}>
                                            <input
                                                type="checkbox"
                                                name={test.name}
                                                checked={assessments.clinicalPrediction[test.name] || false}
                                                onChange={handleCheckboxChange("clinicalPrediction", test.name)}
                                            />
                                            {test.label}
                                        </label>
                                    ))}
                                </AdditionalTestsFormCheckboxContainer>
                            </AdditionalTestsFormGridItemBy6>
                        </>
                    )}

                    {/* Mobility (Visible for both Nurse and Physio) */}
                    <AdditionalTestsFormGridItemBy6>
                        <GroupTitle>{translations.mobility}</GroupTitle>
                        <AdditionalTestsFormCheckboxContainer>
                            {translations.mobilityCheckbox.map((test) => (
                                <label key={test.name}>
                                    <input
                                        type="checkbox"
                                        name={test.name}
                                        checked={assessments.mobility[test.name] || false}
                                        onChange={handleCheckboxChange("mobility", test.name)}
                                    />
                                    {test.label}
                                </label>
                            ))}
                        </AdditionalTestsFormCheckboxContainer>
                    </AdditionalTestsFormGridItemBy6>

                    {/* ADL Related Assessment (Visible for both Nurse and Physio) */}
                    <AdditionalTestsFormGridItemBy6>
                        <GroupTitle>{translations.adlAssessment}</GroupTitle>
                        <AdditionalTestsFormCheckboxContainer>
                            {translations.adlRelatedAssessment.map((test) => (
                                <label key={test.name}>
                                    <input
                                        type="checkbox"
                                        name={test.name}
                                        checked={assessments.adlRelatedAssessment[test.name] || false}
                                        onChange={handleCheckboxChange("adlRelatedAssessment", test.name)}
                                    />
                                    {test.label}
                                </label>
                            ))}
                        </AdditionalTestsFormCheckboxContainer>
                    </AdditionalTestsFormGridItemBy6>

                    {/* Reason for Referral */}
                    <AdditionalTestsFormGridItemBy6>
                        <AdditionalTestsFormLabel htmlFor="reason">
                            {translations.reasonReferral}:
                        </AdditionalTestsFormLabel>
                        <AdditionalTestsFormFieldContainer>
                            <TextArea
                                rows={3}
                                height="auto"
                                color="#000"
                                borderColor="#d1d5db"
                                value={reason}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setReason(e.target.value)
                                }
                            />
                        </AdditionalTestsFormFieldContainer>
                    </AdditionalTestsFormGridItemBy6>

                    {/* Summary */}
                    <AdditionalTestsFormGridItemBy6>
                        <AdditionalTestsFormLabel htmlFor="summary">
                            {translations.summary}:
                        </AdditionalTestsFormLabel>
                        <AdditionalTestsFormFieldContainer>
                            <TextArea
                                rows={3}
                                height="auto"
                                color="#000"
                                borderColor="#d1d5db"
                                value={summary}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setSummary(e.target.value)
                                }
                            />
                        </AdditionalTestsFormFieldContainer>
                    </AdditionalTestsFormGridItemBy6>
                </AdditionalTestsFormGridContainer>
            </AdditionalTestsFormContainer>

            <ClearSubmitFormButtons
                submitText={translations.submitButton}
                isSubmitDisabled={
                    !professional || reason.trim().length < 5 || summary.trim().length < 5
                }
                onSubmit={handleFormSubmit}
                onClear={clearForm}
                RenderComponent={() => <></>}
            />
        </form>
    );
};

export default OrderAdditionalTestsForm;
