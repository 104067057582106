import { NotificationList, Notification } from "./interface";

// Function Signatures
export type GetAllNotificationsType = () => Notification[];
export type AddNotificationToStorageType = (
    turn: number,
    notification: Notification
) => void;
export type MarkNotificationReadType = (id: string) => void;
export type CheckNotificationsDueType = (
    currentTurn: number
) => Notification[] | null;

// Gets all current notifications
export function getAllNotifications(): Notification[] {
    const data = localStorage.getItem("notificationData");
    if (!data) return [];

    const notificationList: NotificationList = JSON.parse(data);
    return notificationList.notifications.flatMap(
        (turnData) => turnData.notifications
    );
}

// Gets the raw notification data including turn information
function getNotificationData(): NotificationList | null {
    const data = localStorage.getItem("notificationData");
    if (!data) return null;
    return JSON.parse(data);
}

export function addNotificationToStorage(
    turn: number,
    notification: Notification
): void {
    let notificationList = getNotificationData() || {
        notifications: [],
        turnLastChecked: turn,
    };

    const notificationAtTurn = notificationList.notifications.find(
        (n) => n.turn === turn
    );

    if (notificationAtTurn) {
        notificationAtTurn.notifications.push(notification);
    } else {
        notificationList.notifications.push({
            turn,
            notifications: [notification],
        });
    }

    localStorage.setItem("notificationData", JSON.stringify(notificationList));
}

export function markNotificationRead(id: string): void {
    const notificationList = getNotificationData();
    if (!notificationList) return;

    notificationList.notifications.forEach((turnData) => {
        turnData.notifications = turnData.notifications.filter(
            (n) => n.id !== id
        );
    });

    // Clean up empty turn entries
    notificationList.notifications = notificationList.notifications.filter(
        (turnData) => turnData.notifications.length > 0
    );

    localStorage.setItem("notificationData", JSON.stringify(notificationList));
}

export function checkNotificationsDue(
    currentTurn: number
): Notification[] | null {
    const notificationList = getNotificationData();
    if (!notificationList) return null;

    const notificationsDue: Notification[] = [];

    notificationList.notifications.forEach((notificationAtTurn) => {
        if (
            notificationAtTurn.turn > notificationList.turnLastChecked &&
            notificationAtTurn.turn <= currentTurn
        ) {
            notificationsDue.push(...notificationAtTurn.notifications);
        }
    });

    // Update turnLastChecked
    notificationList.turnLastChecked = currentTurn;
    localStorage.setItem("notificationData", JSON.stringify(notificationList));

    return notificationsDue.length > 0 ? notificationsDue : null;
}
