import { DiseaseChoiceState } from "./state";

export class DiseaseStateController {
    static instance: DiseaseChoiceState;
    static subscribers: (() => void)[] = [];

    public static getInstance(): DiseaseChoiceState {
        if (!DiseaseStateController.instance) {
            DiseaseStateController.instance = new DiseaseChoiceState();
        }
        return DiseaseStateController.instance;
    }
    // Reset the state to its initial state
    static resetState(): void {
        DiseaseStateController.instance = new DiseaseChoiceState(); // reset to initial state
        this.notifySubscribers(); // notify subscribers that the state has changed
    }
    static updateState(state: DiseaseChoiceState): void {
        this.instance = state;
        this.notifySubscribers();
    }

    private static notifySubscribers(): void {
        this.subscribers.forEach((callback) => callback());
    }

    static subscribe(callback: () => void): () => void {
        this.subscribers.push(callback);
        // Return an unsubscribe function
        return () => {
            this.subscribers = this.subscribers.filter(
                (sub) => sub !== callback
            );
        };
    }
}
