import { RegistrationPageTranslations } from "./types";

export const deTranslations: RegistrationPageTranslations = {
    heading: "Registrieren",
    firstNameError: "Bitte füllen Sie den Vornamen aus",
    lastNameError: "Bitte füllen Sie den Nachnamen aus",
    emailError: "Bitte füllen Sie die E-Mail aus",
    passwordError: "Bitte füllen Sie das Passwort aus",
    success: "Erfolg!",
    confirmEmail: "Bitte überprüfen Sie Ihre E-Mail, um zu bestätigen",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    password: "Passwort",
    language: "Sprache",
    languageTypes: [
        {
            label: "Deutsch",
            value: "german"
        },
        {
            label: "Englisch",
            value: "english"
        }
    ],
    register: "Registrieren"
};
