import styled from "@emotion/styled";

// Styled components for layout
export const PageContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

export const ProfileCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
    background: white;
    padding: 20px;
    border-radius: 0.8em;
`;

export const Heading = styled.h1`
    font-weight: 600;
    text-align: center;
    color: #163b5d;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 2px solid lightgrey;
`;

// Styled component for Paragraph (`p` tags)
export const Paragraph = styled.p`
    display:flex;
    align-items:center;
    font-size: 16px;
    margin: 5px 0;
    font-weight: bold;
`;

// Styled component for Span (`span` tags)
export const Span = styled.span`
    font-weight: 500;
    color: #333;  // You can customize the color as needed
    margin-left: 5px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-wrap:wrap;
    justify-content:center;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    padding-top: 20px;
    border-top: 2px solid lightgrey;
`;

export const Button = styled.a`
    font-size: 0.938rem;
    white-space: nowrap;
    font-family: Helvetica, Arial, sans-serif;
    padding: 0.6em 1.3em;
    border-radius: 0.8em;
    cursor: pointer;
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 0.5em;
    font-weight: 500;
    width: fit-content;
    border: none;
    background-color: rgb(22, 59, 93);
    color: white;
    text-decoration: none;
`

export const Logo = styled.img`
    max-width: 35px; 
    margin-right:5px;

`
export const ProfileTemplateGoBackButton = styled.a`
    font-size: 0.938rem;
    white-space: nowrap;
    font-family: Helvetica, Arial, sans-serif;
    padding: 0.6em 1.3em;
    border-radius: 0.8em;
    cursor: pointer;
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 0.5em;
    font-weight: 500;
    width: fit-content;
    border: none;
    background-color: rgb(22, 59, 93);
    color: white;
    text-decoration: none;
`;
