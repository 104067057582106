// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { consultationData } from "../../../LocalStorage/patients/interface";

/*
The payload for fetching all consultations for a patient.
 */
export interface GetAllConsultationsPayload {
    patient_id: number;
}

/*
The interface for the response from the get-all consultations endpoint.
@status: The HTTP status code.
@error: The error message.
@consultations_orders: The orders of consultations.
 */
export interface GetAllConsultationsResponse {
    status: number;
    error?: string;
    data?: Record<string, consultationData>;
}

export type GetAllConsultationsType = (jwt: string, getAllConsultationsPayload: GetAllConsultationsPayload) => Promise<GetAllConsultationsResponse>;

/*
The function to fetch all consultation orders for a patient.

@jwt: The JWT of the user requesting the consultations.
@getAllConsultationsPayload: The payload containing the patient ID.
@returns: The ApiResponse from the get-all consultations endpoint.
 */
export async function getAllConsultations(jwt: string, getAllConsultationsPayload: GetAllConsultationsPayload): Promise<GetAllConsultationsResponse> {
    const url = new PatientUrl().getConsultations;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<GetAllConsultationsResponse | string>(url, {
            patient_id: getAllConsultationsPayload.patient_id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });

    } catch (error) {
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 200) {
        return {
            status: response.status,
            data: response.data as Record<string, consultationData>
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
