import styled from "@emotion/styled";

export const StyledLabel = styled.label<{ isGroup?: boolean }>`
    display: flex;
    margin-left:5px;
    align-items: center;
    gap: 2px;
    user-select: none;
    cursor: pointer;
    font-size: ${(props) => (props.isGroup ? ".92em" : "0.875em")};
`;
export const StyledInput = styled.input<{ size: number }>`
    height: ${(props) => `${props.size}em`};
    width: ${(props) => `${props.size}em`};
    border-radius: 0.5em;
    outline: none;
    margin-right: 0.7em;
    color: #667eea;
    transition: all 0.15s ease-in-out;
`;
export const StyledSpan = styled.span<{ isGroup?: boolean }>`
    font-size: 1.1em;
    font-weight: ${(props) => (props.isGroup ? "600" : "400")};
`;
