// External Imports
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Internal Imports
import WaitingRoomImage from "../../../assets/waiting_room.jpg";
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import { MainPage } from "../../../components/core/mainPage/MainPage.styles";
import {
    StartGameFormComponent,
    StartGameState,
} from "./StartGameFormComponent/StartGameFormComponent";
import { getJwt } from "../../../core/LocalStorage/AuthSession/core";
import { getClassId } from "../../../core/LocalStorage/classSession/core";
import Spinner from "../../../components/core/spinner/Spinner";
import { LoaderBackground, LoaderText } from "./StartGamePage.styles";

// Dependency Injection Types
import { CreateSessionType } from "../../../core/ServerApiLayer/VOne/sessions/start";
import { SetFromApiDataType } from "../../../core/LocalStorage/gameSession/core";

// Translations Imports
import { useLanguage } from "../../../core/translations/LanguageContext";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { StartGameTranslations } from "./translations/types";
import { deTranslations } from "./translations/de";
import { enTranslations } from "./translations/en";

// Translations
const TRANSLATIONS: Record<SupportedLanguage, StartGameTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/*
The props for the StartGamePage component.

@prop start: a function that makes the create user request to the backend
 */
export interface StartGamePageProps {
    startSessionApiCall: CreateSessionType;
    setGameSessionData: SetFromApiDataType;
}

/**
 * New Game Page
 *
 * This is the page that the student sees after they click "New Game" on the front page
 * They will need to enter the class code to join the class
 */
export const StartGamePage: React.FC<StartGamePageProps> = ({
    startSessionApiCall,
    setGameSessionData,
}) => {
    const navigate = useNavigate(); // Initialize navigate function
    const [error, setError] = useState(""); // State to manage error messages
    const [failure, setFailure] = useState(false); // State to manage failure status
    const [apiHit, setApiHit] = useState(true);

    // Get the language from the context
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    // Handle the submission of the form
    const handleNewGame = async (data: StartGameState) => {
        const jwt = getJwt();
        const class_id = getClassId();

        const language = "ENGLISH";

        if (!jwt || !class_id) {
            setError(translations.loginExpired);
            setFailure(true);
            setApiHit(true);
            return;
        }

        // Construct the payload for API call
        const startGamePayload = {
            class_id: class_id,
            name: data.name,
            language: language,
        };

        try {
            setApiHit(false);
            const response = await startSessionApiCall(jwt, startGamePayload);
            setApiHit(true);

            if (!(response.status === 201 && response.data)) {
                setError(translations.errorOccurred + response.error);
                setFailure(true);
            } else {
                // Put the data in local storage under "sessionData" and
                // patients in local storage under their IDs. This is done as
                // a dependency injection
                setGameSessionData(response.data);

                // Create empty notification list
                localStorage.setItem("notifications", JSON.stringify([]));

                // Navigate to another page on success
                navigate("/dashboard", { state: { session: response.data } });
            }
        } catch (error) {
            setError(translations.failedToStartGame);
            setFailure(true);
            setApiHit(true);
        }
    };

    return (
        <MainPageLayout>
            <MainPage backgroundImage={WaitingRoomImage}>
                {apiHit ? (
                    <StartGameFormComponent
                        error={error}
                        failure={failure}
                        HandleNewGame={handleNewGame}
                        translations={translations}
                    />
                ) : (
                    <LoaderBackground>
                        <Spinner />
                        <LoaderText>{translations.loaderText}</LoaderText>
                    </LoaderBackground>
                )}
            </MainPage>
        </MainPageLayout>
    );
};
