// External imports
import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import Select from "react-select";
import { FaInfoCircle } from "react-icons/fa";

// Internal imports
import {
    admissionDestination,
    admittingTeam,
} from "../../config/diagnosisOptions";
// @ts-ignore
import { formEntryData } from "../../../../../core/LocalStorage/patients/interface";

//Language context
import { useLanguage } from "../../../../../core/translations/LanguageContext";

// Translation imports
import { enTranslations } from "../../translations/en";
import { deTranslations } from "../../translations/de";
import { DischargeFormTranslations } from "../../translations/types";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";
import { ButtonArea, ClearButton, Col6, FormContainer, FormField, InfoLabel, InputFieldArea, MainForm, SplitInputs, SubmitButton, Textarea } from "./FormComponent.styles";

// Constants
const TRANSLATIONS: Record<SupportedLanguage, DischargeFormTranslations> = {
    en: enTranslations,
    de: deTranslations,
};
/**
 * The props for the AdmissionForm component.
 * @onSubmit: A function to handle the form submission (passed in from the parent).
 */
export interface AdmissionComponentProps {
    onSubmit: (formData: formEntryData) => void;
    setFormsToLocalStorage: (
        patientId: number,
        formData: formEntryData,
        turn: number
    ) => void;
    getLatestFormFromLocalStorage: (patientId: number) => formEntryData | null;
    patientId: number;
    turn: number;
    initialFormData?: formEntryData;
}

/**
 * The AdmissionForm component.
 * @onSubmit: A function to handle the form submission (passed in from the parent).
 */
export const AdmissionForm: React.FC<AdmissionComponentProps> = ({
    onSubmit,
    setFormsToLocalStorage,
    getLatestFormFromLocalStorage,
    patientId,
    turn,
    initialFormData,
}) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    // Hook for the state of the form inputs and the state of the form submission
    const [formData, setFormData] = useState<formEntryData>(
        initialFormData || {
            form_type: "ADMISSION",
            discharge_destination: "",
            severity: "", // Will hold admitting team value for now
            patient_details: "",
            actions: "",
            situation: "",
        }
    );
    const [validationSuccess, setValidationSuccess] = useState<boolean>(false);

    // Load form data from localStorage when the component mounts
    useEffect(() => {
        const storedFormData = getLatestFormFromLocalStorage(patientId);
        if (storedFormData) {
            setFormData(storedFormData); // Set the form data from localStorage to state
        }
    }, [getLatestFormFromLocalStorage, patientId]);

    // Handle the change of form inputs (text areas)
    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);
        handleCharacterLimit(updatedFormData);

        // Persist form data in localStorage
        setFormsToLocalStorage(patientId, updatedFormData, turn);
    };

    // Handle the change of select fields
    const handleSelectFieldChange = (name: string, value: string) => {
        if (value && value !== "") {
            const updatedFormData = { ...formData, [name]: value };
            setFormData(updatedFormData);
            handleCharacterLimit(updatedFormData);

            // Persist form data in localStorage
            setFormsToLocalStorage(patientId, updatedFormData, turn);
        }
    };

    // Validation to enable or disable the submit button
    const handleCharacterLimit = (updatedFormData: formEntryData) => {
        const charLimit = 10;
        if (
            updatedFormData.patient_details.length >= charLimit &&
            updatedFormData.actions.length >= charLimit &&
            updatedFormData.situation.length >= charLimit &&
            updatedFormData.discharge_destination !== "" &&
            updatedFormData.severity !== ""
        ) {
            setValidationSuccess(true);
        } else {
            setValidationSuccess(false);
        }
    };

    // Handle the submission of the form
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData); // Pass the formData to the parent component

        // Clear form data from localStorage after successful submission
        setFormsToLocalStorage(
            patientId,
            {
                form_type: "ADMISSION",
                discharge_destination: "",
                severity: "", // Reset admitting team
                patient_details: "",
                actions: "",
                situation: "",
            },
            turn
        );

        // Clear form after submission
        handleReset(e);
    };

    // Handle the reset of the form
    const handleReset = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormData({
            form_type: "ADMISSION",
            discharge_destination: "",
            severity: "", // Reset admitting team
            patient_details: "",
            actions: "",
            situation: "",
        });
        setValidationSuccess(false);
    };

    return (
        <MainForm
            onReset={handleReset}
            onSubmit={handleSubmit}
        >
            <FormContainer>
                <InputFieldArea>
                    <SplitInputs>
                        <Col6>
                            <label>{translations.admission}</label>
                            <Select
                                isClearable
                                options={translations.admissionOptions}
                                value={translations.admissionOptions.find(
                                    (option) =>
                                        option.value ===
                                        formData.discharge_destination
                                )}
                                placeholder={translations.selectPlaceholder}
                                onChange={(e) =>
                                    handleSelectFieldChange(
                                        "discharge_destination",
                                        e?.value || ""
                                    )
                                }
                            />
                        </Col6>
                        <Col6>
                            <label>{translations.admitting}</label>
                            <Select
                                isClearable
                                options={translations.admittingOptions}
                                value={translations.admittingOptions.find(
                                    (option) =>
                                        option.value === formData.severity
                                )} // Severity holds admitting team
                                placeholder={translations.selectPlaceholder}
                                onChange={(e) =>
                                    handleSelectFieldChange(
                                        "severity",
                                        e?.value || ""
                                    )
                                } // Store in severity
                            />
                        </Col6>
                    </SplitInputs>
                </InputFieldArea>
                <InputFieldArea>
                    <label>
                        {translations.presentation}
                        <InfoLabel>
                            <FaInfoCircle size={12} />
                            {translations.presentationTip}
                        </InfoLabel>
                    </label>
                    <Textarea
                        rows={3}
                        name="patient_details"
                        value={formData.patient_details}
                        onChange={handleChange}
                    />
                </InputFieldArea>
                <InputFieldArea>
                    <label>{translations.action}</label>
                    <Textarea
                        rows={3}
                        name="actions"
                        value={formData.actions}
                        onChange={handleChange}
                    />
                </InputFieldArea>
                <InputFieldArea>
                    <label>{translations.follow}</label>
                    <Textarea
                        rows={3}
                        name="situation"
                        value={formData.situation}
                        onChange={handleChange}
                    />
                </InputFieldArea>
            </FormContainer>
            <ButtonArea>
                <ClearButton type="reset">
                    {translations.clearButton}
                </ClearButton>
                <SubmitButton
                    type="submit"
                    disabled={!validationSuccess}
                >
                    {translations.submitButton}
                </SubmitButton>
            </ButtonArea>
        </MainForm>
    );
};
