import * as d3 from "d3";
import React, { useLayoutEffect, useRef } from "react";
import { GraphWrapped } from "./PatientObservationsGraph.styles";

/**
 * Data structure representing a single line series.
 */
export interface PatientObsGraphData {
    /**
     * Unique identifier for the series.
     */
    id: string;
    /**
     * Color to be used for rendering the line.
     */
    color: string;
    /**
     * Array of data points, each with a turn number and value.
     */
    values: Array<{ turn: number; value: number }>;
}

export interface PatientObservationGraphProps {
    /**
     * Width of the chart area.
     */
    width: number;
    /**
     * Height of the chart area.
     */
    height: number;
    /**
     * Array of data series to be plotted.
     */
    data: PatientObsGraphData[];
    /**
     * Callback function triggered when a data point is hovered over.
     */
    onPointMouseEnter: (index: number) => void;
    /**
     * Index of the currently hovered data point.
     */
    hoveredIndex: number;
    /**
     * Callback function triggered when the mouse leaves a data point.
     */
    onPointMouseLeave: () => void;
}

/**
 * LineGraph component for rendering interactive line charts with multiple data series.
 * Supports features like tooltips, vertical lines for blood pressure values, and responsive resizing.
 * Uses D3.js for data-driven rendering and transformations.
 */
const PatientObservationsGraph: React.FC<PatientObservationGraphProps> = ({
    height,
    data,
    hoveredIndex,
    onPointMouseEnter,
    onPointMouseLeave,
}) => {
    // Ref to the SVG element
    const svgRef = useRef<SVGSVGElement>(null);

    // Padding around the chart area
    const padding = { left: 35, right: 35, top: 30, bottom: 35 };

    // Color for the blood pressure lines
    const bpLineColor = "#2e4290";

    // Get the min and max turns from the first data series
    // This assumes all series have the same number of data points
    const xExtent = d3.extent(data?.[0]?.values ?? [], (d) => d.turn) as [
        number,
        number
    ];

    /**
     * Draws the line graph based on the provided data and dimensions.
     * This function is the core of the component, responsible for setting up the scales,
     * creating the axes and grid lines, and rendering the lines and circles for each data series.
     */
    const drawGraph = () => {
        // Select the SVG element and clear any existing content
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove();

        // Add arrow marker definitions for the blood pressure lines
        const defs = svg.append("defs");
        defs.append("marker")
            .attr("id", "arrow2")
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 10)
            .attr("refY", 0)
            .attr("markerWidth", 6)
            .attr("markerHeight", 6)
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M0,-5L10,0L0,5");
        defs.append("marker")
            .attr("id", "arrow3")
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 0)
            .attr("refY", 0)
            .attr("markerWidth", 6)
            .attr("markerHeight", 6)
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M0,-5L10,0L0,5")
            .attr("transform", "rotate(180 5 0)");

        // Get the width of the SVG element
        const width =
            svg.node()?.getBoundingClientRect().width ?? window.innerWidth;

        // Set up scales for the x and y axes
        // x is based on the turn number, y goes from 0 to 200
        const xScale = d3
            .scaleLinear()
            .domain([xExtent[0], xExtent[1] + 3])
            .range([padding.left, width - padding.right]);
        const yScale = d3
            .scaleLinear()
            .domain([0, 200])
            .range([height - padding.bottom, padding.top]);

        // Function to generate the line path
        const line = d3
            .line<{ turn: number; value: number }>()
            .x((d) => xScale(d.turn))
            .y((d) => yScale(d.value))
            .curve(d3.curveMonotoneX);

        // Function to show a tooltip when hovering over a data point
        const showTooltip = (
            e: React.MouseEvent<SVGCircleElement>,
            d: PatientObsGraphData,
            value: number
        ) => {
            const tooltip = svg.append("g").attr("class", "tooltip");
            const [x, y] = d3.pointer(e);

            const text = tooltip
                .append("text")
                .attr("x", x + 20)
                .attr("y", y - 5)
                .attr("font-size", "14px")
                .attr("font-weight", "500")
                .text(`${d.id}: ${Math.round(value)}`);

            const textWidth = text.node()?.getBBox().width ?? 0;
            const textHeight = text.node()?.getBBox().height ?? 0;

            const xPadding = 10;
            const yPadding = 7;
            const boxBounds = [
                x + 20 - xPadding,
                y - 20 - yPadding,
                textWidth + xPadding * 2,
                textHeight + yPadding * 2,
            ];

            tooltip
                .append("rect")
                .lower()
                .attr("x", boxBounds[0])
                .attr("y", boxBounds[1])
                .attr("width", boxBounds[2])
                .attr("height", boxBounds[3])
                .attr("fill", "white")
                .attr("stroke", "#eee")
                .attr("stroke-radius", 5)
                .attr("rx", 5)
                .attr("ry", 5);
        };

        // Function to hide the tooltip
        const hideTooltip = () => {
            d3.selectAll(".tooltip").remove();
        };

        /**
         * Creates an axis for the chart.
         * @param {"x" | "y"} type - Type of axis ("x" or "y").
         * @param {"left" | "right" | "bottom"} position - Position of the axis ("left", "right", or "bottom").
         * @param {number} ticks - Number of ticks to display on the axis.
         * @param {any} scale - D3 scale function for the axis.
         * @returns {any} D3 axis generator.
         */
        const createAxis = (
            type: "x" | "y",
            position: "left" | "right" | "bottom",
            ticks: number,
            scale: any
        ) => {
            const axis =
                type === "x"
                    ? d3.axisBottom(scale)
                    : position === "left"
                    ? d3.axisLeft(scale)
                    : d3.axisRight(scale);
            axis.ticks(ticks);

            if (type === "x") {
                const totalDataPoints = data.length > 0 ? data[0].values.length : 0;
                const tickStep =
                    totalDataPoints > 20 ? Math.ceil(totalDataPoints / 10) : 1;

                // Only show ticks on integer values
                const tickValues = d3.range(
                    Math.max(Math.ceil(xScale.domain()[0]), 1),
                    Math.floor(xScale.domain()[1]),
                    tickStep
                );

                if (tickValues.length > 10) {
                    const amountToRemove = Math.min(
                        Math.max(Math.ceil(tickValues.length * 0.12), 2),
                        8
                    );
                    // Exclude some of the first ticks if there are too many
                    const filteredTickValues = tickValues.slice(amountToRemove);
                    axis.tickValues(filteredTickValues);
                } else {
                    axis.tickValues(tickValues);
                }
            }

            return axis;
        };

        /**
         * Creates a grid line for the chart.
         * @param {"x" | "y"} type - Type of grid line ("x" or "y").
         * @param {any} scale - D3 scale function for the grid line.
         * @returns {any} D3 axis generator for the grid line.
         */
        const createGrid = (type: "x" | "y", scale: any) => {
            const grid =
                type === "x" ? d3.axisBottom(scale) : d3.axisLeft(scale);
            grid.tickSize(
                type === "x"
                    ? -height + padding.top + padding.bottom
                    : -width + padding.left + padding.right
            ).tickFormat(() => "");
            if (type === "y") {
                // For y grid, just show a line in the middle
                grid.tickValues([
                    yScale.domain()[0] +
                        (yScale.domain()[1] - yScale.domain()[0]) / 2,
                ]);
                grid.tickSizeOuter(0);
            } else {
                // For x grid, show lines at integer values
                grid.tickValues(
                    d3.range(
                        Math.max(Math.ceil(xScale.domain()[0]), 1),
                        Math.floor(xScale.domain()[1])
                    )
                );
                grid.tickSizeOuter(0);
            }

            return grid;
        };

        // Function to append an axis to the SVG
        const appendAxis = (
            axis: any,
            className: string,
            transform: string
        ) => {
            svg.append("g")
                .attr("class", className)
                .attr("transform", transform)
                .call(axis);
        };

        // Create and append the x axis and grid
        const xAxis = createAxis("x", "bottom", data?.[0]?.values?.length ?? 0, xScale);
        const xAxisGrid = createGrid("x", xScale);

        appendAxis(xAxis, "x-axis", `translate(0, ${height - padding.bottom})`);
        appendAxis(
            xAxisGrid,
            "x-axis-grid",
            `translate(0, ${height - padding.bottom})`
        );

        // Create and append the y axis and grid
        appendAxis(
            createAxis("y", "left", 5, yScale),
            "y-axis",
            `translate(${padding.left}, 0)`
        );
        appendAxis(
            createGrid("y", yScale).ticks(2),
            "y-axis-grid",
            `translate(${padding.left}, 0)`
        );

        // Style the grid lines
        d3.selectAll(".x-axis-grid line, .y-axis-grid line")
            .attr("stroke", "#ddd")
            .attr("stroke-opacity", 0.7)
            .attr("shape-rendering", "crispEdges");

        // Add labels for the axes
        svg.append("text")
            .attr("class", "x-axis-label")
            .attr("x", padding.left + 28)
            .attr("y", height - 17)
            .style("text-anchor", "middle")
            .style("font-size", "0.75em")
            .text("Turns");

        svg.append("text")
            .attr("class", "y-axis-label")
            .attr("x", -height + padding.bottom + 35)
            .attr("y", 28)
            .attr("transform", "rotate(-90)")
            .style("text-anchor", "middle")
            .style("font-size", "0.75em")
            .text("");

        // Add a legend using a foreignObject
        svg.append("foreignObject")
            .attr("width", width - padding.right)
            .attr("height", padding.top)
            .attr("y", 0)
            .html(() => {
                return `<div style="width:100%; display:flex; align-items:center; justify-content:flex-end; gap:1rem; font-size:0.75rem; height:${
                    padding.top
                }px;">${data
                    .map(
                        (d) =>
                            `<div style="display:flex; align-items:center; gap:0.5rem;"><div style="width:0.5rem; height:0.25rem; border-radius:0.125rem; background-color:${
                                d.color
                            };"></div><span>${d.id.replace(
                                / \([a-zA-Z]+\)/g,
                                ""
                            )}</span></div>`
                    )
                    .filter((_, index) => index !== 1)
                    .join("")}</div>`;
            });

        // Add a group to contain the main chart elements
        const chart = svg.append("g").attr("class", "chart");

        // Draw lines and circles for data
        data.forEach((d, i) => {
            const isPressure = i !== 2;
            if (!isPressure) {
                chart
                    .append("path")
                    .datum(d.values)
                    .attr("d", line as any)
                    .attr("stroke", d.color)
                    .attr("stroke-width", 2)
                    .attr("fill", "none");
            }

            d.values.forEach((point, index) => {
                chart
                    .append("circle")
                    .attr("cx", xScale(point.turn)!)
                    .attr("cy", yScale(point.value))
                    .attr("r", 4)
                    .attr("fill", i === 2 ? "red" : "transparent")
                    .attr("stroke", "transparent")
                    .on("mouseover", (e) => {
                        showTooltip(e, d, point.value);
                        onPointMouseEnter(index);
                    })
                    .on("mouseout", () => {
                        hideTooltip();
                        onPointMouseLeave();
                    });
            });
        });
        const loopLimit = data.length > 0 ? data[0].values.length : 0;

        // Draw vertical lines between systolic and diastolic values
        for (let i = 0; i < loopLimit; i++) {
            const systolicPoint = data[0].values[i];
            const diastolicPoint = data[1].values[i];

            svg.append("line")
                .attr("x1", xScale(systolicPoint.turn))
                .attr("y1", yScale(systolicPoint.value))
                .attr("x2", xScale(diastolicPoint.turn))
                .attr("y2", yScale(diastolicPoint.value))
                .attr("stroke-width", 2)
                .attr("stroke", bpLineColor)
                .attr("marker-start", "url(#arrow2)")
                .attr("marker-end", "url(#arrow3)");
        }
    };

    // Redraw the graph whenever the data or height changes
    useLayoutEffect(() => {
        drawGraph();
        const drawGraphTimeout = () => setTimeout(drawGraph, 1000);
        window.addEventListener("resize", drawGraphTimeout);
        return () => {
            window.removeEventListener("resize", drawGraphTimeout);
        };
    }, [data, height]);

    return (
        <GraphWrapped>
            <style>{`
                #arrow2, #arrow3 {
                    stroke: ${bpLineColor};
                    fill: transparent;
                }
            `}</style>
            <svg ref={svgRef} height={height} style={{ width: "100%" }} />
        </GraphWrapped>
    );
};

export default PatientObservationsGraph;
