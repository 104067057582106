// External imports
import React, { useState } from "react";

// Internal imports
import {
    ButtonContainer,
    PatientButtonsContainer,
    DescribeDefinition,
    DescribeList,
    DescribeListContainer,
    DescribeTerm,
    InformationTitle,
} from "./PatientInfoMain.styles";
import WebsiteButton from "../../../core/button/WebsiteButton";
import { DiagnosisModal } from "../../../core/modal/diagnosis-modal/DiagnosisModal";
import MovePatientModal from "../../../core/modal/move-patient-modal/MovePatientModal";
import {
    GameBarLayoutTranslations,
    DemographicsDependencies,
} from "../../../../core/sharedDependencies/types";

/**
 * PatientInfoMainProps
 *
 * This is the props interface for the PatientInfoMain component.
 *
 * @param id - The id of the patient.
 * @param patientInfo - The information of the patient.
 * @param isDischargeFormOpen - Whether the discharge form is open.
 */
interface PatientInfoMainProps {
    patient?: any;
    isDischargeFormOpen?: boolean;
    translations: GameBarLayoutTranslations;
    demographicsDependencies: DemographicsDependencies;
    refreshPage: () => void;
}

export const PatientInfoMain: React.FC<PatientInfoMainProps> = ({
    patient,
    isDischargeFormOpen = false,
    translations,
    demographicsDependencies,
    refreshPage,
}: PatientInfoMainProps) => {
    const [diagnosisModalOpen, setDiagnosisModalOpen] =
        useState<boolean>(false);
    const [movePatientModalOpen, setMovePatientModalOpen] =
        useState<boolean>(false);

    return (
        <div>
            <div>
                <InformationTitle>Information</InformationTitle>
                <DescribeList>
                    {/*{Object.keys(information).map((key) => (*/}
                    <DescribeListContainer key="symptom">
                        <DescribeTerm>
                            {translations.gameBar.patientSidebar.symptom}
                        </DescribeTerm>
                        <DescribeDefinition>
                            {translations.symptoms[patient.symptom]}
                        </DescribeDefinition>
                    </DescribeListContainer>
                    <DescribeListContainer key="age">
                        <DescribeTerm>
                            {translations.gameBar.patientSidebar.age}
                        </DescribeTerm>
                        <DescribeDefinition>{patient.age}</DescribeDefinition>
                    </DescribeListContainer>
                    <DescribeListContainer key="sex">
                        <DescribeTerm>
                            {translations.gameBar.patientSidebar.sex}
                        </DescribeTerm>
                        <DescribeDefinition>{patient.sex}</DescribeDefinition>
                    </DescribeListContainer>
                    <DescribeListContainer key="sex">
                        <DescribeTerm>
                            {translations.gameBar.patientSidebar.diagnosis}
                        </DescribeTerm>
                        <DescribeDefinition>
                            {translations.diagnosis[patient.diagnosis]}
                        </DescribeDefinition>
                    </DescribeListContainer>
                    {/*))}*/}
                </DescribeList>
                <ButtonContainer>
                    <PatientButtonsContainer>
                        {isDischargeFormOpen ? (
                            <WebsiteButton tag="button" onClick={() => {}}>
                                {
                                    translations.gameBar.patientSidebar
                                        .dischargePatient
                                }
                            </WebsiteButton>
                        ) : (
                            <WebsiteButton
                                tag="button"
                                onClick={() => setMovePatientModalOpen(true)}
                            >
                                {
                                    translations.gameBar.patientSidebar
                                        .movePatient
                                }
                            </WebsiteButton>
                        )}
                        <WebsiteButton
                            tag="button"
                            onClick={() => setDiagnosisModalOpen(true)}
                        >
                            {translations.gameBar.patientSidebar.addDiagnosis}
                        </WebsiteButton>
                        {diagnosisModalOpen && (
                            <DiagnosisModal
                                patientId={patient.patientId}
                                onClose={() => setDiagnosisModalOpen(false)}
                                translations={translations}
                                demographicsDependencies={
                                    demographicsDependencies
                                }
                                refreshPage={refreshPage}
                            />
                        )}
                        {movePatientModalOpen && (
                            <MovePatientModal
                                patient={patient}
                                onClose={() => setMovePatientModalOpen(false)}
                                translations={translations}
                                demographicsDependencies={
                                    demographicsDependencies
                                }
                                refreshPage={refreshPage}
                            />
                        )}
                    </PatientButtonsContainer>
                </ButtonContainer>
            </div>
        </div>
    );
};
