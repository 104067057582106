import { HandoverModalTranslations } from "./types";

export const deHandoverTranslations: HandoverModalTranslations = {
    title: "Übergabevermerke",
    waitingText: "befindet sich derzeit im Wartebereich.",
    pickDestination: "Ziel auswählen",
    selectPlaceholder: "Auswählen...",
    moveText: "Verlegen",
    noHandoverAvailable: "Keine Übergabe verfügbar",
};
