// External imports
import React from "react";
import {
    BsFillCheckCircleFill,
    BsFillQuestionCircleFill,
} from "react-icons/bs";

// Internal imports
import PatientExamCard from "../ExamCard/ExamCard";
import { PatientExamination } from "../../types/PatientExamTypes";
import {
    ExamSidebarSectionHeading,
    ExamSidebarSectionStyle,
} from "./ExamSidebarSection.styles";

/**
 * PatientExamSidebarSectionProps
 *
 * This interface is used to define the props of the PatientExamSidebarSection component
 */
export type PatientExamSidebarSectionProps = {
    cards: PatientExamination[];
    examined: boolean;
    gender?: "Male" | "Female";
    setSelectedCard?: (examination: PatientExamination) => void;
    translations?: any;
};

/**
 * ExamSidebarSection
 *
 * A component that displays a section of the sidebar for the patient examination page. This
 * section will display all the examinations that have been examined or not examined.
 *
 */
export const ExamSidebarSection: React.FC<PatientExamSidebarSectionProps> = ({
    cards,
    examined,
    gender,
    setSelectedCard,
    translations,
}: PatientExamSidebarSectionProps) => {
    // Define the icon to use based on if the examination has been examined
    const Icon = examined ? BsFillCheckCircleFill : BsFillQuestionCircleFill;
    if (!cards || !cards.length) {
        return null;
    }
    return (
        <ExamSidebarSectionStyle>
            <ExamSidebarSectionHeading>
                <Icon color={examined ? "#3D81E1" : "#aaa"} size="1em" />
                {examined
                    ? translations.sideBar.examined
                    : translations.sideBar.notExamined}
            </ExamSidebarSectionHeading>
            {cards.map((card) => (
                <PatientExamCard
                    examination={card}
                    gender={gender}
                    isSidebar
                    key={card.id}
                    setSelectedCard={setSelectedCard}
                    translations={translations}
                />
            ))}
        </ExamSidebarSectionStyle>
    );
};
