import styled from "@emotion/styled";

export const ListContainer = styled.div`
    margin-top: 1rem;

    td {
        text-align: left;
    }
`;

export const ListTitle = styled.h5`
    font-weight: 700;
`;

export const ListTable = styled.table`
    margin-top: 1rem;
    border-radius: 0.375rem;
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    border: 1px solid #e5e7eb;
`;

export const ListTableHead = styled.thead``;

export const ListTableBody = styled.tbody``;

export const ListTableRow = styled.tr<{
    addBackground?: boolean;
    addLightBackground?: boolean;
    addError?: boolean;
}>`
    ${({
        addBackground = false,
        addLightBackground = false,
    }: {
        addBackground?: boolean;
        addLightBackground?: boolean;
    }) =>
        addBackground
            ? "background-color: #e5e7eb;"
            : addLightBackground
            ? "background-color: #F9FAFB;"
            : ""}
    ${({ addError = false }: { addError?: boolean }) =>
        addError && "color: #B91C1C; background-color: #FECACA;"}
`;

export const ListTableData = styled.td<{
    fontBold?: boolean;
    addIconColor?: boolean;
    value?: string | unknown;
}>`
    padding: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border: 1px solid #e5e7eb;
    text-transform: capitalize;

    ${({ fontBold }: { fontBold?: boolean }) =>
        fontBold ? "font-weight: 600;" : ""}

    svg {
        color: ${({
            addIconColor = false,
            value,
        }: {
            addIconColor?: boolean;
            value?: string | unknown;
        }) => (addIconColor ? (value ? "#10B981" : "#EF4444") : "")};
    }
`;
