// External Imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Internal Imports
import { DischargePatientDetails } from "./dischargeBoardTable/DischargePatientTable";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import { getSessionID } from "../../../core/LocalStorage/gameSession/core";
import { getTurnCount } from "../../../core/LocalStorage/turnLogic/core";
import { getJwt } from "../../../core/LocalStorage/AuthSession/core";
import { Patient } from "../../../core/LocalStorage/patients/interface";

// Dependency injections
import {
    setFromPatientGetAllData,
    GetPatientDataByStatusType,
} from "../../../core/LocalStorage/patients/core";
import { GetAllPatientsType } from "../../../core/ServerApiLayer/VOne/patients/getAll";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Translation imports
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { DischargePageTranslations } from "./translations/types";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { IPatientLocation } from "../../../components/core/location-icon/types/IPatientLocation";

// Constants
const PAGE_TRANSLATIONS: Record<SupportedLanguage, DischargePageTranslations> =
    {
        en: enTranslations,
        de: deTranslations,
    };

// Interface for the discharge patient details
export interface DischargedPatient {
    id: string;
    location: IPatientLocation;
    name: string;
    demographics: string;
    symptom: string;
    diagnosis: string;
    real_diagnosis?: string;
    score?: number;
}

/**
 * Interface for the DischargeBoardPage component
 */
interface DischargeBoardPageProps extends WithPatientProps {
    getAllPatientApiCall: GetAllPatientsType;
    getPatientDataStorage: GetPatientDataByStatusType;
}

// Combined Props
type CombinedProps = DischargeBoardPageProps & WithGameDependenciesProps;

/**
 * DischargeBoardPage Component
 *
 * This is the DischargeBoardPage component. This is the main page for the emergency
 * room department. This page displays the current patients in the department,
 * including patients in the waiting room, and patients who are currently being seen.
 * It shows if they have a diagnosis, and if they are ready to be discharged.
 *
 * @returns A DischargeBoardPage component
 */
const DischargeBoardBase: React.FC<CombinedProps> = ({
    getAllPatientApiCall,
    getPatientDataStorage,
    // Injected game dependencies
    demographicsDependencies,
    getJwt,
    useLanguage,
    translations,
    refreshPage,
    turnDependencies,
}) => {
    const navigate = useNavigate();
    const turnCount = getTurnCount();

    const [patients, setPatients] = useState<DischargedPatient[]>([]);

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Translations
    const { language } = useLanguage();
    const pageTranslations = PAGE_TRANSLATIONS[language];

    // fn to tranform the Patient[] to DischargePatient
    const transformPatientToDischarged = (
        patients: Patient[]
    ): DischargedPatient[] => {
        return patients.map((patient) => ({
            id: patient.admin.id.toString(),
            location: patient.admin.location as IPatientLocation,
            name: patient.demographic.full_name || "Unknown", // Fallback if full_name is missing
            demographics: `${patient.demographic.age} ${patient.demographic.gender}`, // Properly concatenate age and gender
            symptom:
                patient.symptoms.presenting_complaint || "No symptoms listed", // Fallback for presenting_complaint
            diagnosis: patient.diagnosis || "Undiagnosed", // Fallback for diagnosis
            real_diagnosis: "TO ADD", // Placeholder value
            score: 80, // Default score
        }));
    };

    useEffect(() => {
        const storedPatients = getPatientDataStorage("discharged");
        if (storedPatients.length > 0) {
            setPatients(transformPatientToDischarged(storedPatients));
        } else {
            const session_id = getSessionID();
            const jwt = getJwt();

            if (!jwt) {
                navigate("/login", {
                    state: { error: "Login has expired. Please login again." },
                });
                return;
            }

            getAllPatientApiCall(jwt, {
                session_id: session_id,
                filter: "all",
            }).then((response) => {
                if (response.status === 200 && response.data?.patients) {
                    setFromPatientGetAllData(response.data.patients);
                    const storedPatients = getPatientDataStorage("discharged");
                    setPatients(transformPatientToDischarged(storedPatients));
                }
            });
        }
    }, [getPatientDataStorage, getAllPatientApiCall, navigate]);

    return (
        <GamePageLayout
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            pageTitle={pageTranslations.dischargedPatients}
            translations={translations}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
            turnDependencies={turnDependencies}
        >
            {/* @ts-ignore */}
            <DischargePatientDetails
                data={patients}
                translations={pageTranslations}
                demographicsDependencies={demographicsDependencies}
            />
        </GamePageLayout>
    );
};

export const DischargeBoardPage =
    withGameDependencies<DischargeBoardPageProps>(DischargeBoardBase);
