import { FormConfigTranslations } from './types';

export const deTranslations: FormConfigTranslations = {
    ecgImages: {
        label: "EKG-Bilder aktiviert",
        description: "Aktivieren Sie EKG-Bilder für Patienten in der Klasse. EKG-Bilder sind als Untersuchungen verfügbar und liefern zusätzliche diagnostische Informationen für jeden Patientenfall."
    },
    clinicalReasoning: {
        label: "Prompts zum klinischen Denken",
        description: "Ein simulierter Oberarzt wird die Studierenden nach Differentialdiagnosen fragen und so kritisches Denken und klinische Entscheidungsfindung fördern."
    }
};