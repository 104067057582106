import React from "react"; 
import { useLocation } from "react-router-dom";

export type INavigationItem<ExtraFields = {}> = {
    name: string;
    href?: string;
    Icon: React.ElementType; 
    subItems?: INavigationItem[];
    hoverItems?: INavigationItem<ExtraFields>[];
} & ExtraFields;

export default function useNavigation(nav: INavigationItem[]) {
    const location = useLocation();

    return {
        items: nav.map((item) => {
            const hoverItems = item.hoverItems ?? [];
            return {
                ...item,
                current:
                    item.href === location.pathname ||
                    hoverItems.some((item) => item.href === location.pathname),
                hoverItems: hoverItems.map((hoverItem) => ({
                    ...hoverItem,
                    current: hoverItem.href === location.pathname,
                })),
            };
        }) as INavigationItem[],
    };
}