import React from "react";

export type ImageProps = {
    src: string;
    alt: string;
    objectFit: "cover" | "contain" | "fill" | "none" | "scale-down";
    height: number;
    width: number;
    style?: React.CSSProperties;
}

const Image: React.FC<ImageProps> = ({
    src,
    alt,
    objectFit,
    height,
    width,
    style,
}) => {
    return (
        <img
            src={src}
            alt={alt}
            style={{
                ...style,
                objectFit: objectFit,
                height: height,
                width: width,
            }}
        />
    );
};

export default Image;
