// External imports
import React, { useState, useEffect } from "react";
import {
    FaSpinner,
    FaWalking,
    FaClone,
    FaCog,
    FaHeartbeat,
} from "react-icons/fa"; // Importing some icons

// Internal imports
//@ts-ignore
import { H2, Icon, LoadingContainer, ProgressBar, ProgressText, pulse, scale, spin } from "./LoadingScreen.styles";

// Interface for the LoadingScreen component
interface LoadingScreenProps {
    translations: any;
}

/**
 * LoadingScreen component is a placeholder component that displays a loading screen with a progress bar.
 */
const LoadingScreen: React.FC<LoadingScreenProps> = ({ translations }) => {
    // State to store the progress value
    const [progress, setProgress] = useState<number>(0);

    // Function to simulate loading process
    useEffect(() => {
        if (progress < 98) {
            const interval = setInterval(() => {
                setProgress((prev) => prev + 1); // Increase progress by 1%
            }, 900); // Update progress every 900 milliseconds

            return () => clearInterval(interval); // Cleanup on component unmount
        }
    }, [progress]);

    // Function to choose the icon and animation based on progress value
      const getIcon = (progress: number) => {
        if (progress < 20)
            return <Icon animation={spin}><FaSpinner/></Icon>;
        if (progress < 40)
            return <Icon animation={pulse}><FaCog/></Icon>;
        if (progress < 60)
            return <Icon animation={scale}><FaHeartbeat/></Icon>;
        if (progress < 95)
            return <Icon animation={pulse}><FaClone/></Icon>;
        return <Icon animation={scale}><FaWalking/></Icon>;
    };

    // Function to choose the text based on progress value
    const getText = (progress: number) => {
        if (progress < 20) return translations.loader.firstMessage;
        if (progress < 40) return translations.loader.secondMessage;
        if (progress < 60) return translations.loader.thirdMessage;
        if (progress < 95) return translations.loader.fourthMessage;
        return translations.loader.completing;
    };

    return (
        <LoadingContainer>
            <H2>{getText(progress)}</H2>
            {getIcon(progress)} {/* Display the dynamic icon with animation */}
            <ProgressBar
                value={progress}
                max={100}
            ></ProgressBar>
            <ProgressText>
                {progress}% {translations.loader.completed}
            </ProgressText>
        </LoadingContainer>
    );
};

export default LoadingScreen;
