/**
 * Custom hook for retrieving resources based on search terms.
 *
 * @param {Object} options - The options for the hook.
 * @param {string} options.search - The search term to filter resources.
 * @returns {Object} - The filtered resources.
 */
export default function useResources({ search }: { search?: string }) {
    // Mock data
    const a = [
        {
            guideline_key: "HEART_FAILURE",
            searchTerms: "heart failure",
            alternativeSearchTerms:
                "congestive cardiac failure, left sided heart failure",
            resources: [
                {
                    name: "Patient.Info",
                    disease: "Heart Failure",
                    url: "https://patient.info/doctor/heart-failure-diagnosis-and-investigation",
                    year: 2018,
                    tags: ["patient"],
                },
                {
                    name: "NICE Guidelines",
                    disease: "Chronic Heart Failure",
                    url: "https://www.nice.org.uk/guidance/ng106",
                    year: 2018,
                    tags: ["nice"],
                },
            ],
        },
        {
            guideline_key: "ISCHAEMIC_STROKE",
            searchTerms: "stroke",
            alternativeSearchTerms: "ischaemic stroke",
            resources: [
                {
                    name: "NICE Guidelines on Initial Management",
                    disease: "Ischaemic Stroke",
                    url: "https://www.nice.org.uk/guidance/ng128/chapter/Recommendations#initial-management-of-suspected-and-confirmed-tia",
                    year: 2019,
                    tags: ["nice"],
                },
            ],
        },
    ];

    // Filter resources based on search term.
    const searchingTerms = a.map((object) => object.alternativeSearchTerms);
    const terms = search
        ? searchingTerms.filter((term) => term.includes(search))
        : [];
    const resources = a.filter(
        (object) => !search || terms.includes(object.alternativeSearchTerms)
    );
    return {
        resources,
    };
}
