export const DIAGNOSIS_TYPES = [
    "CONGESTIVE_HEART_FAILURE",
    "ACUTE_RENAL_FAILURE",
    "ACUTE_PSYCHOSIS",
    "ACUTE_URINARY_RETENTION",
    "COMMUNITY_ACQUIRED_PNEUMONIA",
    "ANAPHYLAXIS",
    "AORTIC_DISSECTION",
    "AORTIC_STENOSIS",
    "ACUTE_APPENDICITIS",
    "NON_INFECTIVE_EXACERBATION_OF_ASTHMA",
    "AVNRT",
    "ABDOMINAL_AORTIC_ANEURYSM",
    "BENIGN_PAROXYSMAL_POSITIONAL_VERTIGO",
    "ACUTE_CHOLECYSTITIS",
    "INFECTIVE_EXACERBATION_OF_COPD",
    "NON_INFECTIVE_EXACERBATION_OF_COPD",
    "COVID_INFECTION",
    "DEHYDRATION",
    "DEPRESSION",
    "DIABETIC_COMA",
    "LEFT_SIDED_ENDOCARDITIS",
    "ECTOPIC_PREGNANCY",
    "FEBRILE_SEIZURE",
    "ACIDIC_REFLUX",
    "TESTICULAR_TORSION",
    "HYPERGLYCAEMIA",
    "HYPERTENSIVE_CRISIS",
    "HYPOGLYCAEMIA",
    "INFECTIVE_GASTROENTERITIS",
    "ISCHAEMIC_STROKE",
    "SEIZURE",
    "PULMONARY_EMBOLISM",
    "MENINGITIS",
    "MIGRAINE",
    "MUSCULOSKELETAL_CHEST_PAIN",
    "KIDNEY_STONES",
    "NSTEMI",
    "UPPER_GI_BLEED",
    "UROSEPSIS",
    "UPPER_RESPIRATORY_TRACT_INFECTION",
    "ORTHOSTATIC_SYNCOPE",
    "PANIC_ATTACK",
    "CHEST_SEPSIS",
    "PNEUMOTHORAX",
    "TRAUMATIC_BRAIN_INJURY",
    "SHOULDER_DISLOCATION",
    "SIGMOID_DIVERTICULITIS",
    "SINUS_TACHYCARDIA",
    "SINUS_VEIN_THROMBOSIS",
    "STEMI_ANTERIOR",
    "STEMI_POSTERIOR",
    "SUBARACHNOID_HEMORRHAGE",
    "ATRIAL_FIBRILLATION",
];

export const DIAGNOSIS_TYPES_NAME = {
    CONGESTIVE_HEART_FAILURE: "Congestive Heart Failure",
    ACUTE_RENAL_FAILURE: "Acute Renal Failure",
    ACUTE_PSYCHOSIS: "Acute Psychosis",
    ACUTE_URINARY_RETENTION: "Acute Urinary Retention",
    COMMUNITY_ACQUIRED_PNEUMONIA: "Community Acquired Pneumonia",
    ANAPHYLAXIS: "Anaphylaxis",
    AORTIC_DISSECTION: "Aortic Dissection",
    AORTIC_STENOSIS: "Aortic Stenosis",
    ACUTE_APPENDICITIS: "Acute Appendicitis",
    NON_INFECTIVE_EXACERBATION_OF_ASTHMA:
        "Non Infective Exacerbation of Asthma",
    AVNRT: "AVNRT",
    ABDOMINAL_AORTIC_ANEURYSM: "Abdominal Aortic Aneurysm",
    BENIGN_PAROXYSMAL_POSITIONAL_VERTIGO:
        "Benign Paroxysmal Positional Vertigo",
    ACUTE_CHOLECYSTITIS: "Acute Cholecystitis",
    INFECTIVE_EXACERBATION_OF_COPD: "Infective Exacerbation of COPD",
    NON_INFECTIVE_EXACERBATION_OF_COPD: "Non Infective Exacerbation of COPD",
    COVID_INFECTION: "COVID Infection",
    DEHYDRATION: "Dehydration",
    DEPRESSION: "Depression",
    DIABETIC_COMA: "Diabetic Coma",
    LEFT_SIDED_ENDOCARDITIS: "Left Sided Endocarditis",
    ECTOPIC_PREGNANCY: "Ectopic Pregnancy",
    FEBRILE_SEIZURE: "Febrile Seizure",
    ACIDIC_REFLUX: "Acidic Reflux",
    TESTICULAR_TORSION: "Testicular Torsion",
    HYPERGLYCAEMIA: "Hyperglycaemia",
    HYPERTENSIVE_CRISIS: "Hypertensive Crisis",
    HYPOGLYCAEMIA: "Hypoglycaemia",
    INFECTIVE_GASTROENTERITIS: "Infective Gastroenteritis",
    ISCHAEMIC_STROKE: "Ischaemic Stroke",
    SEIZURE: "Seizure",
    PULMONARY_EMBOLISM: "Pulmonary Embolism",
    MENINGITIS: "Meningitis",
    MIGRAINE: "Migraine",
    MUSCULOSKELETAL_CHEST_PAIN: "Musculoskeletal Chest Pain",
    KIDNEY_STONES: "Kidney Stones",
    NSTEMI: "NSTEMI",
    UPPER_GI_BLEED: "Upper GI Bleed",
    UROSEPSIS: "Urosepsis",
    UPPER_RESPIRATORY_TRACT_INFECTION: "Upper Respiratory Tract Infection",
    ORTHOSTATIC_SYNCOPE: "Orthostatic Syncope",
    PANIC_ATTACK: "Panic Attack",
    CHEST_SEPSIS: "Chest Sepsis",
    PNEUMOTHORAX: "Pneumothorax",
    TRAUMATIC_BRAIN_INJURY: "Traumatic Brain Injury",
    SHOULDER_DISLOCATION: "Shoulder Dislocation",
    SIGMOID_DIVERTICULITIS: "Sigmoid Diverticulitis",
    SINUS_TACHYCARDIA: "Sinus Tachycardia",
    SINUS_VEIN_THROMBOSIS: "Sinus Vein Thrombosis",
    STEMI_ANTERIOR: "STEMI Anterior",
    STEMI_POSTERIOR: "STEMI Posterior",
    SUBARACHNOID_HEMORRHAGE: "Subarachnoid Hemorrhage",
    ATRIAL_FIBRILLATION: "Atrial Fibrillation",
};
