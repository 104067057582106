import { DashboardTableTranslations } from "./types";

export const deTranslations: DashboardTableTranslations = {
    dashboard: "Dashboard",
    table: {
        review: "Ansehen",
        discharge: "Verlegen/Entlassen",
        dischargeTooltip:
            "Sie können einen Patienten nicht entlassen, ohne eine Diagnose oder ein Entlassungsformular auszufüllen",
        columns: {
            location: "Raum",
            arrivalTime: "Ankunftszeit",
            name: "Name",
            ageGender: "Alter/Geschlecht",
            symptom: "Symptom",
            diagnosis: "Diagnose",
            dischargeForms: "Entlassungsbrief",
        },
    },
};
