import { ConsultationPageTranslations } from "./types";

export const deTranslations: ConsultationPageTranslations = {
    specialtyLabel: "Fachrichtung",
    specialtyOptions: [
        { value: "cardiothoracic", label: "Kardiothorakale Chirurgie" },
        { value: "ent", label: "HNO" },
        { value: "general_surgery", label: "Allgemeine Chirurgie" },
        { value: "neurosurgery", label: "Neurochirurgie" },
        { value: "psychiatry", label: "Psychiatrie" },
        { value: "trauma_ortho_surgery", label: "Orthopädie" },
        { value: "Urology", label: "Urologie" },
    ],
    summaryLabel: "Zusammenfassung des Patienten",
    reasonLabel: "Grund für die Überweisung des Patienten",
    clearButtonText: "Löschen",
    submitButtonText: "Absenden",
    pending: "Ausstehende",
    available: "Verfügbare",
    consultations: "Konsultationen",
};