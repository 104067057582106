import { UserUrl } from "./AUrl";
import { BodylessApiResponse } from "../interfaces";
import axios from 'axios';


/*
The data needed to reset a password.

@email: The email of the user.
@language: The language the user.
 */
export interface PasswordResetRequestPayload {
    email: String;
    language: String;
}


export type PasswordResetRequestType = (payload: PasswordResetRequestPayload) => Promise<BodylessApiResponse>;


export async function passwordResetRequest(payload: PasswordResetRequestPayload): Promise<BodylessApiResponse> {
    const url = new UserUrl().passwordResetRequest;
    try {
        const response = await axios.post(url, payload, {
            headers: {'Content-Type': 'application/json'},
            // Resolve the promise for all HTTP status codes
            validateStatus: () => true
        });

        // Check for status code 200
        if (response.status === 200) {
            return { status: response.status, message: "Password reset request sent successfully" };
        } else {
            // If the status code is not 200, return the status and the message from the response body
            return { status: response.status, message: response.data || `Unexpected response code: ${response.status}` };
        }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            // If it's an Axios error with a response, return the status and error message from the response body
            return { status: error.response.status, message: error.response.data || error.message };
        } else {
            // For other types of errors, return a generic message
            return { status: 500, message: "An error occurred" };
        }
    }
}
