import styled from "@emotion/styled";

export const OrderConsultationModalContainer = styled.div`
    width: 61em;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Helvetica", Arial, sans-serif;

    & > svg {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        cursor: pointer;
        font-size: 20px;
        transform: translateY(0);
        transition: all 0.3s ease-in-out;

        &:hover {
            transform: translateY(-2px);
        }
    }
`;

export const OrderConsultationModalContentContainer = styled.div`
    overflow-y: auto;
    max-height: 35em;
    position: relative;
`;

export const OrderConsultationModalForm = styled.form`
    width: 100%;
`;

export const OrderConsultationModalFormTitle = styled.div`
    width: fit-content;
    height: fit-content;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #111111;
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
        margin: 0px;
    }

    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
    }
`;

export const OrderConsultationModalContentWrapper = styled.div`
    margin: 1.5rem 0px;
`;

export const OrderConsultationModalContentLabel = styled.h3`
    margin: 0.5rem 0px;
    color: #374151;
    font-weight: 500;
    font-size: 14px;
`;

export const OrderConsultationModalContentDescription = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: #374151;
`;

export const OrderConsultationModalFormFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const OrderConsultationModalFlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
