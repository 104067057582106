import { VOneUrl } from "../url";

export class ClassesUrl extends VOneUrl {
    public base: string;
    public startSession: string;
    public newPatient: string;
    public compareTurns: string;

    constructor() {
        super();
        this.base = this.defineRoot() + "api/v1/sessions/";
        this.startSession = this.base + "create";
        this.newPatient = this.base + "get_new_patient";
        this.compareTurns = this.base + "check_turn";
    }
}
