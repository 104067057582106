import { DischargePageTranslations } from "./types";

export const deTranslations: DischargePageTranslations = {
    dischargedPatients: "Entlassene Patient:innen",
    table: {
        feedback: "Feedback",
        noPatient: "Noch keine Patient:innen entlassen",
        columns: {
            location: "Aufenthaltsort",
            name: "Name",
            ageGender: "Alter/Geschlecht",
            symptom: "Symptom",
            diagnosis: "Diagnose",
            realDiagnosis: "korrekte Diagnose",
            score: "Punktzahl",
        },
    },
};
