import React from "react";

// Dependency Injection Types
import {
    InjectedGameDependencies,
    SidebarDocumentationDependencies,
    DemographicsDependencies,
    GameBarLayoutTranslations,
    TurnManagementDependencies,
} from "./types";

// Dependency Injection Functions
import { getAllNotes } from "../ServerApiLayer/VOne/patients/getAllNotes";
import { postNote } from "../ServerApiLayer/VOne/patients/postNote";
import {
    updateNotesDocumentationToLocalStorage,
    getAllDocumentationFromLocalStorage,
    setDocumentationToLocalStorage,
} from "../LocalStorage/patients/notes";
import { updateTurnCount, getTurnCount } from "../LocalStorage/turnLogic/core";
import {
    getAllNotifications,
    markNotificationRead,
    addNotificationToStorage,
} from "../LocalStorage/Notifications/core";
import { getJwt } from "../LocalStorage/AuthSession/core";
import { useLanguage } from "../translations/LanguageContext";
import { postDiagnosisPatients } from "../ServerApiLayer/VOne/patients/postDiagnosis";
import { postLocationPatients } from "../ServerApiLayer/VOne/patients/movePatient";
import { getDemographicsFromLocalStorage } from "../LocalStorage/patients/demographics";
import {
    updateDiagnosis as baseUpdateDiagnosis,
    updateLocation as baseUpdateLocation,
} from "../LocalStorage/patients/demographics";
import {
    getSessionData,
    setSessionData,
} from "../LocalStorage/gameSession/core";

// Translations
import {
    GAME_TRANSLATIONS,
    SYMPTOMS_TRANSLATIONS,
    DIAGNOSIS_TRANSLATIONS,
    LOCATION_TRANSLATIONS,
} from "../translations/gameTranslations";
import { getSessionID } from "../LocalStorage/gameSession/core";
import { compareTurns } from "../ServerApiLayer/VOne/sessions/compareTurns";

/**
 * Props interface for components that have a patient prop.
 */
export interface WithPatientProps {
    patient?: {
        id: number;
    };
}

/**
 * HOC to inject game dependencies into a component.
 * @param WrappedComponent
 */
export const withGameDependencies = <P extends WithPatientProps>(
    WrappedComponent: React.ComponentType<P & InjectedGameDependencies>
) => {
    return (props: P) => {
        // Get language setup
        const { language } = useLanguage();

        // Set up translations
        const translations: GameBarLayoutTranslations = {
            gameBar: GAME_TRANSLATIONS[language],
            symptoms: SYMPTOMS_TRANSLATIONS[language],
            diagnosis: DIAGNOSIS_TRANSLATIONS[language],
            location: LOCATION_TRANSLATIONS[language],
        };

        // Base refresh function
        const refreshPageData = async (patientId: number) => {
            try {
                window.location.reload();
            } catch (error) {
                console.error("Error refreshing page data:", error);
            }
        };

        // Set up sidebar documentation dependencies
        const sidebarDependencies: SidebarDocumentationDependencies = {
            getAllNotesApiCall: getAllNotes,
            postDocumentation: postNote,
            getNotesLocalStorage: getAllDocumentationFromLocalStorage,
            setNotesLocalStorage: setDocumentationToLocalStorage,
            updateDocumentationLocalStorage:
                updateNotesDocumentationToLocalStorage,
        };

        // Set up turn management dependencies
        const turnDependencies: TurnManagementDependencies = {
            getTurnCount,
            updateTurns: async (turn: number) => {
                updateTurnCount(turn);
                if (props.patient?.id) {
                    await refreshPageData(props.patient.id);
                }
            },
            getNotifications: getAllNotifications,
            markNotificationAsRead: markNotificationRead,
            addNotificationToStorage: addNotificationToStorage,
            compareTurns: async () => {
                const sessionData = getSessionData();
                if (!sessionData) {
                    return {
                        status: 500,
                        error: "No session data found",
                    };
                }

                const jwt = getJwt();
                if (!jwt) {
                    return {
                        status: 401,
                        error: "No JWT found",
                    };
                }

                const response = await compareTurns(jwt, {
                    session_id: sessionData.sessionID,
                    turn_number: sessionData.turn,
                });

                if (response.status === 200 && response.data) {
                    // If backend has a different turn number, update local session
                    if (response.data.turn_number !== sessionData.turn) {
                        sessionData.turn = response.data.turn_number;
                        setSessionData(sessionData);
                    }
                }

                return response;
            },
        };

        // Set up demographics dependencies
        const demographicsDependencies: DemographicsDependencies = {
            getDemographicsFromLocalStorage,
            updateDiagnosis: async (patientId: number, diagnosis: any) => {
                try {
                    const jwt = getJwt();
                    if (!jwt) {
                        console.error("JWT is missing or expired.");
                        return;
                    }

                    // Call the API to update the diagnosis on the backend
                    const apiResponse = await postDiagnosisPatients(jwt, {
                        patient_id: patientId,
                        diagnosis,
                    });

                    if (apiResponse.status === 201) {
                        // If successful, update the diagnosis in local storage
                        await baseUpdateDiagnosis(patientId, diagnosis);
                        console.log(
                            "Diagnosis successfully updated in local storage."
                        );

                        // Refresh the page data
                        await refreshPageData(patientId);
                    } else {
                        console.error(
                            `Failed to update diagnosis via API: ${apiResponse.error}`
                        );
                    }
                } catch (error) {
                    console.error("Error while updating diagnosis:", error);
                }
            },
            updateLocation: async (patientId: number, location: string) => {
                try {
                    const jwt = getJwt();
                    if (!jwt) {
                        console.error("JWT is missing or expired.");
                        return;
                    }

                    // Get session ID from local storage
                    const sessionId = getSessionID();

                    // Call the API to update the location on the backend
                    const apiResponse = await postLocationPatients(jwt, {
                        patient_id: patientId,
                        session_id: sessionId,
                        new_type_of_location: location,
                    });

                    if (apiResponse.status === 201) {
                        // If successful, update the location in local storage
                        await baseUpdateLocation(patientId, location);

                        // Refresh the page data
                        await refreshPageData(patientId);
                    } else {
                        console.error(
                            `Failed to update location via API: ${apiResponse.error}`
                        );
                    }
                } catch (error) {
                    console.error("Error while updating location:", error);
                }
            },
        };

        const gameDependencies: InjectedGameDependencies = {
            sidebarDependencies,
            turnDependencies,
            demographicsDependencies,
            getJwt,
            useLanguage,
            refreshPage: () =>
                props.patient?.id
                    ? refreshPageData(props.patient.id)
                    : Promise.resolve(),
            translations,
        };

        return <WrappedComponent {...props} {...gameDependencies} />;
    };
};
