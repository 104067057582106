import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
// @ts-ignore
// @ts-ignore
import myGif from "../../../../assets/tick.gif";
import { DIV, ErrorText, ForgetPasswordFormClass, ForgetPasswordSuccessClass, ForgetPasswordSuccessClassFormClass, ForgotPassword, H2, Image, jiggle, Paragraph } from './FormComponent.styles';


export interface FormData {
    email: string;
    success: boolean;
    failure: boolean;
    error: string;
}

/*
The props for the ForgotPasswordForm component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export interface ForgotPasswordFormProps {
    onSubmit: (data: FormData) => void;
    initialFormData?: FormData;
}


/*
The ForgotPasswordForm component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSubmit, initialFormData }) => {

    // hook for the state of the form inputs and the state of the form submission
    const [formData, setFormData] = useState<FormData>( initialFormData ||{
        email: '',
        success: false,
        failure: false,
        error: '',
    });

    useEffect(() => {
        // @ts-ignore
        setFormData(initialFormData); // Update internal state when initialFormData changes
    }, [initialFormData]);

    // hook for the animation of the form
    const [formClass, setFormClass] = useState('');

    // hook for the animation of the form
    useEffect(() => {
        if (formData.error || formData.success) {
            setFormClass("JiggleAnimation");

            // Optional: Remove the class after the animation ends
            const timer = setTimeout(() => {
                setFormClass('');
            }, 500); // Duration of the animation

            return () => clearTimeout(timer);
        }
    }, [formData.error]);


    // handle the change of the form inputs
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // handle the submission of the form
    // handle the submission of the form
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // check that the form is filled out returning the error message if not to the form
        if (formData.email === '') {
            setFormData({ ...formData, success: false, failure: true, error: 'Please fill in email' });
            return null
        }
        onSubmit(formData);
    };

    if (formData.success) {
        return (
            formClass === "JiggleAnimation"?
            <ForgetPasswordSuccessClassFormClass animation={jiggle}>
                <H2>Check your email!</H2>
                <Image src={myGif} alt="Check your email" />
                <Paragraph id={"successMessage"}>Please check your email to reset your password.</Paragraph>
            </ForgetPasswordSuccessClassFormClass> : 
            <ForgetPasswordSuccessClass>
            <H2>Check your email!</H2>
            <Image src={myGif} alt="Check your email" />
            <Paragraph id={"successMessage"}>Please check your email to reset your password.</Paragraph>
        </ForgetPasswordSuccessClass>
        );
    } else {
        return (
            formClass=== "JiggleAnimation"?
            <ForgetPasswordFormClass onSubmit={handleSubmit} animation={jiggle}>
                <DIV>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder='Email'/>
                </DIV>
                {formData.failure && <ErrorText id={"errorMessage"}>{formData.error}</ErrorText>}
                <button type="submit">Send reset link</button>
            </ForgetPasswordFormClass> :
            <ForgotPassword onSubmit={handleSubmit}>
            <DIV>
                <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder='Email'/>
            </DIV>
            {formData.failure && <ErrorText id={"errorMessage"}>{formData.error}</ErrorText>}
            <button type="submit">Send reset link</button>
        </ForgotPassword>
        );
    }
};
