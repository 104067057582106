import styled from "@emotion/styled";

export const TabSection = styled.div`
    background: #f3f4f6; /* Set background */
    height: 100px; /* Set the full height of tabs section */
    width: 100%; /* Set the full width of tabs section */
    display: flex; /* Include flexbox */
    justify-content: space-between; /* Add spacing in middle of elements */
    align-items: center; /* Align elements to center */
    padding: 0px 20px; /* Include some spacing */
    font-family: "Helvetica", Arial, sans-serif;
`;

export const TabsButtons = styled.div`
    display: flex; /* Include flexbox */
    gap: 10px; /* Include spacing */
`;

export const Button = styled.button`
    width: fit-content; /* Adjust the width as per content */
    display: flex; /* Include flexbox */
    gap: 5px; /* Include spacing */
    font-family: Inter, sans-serif; /* Include font-family */
    font-weight: 600; /* Make the text bold */
    justify-content: center; /* Align it center */
    align-items: center; /* Align it vertically center */
    cursor: pointer; /* Change the cursor to pointer */
    border: none; /* Remove border */
    border-radius: 4px; /* Include some border radius */
    padding: 10px; /*  Add spacing */
    font-size: 16px; /* Set the font size */
       &.tabButton{
    background-color: #bbbbbb; /* Set default background */
}
    &.activeTab{
        background-color: #0584c79e; /* Set active background */
    }
`;

export const LeftSidePanel = styled.div`
    display: flex; /* Include flex box */
    width: 100%; /* Asign width to left section */
    flex-direction: column; /* Align items column wise */
    margin-top: 20px; /* Include spacing from bottom */
`;

