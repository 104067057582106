import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
// @ts-ignore
// @ts-ignore
import myGif from "../../../../assets/tick.gif";
import { SupportedLanguage } from "../../../../core/translations/supportedLanguages";
import { RegistrationPageTranslations } from "../translations/types";
import { enTranslations } from "../translations/en";
import { deTranslations } from "../translations/de";
import { useLanguage } from "../../../../core/translations/LanguageContext";
import { DIV, ErrorText, FirstLastNameContainer, H2, Image, jiggle, Paragraph, RegistrationForms, RegistrationJiggle, RegistrationSuccess, RegistrationSuccessJiggle, SubmitButton } from "./FormComponent.styles";

/*
The data state around the form.

@email: The email for the user for verification
@password: The password for the user being created
@success: A boolean to indicate if the form submission for the backend API was successful
@failure: A boolean to indicate if the form submission for the backend API was unsuccessful
@error: A string to indicate the error message from the backend API
@firstName: The first name of the user
@lastName: The last name of the user
@language: The language of the user being registered
 */
export interface FormData {
    email: string;
    password: string;
    success: boolean;
    failure: boolean;
    error: string;
    firstName: string;
    lastName: string;
    language: string;
}

/*
The props for the RegistrationForm component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export interface RegistrationFormProps {
    onSubmit: (data: FormData) => void;
    initialFormData?: FormData;
}

const TRANSLATIONS: Record<SupportedLanguage, RegistrationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};
/*
The RegistrationForm component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export const RegistrationForm: React.FC<RegistrationFormProps> = ({
    onSubmit,
    initialFormData,
}) => {
    // hook for the state of the form inputs and the state of the form submission
    const [formData, setFormData] = useState<FormData>(
        initialFormData || {
            email: "",
            password: "",
            success: false,
            failure: false,
            error: "",
            firstName: "",
            lastName: "",
            language: "english",
        }
    );

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    useEffect(() => {
        // @ts-ignore
        setFormData(initialFormData); // Update internal state when initialFormData changes
    }, [initialFormData]);

    // hook for the animation of the form
    const [formClass, setFormClass] = useState("");

    // hook for the animation of the form
    useEffect(() => {
        if (formData.error || formData.success) {
            setFormClass("JiggleAnimation");

            // Optional: Remove the class after the animation ends
            const timer = setTimeout(() => {
                setFormClass("");
            }, 500); // Duration of the animation

            return () => clearTimeout(timer);
        }
    }, [formData.error]);

    // handle the change of the form inputs
    const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // handle the submission of the form
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // check that the form is filled out returning the error message if not to the form
        if (formData.firstName === "") {
            setFormData({
                ...formData,
                success: false,
                failure: true,
                error: translations.firstNameError,
            });
            return null;
        } else if (formData.lastName === "") {
            setFormData({
                ...formData,
                success: false,
                failure: true,
                error: translations.lastNameError,
            });
            return null;
        } else if (formData.email === "") {
            setFormData({
                ...formData,
                success: false,
                failure: true,
                error: translations.emailError,
            });
            return null;
        } else if (formData.password === "") {
            setFormData({
                ...formData,
                success: false,
                failure: true,
                error: translations.passwordError,
            });
            return null;
        }
        onSubmit(formData);
    };
    // rendering of the form
    if (formData.success) {
        return (
            formClass === "JiggleAnimation"?
            <RegistrationSuccessJiggle animation={jiggle} >
                <H2>{translations.success}</H2>
                <Image src={myGif} alt="Description of the GIF" />
                <Paragraph id={"RegisterSuccessMessage"}>
                    {translations.confirmEmail}
                </Paragraph>
            </RegistrationSuccessJiggle>:
            <RegistrationSuccess>
            <H2>{translations.success}</H2>
            <Image src={myGif} alt="Description of the GIF" />
            <Paragraph id={"RegisterSuccessMessage"}>
                {translations.confirmEmail}
            </Paragraph>
            </RegistrationSuccess>
        );
    }
    return (
        formClass === "JiggleAnimation"?
        <RegistrationJiggle
            onSubmit={handleSubmit}
            animation={jiggle}
        >
            <FirstLastNameContainer>
                <DIV>
                    <label>{translations.firstName}</label>
                    <input
                        name="firstName"
                        type="text"
                        value={formData.firstName}
                        onChange={handleChange}
                    />
                </DIV>
                <DIV>
                    <label>{translations.lastName}</label>
                    <input
                        name="lastName"
                        type="text"
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                </DIV>
            </FirstLastNameContainer>
            <DIV>
                <label>{translations.email}</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                />
            </DIV>
            <DIV>
                <label>{translations.password}</label>
                <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                />
            </DIV>
            <DIV>
                <label>{translations.language}</label>
                <select
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                >
                    {translations.languageTypes.map((data,index)=>(
                        <option key={index} value={data.value}>{data.label}</option> 
                    ))}
                </select>
            </DIV>
            {formData.failure && (
                <ErrorText id={"errorMessage"}>
                    {formData.error}
                </ErrorText>
            )}
            <button type="submit">{translations.register}</button>
        </RegistrationJiggle>
        :
        <RegistrationForms
            onSubmit={handleSubmit}
        >
            <FirstLastNameContainer>
                <DIV>
                    <label>{translations.firstName}</label>
                    <input
                        name="firstName"
                        type="text"
                        value={formData.firstName}
                        onChange={handleChange}
                    />
                </DIV>
                <DIV>
                    <label>{translations.lastName}</label>
                    <input
                        name="lastName"
                        type="text"
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                </DIV>
            </FirstLastNameContainer>
            <DIV>
                <label>{translations.email}</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                />
            </DIV>
            <DIV>
                <label>{translations.password}</label>
                <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                />
            </DIV>
            <DIV>
                <label>{translations.language}</label>
                <select
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                >
                    {translations.languageTypes.map((data,index)=>(
                        <option key={index} value={data.value}>{data.label}</option> 
                    ))}
                </select>
            </DIV>
            {formData.failure && (
                <ErrorText id={"errorMessage"}>
                    {formData.error}
                </ErrorText>
            )}
            <SubmitButton type="submit">{translations.register}</SubmitButton>
        </RegistrationForms>
    );
};
