import styled from "@emotion/styled";

export const FlexContainer = styled.div`
    display: flex; /* Include flex box */
    gap: 20px; /* Add some gap */
    justify-content: space-between; /* Align items space between */
`;

export const PageContainer = styled.div`
    width: 100%;
    margin-top: 0%; /* Add some margin to the top of the container */
`;

export const PageMain = styled.div`
    display: flex; /* Include flex box */
    gap: 20px; /* Add some gap */
`;

export const Button = styled.button`
    width: fit-content; /* Make the button smaller instead of full width */
    display: flex; /* Include flex box */
    gap: 5px; /* Add some gap */
    font-family: Inter, sans-serif; /* Include font-family */
    font-weight: 600; /* Make text bold */
    justify-content: center; /* Align it center */
    align-items: center; /* Vertical align to center */
`;


export const Textarea = styled.textarea`
    font-size: 0.875rem; /* Set font size */
    line-height: 1.25rem; /* Set line height of email input field */
    padding: 10px; /* Set padding */
    border: 1px solid #ccc; /* Set border to email field */
    border-radius: 4px; /* Set border radius of input field */
    box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    margin-bottom: 20px; /* Add margin between 2 column aligned fields */
    width: 100%; /* Set the width of input field to show in full assigned area */
    font-family: Inter, sans-serif; /* Include font-family */
`;

export const LeftSidePanel =styled.div`
    display: flex; /* Include flex box */
    width: 100%; /* Assign width to left section */
    flex-direction: column; /* Align items column wise */
    margin-top: 20px; /* Include some spacing from top */
`;
export const RightSidePanel = styled.div`
`;

export const InputFieldArea = styled.div`
    gap: 10px; /* Add some space */
    display: flex; /* Include flexbox */
    flex-wrap: wrap; /* Wrap flexbox */
`;
