// External imports
import React, { useState, FormEvent, useEffect } from "react";
import { FaAmbulance, FaUserNurse } from "react-icons/fa";

// Internal imports
import GlobalSmallPopup from "../GlobalSmallPopup";
import {
    Form,
    FormH3,
    FormH4,
    FormInputFieldArea,
    FormFieldSection,
    FormLabel,
    FormSubmitButton,
} from "./HandoverModal.styles";
import SelectBox, { SelectOption } from "../../select-box/SelectBox";
import { handoverData } from "../../../../core/LocalStorage/patients/interface";

// Translation imports
import { HandoverModalTranslations } from "./translations/types";

/*
The props for the HandoverModal component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export interface PopupFormProps {
    onRequestClose: () => void;
    handover: handoverData | null;
    pageTranslations: HandoverModalTranslations;
    translations: any;
    demographicsDependencies: any;
    patientId: number;
}

/*
The HandoverModal component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export const HandoverModal: React.FC<PopupFormProps> = ({
    onRequestClose,
    handover,
    translations,
    pageTranslations,
    demographicsDependencies,
    patientId,
}) => {
    const [selectedDestination, setSelectedDestination] =
        useState<SelectOption>({
            label: "Select a destination",
            value: "",
        });

    // Validate form submission readiness
    const isSubmitEnabled = selectedDestination.value !== "";

    // Handle form submission
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = {
            destination: selectedDestination.value,
        };
        demographicsDependencies.updateLocation(
            patientId,
            formData.destination
        );
        onRequestClose();
    };

    // Dropdown options
    const moveOptions: SelectOption[] = [
        { label: translations.location.Resus, value: "Resus" },
        { label: translations.location.Majors, value: "Majors" },
    ];

    return (
        <GlobalSmallPopup onRequestClose={onRequestClose}>
            <Form onSubmit={handleSubmit}>
                <FormInputFieldArea>
                    <FormH4>{translations.title}</FormH4>

                    <FormH3>
                        {handover ? (
                            <>
                                {handover.professional === "AMBULANCE" && (
                                    <FaAmbulance
                                        style={{
                                            marginRight: "8px",
                                            color: "red",
                                        }}
                                    />
                                )}
                                {handover.professional === "NURSE" && (
                                    <FaUserNurse
                                        style={{
                                            marginRight: "8px",
                                            color: "blue",
                                        }}
                                    />
                                )}
                                {handover.summary}
                            </>
                        ) : (
                            <>{translations.noHandoverAvailable}</>
                        )}
                    </FormH3>
                    <FormFieldSection>
                        <FormLabel>{translations.pickDestination}</FormLabel>
                        <SelectBox
                            options={moveOptions}
                            value={selectedDestination}
                            onChange={setSelectedDestination}
                        />
                    </FormFieldSection>
                    <FormSubmitButton type="submit" disabled={!isSubmitEnabled}>
                        {pageTranslations.moveText}
                    </FormSubmitButton>
                </FormInputFieldArea>
            </Form>
        </GlobalSmallPopup>
    );
};
