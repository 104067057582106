// External imports
import React from 'react';

// Internal imports
import { FooterLinkSection } from "./footerLinkSection/FooterLinkSection";
import useFooterNavigation from "./footerLinkSection/useFooterNavigation";
//@ts-ignore
import HospitalIcon from "./Hospital";
import {
  StyledFooter,
  HiddenHeading,
  Container,
  FlexContainer,
  FlexItem,
  Paragraph,
  SocialLinks,
  LinkGrid,
  FooterBottom,
  Copyright,
} from "./Footer.styles";

// Translations
import { FooterTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

/**
 * Translation record type for the footer
 */
const TRANSLATIONS: Record<SupportedLanguage, FooterTranslations> = {
  en: enTranslations,
  de: deTranslations,
};

/**
 * Footer component that displays navigation links, social media, and company information
 * Supports multiple languages and contains various sections like Solutions, Support, Company, and Legal
 */
const Footer: React.FC = () => {
  const { footerNavigation } = useFooterNavigation();
  const language = localStorage.getItem('language') as SupportedLanguage || 'en';
  const translations = TRANSLATIONS[language];

  return (
      <StyledFooter aria-labelledby="footer-heading">
        <HiddenHeading id="footer-heading">
          {translations.footer}
        </HiddenHeading>

        <Container>
          <FlexContainer>
            <FlexItem>
              <Paragraph>
                {translations.tagline}
              </Paragraph>
              <SocialLinks>
                {footerNavigation.social.map((item) => (
                    <a key={item.name} href={item.href}>
                      <HiddenHeading> {item.name} </HiddenHeading>
                      {item.icon}
                    </a>
                ))}
              </SocialLinks>
            </FlexItem>

            <LinkGrid>
              <FooterLinkSection
                  section={translations.sections.solutions}
                  links={footerNavigation.solutions}
              />
              <FooterLinkSection
                  section={translations.sections.support}
                  links={footerNavigation.support}
              />
              <FooterLinkSection
                  section={translations.sections.company}
                  links={footerNavigation.company}
              />
              <FooterLinkSection
                  section={translations.sections.legal}
                  links={footerNavigation.legal}
              />
            </LinkGrid>
          </FlexContainer>

          <FooterBottom>
            <HospitalIcon />
            <Copyright>
              {translations.copyright}
            </Copyright>
          </FooterBottom>
        </Container>
      </StyledFooter>
  );
};

export default Footer;