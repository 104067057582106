// External imports
import React from "react";

// Internal imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import { MainPage } from "../../../components/core/mainPage/MainPage.styles";
import { 
    CentralButtonComponent, 
    CentralButtonComponentProps 
} from "../../../components/core/centralButtonsComponent/CentralButtonComponent";

// Assets
import WaitingRoomImage from "../../../assets/waiting_room.jpg";

// Language Context
import { useLanguage } from '../../../core/translations/LanguageContext';

// Translations
import { TeacherFrontPageTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../core/translations/supportedLanguages';

// Types
import { TeacherButton } from './translations/types';

const TRANSLATIONS: Record<SupportedLanguage, TeacherFrontPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Teacher Front Page Component
 * 
 * Landing page for teachers with primary action buttons:
 * 1. Create a new class
 * 2. Review previous classes
 */
export const TeacherFrontPage: React.FC = () => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    const buttons: TeacherButton[] = [
        {
            text: translations.buttons.createClass,
            href: "/teacher/new-class",
        },
        {
            text: translations.buttons.reviewClasses,
            href: "/teacher/view-classes",
        },
    ];

    return (
        <MainPageLayout>
            <MainPage backgroundImage={WaitingRoomImage}>
                <CentralButtonComponent 
                    buttons={buttons} 
                />
            </MainPage>
        </MainPageLayout>
    );
};

export default TeacherFrontPage;
