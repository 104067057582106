import styled from "@emotion/styled";

export const BlogsPageContainer = styled.div`
    max-width: 80rem;
    padding: 1rem 2rem;
    margin: 20px auto;
`;
export const BlogsPageTitle = styled.h1`
    color: #173b5e;
    @media (max-width:767px) {
        padding-bottom: 15px;
    }
`;
export const BlogsPageHeadingMain = styled.div`
    width: 100%;
`;
export const BlogsPageHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:767px) {
        display: block;
        text-align: center;
    }
`;
export const BlogsPageSearchField = styled.div`
    position: relative;
`;
export const BlogsPageSearchFieldSpan = styled.span`
    position: absolute;
    width: 60px;
    height: 40px;
    border-radius: 20px;
    background: #d9e8f8;
    & > svg {
        position: absolute;
        top: 10px;
        left: 20px;
        color: #aebac6; 
        @media (min-width:768px) and (max-width:1024px) {
            left: 60px;
        }
        @media (max-width:767px) {
            left: 20px;
        }
        @media (max-width:360px) {
            left: 15px; 
        }
    }
`;
export const BlogFiltersArea = styled.div`
    width: 100%;
    display: flex;
    gap: 50px;
    @media (min-width:768px) and (max-width:1024px) {
        flex-direction: column;
        gap: 30px;
    }
    @media (max-width:767px) {
        flex-direction: column;
        gap: 20px;
    }
    @media (max-width:360px) {
        flex-direction: column;
        gap: 20px;
    }
`;
export const BlogsPageSearchFieldInput = styled.input`
    background: #d9e8f8;
    height: 40px; 
    border-radius: 30px;
    border: none;
    width: 288px;
    font-size: .98em;
    padding: 10px;
    text-align: center;
    &::-webkit-input-placeholder {
        color: #173b5e;
    }
    &:focus {
        border: none;
        outline-color: #173b5e;
        outline-style: solid;
    }
    @media (max-width:360px) {
        width: 100%;
    }
`;
export const BlogsPageBackgroundSection = styled.div`
    background: #e0ecf0;
`;
export const BlogsPageCardContainer = styled.div`
    display: flex;
    gap: 38px;
    margin: 15px 0px;
    flex-wrap: wrap;
`;
export const BlogsPageCard = styled.div`
    flex: 0 0 31.1%;
    a{
        text-decoration: none;
        color: inherit;
    }
    @media (min-width:768px) and (max-width:1024px) {
        flex: 0 0 29%;
    }
    @media (max-width:767px) {
        flex: 0 0 100%; 
    }
`;
export const BlogsPageCardFigure = styled.div`
    background: white;
    border-radius: 20px;
`;
export const BlogsPageCardFigureImage = styled.img`
    width: 100%;
    height: 13rem;
    border-radius: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @media (min-width:768px) and (max-width:1024px) {
        height: 13rem; 
        object-fit: cover; 
    }
    @media (max-width:767px) {
        object-fit: cover;
        height: 10rem;
    }
`;
export const BlogsPageCardTitle = styled.h5`
    color: #1681ff;
    padding-bottom: 15px;
    font-family: 'Inter';
`;
export const BlogsPageCardDescription = styled.div`
    padding: 20px 22px;
    border-radius: 25px;
    position: relative;
    background: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
    & > svg {
        position: absolute;
        right: 15px;
        top: 46px;
        font-size: 25px;
        @media (min-width:834px) and (max-width:900px) {
            position: absolute;
            right: 9px;
            top: 46px;
            font-size: 25px;
        }
        @media (max-width:767px) {
            right: 7px;
            top: 48px;
        }
    }
`;
export const BlogsPageCardDescriptionParagraph = styled.p`
    line-height: 1.5rem;
    font-family: 'Inter';
    padding-top: 15px;
`;
export const BlogsPageCardDescriptionSubTitle = styled.h2`
    display: -webkit-box;
    font-family: 'Inter';
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #232242;
    font-size: 1.25rem;
    overflow: hidden;
`;
export const BlogsPageSearchFieldDropdown = styled.div`
    position: relative;
`;
export const BlogsPageSearchFieldDropdownSpan = styled.span`
    position: absolute;
    width: 60px;
    height: 40px;
    right: 0px;
    border-radius: 20px;
    background: #d9e8f8;
    & > svg {
        position: absolute;
        left: 20px;
        color: #aebac6; 
        &:first-child{
            top: 15px; 
        }
        &:last-child{
            top: 5px;
        }
        @media (min-width:768px) and (max-width:1024px) {
            right: 50px;
        }
        @media (max-width:767px) {
            right: 50px;
        }
        @media (max-width:360px) {
            right: 15px; 
        }
    }
`;
export const BlogsPageSearchFieldDropdownInput = styled.input`
    background: #d9e8f8;
    height: 40px; 
    border-radius: 30px;
    border: none;
    width: 288px;
    font-size: .98em;
    padding: 15px;
    text-align: left;
    &::-webkit-input-placeholder {
        color: #173b5e;
    }
    &:focus {
        border: none;
        outline-color: #173b5e;
        outline-style: solid;
    }
    @media (max-width:360px) {
        width: 100%;
    }
`;
export const DropdownFieldArea = styled.div`
    position: relative;
    display: inline-block;
    &:hover > div{
        display: block;
    }
`;
export const DropdownField = styled.div`
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 90%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 30px;
    z-index: 1;
    max-height: 200px;
    overflow-y: scroll;
`;
export const DropdownList = styled.ul`
    list-style-type: none;
`;
export const DropdownListItem = styled.li`
    padding: 5px;
`;