import styled from "@emotion/styled";

export const StyledOrderTreatmentForm = styled.form`
    width: 100%;
`;

export const OrderTreatmentFormContainer = styled.div`
    width: 100%;
`;

export const OrderTreatmentFormGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 1rem;
    row-gap: 1.5rem;

    @media (min-width: 640px) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
`;

export const OrderTreatmentFormGridItemBy3 = styled.div`
    @media (min-width: 640px) {
        grid-column: span 3 / span 3;
    }
`;

export const OrderTreatmentFormGridItemBy6 = styled.div`
    @media (min-width: 640px) {
        grid-column: span 6 / span 6;
    }
`;

export const OrderTreatmentFormLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #374151;
`;

export const OrderTreatmentFormFieldContainer = styled.div`
    margin-top: 0.5rem;
`;

export const OrderTreatmentTurnLabel = styled.p`
    font-size: 0.875rem;
`;

export const OrderTreatmentDivider = styled.div`
    margin: 2rem 0px;
    width: 100%;
    height: 1px;
    background-color: #f3f4f6;
`;