import styled from "@emotion/styled";

export const Image = styled.img`
  width: 100%;
  height: auto;
  @media (min-width: 1024px) {
    width: 50%
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 4em 0;
  @media (min-width: 1024px) {
    flex-direction: column;
    width: 60%;
    margin: auto;
  }
`;

export const TextSection = styled.p`
    text-align: justify;
    margin-bottom: 30px;
`;

export const TextSectionAnchor = styled.a`
  color:#0058be;
  &:hover {
      color: #002147;
  }
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #173b5e;
  @media (min-width: 640px) {
    font-size:3rem;

  }
`;

export const Description = styled.div`
  margin-top: 24px;
  max-width: 100%;
  font-size: 1rem;
  font-weight: regular;
  color: #173b5e;
  line-height: 1.7em;
  @media (min-width: 640px) {
    max-width: 100%;
  }
`;

export const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;
