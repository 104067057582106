// defines the button for a tab
import { createElement } from "react";
import { NewClassFormStyledButton } from "./NewClassFormTabButton.styles";


/*
A button for a tab.

@prop active - whether the tab is active
@prop icon - the icon for the tab
@prop onClick - the function to call when the tab is clicked
@prop label - the label for the tab
@prop id - the id for the tab
 */
export const NewClassFormTabButton: React.FC<{
    active: boolean;
    icon: any;
    onClick: () => void;
    label: string;
    id: string;
}> = ({ icon, active, onClick, label , id}) => {
    return (
        <NewClassFormStyledButton
            type="button"
            onClick={onClick}
            active={active}
            id={id}
        >
            {icon
                ? createElement(icon, { color: active ? "white" : "#565656" })
                : null}
            {label}
        </NewClassFormStyledButton>
    );
};
