import styled from "@emotion/styled";

export const StyledTextArea = styled.textarea<{
    color?: string;
    borderColor?: string;
    height?: string;
}>`
    padding: 0.5rem;
    margin: 0px;
    display: block;
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: ${(props) => props.borderColor || "#f3f4f6"};
    width: 100%;
    color: ${(props) => props.color || "#374151"};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    height: ${(props) => props.height || "9rem"};

    @media (min-width: 640px) {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
`;
