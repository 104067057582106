// src/pages/pricing/Pricing.config.ts

/**
 * Interface for pricing card configuration
 */
interface PricingCardConfig {
    id: 'individual' | 'team';
    monthlyPrice: string;
    yearlyPrice: string;
}

/**
 * Pricing card configuration data
 * Contains only the non-translatable data (prices)
 */
export const PRICING_CARD_DATA: PricingCardConfig[] = [
    {
        id: 'individual',
        monthlyPrice: "19.99",
        yearlyPrice: "199.99",
    },
    {
        id: 'team',
        monthlyPrice: "29.99",
        yearlyPrice: "299.99",
    },
];