// External imports
import {
    FaBinoculars,
    FaEyeDropper,
    FaHandHoldingMedical,
    FaHospitalAlt,
    FaRegStickyNote,
    FaStethoscope, FaUserPlus
} from "react-icons/fa";
import { CiPill } from "react-icons/ci";

/**
 * ICON_TYPES
 * 
 * This is the icon types for the notes list items.
 */
export const ICON_TYPES = {
    HANDOVER: { type: FaHandHoldingMedical, color: "#6366F1" },
    BLOOD_ORDER: { type: FaEyeDropper, color: "#EF4444" },
    NOTE: { type: FaRegStickyNote, color: "#F59E0B" },
    INVESTIGATION_NOTE: { type: FaRegStickyNote, color: "#F59E0B" },
    DIAGNOSIS: { type: FaBinoculars, color: "#10B981" },
    INVESTIGATION_ORDER: { type: FaBinoculars, color: "#10B981" },
    TREATMENT_ORDER: { type: FaStethoscope, color: "#3B82F6" },
    ADMISSION_FORM: { type: FaHospitalAlt, color: "#8B5CF6" },
    CONSULTATION_ORDER: {
        type: FaStethoscope,
        color: "#FBBF24",
    },
    DRUG_PRESCRIPTION: { type: CiPill, color: "#3B82F6" },
    DISCHARGE_FORM: { type: FaHospitalAlt, color: "#1D4ED8" },
    ADDITIONAL_TEST: { type: FaUserPlus, color: "#F59E0B" }
};

/**
 * NOTES_TYPE - this is the notes type for the notes list items.
 * 
 * @todo: WIll need to changes this to account for language translation.
 */
export const NOTES_TYPE = {
    HANDOVER: "Handover",
    BLOOD_ORDER: "Blood Order",
    NOTE: "Note",
    INVESTIGATION_NOTE: "Investigation Note",
    DIAGNOSIS: "Diagnosis",
    INVESTIGATION_ORDER: "Investigation Order",
    TREATMENT_ORDER: "Treatment Order",
    ADMISSION_FORM: "Admission Form",
    CONSULTATION_ORDER: "Consultation Order",
    DRUG_PRESCRIPTION: "Drug Prescription",
    DISCHARGE_FORM: "Discharge Form",
    ADDITIONAL_TEST: "Additional Tests"
};