// Internal imports
import { promptData} from "./interface";
import { getSpecificPatientData, updatePatientData } from "./core";

// Function signature type for setting to local storage
export type SetPromptToLocalStorageType = (patient_id: number, newPromptData: promptData) => void;

/*
Set the prompt data for a patient in localStorage.
 */
export function setPromptToLocalStorage(patient_id: number, newPromptData: promptData): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has prompt data, initialize if none
    let existingPrompts = patientData.prompt || {};

    // Check if the prompt type in newPromptData already exists in the patient's existing prompts
    const promptType = newPromptData.prompt;

    if (!existingPrompts[promptType]) {
        // If prompt doesn't exist, add it
        existingPrompts[promptType] = newPromptData.prompt;
    } else {
        // Just pass.
    }

    // Update the patient's prompt data
    patientData.prompt = existingPrompts;

    // Save the updated patient data back to localStorage
    updatePatientData(patient_id, patientData);
}



// Function signature type for getting from local storage
export type GetPromptDataFromLocalStorageType = (patient_id: number, promptType: string) => string | null;

// Function to get the prompt data for a patient from localStorage
export function getPromptDataFromLocalStorage(patient_id: number, promptType: string): string | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // Check if the patient has prompt data
    if (!patientData?.prompt) {
        return null;
    }

    // Return the prompt data, or null if no data is found
    return patientData?.prompt[promptType] || null;
}