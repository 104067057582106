// External imports
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Internal imports
import OrderInvestigationForm from "./components/OrderInvestigationForm/OrderInvestigationForm";
import {
    OrderInvestigationContainer,
    OrderInvestigationDivider,
    OrderInvestigationSpace,
    OrderInvestigationPageContainer,
} from "./OrderInvestigation.styles";
import { OrderInvestigationResult } from "./components/InvestigationResult/orderInvestigationResult/OrderInvestigationResult";
import InvestigationAlreadyOrderedModal from "../../../components/core/modal/investigation-error-modal/InvestigationErrorModal";
import { STATUS } from "./config/investigationOptions";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Dependency Injection for investigation page only
import { PostInvestigationType } from "../../../core/ServerApiLayer/VOne/patients/postInvestigate";
import {
    SetToLocalStorageType,
    GetInvestigationDataFromLocalStorageType,
    FilterInvestigationDataType,
} from "../../../core/LocalStorage/patients/investigations";
import { UpdateInvestigationDocumentationToLocalStorageType } from "../../../core/LocalStorage/patients/notes";

// Translation
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { InvestigationPageTranslations } from "./translations/types";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { PriorityTypesTranslations } from "../../../core/translations/priority/types";
import { dePriorityTranslations } from "../../../core/translations/priority/de";
import { enPriorityTranslations } from "../../../core/translations/priority/en";
import { InvestigationTypesTranslations } from "../../../core/translations/investigations/types";
import { enInvestigationTranslations } from "../../../core/translations/investigations/en";
import { deInvestigationTranslations } from "../../../core/translations/investigations/de";

// Set the translations for the page
const TRANSLATIONS: Record<SupportedLanguage, InvestigationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

// Define the translations for the priority types
const PRIORITY_TRANSLATIONS: Record<
    SupportedLanguage,
    PriorityTypesTranslations
> = {
    en: enPriorityTranslations,
    de: dePriorityTranslations,
};

// Define the translations for the investigation types
const INVESTIGATION_TRANSLATIONS: Record<
    SupportedLanguage,
    InvestigationTypesTranslations
> = {
    en: enInvestigationTranslations,
    de: deInvestigationTranslations,
};

/*
Dependency Injection for OrderInvestigationPage

@param postInvestigationApiCall - makes the api call to the backend to post the investigation
@param setInvestigationLocalStorage - sets the investigation data to local storage
@param getInvestigationLocalStorage - provides the investigation data from local storage
@param filterInvestigationLocalStorage - provides 2 lists of pending and available investigations
@param updateInvestigationDocumentationToLocalStorage - updates the investigation documentation in notes
 */
interface OrderInvestigationPageProps extends WithPatientProps {
    postInvestigationApiCall: PostInvestigationType;
    setInvestigationLocalStorage: SetToLocalStorageType;
    getInvestigationLocalStorage: GetInvestigationDataFromLocalStorageType;
    filterInvestigationLocalStorage: FilterInvestigationDataType;
    updateInvestigationDocumentationToLocalStorage: UpdateInvestigationDocumentationToLocalStorageType;
}

type CombinedProps = OrderInvestigationPageProps & WithGameDependenciesProps;

/**
 * OrderInvestigationPage
 *
 * These are the dependencies for the OrderInvestigationPage component.
 * @param postInvestigationApiCall
 * @param setInvestigationLocalStorage
 * @param getInvestigationLocalStorage
 * @param filterInvestigationLocalStorage
 * @constructor
 */
const OrderInvestigationPageBase: React.FC<CombinedProps> = ({
    // Investigation-specific props
    postInvestigationApiCall,
    setInvestigationLocalStorage,
    getInvestigationLocalStorage,
    filterInvestigationLocalStorage,
    updateInvestigationDocumentationToLocalStorage,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount();
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Translations for the Investigation Page
    const { language } = useLanguage();
    const investigationTranslations = INVESTIGATION_TRANSLATIONS[language];
    const priorityTranslations = PRIORITY_TRANSLATIONS[language];
    const pageTranslations = TRANSLATIONS[language];

    // State for managing investigation results
    const [pendingData, setPendingData] = useState<any[]>([]);
    const [availableData, setAvailableData] = useState<any[]>([]);

    // Modal state
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({
        turnOrdered: 0,
        turnAvailable: 0,
    });

    // Fetch and update investigation data on component mount and after
    // successful form submission
    const fetchInvestigationData = () => {
        const data = filterInvestigationLocalStorage(
            Number(patientId),
            turnCount
        );
        setPendingData(data.pending);
        setAvailableData(data.available);
    };

    // Fetch investigation data on component mount
    useEffect(() => {
        // Fetch data when component mounts
        fetchInvestigationData();
    }, []);

    // Handle form submission for ordering an investigation. This handles the
    // API call to post the investigation, updates the local storage, and updates
    // the UI with the new data, plus incrementing turns.
    const handleSubmit = async (
        investigation: string,
        priority: string,
        justification: string
    ): Promise<boolean> => {
        const payload = {
            turn: turnCount,
            patient_id: Number(patientId),
            investigation,
            reason: justification,
            priority: priority as "Low" | "Medium" | "High",
        };

        // Check if the investigation has already been ordered, and if so, show a modal
        // with the turn it was ordered and the turn it will be available.
        const existingInvestigation = getInvestigationLocalStorage(
            Number(patientId)
        );
        if (existingInvestigation && existingInvestigation[investigation]) {
            const { turn_ordered, turn_available } =
                existingInvestigation[investigation];
            setModalData({
                turnOrdered: turn_ordered,
                turnAvailable: turn_available,
            });
            setModalOpen(true);
            return false;
        }

        // Make the API call to post the investigation
        const jwt = getJwt();
        if (!jwt) {
            console.error("JWT not found in local storage");
            return false;
        }
        const response = await postInvestigationApiCall(jwt, payload);

        if (response.status === 201) {
            turnDependencies.updateTurns(3);

            setInvestigationLocalStorage(patientId, response.data!);

            // Update the notes data in local storage
            updateInvestigationDocumentationToLocalStorage(
                patientId,
                turnCount,
                response.data!
            );

            // Re-fetch the data to update the UI instantly
            fetchInvestigationData();
            refreshPage();
            return true;
        } else {
            console.error("Error posting investigation:", response.error);
            return false;
        }
    };

    return (
        <GamePageLayout
            pageTitle={pageTranslations.title}
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            patient={patient}
            translations={translations}
            documentationDependencies={sidebarDependencies}
            turnDependencies={turnDependencies}
            demographicsDependencies={demographicsDependencies}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
        >
            {isModalOpen && (
                <InvestigationAlreadyOrderedModal
                    onClose={() => setModalOpen(false)}
                    turnOrdered={modalData.turnOrdered}
                    turnAvailable={modalData.turnAvailable}
                    currentTurn={turnCount}
                />
            )}
            <OrderInvestigationPageContainer>
                <OrderInvestigationContainer>
                    <OrderInvestigationForm
                        onSubmit={handleSubmit}
                        investigationTranslations={investigationTranslations}
                        priorityTranslations={priorityTranslations}
                    />
                    <OrderInvestigationDivider />
                    <OrderInvestigationResult
                        status={STATUS.PENDING}
                        data={pendingData}
                    />
                    <OrderInvestigationSpace />
                    <OrderInvestigationResult
                        status={STATUS.AVAILABLE}
                        data={availableData}
                    />
                </OrderInvestigationContainer>
            </OrderInvestigationPageContainer>
        </GamePageLayout>
    );
};

/**
 * OrderInvestigationPage with dependencies required
 */
export const OrderInvestigationPage =
    withGameDependencies<OrderInvestigationPageProps>(
        OrderInvestigationPageBase
    );
