import {
    noteData,
    bloodsDocs,
    individualInvestigationData,
    IndividualTreatmentData,
    drugChartData,
    formEntryData,
    formsData,
    consultationData,
    additionalTestsData,
    additionalTest,
    investigationData, individualConsultationData
} from "./interface";
import { getSpecificPatientData, updatePatientData } from "./core";

// Function signature type for setting documentation to local storage
export type SetDocumentationToLocalStorageType = (
    patient_id: number,
    newDocumentationData: noteData
) => void;

/*
Set the documentation data for a patient in localStorage.
*/
export function setDocumentationToLocalStorage(
    patient_id: number,
    newDocumentationData: noteData
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }
    // Update the patient's note data
    patientData.notes = newDocumentationData;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}

// Function signature type for getting documentation from local storage
export type GetAllDocumentationFromLocalStorageType = (
    patient_id: number
) => noteData | null;

/*
Get the documentation data for a patient from localStorage.
*/
export function getAllDocumentationFromLocalStorage(
    patient_id: number
): noteData | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // Return the documentation data, or null if no data is found
    return patientData?.notes || null;
}

// Function signature type for updating blood documentation in local storage
export type UpdateBloodDocumentationToLocalStorageType = (
    patient_id: number,
    updatedBloodData: { [turn: string]: bloodsDocs }
) => void;

/*
Update the blood documentation for a patient in localStorage.
 */
export function updateBloodDocumentationToLocalStorage(
    patient_id: number,
    updatedBloodData: { [turn: string]: bloodsDocs }
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has note data, initialize if none
    let existingNotes = patientData.notes || {
        additional_tests: {},
        bloods: {},
        consultations: {},
        documentation: {},
        drugs: {},
        exams: {},
        forms: {},
        investigations: {},
        treatments: {},
        patient_id: patient_id,
    };

    // Add or update the specific blood documentation
    existingNotes.bloods = updatedBloodData || {};

    // Update the patient's note data
    patientData.notes = existingNotes;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}

// Function signature type for updating investigation documentation in local storage

export type UpdateInvestigationDocumentationToLocalStorageType = (
    patient_id: number,
    turn: number,
    updatedInvestigationData: individualInvestigationData
) => void;

/*
Update the investigation documentation for a patient in localStorage.
 */
export function updateInvestigationDocumentationToLocalStorage(
    patient_id: number,
    turn: number,
    updatedInvestigationData: individualInvestigationData
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has note data, initialize if none
    let existingNotes = patientData.notes || {
        additional_tests: {},
        bloods: {},
        consultations: {},
        documentation: {},
        drugs: {},
        exams: {},
        forms: {},
        investigations: {},
        treatments: {},
        patient_id: patient_id,
    };

    // Add or update the specific investigation documentation
    existingNotes.investigations[turn] = updatedInvestigationData || {};

    console.log("Updated notes", existingNotes);

    // Update the patient's note data
    patientData.notes = existingNotes;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}

//Function signature type for updating treatments documentation in local storage
export type UpdateTreatmentsDocumentationToLocalStorageType = (
    patient_id: number,
    turn: number,
    updatedTreatmentData: IndividualTreatmentData
) => void;

/*
Update the treatments documentation for a patient in localStorage.
 */
export function updateTreatmentsDocumentationToLocalStorage(
    patient_id: number,
    turn: number,
    updatedTreatmentData: IndividualTreatmentData
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has note data, initialize if none
    let existingNotes = patientData.notes || {
        additional_tests: {},
        bloods: {},
        consultations: {},
        documentation: {},
        drugs: {},
        exams: {},
        forms: {},
        investigations: {},
        treatments: {},
        patient_id: patient_id,
    };

    // Add or update the specific treatment documentation
    existingNotes.treatments[turn] = updatedTreatmentData || {};

    // Update the patient's note data
    patientData.notes = existingNotes;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}

// Function signature type for updating drugs documentation in local storage
export type UpdateDrugsDocumentationToLocalStorageType = (
    patient_id: number,
    updatedDrugData: drugChartData
) => void;

/*
Update the drugs documentation for a patient in localStorage.
 */
export function updateDrugsDocumentationToLocalStorage(
    patient_id: number,
    updatedDrugData: drugChartData
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has note data, initialize if none
    let existingNotes = patientData.notes || {
        additional_tests: {},
        bloods: {},
        consultations: {},
        documentation: {},
        drugs: {},
        exams: {},
        forms: {},
        investigations: {},
        treatments: {},
        patient_id: patient_id,
    };

    // Add or update the specific drug documentation
    existingNotes.drugs = updatedDrugData || {};

    // Update the patient's note data
    patientData.notes = existingNotes;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}

export type UpdateFormDocumentationToLocalStorageType = (
    patient_id: number,
    updatedFormData: formsData
) => void;

export function updateFormDocumentationToLocalStorage(
    patient_id: number,
    updatedFormData: formsData
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has note data, initialize if none
    let existingNotes = patientData.notes || {
        additional_tests: {},
        bloods: {},
        consultations: {},
        documentation: {},
        drugs: {},
        exams: {},
        forms: {},
        investigations: {},
        treatments: {},
        patient_id: patient_id,
    };

    // Add or update the specific form documentation
    existingNotes.forms = updatedFormData || {};

    // Update the patient's note data
    patientData.notes = existingNotes;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}

export type UpdateConsultationDocumentationToLocalStorageType = (
    patient_id: number,
    turn: number,
    updatedConsultationData: individualConsultationData
) => void;

export function updateConsultationDocumentationToLocalStorage(
    patient_id: number,
    turn: number,
    updatedConsultationData: individualConsultationData
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has note data, initialize if none
    let existingNotes = patientData.notes || {
        additional_tests: {},
        bloods: {},
        consultations: {},
        documentation: {},
        drugs: {},
        exams: {},
        forms: {},
        investigations: {},
        treatments: {},
        patient_id: patient_id,
    };

    console.log("Existing notes", existingNotes);
    console.log("Updated consultation data", updatedConsultationData);

    // Add or update the specific consultation documentation
    existingNotes.consultations[turn] = updatedConsultationData || {};

    // Update the patient's note data
    patientData.notes = existingNotes;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}

export type UpdateAdditionalTestsDocumentationToLocalStorageType = (
    patient_id: number,
    turn: number,
    updatedAdditionalTestsData: additionalTest
) => void;

export function updateAdditionalTestsDocumentationToLocalStorage(
    patient_id: number,
    turn: number,
    updatedAdditionalTestsData: additionalTest
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has note data, initialize if none
    let existingNotes = patientData.notes || {
        additional_tests: {},
        bloods: {},
        consultations: {},
        documentation: {},
        drugs: {},
        exams: {},
        forms: {},
        investigations: {},
        treatments: {},
        patient_id: patient_id,
    };

    // Add or update the specific additional test documentation
    existingNotes.additional_tests[turn] = updatedAdditionalTestsData || {};

    // Update the patient's note data
    patientData.notes = existingNotes;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}

// Function signature type for updating notes documetnation
export type UpdateNotesDocumentationToLocalStorageType = (
    patient_id: number,
    updatedNotesData: noteData["documentation"]
) => void;

/*
Update the notes documentation for a patient in localStorage.
 */
export function updateNotesDocumentationToLocalStorage(
    patient_id: number,
    updatedNotesData: noteData["documentation"]
): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has note data, initialize if none
    let existingNotes = patientData.notes || {
        additional_tests: {},
        bloods: {},
        consultations: {},
        documentation: {},
        drugs: {},
        exams: {},
        forms: {},
        investigations: {},
        treatments: {},
        patient_id: patient_id,
    };

    // Add or update the specific additional test documentation
    existingNotes.documentation = updatedNotesData || {};

    // Update the patient's note data
    patientData.notes = existingNotes;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}
