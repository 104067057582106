import styled from "@emotion/styled";

/** Global Styles **/
export const ADO_BLUE = "#1264a3";
export const BTN_COLOR = "#173B5E";
export const WHITE_BG = "#fff";

/** Banners Styles **/
export const PartnersBannerSectionContainer = styled.section`
    display: flex;
    padding: 30px;
    background-color: #e0f2fe;
    justify-content: space-around;
`;

export const PartnersBannerSectionContentContainer = styled.div`
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 20px;
`;

export const PartnersBannerSectionContentTitle = styled.h1`
    font-size: 47px;
    font-weight: 700;
    line-height: 4rem;
`;

export const PartnersBannerSectionContentDescription = styled.p`
    font-size: 14px;
    font-weight: 600;
`;

export const PartnersBannerSectionContentSubTitle = styled.p`
    font-size: 17px;
    font-weight: 500;
    width: 34rem;
    margin-top: 10px;
`;

export const PartnersBannerSectionContentSmallText = styled.p`
    font-size: 13px;
    font-weight: 500;
    margin-top: 15px;
`;

export const PartnersBannerSectionContentButton = styled.button`
    width: 90%;
    border: none;
    outline: none;
    background-color: ${BTN_COLOR};
    color: white;
    padding: 20px 30px;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
`;

export const PartnersBannerSectionContentLink = styled.span`
    color: ${ADO_BLUE};
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const PartnersBannerSectionImageContainer = styled.div`
    width: 600px;
    height: 434px;
`;

export const PartnersBannerSectionImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
`;

/** Benefits Styles **/
export const PartnersBenefitsSectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem 0px;
    gap: 4rem;
    border-bottom: 0.1rem solid #ebeaeb;
`;

export const PartnersBenefitsSectionContentContainer = styled.div`
    text-align: center;
`;

export const PartnersBenefitsSectionContentTitle = styled.h1`
    font-size: 1.8em;
    text-transform: capitalize;
`;

export const PartnersBenefitsSectionContentDescription = styled.p`
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
`;

export const PartnersBenefitsCardContainer = styled.div`
    display: flex;
    gap: 5.8rem;
    margin-top: 2rem;

    svg {
        font-size: 45px;
    }
`;

export const PartnersBenefitsCard = styled.div`
    width: 20rem;
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
        font-size: 16px;
        font-weight: 600;
    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
`;

/** Meet Ours Styles **/
export const PartnersMeetOursSectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 2rem;
    border-bottom: 0.1rem solid #ebeaeb;
`;

export const PartnersMeetOursSectionContentContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 50px;
`;

export const PartnersMeetOursSectionContentTitle = styled.h1`
    font-size: 1.8em;
    text-align: center;
`;

export const PartnersMeetOursSectionContentDescription = styled.p`
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    line-height: 1.5;
`;

export const PartnersMeetOursSectionContentButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 0.5rem;

    svg {
        color: ${ADO_BLUE};
        font-size: 20px;
        margin-left: 0.4rem;
        font-weight: 100;
    }
`;

export const PartnersMeetOursSectionContentButton = styled.button`
    border: none;
    outline: none;
    background: transparent;
    color: ${ADO_BLUE};
    font-size: 1.1rem;
    cursor: pointer;

    &::after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background: ${ADO_BLUE};
        transition: width 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover::after {
        width: 100%;
    }
`;
export const PartnersMeetOursSectionGridContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin: 0 auto;
`;

export const PartnersMeetOursSectionGridItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    min-height: 420px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
    padding: 10px;
    position: relative;
    z-index: 1;
    transition: transform 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
    border-radius: 2px;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }

    svg {
        color: ${ADO_BLUE};
        font-size: x-large;
        align-self: flex-end;
    }
`;

export const PartnersMeetOursSectionGridItemImageContainer = styled.div<{
    color: string;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 11.25rem;
    background-color: ${({ color }) => color};

    img {
        width: 90%;
        height: 90%;
        object-fit: contain;
    }
`;

export const PartnersMeetOursSectionGridItemTitle = styled.h1`
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0.8rem 0;
`;

export const PartnersMeetOursSectionGridItemDescription = styled.p`
    font-size: 0.875rem;
    color: #454245;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.1px;
`;

export const PartnersMeetOursSectionGridItemButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 4rem auto;
`;

export const PartnersMeetOursSectionGridItemButton = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    padding: 1.3rem 2.4rem;
    border-radius: 5px;
    color: ${BTN_COLOR};
    transition: 0.2s ease-in-out;
    fill: ${BTN_COLOR};
    box-shadow: inset 0 0 0 1px ${BTN_COLOR};
    margin-top: 3rem;
    cursor: pointer;

    &:hover {
        box-shadow: inset 0 0 0 2px ${BTN_COLOR};
    }
`;

export const CurrentUsersSectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 2rem;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  padding: 4em 0;
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`;