import styled from "@emotion/styled";

export const NewClassLeftPanelWrapper = styled.div`
    font-family: "Helvetica", Arial, sans-serif;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    width: 100%;
    .tabContent {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: stretch;
        justify-content: stretch;
        > * {
            width: 100%;
        }
    }
`;
