
import styled from "@emotion/styled";

export const PageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding: 1rem 0;

    h1 {
        font-size: 1.5rem;
        margin: 0;
    }
`;

export const CenteredTableContainer = styled.div`
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin: 1rem auto;
    width: 100%;
    max-width: 1400px;
`;

export const ActionButton = styled.button`
    background: transparent;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 0.875rem;

    &.lock-button {
        color: #e53e3e;
        border-color: #e53e3e;

        &:hover {
            background: #fff5f5;
        }
    }
`;

export const ReviewLink = styled.a`
    color: #3182ce;
    text-decoration: none;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: all 0.2s;

    &:hover {
        background: #ebf8ff;
    }
`;

export const StatusIcon = styled.div`
    color: #718096;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export const ErrorMessage = styled.div`
    color: #e53e3e;
    background: #fff5f5;
    border: 1px solid #fed7d7;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
`;

export const LoadingIndicator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    color: #718096;
`;

export const StatusContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

export const ClassCode = styled.span`
    background: #0084c7;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: medium;
`;