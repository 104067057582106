// External Imports
import React, { useState } from "react";
import { useParams } from "react-router-dom";

// Internal Imports
import { ExamPanel } from "./components/ExamPanel/ExamPanel";
import { ExamPageStyle } from "./ExamPage.styles";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";

// Dependency Injection for exam page only
import { PostExamType } from "../../../core/ServerApiLayer/VOne/patients/postExam";
import {
    SetToLocalStorageType,
    GetExamDataFromLocalStorageType,
} from "../../../core/LocalStorage/patients/examination";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Translation imports
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { ExamTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { ExamTypesTranslations } from "../../../core/translations/examinations/types";
import { enExamTranslations } from "../../../core/translations/examinations/en";
import { deExamTranslations } from "../../../core/translations/examinations/de";

// Define the translations for the exam page
const TRANSLATIONS: Record<SupportedLanguage, ExamTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

// Define EXAMINATION TYPE translations
const EXAM_TRANSLATIONS: Record<SupportedLanguage, ExamTypesTranslations> = {
    en: enExamTranslations,
    de: deExamTranslations,
};

/**
 * Interface for the ExamPage component
 */
interface ExamPageProps extends WithPatientProps {
    postExamApiCall: PostExamType;
    setExamLocalStorage: SetToLocalStorageType;
    getExamLocalStorage: GetExamDataFromLocalStorageType;
}

type CombinedProps = ExamPageProps & WithGameDependenciesProps;

/**
 * ExamPage Component
 *
 * This is the ExamPage component. This is the main page for the student
 * to examine the patient. This page displays the different exams that the
 * student can perform on the patient.
 */
const ExamPageBase: React.FC<CombinedProps> = ({
    postExamApiCall,
    setExamLocalStorage,
    getExamLocalStorage,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount();
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Translations for the ExamPage
    const { language } = useLanguage();
    const pageTranslations = TRANSLATIONS[language];
    const examTranslations = EXAM_TRANSLATIONS[language];

    // State to force a re-render when an exam is marked as examined
    const [, setForceUpdate] = useState(false);

    // Force a re-render when an exam is marked as examined
    const onExaminedUpdate = () => {
        setForceUpdate((prev) => !prev);
    };

    const genderExam = patient?.sex || "Male";

    return (
        <GamePageLayout
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            patient={patient}
            translations={translations}
            documentationDependencies={sidebarDependencies}
            turnDependencies={turnDependencies}
            demographicsDependencies={demographicsDependencies}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
        >
            <ExamPageStyle>
                <ExamPanel
                    // @ts-ignore
                    gender={genderExam}
                    // @ts-ignore
                    patientId={parseInt(patientId)}
                    postExamApiCall={postExamApiCall}
                    setExamLocalStorage={setExamLocalStorage}
                    getExamLocalStorage={getExamLocalStorage}
                    onExaminedUpdate={onExaminedUpdate}
                    translations={pageTranslations}
                    examTranslations={examTranslations}
                    turnDependencies={turnDependencies}
                    getJwt={getJwt}
                />
            </ExamPageStyle>
        </GamePageLayout>
    );
};

export const ExamPage = withGameDependencies<ExamPageProps>(ExamPageBase);
