import { individualInvestigationData, investigationData } from "./interface";
import { getSpecificPatientData, updatePatientData} from "./core";


// Function signature type for setting to local storage
export type SetToLocalStorageType = (patient_id: number, newInvestigationData: individualInvestigationData) => void;


/*
Set the investigation data for a patient in localStorage.
*/
export function setToLocalStorage(patient_id: number, newInvestigationData: individualInvestigationData): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has investigation data, initialize if none
    let existingInvestigations = patientData.investigation || {};

    // Get keys of the new investigation data
    const investigationType = newInvestigationData.investigation;

    // Add or update the specific investigation type
    existingInvestigations[investigationType] = newInvestigationData;

    // Update the patient's investigation data
    patientData.investigation = existingInvestigations;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}



// Function signature type for getting from local storage
export type GetInvestigationDataFromLocalStorageType = (patient_id: number) => investigationData | null;


// Function to get the investigation data for a patient from localStorage
export function getInvestigationDataFromLocalStorage(patient_id: number): investigationData | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // Return the investigation data, or null if no data is found
    return patientData?.investigation || null;
}


/*
Type for the filtered investigation data.
 */
export type FilteredInvestigationData = {
    available: individualInvestigationData[];
    pending: individualInvestigationData[];
}


// Function signature type for filtering investigation data
export type FilterInvestigationDataType = (patient_id: number, turn: number) => FilteredInvestigationData;

export function filterInvestigationData(patient_id: number, turn: number): FilteredInvestigationData {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return { available: [], pending: [] };
    }

    // Check if the patient already has investigation data, initialize if none
    let existingInvestigations = patientData.investigation || {};

    // Get the keys of the investigation data
    const investigationKeys = Object.keys(existingInvestigations);

    // Filter the investigation data based on the turn
    const availableData: individualInvestigationData[] = [];
    const pendingData: individualInvestigationData[] = [];

    investigationKeys.forEach((key) => {
        const investigation = existingInvestigations[key];
        if (investigation.turn_available <= turn) {
            availableData.push(investigation);
        } else {
            pendingData.push(investigation);
        }
    });

    return { available: availableData, pending: pendingData };
}