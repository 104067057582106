// External Imports
import React, { ChangeEvent, useState, useEffect } from 'react';
import { FaInfo } from 'react-icons/fa';

// Internal Imports
import { CentralFormPanel, NewGameInput, Input, Paragraph, ErrorMessage } from './../../joinClassPage/NewGameFormComponent/NewGameFormComponent.styles';
import WebsiteButton from '../../../../components/core/button/WebsiteButton';
import Tooltip from '../../../../components/core/tooltip/Tooltip';
import { TooltipIcon } from "../../../../components/core/tooltip/Tooltip.styles";
import { StartGameTranslations } from "../translations/types";

/*
The props for the StartGameFormComponent.

@prop error: string - the error message to be displayed
@prop failure: boolean - if the error message should be displayed
 */
export interface NewGameFormProps {
    error: string;
    failure: boolean;
    HandleNewGame: (data: StartGameState) => void;
    translations: StartGameTranslations;
}


/*
The state for the StartGameFormComponent (data to be sent to backend).

@prop code: string - the code of the new game
 */
export interface StartGameState {
    name: string;
}


/*
The state for the StartGameFormComponent.

@prop error: string - the error message to be displayed
@prop failure: boolean - if the error message should be displayed
 */
export interface ComponentState {
    error: string;
    failure: boolean;
}

/**
 * The Start Game Component
 * 
 * This component is used to display the form for the student to enter a name for the game. We
 * must have error handling for the form input, as the game may not be able to be created.
 * 
 * From this component there will be an API call to the backend to create the game.
 */
export const StartGameFormComponent: React.FC<NewGameFormProps> = ({
    HandleNewGame, error, failure, translations
}) => {
    // Set the state for the component
    const [newGameState, setNewGameState] = useState<StartGameState>({ name: "" });
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [state, setState] = useState<ComponentState>({
        error: error,
        failure: failure,
    });

    useEffect(() => {
        // If the name is not empty, then we can enable the button
        if (newGameState.name !== "") {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [newGameState.name]);

    // Handle the change of the input. This will update the state
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNewGameState({ ...newGameState, [e.target.name]: e.target.value.trim() });
    };

    // Handle the submission of the form
    const handleSubmit = (e: React.FormEvent) => {
        if (!isButtonDisabled && !failure) {
            HandleNewGame(newGameState);
        }
    };

    // Handle button click
    const handleButtonClick = (e: any) => {
        e.preventDefault();
        handleSubmit(e);
    }


    return (
        <CentralFormPanel onSubmit={handleSubmit} failure={state.failure}>
            {
                <>
                    <Paragraph>{translations.enterName}
                        <Tooltip placement={"right-start"} tooltip={
                            <p>
                                {translations.gameAnonymised}
                            </p>
                        }>
                            <TooltipIcon>
                                <FaInfo size={16} />
                            </TooltipIcon>
                        </Tooltip>
                    </Paragraph>
                    <NewGameInput>
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            placeholder={translations.placeholder}
                            onChange={handleChange}
                            value={newGameState.name} />

                        <WebsiteButton id="submitSessionButton" type="submit" disabled={isButtonDisabled} onClick={handleButtonClick}>{translations.startGame}</WebsiteButton>
                    </NewGameInput>
                </>
            }

            {failure && <ErrorMessage id="ErrorMessage">{error}</ErrorMessage>}
        </CentralFormPanel>
    );
};