import styled from '@emotion/styled';

export const PageMain = styled.div`
    width: 100%;
`;

export const FormSection = styled.div`
    display: flex;
    flex-direction: column; 
    h1 {
    margin-bottom: 50px; /* Add some margin in bottom between title and form */
}
`;