import { PricingTranslations } from "./types";

export const deTranslations: PricingTranslations = {
    section: {
        title: "Preispläne für alle",
        description: "Wählen Sie einen günstigen Plan mit den besten Funktionen - ob Sie Medizinstudent:in auf der Suche nach Verbesserung, Assistenzarzt/-ärztin in der Prüfungsvorbereitung oder eine medizinische Fakultät sind, die interaktive Werkzeuge in ihren Lehrplan aufnehmen möchte",
        toggleMonthly: "Monatlich",
        toggleYearly: "Jährlich",
        perMonth: "Monat",
        perYear: "Jahr"
    },
    plans: {
        individual: {
            title: "Einzelplan",
            details: "Ein Plan, der Ihnen unbegrenzte Übungsfälle in Ihrem eigenen Tempo ermöglicht und das Lernen an Ihre individuellen Ziele anpasst",
            features: [
                "52 Krankheiten",
                "Basis-Analysen",
                "Chatbot-Patienten",
                "Zugang zu Leitlinien"
            ]
        },
        team: {
            title: "Institutionsplan",
            details: "Ein Plan, der Ihren Studierenden unbegrenzte Übungsfälle ermöglicht und Ihnen die Kontrolle über deren Lernfortschritt gibt",
            features: [
                "52 Krankheiten",
                "Chatbot-Patienten",
                "Erweiterte Analysen",
                "Forscher-Erweiterung"
            ]
        }
    }
};