import type { Placement } from "@floating-ui/react";
import {
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useMergeRefs,
    useRole,
} from "@floating-ui/react";
import * as React from "react";
import { TooltipTriggerButton } from "./Tooltip.styles";

/**
 * TooltipOptions interface.
 * @interface TooltipOptions
 * @description The options for the tooltip.
 * @property {boolean} initialOpen - Whether the tooltip should be initially open.
 * @property {Placement} placement - The placement of the tooltip.
 * @property {boolean} open - Whether the tooltip is open.
 * @property {(open: boolean) => void} onOpenChange - The callback for when the tooltip is opened or closed.
 */
interface TooltipOptions {
    initialOpen?: boolean;
    placement?: Placement;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

/**
 * Hook for creating a tooltip.
 * @param options - The options for the tooltip.
 * @returns The tooltip hook API.
 */
export function useTooltip({
    initialOpen = false,
    placement = "top",
    open: controlledOpen,
    onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                fallbackAxisSideDirection: "start",
            }),
            shift({ padding: 5 }),
        ],
    });

    const context = data.context;

    const hover = useHover(context, {
        move: false,
        enabled: controlledOpen == null,
    });
    const focus = useFocus(context, {
        enabled: controlledOpen == null,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: "tooltip" });

    const interactions = useInteractions([hover, focus, dismiss, role]);

    return React.useMemo(
        () => ({
            open,
            setOpen,
            ...interactions,
            ...data,
        }),
        [open, setOpen, interactions, data]
    );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

/**
 * Hook for accessing the tooltip context.
 * @returns The tooltip context.
 * @throws Error if the Tooltip components are not wrapped in a Tooltip component.
 */
export const useTooltipContext = () => {
    const context = React.useContext(TooltipContext);

    if (context == null) {
        throw new Error("Tooltip components must be wrapped in <Tooltip />");
    }

    return context;
};

/**
 * Core component for creating a tooltip.
 * @param children - The content of the tooltip.
 * @param options - The options for the tooltip.
 * @returns The tooltip component.
 */
export function TooltipCore({
    children,
    ...options
}: { children: React.ReactNode } & TooltipOptions) {
    const tooltip = useTooltip(options);
    return (
        <TooltipContext.Provider value={tooltip}>
            {children}
        </TooltipContext.Provider>
    );
}

/**
 * Trigger component for the tooltip.
 * @param children - The content of the trigger.
 * @param asChild - Whether the trigger should be treated as a child element.
 * @param props - Additional props for the trigger element.
 * @returns The tooltip trigger component.
 */
export const TooltipTrigger = React.forwardRef<
    HTMLElement,
    React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
    const context = useTooltipContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    if (asChild && React.isValidElement(children)) {
        return React.cloneElement(
            children,
            context.getReferenceProps({
                ref,
                ...props,
                ...children.props,
                "data-state": context.open ? "open" : "closed",
            })
        );
    }

    return (
        <TooltipTriggerButton
            ref={ref}
            data-state={context.open ? "open" : "closed"}
            {...context.getReferenceProps(props)}
        >
            {children}
        </TooltipTriggerButton>
    );
});

/**
 * Content component for the tooltip.
 * @param props - Additional props for the content element.
 * @returns The tooltip content component.
 */
export const TooltipContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ ...props }, propRef) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    return (
        <FloatingPortal>
            {context.open && (
                <div
                    ref={ref}
                    style={{
                        position: context.strategy,
                        top: context.y ?? 0,
                        left: context.x ?? 0,
                        visibility: context.x == null ? "hidden" : "visible",
                        backgroundColor: "white",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                        color: "black",
                        fontSize: "0.9em",
                        padding: ".2em .5em",
                        borderRadius: "3px",
                        width: "max-content",
                        maxWidth: "300px",
                        zIndex: 999999,
                        ...props.style,
                    }}
                    {...context.getFloatingProps(props)}
                />
            )}
        </FloatingPortal>
    );
});
