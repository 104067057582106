import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

export const ForgotPassword = styled.form`
    font-family: Inter, sans-serif; /* Include font family */
    max-width: 400px; /* Set max width of form section */
    margin: 0 auto; /* Set margin */
    border-radius: 8px; /* Set form border radius */
    div {
        margin-bottom: 15px; /* Add margin bottom between button and form field design */
    }
    input[type="email"] {
        font-size: 0.875rem; /* Set font size */
        line-height: 1.25rem; /* Set line height of email input field */
        width: 100%; /* Set the width of input field to show in full assigned area */
        padding: 8px; /* Set padding */
        border: 1px solid #ccc; /* Set border to email field */
        border-radius: 4px; /* Set border radius of input field */
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }
    button {
        width: 100%; /* Set the width of button field to show in full assigned area */
        padding: 10px; /* Set padding */
        border: none; /* Remove button border */
        border-radius: 4px; /* Set border radius of button field */
        background-color: #0284d2; /* Set background color */
        color: white; /* Set color */
        cursor: pointer; /* Change the cursor as pointer in button area */
        font-size: 16px; /* Set font size of text available in the button */
        font-weight: 600; /* Make the text bold */
        font-family: Inter, sans-serif; /* Include font family */
    }
`;

export const jiggle = keyframes`
     0% { transform: translateY(0); }
    25% { transform: translateY(-5px); }
    50% { transform: translateY(5px); }
    75% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
`;

export const JiggleAnimation = styled.div<{ animation: string }>`
    animation: ${({ animation }) => animation} 0.5s ease-in-out;
`;

export const SuccessClass = styled.div`
    text-align: center;
    p {
        color: green;
        font-weight: bold;
    }
`;

export const ForgetPasswordSuccessClassFormClass = styled.div<{
    animation: string;
}>`
    font-family: Inter, sans-serif; /* Include font family */
    max-width: 400px; /* Set max width of form section */
    margin: 0 auto; /* Set margin */
    border-radius: 8px; /* Set form border radius */
    div {
        margin-bottom: 15px; /* Add margin bottom between button and form field design */
    }
    input[type="email"] {
        font-size: 0.875rem; /* Set font size */
        line-height: 1.25rem; /* Set line height of email input field */
        width: 100%; /* Set the width of input field to show in full assigned area */
        padding: 8px; /* Set padding */
        border: 1px solid #ccc; /* Set border to email field */
        border-radius: 4px; /* Set border radius of input field */
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }
    button {
        width: 100%; /* Set the width of button field to show in full assigned area */
        padding: 10px; /* Set padding */
        border: none; /* Remove button border */
        border-radius: 4px; /* Set border radius of button field */
        background-color: #0284d2; /* Set background color */
        color: white; /* Set color */
        cursor: pointer; /* Change the cursor as pointer in button area */
        font-size: 16px; /* Set font size of text available in the button */
        font-weight: 600; /* Make the text bold */
        font-family: Inter, sans-serif; /* Include font family */
    }
    text-align: center;
    p {
        color: green;
        font-weight: bold;
    }
    animation: ${({ animation }) => animation} 0.5s ease-in-out;
`;

export const ForgetPasswordSuccessClass = styled.div`
    font-family: Inter, sans-serif; /* Include font family */
    max-width: 400px; /* Set max width of form section */
    margin: 0 auto; /* Set margin */
    border-radius: 8px; /* Set form border radius */
    div {
        margin-bottom: 15px; /* Add margin bottom between button and form field design */
    }
    input[type="email"] {
        font-size: 0.875rem; /* Set font size */
        line-height: 1.25rem; /* Set line height of email input field */
        width: 100%; /* Set the width of input field to show in full assigned area */
        padding: 8px; /* Set padding */
        border: 1px solid #ccc; /* Set border to email field */
        border-radius: 4px; /* Set border radius of input field */
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }
    button {
        width: 100%; /* Set the width of button field to show in full assigned area */
        padding: 10px; /* Set padding */
        border: none; /* Remove button border */
        border-radius: 4px; /* Set border radius of button field */
        background-color: #0284d2; /* Set background color */
        color: white; /* Set color */
        cursor: pointer; /* Change the cursor as pointer in button area */
        font-size: 16px; /* Set font size of text available in the button */
        font-weight: 600; /* Make the text bold */
        font-family: Inter, sans-serif; /* Include font family */
    }
    text-align: center;
    p {
        color: green;
        font-weight: bold;
    }
`;

export const ForgetPasswordFormClass = styled.form<{
    animation: string;
}>`
    font-family: Inter, sans-serif; /* Include font family */
    max-width: 400px; /* Set max width of form section */
    margin: 0 auto; /* Set margin */
    border-radius: 8px; /* Set form border radius */
    div {
        margin-bottom: 15px; /* Add margin bottom between button and form field design */
    }
    input[type="email"] {
        font-size: 0.875rem; /* Set font size */
        line-height: 1.25rem; /* Set line height of email input field */
        width: 100%; /* Set the width of input field to show in full assigned area */
        padding: 8px; /* Set padding */
        border: 1px solid #ccc; /* Set border to email field */
        border-radius: 4px; /* Set border radius of input field */
        box-sizing: border-box; /* Ensures padding doesn't affect overall width */
    }
    button {
        width: 100%; /* Set the width of button field to show in full assigned area */
        padding: 10px; /* Set padding */
        border: none; /* Remove button border */
        border-radius: 4px; /* Set border radius of button field */
        background-color: #0284d2; /* Set background color */
        color: white; /* Set color */
        cursor: pointer; /* Change the cursor as pointer in button area */
        font-size: 16px; /* Set font size of text available in the button */
        font-weight: 600; /* Make the text bold */
        font-family: Inter, sans-serif; /* Include font family */
    }
    animation: ${({ animation }) => animation} 0.5s ease-in-out;
`;

export const ErrorText = styled.p`
    color: red;
    font-size: 14px;
    margin-top: 10px;
`;

export const H2 = styled.h2``;
export const Image = styled.img``;
export const Paragraph = styled.p``;
export const DIV = styled.div``;
