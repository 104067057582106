import { ComponentProps } from "react";
import { Modal } from "../Modal";
import {
    IntroductionModalContainer,
    IntroductionModalContentContainer,
    IntroductionModalContentWrapper,
    IntroductionModalTitle,
    IntroductionModalText,
} from "./IntroductionModal.styles";
import { SupportedLanguage } from "../../../../core/translations/supportedLanguages";
import { IntroductionModalTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { useLanguage } from "../../../../core/translations/LanguageContext";

const IntroductionModal = (
    props: Omit<ComponentProps<typeof Modal>, "children">
) => {
    const TRANSLATIONS: Record<
        SupportedLanguage,
        IntroductionModalTranslations
    > = {
        en: enTranslations,
        de: deTranslations,
    };
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    return (
        <Modal {...props}>
            <IntroductionModalContainer>
                <IntroductionModalContentContainer>
                    <IntroductionModalTitle>
                        {translations.heading}
                    </IntroductionModalTitle>
                    <IntroductionModalContentWrapper>
                        {translations.paragraphs.map((paragraph, index) => (
                            <IntroductionModalText key={index}>
                                {paragraph}
                            </IntroductionModalText>
                        ))}
                    </IntroductionModalContentWrapper>
                </IntroductionModalContentContainer>
            </IntroductionModalContainer>
        </Modal>
    );
};

export default IntroductionModal;
