// External Imports
import React, { useState } from "react";

// Internal Imports
import { Modal } from "../Modal";
import {
    MovePatientModalContainer,
    MovePatientModalContentContainer,
    MovePatientModalContentWrapper,
    MovePatientModalHighlightText,
} from "./MovePatientModal.styles";
import SelectBox, { SelectOption } from "../../select-box/SelectBox";
import WebsiteButton from "../../button/WebsiteButton";

// Shared dependencies
import {
    DemographicsDependencies,
    GameBarLayoutTranslations,
} from "../../../../core/sharedDependencies/types";

/**
 * MovePatientModal Props.
 * @interface MovePatientModalProps
 * @property {() => void} onClose - The function to close the modal.
 */
interface MovePatientModalProps {
    patient: any;
    onClose: () => void;
    translations: GameBarLayoutTranslations;
    demographicsDependencies: DemographicsDependencies;
    refreshPage: () => void;
}

/**
 * MovePatientModal component.
 * @component MovePatientModal
 * @description A modal component for moving a patient.
 * @param {MovePatientModalProps} props - The component props.
 * @returns {JSX.Element} The rendered MovePatientModal component.
 */
export const MovePatientModal: React.FC<MovePatientModalProps> = ({
    patient,
    onClose,
    translations,
    demographicsDependencies,
    refreshPage,
}: MovePatientModalProps) => {
    // Restrict options to Resus and Majors only
    const moveOptions: SelectOption[] = [
        { label: translations.location.Resus, value: "Resus" },
        { label: translations.location.Majors, value: "Majors" },
    ];

    const [selectedOption, setSelectedOption] = useState<SelectOption>({
        label: translations.gameBar.movePatientModal.placeholder,
        value: "",
    });

    const handleMovePatient = async () => {
        if (selectedOption.value) {
            try {
                await demographicsDependencies.updateLocation(
                    patient.patientId,
                    selectedOption.value
                );
                refreshPage();
                onClose();
            } catch (error) {
                console.error("Failed to update patient location:", error);
            }
        }
    };

    return (
        <Modal width="30%" onRequestClose={onClose}>
            <MovePatientModalContainer>
                <MovePatientModalContentContainer>
                    <p>
                        {patient.name}{" "}
                        {
                            translations.gameBar.movePatientModal
                                .sentenceAboutLocation
                        }
                        {translations.location[patient.location]}.
                    </p>
                    <p>{translations.gameBar.movePatientModal.dischargeInfo}</p>
                    <MovePatientModalContentWrapper>
                        <SelectBox
                            options={moveOptions}
                            value={selectedOption}
                            onChange={setSelectedOption}
                        />
                    </MovePatientModalContentWrapper>
                    {selectedOption && (
                        <MovePatientModalHighlightText>
                            {translations.gameBar.movePatientModal.illegalMove}
                        </MovePatientModalHighlightText>
                    )}
                    <WebsiteButton
                        tag="button"
                        disabled={!selectedOption.value}
                        onClick={handleMovePatient}
                    >
                        {translations.gameBar.movePatientModal.movePatient}
                    </WebsiteButton>
                </MovePatientModalContentContainer>
            </MovePatientModalContainer>
        </Modal>
    );
};

export default MovePatientModal;
