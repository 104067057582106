import { guidesMap } from './guidesMap';
import { Guide } from './types';

/**
 * Loads a guide by slug and language
 */
export async function loadGuide(slug: string, language: string): Promise<Guide | null> {
    try {
        const guide = guidesMap[language]?.[slug];
        if (!guide) {
            console.error(`Guide ${slug} not found for language ${language}`);
            return null;
        }
        return guide;
    } catch (error) {
        console.error(`Error loading guide ${slug} for language ${language}:`, error);
        return null;
    }
}

/**
 * Loads all guides for a specific language
 */
export async function loadGuides(language: string): Promise<Guide[]> {
    try {
        const guidesForLanguage = guidesMap[language];
        if (!guidesForLanguage) {
            console.error(`No guides found for language ${language}`);
            return [];
        }

        // Convert the map of guides to an array
        return Object.values(guidesForLanguage) as Guide[];
    } catch (error) {
        console.error(`Error loading guides for language ${language}:`, error);
        return [];
    }
}
