import { Blog, BlogSection, BlogLanguage } from './types';

/**
 * Type guard for BlogLanguage
 */
export function isBlogLanguage(language: string): language is BlogLanguage {
    return ['en', 'de'].includes(language);
}

/**
 * Type guard for BlogSection
 */
export function isBlogSection(section: any): section is BlogSection {
    return (
        typeof section === 'object' &&
        typeof section.id === 'string' &&
        typeof section.title === 'string' &&
        typeof section.helpText === 'string' &&
        typeof section.hereText === 'string' &&
        typeof section.hereLink === 'string' &&
        typeof section.queryText === 'string' &&
        typeof section.queryEmail === 'string' &&
        Array.isArray(section.paragraphs) &&
        section.paragraphs.every((p: any) => typeof p === 'string') &&
        (section.image === undefined || typeof section.image === 'string')
    );
}

/**
 * Type guard for Blog
 */
export function isBlog(blog: any): blog is Blog {
    return (
        typeof blog === 'object' &&
        typeof blog.slug === 'string' &&
        isBlogLanguage(blog.language) &&
        typeof blog.title === 'string' &&
        typeof blog.featureImage === 'string' &&
        typeof blog.summary === 'string' &&
        Array.isArray(blog.sections) &&
        blog.sections.every(isBlogSection) &&
        (blog.meta === undefined || (
            typeof blog.meta === 'object' &&
            (blog.meta.author === undefined || typeof blog.meta.author === 'string') &&
            (blog.meta.publishDate === undefined || typeof blog.meta.publishDate === 'string') &&
            (blog.meta.category === undefined || typeof blog.meta.category === 'string')
        ))
    );
}