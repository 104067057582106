import { StartGameTranslations } from "./types";

export const deTranslations: StartGameTranslations = {
    loginExpired: "Bitte melden Sie sich erneut an. Die Anmeldung ist abgelaufen.",
    errorOccurred: "Ein Fehler ist aufgetreten: ",
    failedToStartGame: "Sitzung konnte nicht gestartet werden",
    enterName: "Bitte geben Sie einen Namen für Ihre Sitzung ein",
    gameAnonymised:
        "Sitzungen sind für den/die Lehrer:innen anonymisiert - geben Sie jedoch keine identifizierbaren Informationen an, wie Ihren Namen oder Ihre Schüler-ID, und den Namen der Sitzung, es sei denn, Sie beabsichtigen dies (zum Beispiel klinische Studie)",
    placeholder: "Kardiologie 101",
    startGame: "Start",
    loaderText: "Bitte warten Sie einen Moment."
};
