import { ResearchTranslations } from "./types";

export const enTranslations: ResearchTranslations = {
    pageTitle: "Research",
    labels: {
        published: "Published",
        preprint: "Preprint",
        inreview: "Under Review"
    },
    date: "February 2024"
};