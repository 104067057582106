import styled from "@emotion/styled";

export const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: 1240px) and (min-width: 1024px) {
    width: calc(70% + 4rem);
    margin-right: 0;
    transform: scale(1.5) translateX(20%);
  }
`;
export const PageContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  padding: 4em 0;
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`;
export const TextSection = styled.div`
  width: 100%;
  text-align: left;
  padding-right: 3em;
  @media (min-width: 1024px) {
    width: 40%;
  }
`;

export const Title = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.13em;
  color: #173b5e;
  @media (min-width: 640px) {
    font-size: 3rem;
  }
`;
export const Subtitle = styled.span`
  color: #3284d1;
`;
export const Description = styled.p`
  margin-top: 24px;
  max-width: 100%;
  font-size: 1rem;
  font-weight: regular;
  color: #173b5e;
  line-height: 1.7em;
  @media (min-width: 640px) {
    max-width: 100%;
  }
`;
export const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 12px;
  justify-content: start;
  max-width: 100%;
`;
export const ImageSection = styled.div`
  width: 60%;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;
