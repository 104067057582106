import styled from "@emotion/styled";

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
export const FieldLabel = styled.label`
    font-weight: 600;
    color: #555;
    margin-bottom: 10px;
    font-size: 16px;
    display: block;
`;
