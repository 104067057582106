// External Imports
import React, { useState } from "react";

// Internal Imports
// @ts-ignore
import { TableDataProps } from "../DrugChartLayout/DrugChartLayout";
import { DrugsTable, ListingLabels } from "./SelectedDrugsListing.styles";

/**
 * The props for the SelectedDrugsListing component.
 */
interface SelectedDrugsListingProps {
    initalTableData?: Array<TableDataProps>;
    translations?: any;
}

/**
 * The SelectedDrugsListing component. This component is used to display the selected drugs in a table.
 * @initalTableData: The initial state of the table (passed in from the parent node)
 */
const SelectedDrugsListing: React.FC<SelectedDrugsListingProps> = ({
    initalTableData,
    translations,
}) => {
    return (
        <>
            <ListingLabels>Prescribed Drugs</ListingLabels>
            <DrugsTable>
                <thead>
                    <tr>
                        <th>{translations.time}</th>
                        <th>{translations.drugName}</th>
                        <th>{translations.route}</th>
                        <th>{translations.dose}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        //@ts-ignore
                        initalTableData.map((row, index) => (
                            <tr key={index}>
                                <td>{row.turn}</td>
                                <td>{row.drugName}</td>
                                <td>{row.route}</td>
                                <td>{row.dose}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </DrugsTable>
        </>
    );
};

export default SelectedDrugsListing;
