import { ExamTranslations } from "./types";

export const enTranslations: ExamTranslations = {
    sideBar: {
        examined: "Examined",
        notExamined: "Not Examined",
    },
    contents: {
        loadingFindings: "Loading findings...",
        noFindingsAvailable: "No findings available",
        examinePatient: "Examine",
        findings: "Findings",
    },
};
