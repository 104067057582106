import { examData } from "./interface";
import { getSpecificPatientData, updatePatientData } from "./core";


// Function signature type for setting to local storage
export type SetToLocalStorageType = (patient_id: number, newExamData: examData) => void;

/*
Set the examination data for a patient in localStorage.
 */
export function setToLocalStorage(patient_id: number, newExamData: examData): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Check if the patient already has examination data, initialize if none
    let existingExams = patientData.examination || {};

    // Check if the exam type in newExamData already exists in the patient's existing exams
    const examType = newExamData.exam;  // The key is 'exam'

    if (!existingExams[examType]) {
        // If exam doesn't exist, add it
        existingExams[examType] = newExamData.examination_findings;
    } else {
        // Just pass. No need to update existing exam data

    }

    // Update the patient's exam data
    patientData.examination = existingExams;

    // Save the updated patient data back to localStorage
    updatePatientData(patient_id, patientData);
}


// Function signature type for getting from local storage
export type GetExamDataFromLocalStorageType = (patient_id: number) => examData | null;


// Function to get the examination data for a patient from localStorage
export function getExamDataFromLocalStorage(patient_id: number): examData | null {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);

    // Return the exam data, or null if no data is found
    return patientData?.examination || null;
}
