import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { DepartmentRole, InstitutionRole, RolesResponse } from "../../../../core/ServerApiLayer/VOne/auth/login";
// @ts-ignore
import { RoleCard, RoleCardBySelectedRoleCard, RoleChoiceForm, SubmitButton } from './RoleChoiceComponent.styles';


/*
What is returned to the parent component when the user submits their role choice.

@prop role: the role that the user chose to update their current auth session with
 */
export interface ChosenRole {
    role: InstitutionRole | DepartmentRole;
}


/*
The props passed into the component from the parent component.

@prop onSubmit: the function to call when the user submits their role choice which should hit the
                backend to update the user's role for the current auth session
@prop initialRoleChoicesData: the initial data to render the role choices which is the roles that
                              the user has to choose from
 */
export interface RoleChoiceProps {
    onSubmit: (data: ChosenRole) => void;
    initialRoleChoicesData?: RolesResponse;
    // TODO => add prop for loading status and, success status, and error status
}


export const RoleChoices: React.FC<RoleChoiceProps> = ({ onSubmit, initialRoleChoicesData }) => {

    // define the state for the selected role key so we can change the color of the selected role
    const [selectedRoleKey, setSelectedRoleKey] = useState<ChosenRole | null>(null);
    // define the state for the selected role so we can pass it to the parent component
    const [selectedRole, setSelectedRole] = useState(null);

    // call the function that was passed into the component with the selected role (on button click)
    const handleSubmit = () => {
        if (selectedRole) {
            onSubmit({ role: selectedRole });
        }
    };

    // update the selected role key and role when we click on a role card to select that role
    const handleRoleClick = (role: InstitutionRole | DepartmentRole, type: string) => {
        const compositeKey = `${type}-${role.id}`;
        // @ts-ignore
        setSelectedRoleKey(compositeKey);
        // @ts-ignore
        setSelectedRole(role);
    };

    // define the type of CSS class for the role card. If the role card is selected, then we add an
    // additional class to change the color of the card
    const getRoleCardClassName = (role: InstitutionRole | DepartmentRole, type: string) => {
        const compositeKey = `${type}-${role.id}`;
        // @ts-ignore
        if (compositeKey === selectedRoleKey) {
            return "RoleCardWithSelectedRoleCard";
        }
        return "RoleCard";
    };

    return (
        <RoleChoiceForm>
            {/*Render the choices only if there are roles to choose from*/}
            {initialRoleChoicesData && (
                <>
                    {/*Render the department roles only if there are department roles to choose from*/}
                    {initialRoleChoicesData.department_roles && initialRoleChoicesData.department_roles.length > 0 && (
                        <>
                            <h2>Department Roles</h2>
                            <div>
                                {initialRoleChoicesData.department_roles.map(role => (
                                    getRoleCardClassName(role, 'department') === "RoleCardWithSelectedRoleCard"?
                                        <RoleCardBySelectedRoleCard key={`department-${role.id}`}
                                        id={`department-${role.id}`}
                                        onClick={() => handleRoleClick(role, 'department')}>
                                       <p>{role.role_name}:</p>
                                       <p>{role.department_name}</p>
                                   </RoleCardBySelectedRoleCard>:
                                   <RoleCard key={`department-${role.id}`}
                                         id={`department-${role.id}`}
                                         className={getRoleCardClassName(role, 'department')}
                                         onClick={() => handleRoleClick(role, 'department')}>
                                        <p>{role.role_name}:</p>
                                        <p>{role.department_name}</p>
                                    </RoleCard>
                                    
                                    
                                ))}
                            </div>
                        </>
                    )}

                    {/*Render the institution roles only if there are roles to choose from*/}
                    {initialRoleChoicesData.institution_roles && initialRoleChoicesData.institution_roles.length > 0 && (
                        <>
                            <h2>Institution Roles</h2>
                            <div>
                                {initialRoleChoicesData.institution_roles.map(role => (
                                    <div key={`institution-${role.id}`}
                                         id={`institution-${role.id}`}
                                         className={getRoleCardClassName(role, 'institution')}
                                         onClick={() => handleRoleClick(role, 'institution')}>
                                        <p>{role.role_name}:</p>
                                        <p>{role.institution_name}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </>
            )}
            <SubmitButton onClick={handleSubmit}>Submit Choice</SubmitButton>
        </RoleChoiceForm>
    );
};
