import styled from '@emotion/styled';

export const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  justify-content: space-between;
`;

export const ProgressBar = styled.progress`
  width: 80%; /* Adjust the width if needed */
  height: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  appearance: none;
  border-radius: 5px;
  background-color: #f3f3f3;
  
  &::-webkit-progress-bar {
    background-color: #f3f3f3;
    border-radius: 5px;
  }

  &::-webkit-progress-value {
    background-color: rgb(51, 65, 85);
    border-radius: 5px;
  }

  &::-moz-progress-bar {
    background-color: rgb(51, 65, 85);
  }
`;

export const StartNumber = styled.span`
  font-size: 1rem;
  color: #718096;
`;

export const EndNumber = styled.span`
  font-size: 1rem;
  color: #718096;
`;

export const TransparentContainer = styled.div`
    position: absolute;
    padding: 15px;
    width: 20em;
    top: 1.6em;
    right: 1em;
    z-index: 100;
`;

export const PopupContainer = styled.div`
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1);
`;

export const PopupContent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
    padding: 20px;
    font-size: 0.875rem;
    color: #718096;
`;

export const ClockText = styled.span`
    color: #718096;
    font-size: 0.875rem;
    text-align: center;
`;
