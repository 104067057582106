// External Imports
import React, { useState } from "react";

// Internal Imports
import { Modal } from "../Modal";
import {
    DiagnosisModalButtonContainer,
    DiagnosisModalContainer,
    DiagnosisModalContentContainer,
    DiagnosisModalContentWrapper,
    DiagnosisModalTitle,
} from "./DiagnosisModal.styles";
import SelectBox, { SelectOption } from "../../select-box/SelectBox";
import {
    DIAGNOSIS_TYPES,
    DIAGNOSIS_TYPES_NAME,
} from "./config/DiagnosisModal.config";
import {
    onSelectOptionChange,
    onSelectOptionConversion,
} from "../../select-box/functions/SelectBox.functions";
import WebsiteButton from "../../button/WebsiteButton";

// Shared dependencies
import {
    GameBarLayoutTranslations,
    DemographicsDependencies,
} from "../../../../core/sharedDependencies/types";

/**
 * DiagnosisModal Props.
 * @interface DiagnosisModalProps
 * @property {() => void} onClose - The function to close the modal.
 * @property {number} patientId - The ID of the patient being diagnosed.
 * @property {GameBarLayoutTranslations} translations - The translations for the modal.
 * @property {DemographicsDependencies} demographicsDependencies - Dependencies for updating patient demographics.
 */
interface DiagnosisModalProps {
    onClose: () => void;
    patientId: number;
    translations: GameBarLayoutTranslations;
    demographicsDependencies: DemographicsDependencies;
    refreshPage: () => void;
}

/**
 * DiagnosisModal component.
 * @component DiagnosisModal
 * @description A modal component for diagnosing a patient.
 * @param {DiagnosisModalProps} props - The component props.
 * @returns {JSX.Element} The rendered DiagnosisModal component.
 */
export const DiagnosisModal: React.FC<DiagnosisModalProps> = ({
    onClose,
    patientId,
    translations,
    demographicsDependencies,
    refreshPage,
}: DiagnosisModalProps) => {
    const [diagnosis, setDiagnosis] = useState<SelectOption>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const diagnosisOptions = onSelectOptionConversion(
        DIAGNOSIS_TYPES,
        DIAGNOSIS_TYPES_NAME,
        true
    );

    /**
     * Handle saving the diagnosis
     * Updates the patient's diagnosis and closes the modal
     */
    const handleSaveDiagnosis = async () => {
        if (!diagnosis || isSubmitting) return;

        setIsSubmitting(true);

        try {
            // Wait for both operations to complete
            await Promise.all([
                demographicsDependencies.updateDiagnosis(
                    patientId,
                    diagnosis.value
                ),
                refreshPage(),
            ]);

            onClose();
        } catch (error) {
            console.error("Error updating diagnosis:", error);
            // You might want to add error handling UI here
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal width="35%" onRequestClose={onClose}>
            <DiagnosisModalContainer>
                <DiagnosisModalContentContainer>
                    <DiagnosisModalTitle>
                        {translations.gameBar.diagnosisModal.modalTitle}
                    </DiagnosisModalTitle>
                    <DiagnosisModalContentWrapper>
                        <SelectBox
                            value={[
                                diagnosis ? diagnosis : diagnosisOptions[0],
                            ]}
                            options={diagnosisOptions}
                            onChange={(value: SelectOption) =>
                                onSelectOptionChange(
                                    value,
                                    diagnosisOptions,
                                    setDiagnosis
                                )
                            }
                            placeholder={
                                translations.gameBar.diagnosisModal
                                    .selectPlaceholder
                            }
                        />

                        <DiagnosisModalButtonContainer>
                            <WebsiteButton
                                tag="button"
                                onClick={handleSaveDiagnosis}
                                disabled={isSubmitting}
                            >
                                {translations.gameBar.diagnosisModal.saveButton}
                            </WebsiteButton>
                        </DiagnosisModalButtonContainer>
                    </DiagnosisModalContentWrapper>
                </DiagnosisModalContentContainer>
            </DiagnosisModalContainer>
        </Modal>
    );
};
