import styled from "@emotion/styled";

// Main container that wraps the page layout
export const BloodOrderPageMain = styled.div`
  display: flex;
  padding: 10px;
  gap: 20px;
`;

// Left side panel styling
export const LeftSidePanel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

// Right side panel styling
export const RightSidePanel = styled.div`
  display: flex;
  width: 30%;
`;

// Accordion styling for collapsible sections
export const Accordion = styled.p`
  background-color: #334155;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 0.875rem;
  transition: 0.4s;
  font-family: sans-serif;
  margin: 10px 0px 0px 0px;
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;

  &:hover {
    background-color: #163b5d;
  }

  svg {
    position: absolute;
    right: 15px;
  }
`;

// Panel content that shows when the accordion is open
export const Panel = styled.div<{ isOpen: boolean }>`
    background-color: white;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px 18px;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    overflow: hidden;
    border: 1px solid #f5e9e9;

    div {
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding: 10px;
        border-radius: 4px;
        box-sizing: border-box;
        margin-bottom: 5px;
        width: 100%;
    }

    input[type="checkbox"] {
        accent-color: aliceblue;
        width: 18px;
        height: 18px;
    }
`;

// Accordion section container for wrapping each collapsible section
export const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;
