import React from "react";
import WebsiteButton from "../button/WebsiteButton";
import {
    ClearSubmitFormButtonContainer,
    ClearSubmitFormButtonContentContainer,
} from "./ClearSubmitFormButtons.styles";

interface ClearSubmitFormButtonsProps {
    isSubmitDisabled: boolean;
    submitText?: string;
    clearText?: string;
    onSubmit: () => void;
    onClear: () => void;
    RenderComponent: React.FC;
}

function ClearSubmitFormButtons({
    submitText,
                                    clearText,
    isSubmitDisabled,
    onClear,
    onSubmit,
    RenderComponent,
}: ClearSubmitFormButtonsProps) {
    return (
        <ClearSubmitFormButtonContainer>
            <ClearSubmitFormButtonContentContainer>
                {RenderComponent && <RenderComponent />}

                <WebsiteButton tag="button" secondary onClick={onClear}>
                    { clearText ?? "Clear"}
                </WebsiteButton>
                <WebsiteButton
                    tag="button"
                    disabled={isSubmitDisabled}
                    onClick={
                    // Submit the form and then clear the form
                    () => {
                        onSubmit();
                        onClear();
                    }}
                >
                    {submitText ?? "Submit"}
                </WebsiteButton>
            </ClearSubmitFormButtonContentContainer>
        </ClearSubmitFormButtonContainer>
    );
}

export default ClearSubmitFormButtons;
