import React from "react";

import { CentralButtonsPanel } from "./CentralButtonComponent.styles";
import WebsiteButton, { WebsiteButtonProps } from "../button/WebsiteButton";

/**
 * Props for the Button
 */
export interface ButtonProps {
    text: string;
    href: string;
    onClick?: () => void;
}

/**
 * Props for the CentralButtonComponent. This consists of the buttons to display
 */
export interface CentralButtonComponentProps {
    buttons: ButtonProps[];
}

/**
 * This component is used to display the two buttons in the center of the teacher front page
 * We pass in the buttons as props, as this is a generic component that can be used in other places
 * 
 * @param buttons - the buttons to display
 * @returns CentralButtonComponent
 */
export const CentralButtonComponent: React.FC<CentralButtonComponentProps> = ({ buttons }) => {
    return (
        <CentralButtonsPanel>
            {buttons.map((button, index) => (
                // Wrap each WebsiteButton in a div for spacing control
                <div key={index} style={{ marginBottom: index !== buttons.length - 1 ? '10px' : '0' }}>
                    <WebsiteButton href={button.href} secondary={index !== 0}>{button.text}</WebsiteButton>
                </div>
            ))}
        </CentralButtonsPanel>
    );
};