import { InvestigationPageTranslations } from './types';

export const deTranslations: InvestigationPageTranslations = {
    title: "Untersuchung",
    investigation: "Untersuchungen",
    priority: "Priorität",
    justification: "Begründung",
    submit: "Untersuchung bestellen",
    clear: "Löschen",
    pendingResults: "Ausstehende Ergebnisse",
    availableResults: "Verfügbare Ergebnisse",
    viewImage: "Bild anzeigen",
    viewReport: "Bericht anzeigen",
    noReason: "Kein Grund",
    investigationReport: "Untersuchungsbericht für",
    reasonForInvestigation: "Grund für Untersuchung",
    report: "Bericht:",
    imageAvailable: "Bild verfügbar:",
    clickToView: "Klicken Sie zum Anzeigen",
    notesSection: "Notizen Abschnitt",
    addAnyNotes: "Fügen Sie beliebige Notizen hinzu",
    save: "Speichern",
    review: "Überprüft",
    clickButtonToViewImage: "Klicken Sie auf die Schaltfläche, um das Bild anzuzeigen",
    timeOrdered: "Bestellzeit",
    getRadiologyHelp: "Holen Sie sich radiologische Hilfe",
}