import styled from "@emotion/styled";

export const DepartmentalDetailsTableContainer = styled.div`
    max-width: 80rem;
    max-height: 72vh;
    font-family: "Helvetica", Arial, sans-serif;
    padding: 0px 1rem;
    overflow-y: auto;

    th,
    td {
        text-align: center;
    }

    button {
        width: 100%;
    }
`;

export const DemographicsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.25rem;
    align-items: center;
`;

export const DepartmentalDetailsFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const DischargeFormIconContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
        color: green;
        font-size: 1rem;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    svg:hover {
        color: #0c4a6e;
    }
`;

export const ReviewButton = styled.div`
    color: #0284c7;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;

    &:hover {
        color: #0c4a6e;
    }
`;

export const DischargeButton = ReviewButton.withComponent("div");
