// External imports
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Internal imports
import {
    OrderTreatmentContainer,
    OrderTreatmentPageContainer,
} from "./OrderTreatment.styles";
import { OrderTreatmentForm } from "./components/OrderTreatmentForm/OrderTreatmentForm";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import { OrderTreatmentsResults } from "./components/OrderTreatmentResult/OrderTreatmentsResults";
import { IndividualTreatmentData } from "../../../core/LocalStorage/patients/interface";
import { OrderTreatmentDivider } from "./components/OrderTreatmentForm/OrderTreatmentForm.styles";
import TreatmentAlreadyOrderedModal from "../../../components/core/modal/treament-error-modal/TreatmentErrorModal";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Dependency Injection for Order Treatment Page only
import { PostTreatmentType } from "../../../core/ServerApiLayer/VOne/patients/postTreatment";
import {
    listOfTreatmentDataFromLocalStorageType,
    SetTreatmentToLocalStorageType,
    GetTreatmentDataFromLocalStorageType,
} from "../../../core/LocalStorage/patients/treatments";
import { UpdateTreatmentsDocumentationToLocalStorageType } from "../../../core/LocalStorage/patients/notes";

// Translation imports
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { TreatmentPageTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { TreatmentTypesTranslations } from "../../../core/translations/treatments/types";
import { enTreatmentTranslations } from "../../../core/translations/treatments/en";
import { deTreatmentTranslations } from "../../../core/translations/treatments/de";
import { PriorityTypesTranslations } from "../../../core/translations/priority/types";
import { dePriorityTranslations } from "../../../core/translations/priority/de";
import { enPriorityTranslations } from "../../../core/translations/priority/en";

// Define the translations for the treatment page
const TRANSLATIONS: Record<SupportedLanguage, TreatmentPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

// Define the translations for the treatment types
const TREATMENT_TRANSLATIONS: Record<
    SupportedLanguage,
    TreatmentTypesTranslations
> = {
    en: enTreatmentTranslations,
    de: deTreatmentTranslations,
};

// Define the translations for the priority types
const PRIORITY_TRANSLATIONS: Record<
    SupportedLanguage,
    PriorityTypesTranslations
> = {
    en: enPriorityTranslations,
    de: dePriorityTranslations,
};

/**
 * Dependency Injection for Order Treatment Page
 *
 * @param postTreatmentApiCall - The API call to post the treatment
 * @param setTreatmentLocalStorage - The function to set the treatment data to local storage
 * @param getTreatmentLocalStorage - The function to get the treatment data from local storage
 * @param listOfTreatmentDataFromLocalStorage - The function to get the list of treatment data from local storage
 * @param updateTreatmentDocumentationToLocalStorage - The function to update the notes in local storage
 */
interface OrderTreatmentProps extends WithPatientProps {
    postTreatmentApiCall: PostTreatmentType;
    setTreatmentLocalStorage: SetTreatmentToLocalStorageType;
    getTreatmentLocalStorage: GetTreatmentDataFromLocalStorageType;
    listOfTreatmentDataFromLocalStorage: listOfTreatmentDataFromLocalStorageType;
    updateTreatmentDocumentationToLocalStorage: UpdateTreatmentsDocumentationToLocalStorageType;
}

type CombinedProps = OrderTreatmentProps & WithGameDependenciesProps;

/**
 * OrderTreatmentPage
 *
 * @description Represents the treatment order page.
 * @returns {JSX.Element} The rendered OrderTreatment component.
 */
const OrderTreatmentPageBase: React.FC<CombinedProps> = ({
    // Treatement Order Page Dependencies
    postTreatmentApiCall,
    setTreatmentLocalStorage,
    getTreatmentLocalStorage,
    listOfTreatmentDataFromLocalStorage,
    updateTreatmentDocumentationToLocalStorage,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount();
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Get the translations
    const { language } = useLanguage();
    const treatmentTranslations = TREATMENT_TRANSLATIONS[language];
    const priorityTranslations = PRIORITY_TRANSLATIONS[language];
    const pageTranslations = TRANSLATIONS[language];

    // State of the treatment data
    const [data, setData] = useState<IndividualTreatmentData[]>([]);

    // Modal state
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({ turnOrdered: 0 });

    const fetchTreatmentData = () => {
        const savedData = listOfTreatmentDataFromLocalStorage(
            Number(patientId)
        );
        if (savedData) {
            setData(savedData);
        }
    };

    useEffect(() => {
        fetchTreatmentData();
    }, [patientId, listOfTreatmentDataFromLocalStorage]);

    const handleSubmit = async (
        treatment: string,
        priority: string,
        reason: string
    ): Promise<boolean> => {
        const payload = {
            turn: turnCount,
            patient_id: Number(patientId),
            treatment_type: {
                treatment,
                reason,
                priority: priority,
            },
        };

        const existingTreatment = getTreatmentLocalStorage(Number(patientId));
        if (existingTreatment && existingTreatment[treatment]) {
            // @ts-ignore
            const { turn_ordered } = existingTreatment[treatment];
            // @ts-ignore
            setModalData({ turnOrdered: turn_ordered });
            setModalOpen(true);
            return false;
        }

        const jwt = getJwt();
        if (!jwt) {
            console.error("JWT token not found");
            return false;
        }

        //@ts-ignore
        const response = await postTreatmentApiCall(jwt, payload);

        if (response.status === 201) {
            turnDependencies.updateTurns(3);

            setTreatmentLocalStorage(Number(patientId), response.data!);

            // Update the notes data in local storage
            updateTreatmentDocumentationToLocalStorage(
                patientId,
                turnCount,
                response.data!
            );

            fetchTreatmentData();
            return true;
        } else {
            console.error("Error posting treatment:", response.error);
            return false;
        }
    };

    return (
        <GamePageLayout
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            pageTitle={pageTranslations.pageTitle}
            patient={patient}
            translations={translations}
            documentationDependencies={sidebarDependencies}
            turnDependencies={turnDependencies}
            demographicsDependencies={demographicsDependencies}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
        >
            {isModalOpen && (
                <TreatmentAlreadyOrderedModal
                    onClose={() => setModalOpen(false)}
                    turnOrdered={modalData.turnOrdered}
                />
            )}
            <OrderTreatmentPageContainer>
                <OrderTreatmentContainer>
                    <OrderTreatmentForm
                        onSubmit={handleSubmit}
                        translations={pageTranslations}
                        treatmentTranslations={treatmentTranslations}
                        priorityTranslations={priorityTranslations}
                    />
                    <OrderTreatmentDivider />
                    <OrderTreatmentsResults
                        data={data}
                        translations={pageTranslations}
                        treatmentTranslations={treatmentTranslations}
                    />
                </OrderTreatmentContainer>
            </OrderTreatmentPageContainer>
        </GamePageLayout>
    );
};

export const OrderTreatmentPage = withGameDependencies<OrderTreatmentProps>(
    OrderTreatmentPageBase
);
