import styled from "@emotion/styled";

export const Form = styled.form`
`;
export const FormH3 = styled.h3`
    font-family: Inter, sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;
export const FormLabel = styled.label`
    font-family: Inter, sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const FormInputFieldArea = styled.div`
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
`
export const FormFieldSection = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    flex-direction: column;
    margin-top: 10px;
`
export const FormH4 = styled.h4`
    font-family: Inter, sans-serif;
    font-weight: 600;
    margin: 10px 0px;
`;
export const FormSelectField = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px; 
`;
export const FormSubmitButton = styled.button`
    background-color: #0284d2;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    color: white;
    width: 100%;
    &:disabled{
        background-color: #99a0aa;
        border: none;
        border-radius: 4px;
        padding: 10px;
        font-size: 14px;
        color: white;
        width: 100%;
    }
`;