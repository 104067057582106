import { ViewClassesTranslations } from './types';

export const deTranslations: ViewClassesTranslations = {
    title: "Klassen anzeigen",
    columns: {
        className: "Klassenname",
        status: "Status",
        studentsEnrolled: "Eingeschriebene Schüler",
        gamesPlayed: "Gespielte Spiele",
        dateCreated: "Erstellungsdatum",
        dateOpened: "Öffnungsdatum",
        dateClosed: "Schließungsdatum",
        review: "Überprüfung"
    },
    actions: {
        lock: "Sperren",
        review: "Überprüfen"
    },
    errors: {
        loadingFailed: "Klassen konnten nicht geladen werden",
        lockFailed: "Klasse konnte nicht gesperrt werden. Bitte versuchen Sie es erneut."
    },
    status: {
        locked: "Gesperrt"
    }
};