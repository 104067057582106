import { DiseaseTranslations } from "./types";

export const enDiseaseTranslations: DiseaseTranslations = {
    categories: {
        CARDIOVASCULAR: "Cardiovascular",
        RESPIRATORY: "Respiratory",
        NEUROLOGICAL: "Neurological",
        ENDOCRINOLOGY: "Endocrinology",
        UROLOGY: "Urology",
        GENERAL_SURGERY: "General Surgery",
        GASTROENTEROLOGY: "Gastroenterology",
        OBS_GYNAE: "Obstetrics and Gynaecology",
        ORTHOPAEDICS: "Orthopaedics",
        OTHER: "Other",
    },
    diseases: {
        STEMI_ANTERIOR: { name: "STEMI Anterior", description: "Heart attack affecting the front of the heart" },
        STEMI_POSTERIOR: { name: "STEMI Posterior", description: "Heart attack affecting the back of the heart" },
        NSTEMI: { name: "NSTEMI", description: "Non-ST elevation myocardial infarction" },
        ATRIAL_FIBRILLATION: { name: "Atrial Fibrillation", description: "Irregular heartbeat" },
        CONGESTIVE_HEART_FAILURE: { name: "Congestive Heart Failure", description: "Heart's inability to pump blood effectively" },
        ORTHOSTATIC_SYNCOPE: { name: "Orthostatic Syncope", description: "Fainting due to low blood pressure" },
        HYPERTENSIVE_CRISIS: { name: "Hypertensive Crisis", description: "Severe increase in blood pressure" },
        AVNRT: { name: "AVNRT", description: "Atrioventricular nodal reentrant tachycardia" },
        AORTIC_STENOSIS: { name: "Aortic Stenosis", description: "Narrowing of the aortic valve" },
        LEFT_SIDED_ENDOCARDITIS: { name: "Left-Sided Endocarditis", description: "Infection of the inner lining of the heart" },
        AORTIC_DISSECTION: { name: "Aortic Dissection", description: "Tear in the inner layer of the aorta" },
        AV_BLOCK_COMPLETE: { name: "Complete AV Block", description: "Complete block of electrical signals in the heart" },
        HYPERTENSIVE_EMERGENCY: { name: "Hypertensive Emergency", description: "Severe hypertension with organ damage" },
        PERIPHERAL_ARTERY_DISEASE: { name: "Peripheral Artery Disease", description: "Narrowed arteries reducing blood flow to limbs" },

        NON_INFECTIVE_EXACERBATION_OF_ASTHMA: { name: "Non-infective Asthma Exacerbation", description: "Worsening asthma without infection" },
        NON_INFECTIVE_EXACERBATION_OF_COPD: { name: "Non-infective COPD Exacerbation", description: "Worsening of COPD without infection" },
        INFECTIVE_EXACERBATION_OF_COPD: { name: "Infective COPD Exacerbation", description: "Worsening of COPD with infection" },
        COMMUNITY_ACQUIRED_PNEUMONIA: { name: "Community-Acquired Pneumonia", description: "Pneumonia acquired outside hospitals" },
        PULMONARY_EMBOLISM: { name: "Pulmonary Embolism", description: "Blood clot in the lung" },
        CHEST_SEPSIS: { name: "Chest Sepsis", description: "Infection spreading in the chest" },

        HYPERGLYCAEMIA: { name: "Hyperglycaemia", description: "High blood sugar" },
        HYPERKALEMIA: { name: "Hyperkalemia", description: "High potassium levels" },
        HYPERTHYROIDISM: { name: "Hyperthyroidism", description: "Overactive thyroid" },
        HYPOGLYCAEMIA: { name: "Hypoglycaemia", description: "Low blood sugar" },

        UROSEPSIS: { name: "Urosepsis", description: "Severe urinary tract infection" },
        ACUTE_URINARY_RETENTION: { name: "Acute Urinary Retention", description: "Sudden inability to urinate" },
        KIDNEY_STONES: { name: "Kidney Stones", description: "Solid masses in the kidneys" },

        ACUTE_APPENDICITIS: { name: "Acute Appendicitis", description: "Inflammation of the appendix" },
        ACUTE_CHOLECYSTITIS: { name: "Acute Cholecystitis", description: "Inflammation of the gallbladder" },

        ISCHAEMIC_STROKE: { name: "Ischaemic Stroke", description: "Stroke due to blood clot" },
        MIGRAINE: { name: "Migraine", description: "Severe headache" },
        SEIZURE: { name: "Seizure", description: "Sudden, uncontrolled electrical disturbance in the brain" },
        BENIGN_PAROXYSMAL_POSITIONAL_VERTIGO: { name: "BPPV", description: "Dizziness due to changes in head position" },
        SUBARACHNOID_HEMORRHAGE: { name: "Subarachnoid Hemorrhage", description: "Bleeding in the space around the brain" },
        SUBDURAL_HEMATOMA: { name: "Subdural Hematoma", description: "Blood collection between brain and skull" },
        TRANSIENT_ISCHAEMIC_ATTACK: { name: "TIA", description: "Temporary blockage of blood flow to the brain" },

        ACIDIC_REFLUX: { name: "Acidic Reflux", description: "Acid from stomach flows back into esophagus" },
        INFECTIVE_GASTROENTERITIS: { name: "Infective Gastroenteritis", description: "Stomach infection" },
        UPPER_GI_BLEED: { name: "Upper GI Bleed", description: "Bleeding in the upper gastrointestinal tract" },
        LOWER_GI_BLEEDING: { name: "Lower GI Bleed", description: "Bleeding in the lower gastrointestinal tract" },
        MESENTERIC_ISCHAEMIA: { name: "Mesenteric Ischaemia", description: "Lack of blood flow to intestines" },
        SIGMOID_DIVERTICULITIS: { name: "Sigmoid Diverticulitis", description: "Inflammation of small pouches in the digestive tract" },

        ECTOPIC_PREGNANCY: { name: "Ectopic Pregnancy", description: "Pregnancy outside the womb" },
        OVARIAN_TORSION: { name: "Ovarian Torsion", description: "Twisting of the ovary" },
        PREECLAMPSIA: { name: "Preeclampsia", description: "High blood pressure in pregnancy" },

        KNEE_OSTEOARTHRITIS: { name: "Knee Osteoarthritis", description: "Degeneration of knee joint cartilage" },
        HIP_FRACTURE: { name: "Hip Fracture", description: "Break in the hip bone" },
        HIP_OSTEOARTHRITIS: { name: "Hip Osteoarthritis", description: "Degeneration of hip joint cartilage" },
        INFECTION_OF_PROSTHETIC_JOINT: { name: "Prosthetic Joint Infection", description: "Infection in an artificial joint" },

        MUSCULOSKELETAL_CHEST_PAIN: { name: "Musculoskeletal Chest Pain", description: "Chest pain due to muscle or bone issues" },
    }
};
